export const LOGIN = '/login';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_INDEX = '/dashboard-index';
export const INVITE_FORM = '/dashboard/invite';
export const PROFILE = '/profile';
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGET_PASSWORD = '/forget-password';
export const RESET_PASSWORD_SUCCESS = '/forget-password/success';

export const USER = '/user';
export const BENEFICIAL_USER = '/beneficial-user';
export const USER_MESSAGES = '/user/messages';
export const USER_MESSAGE_DETAIL = '/user/messages/detail';
export const USER_NOTES = '/user/notes';
export const NOTE = '/note';

export const COMMUNICATIONS = '/communications';
export const COMMUNICATIONS_DRAFT = '/communications/draft';
export const COMMUNICATIONS_DETAIL = '/communications/detail';
export const NOTIFICATION_ROUTING = '/notification-routing';

//entity
export const ENTITIES = '/entity';
export const ENTITY_DETAILS = '/entity/details';

export const MANAGE_REPOSITORIES = '/manage-repositories';
