import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddTaxId from './AddTaxId';
import TaxIdDetails from './TaxIdDetails';
import { TLoader, theme } from '../myde-react-components';
import { getTaxId, selectEntity } from '../../redux/feature/id-process/entity/entitySlice';
import { EntityDetail } from '../../types/entityTypes';
import { TAX_ID_VISIBILITY_LEVEL } from '../../constants/constants';

const TaxIdIndex = () => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux values
  const { taxId, loading, assignedEntityDetails } = useSelector(selectEntity);

  //State variables
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);
  const [enteredTaxId, setEnteredTaxId] = useState('');

  //Use Effects
  useEffect(() => {
    if (assignedEntityDetails) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  useEffect(() => {
    if (selectedEntity) {
      const data = {
        entity_uid: selectedEntity?.trellis_uid,
        params: {
          visibility_level: TAX_ID_VISIBILITY_LEVEL.SOURCE,
        },
      };
      dispatch(getTaxId(data));
    }
  }, [selectedEntity]);

  useEffect(() => {
    if (taxId) {
      setEnteredTaxId(taxId);
    } else {
      setEnteredTaxId('');
    }
  }, [taxId]);

  return (
    <Box sx={{ pt: theme.spacing(9), pl: 5 }}>
      <TLoader loading={loading} />
      <Box className="font-weight-semibold text-h2">{t('taxId')}</Box>
      {enteredTaxId ? <TaxIdDetails taxId={enteredTaxId} /> : <AddTaxId />}
    </Box>
  );
};

export default TaxIdIndex;
