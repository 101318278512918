import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { WetSignatureIdProcessType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  wetSignatureLoading: false,
  error: false,
  errorObj: <any>{},
  wetSignatureIdProcessDetail: <WetSignatureIdProcessType>{},
};

export const selectWetSignature = ({ wetSignatureIdProcess }: RootState) => ({
  wetSignatureIdProcessDetail: wetSignatureIdProcess.wetSignatureIdProcessDetail,
  wetSignatureLoading: wetSignatureIdProcess.wetSignatureLoading,
  error: wetSignatureIdProcess.error,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'wetSignature/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const wetSignatureIdProcessSlice = createSlice({
  name: 'wetSignature',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.wetSignatureLoading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.wetSignatureLoading = false;
        state.error = false;
        if (action.payload) {
          state.wetSignatureIdProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.wetSignatureLoading = false;
        state.error = true;
      });
  },
});

export const {} = wetSignatureIdProcessSlice.actions;

export default wetSignatureIdProcessSlice.reducer;
