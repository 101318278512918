import * as z from 'zod';
import { formatDate } from '../../../utils/utils';
export const FilterFormSchema = z
  .object({
    dateFrom: z.string().nonempty('Date is required'),
    dateTill: z
      .string()
      .refine((value) => Date.parse(formatDate(value)) <= Date.parse(formatDate(new Date().toString())), {
        message: 'Date cannot be a future date',
      }),
  })
  .refine((data) => Date.parse(formatDate(data.dateTill)) >= Date.parse(formatDate(data.dateFrom)), {
    message: 'Date should be same or less than date till',
    path: ['dateFrom'],
  })
  .refine((data) => Date.parse(formatDate(data.dateTill)) >= Date.parse(formatDate(data.dateFrom)), {
    message: 'Date should be same or greater than date from',
    path: ['dateTill'],
  });

export type FilterFormSchemaPayload = z.infer<typeof FilterFormSchema>;
