import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { colorPalette } from '../myde-react-components';
export interface StyleProps {
  iconSize?: string;
  icon: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  userIconContainer: {
    position: 'relative',
    width: ({ iconSize }) => (iconSize ? iconSize : '120px'),
    height: ({ iconSize }) => (iconSize ? iconSize : '120px'),
    backgroundColor: colorPalette.iconColor.main,
    borderRadius: '50%',
    textAlign: 'center',
    margin: 'auto',
  },
  userIcon: {
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'auto',
      textAlign: 'center',
      color: colorPalette.iconColor.base,
      width: ({ icon }) => icon / 2,
    },
  },
  profileImage: {
    borderRadius: '50%',
    width: ({ iconSize }) => (iconSize ? iconSize : '120px'),
    height: ({ iconSize }) => (iconSize ? iconSize : '120px'),
  },
}));

interface UserIconProps {
  iconSize?: string;
}

const UserIcon = ({ iconSize = '120px' }: UserIconProps) => {
  const icon = parseInt(iconSize, 10);
  const styleProp = {
    iconSize,
    icon,
  };
  const classes = useStyles(styleProp);

  return (
    <>
      <Box className={classes.userIconContainer}>
        <div className={classes.userIcon}>
          <PersonOutlineIcon />
        </div>
      </Box>
    </>
  );
};

export default UserIcon;
