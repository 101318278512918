import { apiGet, apiPost } from './axios';

export const getCountOfNotifications = async () => {
  const res = await apiGet(`/notification/merchant/count`);
  return res.data;
};

export const getUserNotifications = async (params: object) => {
  const res = await apiGet(`/notification/merchant/user`, { params: params });
  return res.data;
};

export const getSystemNotifications = async (params: object) => {
  const res = await apiGet('/notification/merchant/system', { params: params });
  return res.data;
};

export const markNotificationsAsRead = async (data: object) => {
  const res = await apiPost('notification/merchant/mark-as-read', data);
  return res.data;
};
