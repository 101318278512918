import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../..';

import {
  addNotesToApplication,
  deleteApplicationNoteList,
  filteredNotes,
  getApplicationNoteList,
  getApplicationNotesById,
  getApplicationNoteSummary,
  updateNotesToApplication,
  getApplicationNoteHistory,
} from '../../../api/applicants';
import {
  DeleteNoteApiFormData,
  NoteApiFormData,
  SummaryDataType,
  UpdateNoteApiFormData,
  HistoryDataType,
} from '../../../components/userMessages/UserMessageList';
import { NotesFilterApiFormData } from '../../../components/userNotes/UserNoteList';
import { ApplicantAppliedFilterNoteType, ApplicantNoteType, ApplicantType } from '../../../types/applicantTypes';

interface applicationStatusType {
  id: string;
  noteId?: string;
  data?: any;
}

const defaultNote = {} as ApplicantNoteType;
const initialState = {
  loading: false,
  error: false,
  errorObj: <any>{},
  applicantDetails: <ApplicantType>{},
  noteList: [defaultNote],
  allNotesList: [defaultNote],
  noteCount: 0,
  count: 0,
  selectedNote: <ApplicantNoteType>{},
  historyList: defaultNote,
  noteFilterSet: <ApplicantAppliedFilterNoteType>{},
  noteUpdated: false,
  isArchivedNotes:false
};

export const selectApplicantNotes = ({ applicantNotes }: RootState) => ({
  applicantDetails: applicantNotes.applicantDetails,
  loading: applicantNotes.loading,
  noteList: applicantNotes.noteList,
  allNotesList: applicantNotes.allNotesList,
  noteCount: applicantNotes.noteCount,
  count: applicantNotes.count,
  selectedNote: applicantNotes.selectedNote,
  error: applicantNotes.error,
  historyList: applicantNotes.historyList,
  noteFilterSet: applicantNotes.noteFilterSet,
  noteUpdated: applicantNotes.noteUpdated,
  isArchivedNotes: applicantNotes.isArchivedNotes,
});

// Actions
export const addApplicationNotes = createAsyncThunk(
  'applicantNotes/addApplicationNotes',
  async (data: applicationStatusType) => {
    return await addNotesToApplication(data.id, data);
  }
);
export const getApplicationNotes = createAsyncThunk(
  'applicantNotes/getApplicationNotes',
  async (formData: NoteApiFormData) => {
    return await getApplicationNoteList(formData);
  }
);
export const deleteApplicationNotes = createAsyncThunk(
  'applicantNotes/deleteApplicationNotes',
  async (formData: DeleteNoteApiFormData) => {
    return await deleteApplicationNoteList(formData);
  }
);
export const getApplicationNoteById = createAsyncThunk(
  'applicantNotes/getApplicationNoteById',
  async (formData: DeleteNoteApiFormData) => {
    return await getApplicationNotesById(formData);
  }
);
export const getNoteSummary = createAsyncThunk(
  'applicantNotes/getNoteSummary',

  async (summaryData: SummaryDataType) => {
    return await getApplicationNoteSummary(summaryData);
  }
);
export const getNoteHistory = createAsyncThunk('applicantNotes/getNoteHistory', async (data: HistoryDataType) => {
  return await getApplicationNoteHistory(data.applicationId, data.noteId);
});
export const updateApplicationNotes = createAsyncThunk(
  'applicantNotes/updateApplicationNotes',
  async (data: UpdateNoteApiFormData) => {
    return await updateNotesToApplication(data.id, data);
  },
);
export const getFilteredNotes = createAsyncThunk(
  'applicationMessage/getfilteredRecords',
  async (filterData: NotesFilterApiFormData) => {
    return await filteredNotes(filterData);
  },
);

// Reducers
export const applicantAccountNotesSlice = createSlice({
  name: 'applicantNotes',
  initialState,
  reducers: {

    resetHistoryList: (state, action) => {
      state.historyList = action.payload;
    },

    setNotesFilterSet: (state, action) => {
      state.noteFilterSet = action.payload;
    },
    setNoteUpdated: (state, action) => {
      state.noteUpdated = action.payload;
    },
    setNotesIsArchived: (state, action) => {
      state.isArchivedNotes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addApplicationNotes.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addApplicationNotes.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.error = false;
          state.noteUpdated = true;
          toast.success('Note created successfully!');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to add new note. Please try after sometime.');
        }
      })
      .addCase(addApplicationNotes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getApplicationNotes.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationNotes.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.count = action.payload.count;
          state.allNotesList = action.payload?.results || [defaultNote];
          state.noteList = action.payload?.results || [defaultNote];
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationNotes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteApplicationNotes.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteApplicationNotes.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.error = false;
          toast.success('Note archived successfully!');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to archive note. Please try after sometime.');
        }
      })
      .addCase(deleteApplicationNotes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getApplicationNoteById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationNoteById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.selectedNote = action.payload.results[0] || {};
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationNoteById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getNoteSummary.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getNoteSummary.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.noteCount = action.payload?.total_count || 0;
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(getNoteSummary.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateApplicationNotes.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateApplicationNotes.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.error = false;
          toast.success('Note updated successfully!');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to update note. Please try after sometime.');
        }
      })
      .addCase(updateApplicationNotes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getNoteHistory.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getNoteHistory.fulfilled, (state, action: any) => {
        state.loading = false;
        if (action.payload) {
          state.error = false;
          state.historyList = action.payload;
        } else {
          state.error = true;
          toast.error('Sorry! Unable to get note history. Please try after sometime.');
        }
      })
      .addCase(getNoteHistory.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getFilteredNotes.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getFilteredNotes.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.noteList = action.payload?.results || [];
        } else {
          state.error = true;
        }
      })
      .addCase(getFilteredNotes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { resetHistoryList, setNotesFilterSet, setNoteUpdated, setNotesIsArchived } = applicantAccountNotesSlice.actions;

export default applicantAccountNotesSlice.reducer;
