import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, FormGroup, InputAdornment, Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';

import { TButton, TCheckbox, TDialog, TTextField, theme } from '../../myde-react-components';

import { BtnType, IdNameType } from '../../../types/commonTypes';
import { fontStyle } from '../../../styles/style';
import { useTheme } from '../../../providers/custom-theme-provider';
import { PORTAL } from '../../myde-react-components/src/constants/portal';
import clsx from 'clsx';

interface ListModalProps {
  list: IdNameType[];
  idList?: string[];
  dialogBtns?: BtnType[];
  dialogTitle: string;
  open: boolean;
  hasSelectedData?: boolean;
  hasSelectAll: boolean;
  hasCheckboxSelection: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  hasMore: boolean;
  onSearch: (value: string) => void;
  onClose: () => void;
  enableActionButtons?: (value: boolean) => void;
  getSelectedData?: (data: Array<IdNameType>) => void;
  fetchMoreData?: (value: boolean) => void;
}

export interface StyleProps {
  currentThemes: Theme;
}

// styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  btnStyle: {
    '& .MuiDialog-paper': {
      height: '100vh',
      maxWidth: '460px',
      '& .MuiDialogActions-root': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  tagCheckbox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  selectAllLabel: {
    '& .MuiTypography-root': {
      ...fontStyle.fontWeight.font500,
      color: ({ currentThemes }) => currentThemes.palette.primary.main,
    },
  },
}));

const ListModal = ({
  list,
  dialogBtns,
  dialogTitle,
  open,
  maxWidth,
  hasSelectedData,
  hasSelectAll = true,
  hasCheckboxSelection,
  idList,
  hasMore,
  onSearch,
  onClose,
  enableActionButtons,
  getSelectedData,
  fetchMoreData,
}: ListModalProps) => {
  // state variables
  const [searchValue, setSearchValue] = useState('');
  const [checkboxes, setCheckboxes] = useState<Array<IdNameType>>([]);
  const [uniqueIdList, setUniqueIdList] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  // constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // use effects
  useEffect(() => {
    if (list?.length > 0) {
      setCheckboxes(list);
    } else {
      setCheckboxes([]);
    }
  }, [list]);

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (idList && idList?.length > 0) {
      setUniqueIdList(idList);
    }
  }, [idList]);

  useEffect(() => {
    if (uniqueIdList?.length > 0) {
      enableActionButtons &&
        enableActionButtons(
          checkboxes?.filter((element) => uniqueIdList?.some((item) => item === element?.id)).length > 0,
        );
    } else {
      enableActionButtons && enableActionButtons(false);
    }
  }, [uniqueIdList]);

  useEffect(() => {
    if (hasSelectedData) {
      checkboxes?.length > 0
        ? getSelectedData &&
          getSelectedData(checkboxes?.filter((element) => uniqueIdList?.some((item) => item === element?.id)))
        : getSelectedData && getSelectedData([]);
    } else {
      getSelectedData && getSelectedData([]);
    }
  }, [hasSelectedData, checkboxes]);

  useEffect(() => {
    if (selectAll) {
      const filterArray: Array<string> = [];
      checkboxes?.forEach((item) => {
        filterArray.push(item?.id);
      });
      setUniqueIdList(filterArray);
    } else if (!selectAll && isSelected) {
      setUniqueIdList([]);
    }
  }, [selectAll, isSelected]);

  // methods
  const handleChange = debounce((value: string) => {
    setSearchValue(value);
  }, 100);

  const clearSearch = () => {
    setSearchValue('');
    onSearch('');
  };

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event?.target?.checked);
    if (event.target.checked) {
      setIsSelected(true);
    }
  };

  const handleCheckbox = (item: IdNameType) => {
    const data = [...uniqueIdList];
    if (data.includes(item?.id)) {
      const index = data.indexOf(item?.id);
      data.splice(index, 1);
    } else {
      data.push(item?.id);
    }
    setUniqueIdList(data);
  };

  const fetchData = () => {
    if (fetchMoreData) {
      fetchMoreData(true);
    }
  };

  return (
    <TDialog
      className={classes.btnStyle}
      open={open}
      buttons={list?.length > 0 ? dialogBtns : []}
      title={dialogTitle}
      transparentTitle
      maxWidth={maxWidth}
      onClose={onClose}
      portalName={PORTAL.MERCHANT}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="textFieldSearchWithIcon">
              <TTextField
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  handleChange(e?.target?.value);
                }}
                value={searchValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search textColor-400" />
                    </InputAdornment>
                  ),
                  endAdornment: searchValue && (
                    <InputAdornment position="end">
                      <TButton icon={<CloseIcon />} onClick={clearSearch} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
            </Box>
          </Grid>
        </Grid>
        {checkboxes?.length > 0 ? (
          <Box sx={{ mt: 3 }}>
            {hasCheckboxSelection ? (
              <InfiniteScroll
                dataLength={checkboxes.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<Box className="text-large textColor-200 font-weight-semibold text-center">Loading...</Box>}
                endMessage={<></>}
                scrollableTarget="scrollableDiv"
              >
                <Box sx={{ pl: 1, pb: 2 }}>
                  {hasSelectAll && (
                    <FormGroup>
                      <FormControlLabel
                        className={classes.selectAllLabel}
                        control={<TCheckbox value={'all'} checked={selectAll} onChange={handleAllChange} />}
                        label={'Select All'}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    {checkboxes?.map((item: IdNameType) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <TCheckbox
                            value={item.id}
                            checked={uniqueIdList?.includes(item?.id)}
                            onChange={() => handleCheckbox(item)}
                          />
                        }
                        label={item.name}
                      />
                    ))}
                  </FormGroup>
                </Box>
              </InfiniteScroll>
            ) : (
              <Box sx={{ my: 3 }}>
                {checkboxes?.map((item: IdNameType) => (
                  <Box
                    sx={{ pl: theme.spacing(3), mb: theme.spacing(5) }}
                    key={item?.id}
                    className={clsx('textColor-300 text-medium font-weight-regular', {
                      ['textCapitalize']: item?.type,
                    })}
                  >
                    {item?.name}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        ) : (
          <Box className="p-relative" sx={{ py: 10 }}>
            <Box className="text-center text-h3 font-weight-semibold textColor-300">No records found.</Box>
          </Box>
        )}
      </Box>
    </TDialog>
  );
};

export default ListModal;
