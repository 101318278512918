import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, ListItem, ListItemText, ListItemIcon, ListItemButton, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getSpecificIdProcessDetails, selectKyc } from '../../../redux/feature/id-process/kycIdProcessSlice';
import { colorPalette, TButton, TLoader } from '../../myde-react-components';
import { fontStyle } from '../../../styles/style';
import { ClickHandlerType } from '../../../types/commonTypes';
import { selectApplicant } from '../../../redux/feature/applicant/applicantSlice';
import { PredefinedProcessType } from '../../../types/applicantTypes';
import TabHeader from '../../common/tab-header/TabHeader';
import UserNoteSummary from '../../userNotes/UserNoteSummary';
import { selectApplicationActions } from '../../../redux/feature/id-process/idProcessActionsSlice';
import { getAction, getIdProcessReviewStatus } from '../../../utils/idProcess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';

// Interface
interface KycAmlDetailsProp {
  processDetails: PredefinedProcessType;
  onShowRecordSection?: ClickHandlerType;
  onShowSecondarySection?: ClickHandlerType;
  onShowReportHistorySection?: ClickHandlerType;
}

// Styles
const useStyles = makeStyles({
  icon: {
    fontSize: fontStyle.fontSize.font24.fontSize,
  },
  list: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    '&:last-child': {
      borderBottom: `1px solid ${colorPalette.border.card}`,
    },
  },

  kycSummaryItem: {
    borderTop: `1px solid ${colorPalette.border.base}`,
    padding: '16px 0',
    '&:last-child': {
      borderBottom: `1px solid ${colorPalette.border.base}`,
    },

    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      marginRight: 10,
    },
    '& .MuiListItemButton-root': {
      justifyContent: 'flex-end',
      padding: 0,
      '&:hover': {
        background: 'none',
      },
    },
  },
});

// Main Component
const KycAmlDetail = ({
  processDetails,
  onShowRecordSection,
  onShowSecondarySection,
  onShowReportHistorySection,
}: KycAmlDetailsProp) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // Constants
  const { applicantionDetail, applicationId, applicationIdProcessList } = useSelector(selectApplicant);
  const { kycIdProcessDetail, kycLoading } = useSelector(selectKyc);
  const { loading } = useSelector(selectApplicationActions);

  // State Values
  const [recordSectionFlag, setRecordSectionFlag] = useState(false);
  const [secondarySectionFlag, setSecondarySectionFlag] = useState(false);
  const [numOfRecords, setRecordNum] = useState(0);
  const [numOfSecondary, setSecondaryNum] = useState(0);
  const [status, setStatus] = useState('');

  // Use Effects
  useEffect(() => {
    try {
      if (processDetails?.process_id) {
        const data = {
          applicationId: applicantionDetail?.id,
          processId: processDetails?.process_id,
        };
        dispatch(getSpecificIdProcessDetails(data));
      }
    } catch (error: any) {}
  }, [processDetails]);

  useEffect(() => {
    if (kycIdProcessDetail?.records?.length > 0) {
      let count = 0;
      kycIdProcessDetail.records.forEach((record) => {
        count += record?.details?.length || 0;
      });
      setRecordNum(count);
    }
    if (kycIdProcessDetail?.secondary_records?.length > 0) {
      setSecondaryNum(kycIdProcessDetail?.secondary_records[0]?.details?.length);
    }
  }, [kycIdProcessDetail]);

  useEffect(() => {
    const currentProcess =
      applicationIdProcessList?.id_process_list?.find((process) => process.process_id === processDetails?.process_id) ||
      ({} as PredefinedProcessType);
    if (Object.keys(currentProcess)?.length > 0) {
      setStatus(getIdProcessReviewStatus(currentProcess?.merchant_review_status, processDetails?.status));
    }
  }, [applicationIdProcessList]);

  // Methods
  const showRecordSection = () => {
    setRecordSectionFlag(true);
    setSecondarySectionFlag(false);
    onShowRecordSection && onShowRecordSection();
  };

  const showSecondarySection = () => {
    setSecondarySectionFlag(true);
    setRecordSectionFlag(false);
    onShowSecondarySection && onShowSecondarySection();
  };

  const showReportHistorySection = () => {
    onShowReportHistorySection && onShowReportHistorySection();
  };

  // HTML
  return (
    <>
      {kycLoading || loading || !status ? (
        <TLoader loading={kycLoading || loading || !status} />
      ) : (
        <>
          {(!recordSectionFlag || !secondarySectionFlag) && (
            <Grid container>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <TabHeader
                  title={processDetails?.title}
                  status={status}
                  applicationId={applicationId}
                  processId={processDetails?.process_id}
                  accountReviewStatus={applicantionDetail?.status}
                  section={processDetails?.title}
                />
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <Box className="text-h3 font-weight-semibold textColor-200" sx={{ mb: 2 }}>
                  Categories
                </Box>
                {kycIdProcessDetail?.summary?.map((item, index) => (
                  <ListItem disablePadding key={index} className={classes.kycSummaryItem}>
                    <ListItemText className="text-medium textColor-200" primary={item?.name} />
                    <ListItemIcon>{getAction(item?.result)}</ListItemIcon>
                  </ListItem>
                ))}
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mt: 5 }}>
                <Box className="text-h3 font-weight-semibold textColor-200" sx={{ mb: 2 }}>
                  Records
                </Box>
                <ListItem disablePadding className={classes.kycSummaryItem}>
                  <ListItemText className="text-medium textColor-200" primary={`Records found (${numOfRecords})`} />
                  <ListItemButton>
                    {kycIdProcessDetail?.records[0]?.details?.length > 0 && (
                      <TButton variant="text" btnText="View" onClick={showRecordSection} />
                    )}
                  </ListItemButton>
                </ListItem>
              </Grid>

              {numOfSecondary > 0 && (
                <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mt: 5 }}>
                  <Box className="text-h3 font-weight-semibold textColor-200" sx={{ mb: 2 }}>
                    Secondary Checks
                  </Box>
                  <ListItem disablePadding className={classes.kycSummaryItem}>
                    <ListItemText className="text-medium textColor-200" primary={`Secondary Checks `} />
                    <ListItemButton>
                      <TButton variant="text" btnText="View" onClick={showSecondarySection} />
                    </ListItemButton>
                  </ListItem>
                </Grid>
              )}

              {kycIdProcessDetail?.history_summary?.length > 0 && (
                <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mt: 5 }}>
                  <Box className="flex-basic-space-between">
                    <Box className="text-h3 font-weight-semibold textColor-200 flex-basic-center" sx={{ mb: 2 }}>
                      {t('reportHistory')}
                    </Box>
                    <Box className={'cursorPointer'} onClick={showReportHistorySection}>
                      <ChevronRightIcon />
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 2 }} />
                </Grid>
              )}

              <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mt: 5 }}>
                <UserNoteSummary />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default KycAmlDetail;
