import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SECTION_LIST } from '../../constants/constants';
import {
  getApplicationNoteById,
  getNoteSummary,
  selectApplicantNotes,
  updateApplicationNotes,
} from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { ClickHandlerType } from '../../types/commonTypes';
import MessageNoteModal from '../common/messageNotes/MessageNoteModals';
import { MAX_CHAR_LIMIT } from '../myde-react-components';
import { NoteDataType } from '../userMessages/UserMessageList';

interface UserNoteModalProps {
  openDialog: boolean;
  onClose: ClickHandlerType;
}

const EditUserNoteModal = ({ openDialog, onClose }: UserNoteModalProps) => {
  const dispatch = useDispatch();
  const { applicationId, applicationIdProcessList, selectedSection } = useSelector(selectApplicant);
  const { selectedNote } = useSelector(selectApplicantNotes);
  // Constants
  const defaultSection = {
    label: 'Account',
    id: 'Account',
  };

  // State Values
  const [sectionList, setSectionList] = useState([defaultSection]);

  // Use Effects
  useEffect(() => {
    const arr = [defaultSection];
    if (applicationIdProcessList?.id_process_list.length > 0) {
      applicationIdProcessList?.id_process_list?.forEach((idProcess) => {
        const obj = {
          label: idProcess?.title,
          id: idProcess?.title,
        };
        arr.push(obj);
      });
      setSectionList(arr);
    }
  }, [applicationIdProcessList]);

  // Methods
  const onCancelNewNote = () => {
    onClose();
    const formData = { applicationId: applicationId, noteId: selectedNote.id };
    dispatch(getApplicationNoteById(formData));
  };

  const updateNote = (data: NoteDataType) => {
    const reqData = {
      noteId: selectedNote?.id || '',
      id: applicationId,
      data: data,
    };
    dispatch(updateApplicationNotes(reqData));
    const formData = { applicationId: applicationId, noteId: selectedNote.id };
    dispatch(getApplicationNoteById(formData));
    const summaryData = {
      applicationId: applicationId,
      section: '',
    };
    if (selectedSection !== SECTION_LIST.ACCOUNT) {
      summaryData.section = selectedSection;
    }
    dispatch(getNoteSummary(summaryData));
    onCancelNewNote();
  };

  return (
    <MessageNoteModal
      selectedValue={selectedNote}
      title="Edit Note"
      showDialog={openDialog}
      onCancelModal={onCancelNewNote}
      onSubmitForm={updateNote}
      options={sectionList}
      noBtnText="Cancel"
      yesBtnText="Update"
      label="Type your note here"
      charLimit={MAX_CHAR_LIMIT.CHAR_LENGTH}
    />
  );
};

export default EditUserNoteModal;
