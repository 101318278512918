import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fontStyle } from '../../../styles/style';

import { ClickHandlerType } from '../../../types/commonTypes';
import { TDialog, colorPalette } from '../../myde-react-components';
import { selectApplicantNotes, resetHistoryList } from '../../../redux/feature/applicant/applicantAccountNotesSlice';
import { formatDate, getTimeStamp, getInitials } from '../../../utils/utils';
import { ApplicantNoteType, NoteHistoryType } from '../../../types/applicantTypes';
import { PORTAL } from '../../myde-react-components/src/constants/portal';

interface NoteHistoryModalProps {
  showDialog: boolean;
  title: string;
  onCancelModal: ClickHandlerType;
}

const useStyles = makeStyles<Theme>(() => ({
  noteBox: {
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: 10,
    padding: 16,
    marginBottom: '25px',
  },
  noteDetails: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
    paddingBottom: 12,
  },
  boldText: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  noteText: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken1,
  },
}));

export const NoteHistoryModal = ({
  title = 'Note History',
  showDialog = false,
  onCancelModal,
}: NoteHistoryModalProps) => {
  //constants
  const { historyList } = useSelector(selectApplicantNotes);
  const noteHistory = historyList?.note_history || '';
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reverseHistory, setReverseHistory] = useState([]);
  const [accountHistoryList, setAccountHistoryList] = useState({} as ApplicantNoteType);

  //methods
  const onCancel = () => {
    onCancelModal();
    dispatch(resetHistoryList([]));
  };

  //useEffect
  useEffect(() => {
    if (noteHistory?.length > 0) {
      const tempNoteHistory = JSON.parse(JSON.stringify(noteHistory));
      setReverseHistory(tempNoteHistory.reverse());
    }
  }, [noteHistory]);

  useEffect(() => {
    const tempHistoryList = JSON.parse(JSON.stringify(historyList));
    setAccountHistoryList(tempHistoryList);
  }, [historyList]);

  return (
    <TDialog portalName={PORTAL.MERCHANT} open={showDialog} title={title} onClose={onCancel}>
      {noteHistory?.length === 0 ? (
        <Box className="flex-basic-center">
          <Box sx={{ mt: 5 }} className="text-h3 textColor-300 font-weight-semibold">
            No history found
          </Box>
        </Box>
      ) : (
        <>
          <Box className={classes.noteBox}>
            <Box className={classes.noteDetails}>
              {`Section: ${accountHistoryList?.section} |`}
              {` Edited on:`} {formatDate(accountHistoryList?.modified_on)} {` at `}
              {getTimeStamp(accountHistoryList?.modified_on)} {` by `}
              <span className={classes.boldText}>
                {accountHistoryList?.account?.first_name} {getInitials(accountHistoryList?.account?.last_name)}
              </span>
            </Box>
            <Box className={classes.noteText}>{accountHistoryList?.note}</Box>
          </Box>
          {reverseHistory?.map((item: NoteHistoryType, index: number) => (
            <Box className={classes.noteBox} key={accountHistoryList?.id}>
              <Box className={classes.noteDetails}>
                {`Section: ${item?.section} | `}
                {index === reverseHistory?.length - 1
                  ? accountHistoryList?.created_on &&
                    `Posted on:  ${formatDate(accountHistoryList?.created_on)} 
                      at 
                      ${getTimeStamp(accountHistoryList?.created_on)}  by
                    `
                  : item?.edited_on &&
                    `Edited on: ${formatDate(item?.edited_on)} at  ${getTimeStamp(item?.edited_on)} by `}

                <span className={classes.boldText}>
                  {accountHistoryList?.account?.first_name} {getInitials(accountHistoryList?.account?.last_name)}
                </span>
              </Box>
              <Box className={classes.noteText}>{item?.note}</Box>
            </Box>
          ))}
        </>
      )}
    </TDialog>
  );
};

export default NoteHistoryModal;
