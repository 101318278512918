import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';

import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { IncomeVerificationResultType } from '../../../types/applicantTypes';

const initialState = {
    loading: false,
    error: false,
    incomeIdProcessDetail: <IncomeVerificationResultType>{},
  };
  
  export const selectIncomeVerificationIdProcess = ({ incomeVerification }: RootState) => ({
    incomeIdProcessDetail: incomeVerification.incomeIdProcessDetail,
    loading: incomeVerification.loading,
    error: incomeVerification.error,
  });

  //actions
  export const getIncomeVerificationDetails = createAsyncThunk(
    'incomeVerification/getIncomeVerificationDetails',
    async (data:{applicationId: string, processId: number}) => {
      return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
    },
  );

  //reducer
  export const incomeVerificationSlice = createSlice({
    name:'incomeVerification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getIncomeVerificationDetails.pending,(state)=>{
            state.loading = true;
            state.error = false;
        })
        .addCase(getIncomeVerificationDetails.fulfilled,(state,action)=>{
            state.loading = false;
            state.error = false;
            if(action.payload){
                state.incomeIdProcessDetail = action.payload
            } else {
                state.error=true
            }
        })
        .addCase(getIncomeVerificationDetails.rejected,(state)=>{
            state.loading = false;
            state.error = true;
        })
    }
  })

  export default incomeVerificationSlice.reducer