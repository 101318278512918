import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, Theme, IconButton } from '@mui/material';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import clsx from 'clsx';

import { useTheme } from '../../providers/custom-theme-provider';
import {
  DEFAULT_TABLE_OPTIONS,
  FilePreviewType,
  ITEMS_PER_PAGE_LIST,
  PORTAL,
  TDocumentViewModal,
  TPagination,
  TTooltip,
  TLoader,
} from '../myde-react-components';
import { formatDate } from '../../utils/utils';
import { getRepositoryList, selectRepository } from '../../redux/feature/repositories/repositorySlice';
import { getRepositoryById } from '../../api/repository';
import { RepositoryResultType } from '../../types/repositoryTypes';
import NoDataPlaceHolder from '../myde-react-components/src/components/PlaceholderComponent/NoDataPlaceHolder';
import { TABLE_DIRECTIONS } from '../../constants/constants';

// Interfaces
interface RepositoriesListProps {
  searchValue: string;
  isListUpdated: boolean;
}

interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  btnColor: {
    color: ({ primaryColor }) => `${primaryColor}!important`,
  },
});

const RespositoriesList = ({ searchValue, isListUpdated = false }: RepositoriesListProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes?.palette?.primary?.main;
  const styleProps: StyleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  // State Variables
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [file, setFile] = useState({} as FilePreviewType);

  // Redux Values
  const { repositoryListCount, repositoryResults, loading } = useSelector(selectRepository);

  // Use Effect
  useEffect(() => {
    getRepositoriesListing(page, pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    if (isListUpdated) {
      setPage(1);
      setPageSize(10);
    }
  }, [isListUpdated]);

  useEffect(() => {
    if (searchValue) {
      const params = {
        doc_name: searchValue,
      };
      getRepositoriesListing(page, pageSize, params);
    } else {
      getRepositoriesListing(page, pageSize);
    }
  }, [searchValue]);

  // Methods
  const getRepositoriesListing = (pageNumber: number, limit: number, filters?: object) => {
    const extraParams = filters ? filters : {};
    const formData = {
      page: pageNumber,
      limit: limit,
      ...extraParams,
    };
    dispatch(getRepositoryList(formData));
  };
  const onRowChange = (value: any) => {
    setPageSize(value);
  };

  const onPageChange = (value: any) => {
    setPage(value);
  };

  const onColumnSortChange = (changedColumn: string, direction: string) => {
    const params = {
      ordering: direction === TABLE_DIRECTIONS.DESCENDING ? `-${changedColumn}` : changedColumn,
    };
    getRepositoriesListing(page, pageSize, params);
  };

  const getSpecificRepositoryData = async (id: string) => {
    return (await getRepositoryById(id)) || ({} as RepositoryResultType);
  };

  const selectedRepository = async (repository: any) => {
    const repositoryId = repository?.rowData[0];
    const result = (await getSpecificRepositoryData(repositoryId)) || ({} as RepositoryResultType);
    if (result?.id) {
      const filePreviewPayload = {
        name: result?.document?.doc_name,
        preview: result?.document?.signed_url,
        size: result?.document?.size_in_kb,
        type: result?.document?.mime_type,
      };
      setFile(filePreviewPayload);
      setShowDocumentDialog(true);
    }
  };

  const downloadSelectedDocument = async (item: any) => {
    const specificRepositoryId = item?.rowData[0];
    const result = (await getSpecificRepositoryData(specificRepositoryId)) || ({} as RepositoryResultType);
    if (result?.id) {
      window.open(result?.document?.signed_url, '_blank');
    }
  };

  // Table constants
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: { display: false },
    },
    {
      name: 'doc_name',
      label: 'Title',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          // any as type kept as row data can be object or string.
          return (
            <Box
              className={clsx(classes.btnColor, 'cursorPointer text-large font-weight-medium')}
              onClick={() => selectedRepository(tableMeta)}
            >
              {value}
            </Box>
          );
        },
        sort: false,
      },
    },
    {
      name: 'created_on',
      label: 'Created On',
      options: {
        customBodyRender: (value: any) => {
          return <Box className="text-large font-weight-regular textColor-200">{formatDate(value)}</Box>;
        },
      },
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        customBodyRender: (_value: any, tableMeta: any) => {
          return (
            <Box>
              <TTooltip title={t('download')}>
                <IconButton color="inherit" size="small" onClick={() => downloadSelectedDocument(tableMeta)}>
                  <Box className={clsx('icon-download-files', classes.btnColor)} />
                </IconButton>
              </TTooltip>
            </Box>
          );
        },
        sort: false,
      },
    },
  ];

  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    textLabels: {
      body: {
        columnHeaderTooltip: (_column: MUIDataTableColumn) => '',
        noMatch: (
          <Box sx={{ px: 10 }}>
            <NoDataPlaceHolder
              icon={'icon-document'}
              isButton={false}
              placeholderText={t('noDocuments')}
              isPrimaryColor={true}
              subTitle={t('createRepositoryMessage')}
            />
          </Box>
        ),
      },
    },
    rowsPerPage: pageSize,
    rowsPerPageOptions: { ITEMS_PER_PAGE_LIST },
    onColumnSortChange: onColumnSortChange,
  };

  return (
    <>
      <TLoader loading={loading} />
      <Box className={classes.tableContainer}>
        <MUIDataTable title="" data={repositoryResults} columns={columns} options={options} />
        {repositoryResults?.length > 0 && (
          <Box className="flex-basic-end">
            <TPagination
              primaryColor={primaryColor}
              rowsPerPageOptions={ITEMS_PER_PAGE_LIST}
              rowsPerPage={pageSize}
              count={Math.ceil(repositoryListCount / pageSize)}
              onRowChange={onRowChange}
              onPageChange={onPageChange}
              page={page}
            />
          </Box>
        )}
      </Box>
      <TDocumentViewModal
        open={showDocumentDialog}
        file={file}
        closeModal={() => setShowDocumentDialog(false)}
        portalName={PORTAL.MERCHANT}
      />
    </>
  );
};

export default RespositoriesList;
