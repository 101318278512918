import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  acceptRejctInvitations,
  getPendingInvitations,
  invitations,
  resendInvitation,
  revokeInvitation,
} from '../../../api/invitations';
import { RootState } from '../..';
import { toast } from 'react-toastify';
import { INVITE_STATUS } from '../../../constants/constants';
import { ApplicationRequestType, InvitationType } from '../../../types/applicantTypes';
import { InviteResultType } from '../../../types/inviteTypes';
import { IdName } from '../../../types/commonTypes';

const initialState = {
  inviteData: [] as InviteResultType[],
  loading: false,
  error: false,
  count: 0,
  errorObj: <any>{},
  currentTabId: 0,
  inviteUpdated: false,
  inviteList: [] as InvitationType[],
  statusData: [] as IdName[],
  selectedCoordinatorData: {} as IdName,
  emailData: '',
  applicantFilters: <any>{},
  inviteLoading: false,
  inviteCount: 0,
  hasNextInvites: '',
  selectedApplication: <InviteResultType>{},
  inviteType: '',
};

const updateInviteData = (state: any, action: any, type: 'application' | 'invitation') => {
  state.loading = false;
  state.error = false;
  state.inviteLoading = false;
  if (action.payload) {
    state.count = action.payload.count;
    state.hasNextInvites = action.payload.next;
    if (type === 'application') {
      state.inviteData = action.payload.results;
    } else {
      state.inviteList = action.payload.results;
    }
  } else {
    state.error = true;
  }
};

// Selector
export const selectInvitation = ({ merchantInvite }: RootState) => ({
  inviteData: merchantInvite.inviteData,
  loading: merchantInvite.loading,
  error: merchantInvite.error,
  count: merchantInvite.count,
  currentTabId: merchantInvite.currentTabId,
  inviteUpdated: merchantInvite.inviteUpdated,
  inviteList: merchantInvite.inviteList,
  statusData: merchantInvite.statusData,
  selectedCoordinatorData: merchantInvite.selectedCoordinatorData,
  emailData: merchantInvite.emailData,
  applicantFilters: merchantInvite.applicantFilters,
  inviteLoading: merchantInvite.inviteLoading,
  hasNextInvites: merchantInvite.hasNextInvites,
  selectedApplication: merchantInvite.selectedApplication,
  inviteType: merchantInvite.inviteType,
});

// Actions
export const getMerchantInvit = createAsyncThunk(
  'merchantInvit/getMerchantInvit',
  async (formData: ApplicationRequestType) => {
    return await invitations(formData);
  },
);
export const updateMerchantInvit = createAsyncThunk(
  'merchantInvit/updateMerchantInvit',
  async (formData: { id: string; status: string; note?: string }) => {
    return await acceptRejctInvitations(formData);
  },
);
export const getAllInvites = createAsyncThunk(
  'merchantInvit/getAllInvites',
  async (formData: ApplicationRequestType) => {
    return await getPendingInvitations(formData);
  },
);
export const resendSelectedInvite = createAsyncThunk('merchantInvit/resendSelectedInvite', async (inviteId: string) => {
  return await resendInvitation(inviteId);
});
export const revokeSelectedInvite = createAsyncThunk('merchantInvit/revokeSelectedInvite', async (inviteId: string) => {
  return await revokeInvitation(inviteId);
});

// Reducers
export const inviteSlice = createSlice({
  name: 'merchantInvit',
  initialState,
  reducers: {
    setCurrentTabId: (state, action) => {
      state.currentTabId = action.payload;
    },
    setInviteUpdateFlag: (state, action) => {
      state.inviteUpdated = action.payload;
    },
    setStatusData: (state, action) => {
      state.statusData = action.payload;
    },
    setSelectedCoordinatorsData: (state, action) => {
      state.selectedCoordinatorData = action.payload;
    },
    setEmailData: (state, action) => {
      state.emailData = action.payload;
    },
    setFilters: (state, action) => {
      state.applicantFilters = action.payload;
    },
    setSelectedApplication: (state, action) => {
      state.selectedApplication = action.payload;
    },
    setInviteType: (state, action) => {
      state.inviteType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllInvites
      .addCase(getMerchantInvit.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.inviteLoading = true;
      })
      .addCase(getMerchantInvit.fulfilled, (state, action: any) => {
        updateInviteData(state, action, 'application');
      })
      .addCase(getMerchantInvit.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.inviteLoading = false;
      })
      // updateMerchantInvit
      .addCase(updateMerchantInvit.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateMerchantInvit.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload.status === INVITE_STATUS.APPROVED) {
          toast.success('Application approved successfully');
        } else if (action.payload.status === INVITE_STATUS.REJECTED) {
          toast.success('Application declined');
        } else {
          state.error = true;
        }
      })
      .addCase(updateMerchantInvit.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getAllInvites
      .addCase(getAllInvites.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllInvites.fulfilled, (state, action: any) => {
        updateInviteData(state, action, 'invitation');
      })
      .addCase(getAllInvites.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // resendSelectedInvite
      .addCase(resendSelectedInvite.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(resendSelectedInvite.fulfilled, (state, action: any) => {
        state.loading = false;
        if (action.payload) {
          state.error = false;
          state.inviteUpdated = true;
          toast.success('Invitation resent successfully.');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to resend invitation. Please try again later.');
        }
      })
      .addCase(resendSelectedInvite.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // revokeSelectedInvite
      .addCase(revokeSelectedInvite.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(revokeSelectedInvite.fulfilled, (state, action: any) => {
        state.loading = false;
        if (action.payload) {
          state.error = false;
          state.inviteUpdated = true;
          toast.success('Invitation revoked successfully.');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to revoked invitation. Please try again later.');
        }
      })
      .addCase(revokeSelectedInvite.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setCurrentTabId,
  setInviteUpdateFlag,
  setStatusData,
  setSelectedCoordinatorsData,
  setEmailData,
  setFilters,
  setSelectedApplication,
  setInviteType,
} = inviteSlice.actions;

export default inviteSlice.reducer;
