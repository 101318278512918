import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../providers/custom-theme-provider';
import {
  selectCommunications,
  getMerchantCommunicationsList,
  getSpecificCommunicationsData,
  setCurrentTabId,
} from '../../redux/feature/communication/communicationSlice';

import { TLoader, TPagination, TTextField, TTooltip } from '../../components/myde-react-components';

import * as ROUTES from '../../constants/routes';
import { fontStyle } from '../../styles/style';
import { COMMUNICATION_STATUS, SORTING_PARAM } from '../../constants/constants';
import { formatDate } from '../../utils/utils';
import { MerchantCommunicationListType, CustomMerchantCommunicationType } from '../../types/communicationTypes';
import { useRouter } from '../../providers/custom-router-provider';
import { DEFAULT_TABLE_OPTIONS, ITEMS_PER_PAGE_LIST } from '../myde-react-components/src/constants/constants';

interface CommunnicationListingProps {
  status: string;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  linkText: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    maxWidth: 500,
  },
  cellText: {
    ...fontStyle.fontWeight.font400,
    ...fontStyle.fontSize.font16,
  },
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
        '& .MuiTableBody-root': {
          '& .MuiTableRow-root': {
            cursor: 'pointer',
          },
        },
      },
    },
  },
  actionIcons: {
    height: '0.8em !important',
    width: '0.8em !important',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'end',
  },

  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const CommunicationListing = ({ status }: CommunnicationListingProps) => {
  // constants
  const { currentThemes } = useTheme();
  const dispatch = useDispatch();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const primaryColor = currentThemes.palette.primary.main;
  const ordering = status === COMMUNICATION_STATUS.SENT ? { ordering: '-sent_on' } : {};
  // state variables
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const formData = {
    page,
    status: status,
    ...ordering,
    limit: 10,
  };

  // selectors
  const { loading, merchantCommunications, merchantCommunicationsCount } = useSelector(selectCommunications);

  // use effects
  useEffect(() => {
    dispatch(getMerchantCommunicationsList(formData));
  }, []);

  useEffect(() => {
    let selectedTab = 0;
    switch (status) {
      case COMMUNICATION_STATUS.SENT:
        selectedTab = 0;
        break;
      case COMMUNICATION_STATUS.DRAFT:
        selectedTab = 1;
        break;
    }
    dispatch(setCurrentTabId(selectedTab));
  }, [status]);

  useEffect(() => {
    if (page) {
      changePage();
    }
  }, [page, pageSize]);

  // methods
  const handleRowClick = (_rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
    goToSpecificData(rowMeta);
  };

  const goToSpecificData = (data: any) => {
    const rowIndex: number = data?.rowIndex || 0;
    const communicationId = merchantCommunications[rowIndex]?.id;
    if (status === COMMUNICATION_STATUS.DRAFT) {
      dispatch(getSpecificCommunicationsData(communicationId));
      routeTo(ROUTES.COMMUNICATIONS_DRAFT);
    } else if (status === COMMUNICATION_STATUS.SENT) {
      dispatch(getSpecificCommunicationsData(communicationId));
      routeTo(ROUTES.COMMUNICATIONS_DETAIL);
    }
  };

  const changePage = () => {
    dispatch(getMerchantCommunicationsList(formData));
  };

  const onRowChange = (value: any) => {
    setPageSize(value);
  };

  const onPageChange = (value: any) => {
    setPage(value);
  };

  const customBodyRender = (value: any) => {
    return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
  };

  const handleFilterChange = (event: any) => {
    const filters = {
      ...formData,
      title: event?.target?.value,
    };
    dispatch(getMerchantCommunicationsList(filters));
  };

  const getIconType = () => {
    let result = 'icon-messages icon-size-72';
    if (status === COMMUNICATION_STATUS.DRAFT) {
      result = 'icon-file icon-size-72';
    }
    return result;
  };

  const getText = () => {
    let result = t('noSentCommunications');
    if (status === COMMUNICATION_STATUS.DRAFT) {
      result = t('noDraftCommunications');
    }
    return result;
  };

  const getConsolidatedData = (data: MerchantCommunicationListType[]) => {
    const arr: CustomMerchantCommunicationType[] = [];
    data?.forEach((item) => {
      const obj = {
        id: item.id,
        title: item.title,
        files_attached: item?.document_list?.length,
        sent_on: formatDate(item?.sent_on),
        created_on: formatDate(item?.created_on),
        status: item?.status,
      };
      arr.push(obj);
    });
    return arr;
  };

  const onColumnSortChange = (changedColumn: string, direction: string) => {
    let orderingValue = '';
    if (changedColumn === SORTING_PARAM.TITLE || changedColumn === SORTING_PARAM.FILES) {
      return;
    } else {
      orderingValue = changedColumn;
    }
    const allFilters = {
      ...formData,
      ordering: direction === 'desc' ? '-' + orderingValue : orderingValue,
    };
    dispatch(getMerchantCommunicationsList(allFilters));
  };

  const columns = [
    {
      name: 'title',
      label: 'Title',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <TTooltip title={value ? value : '-'}>
              <Box
                className={clsx(classes.linkText, 'cursorPointer text-large font-weight-semibold')}
                onClick={() => {
                  goToSpecificData(tableMeta);
                }}
              >
                {value ? value : '-'}
              </Box>
            </TTooltip>
          );
        },
      },
    },
    {
      name: 'files_attached',
      label: 'Files attached',
      options: { customBodyRender },
    },
    {
      name: 'sent_on',
      label: 'Date',
      options: { customBodyRender, display: status === COMMUNICATION_STATUS.SENT },
    },
    {
      name: 'created_on',
      label: 'Date',
      options: { customBodyRender, display: status === COMMUNICATION_STATUS.DRAFT },
    },
  ];

  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    rowHover: true,
    onRowClick: handleRowClick,
    onColumnSortChange: onColumnSortChange,
  };

  // HTML
  return (
    <>
      <Box sx={{ mb: 1 }} className="flex-basic-end  textFieldSearchWithIcon">
        <TTextField
          variant="outlined"
          placeholder="Search by Title"
          onChange={handleFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="icon-search textColor-400" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {merchantCommunications?.length > 0 ? (
        <Box className={classes.tableContainer}>
          <TLoader loading={loading} />
          <MUIDataTable
            title=""
            data={getConsolidatedData(merchantCommunications)}
            columns={columns}
            options={options}
          />
          <Box sx={{ mt: 2 }} className={classes.pageContainer}>
            <TPagination
              primaryColor={primaryColor}
              rowsPerPageOptions={ITEMS_PER_PAGE_LIST}
              rowsPerPage={pageSize}
              count={Math.ceil(merchantCommunicationsCount / pageSize)}
              onRowChange={onRowChange}
              onPageChange={onPageChange}
              page={page}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 5 }} className={classes.noDataContainer}>
          <Box
            className={clsx({
              ['textColor-400']: true,
              [getIconType()]: true,
            })}
          />
          <Box sx={{ mt: 4 }} className="text-h3 textColor-300 font-weight-semibold">
            {getText()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default CommunicationListing;
