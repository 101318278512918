import { colorPalette } from '../components/myde-react-components';
export const fontStyle = {
  fontSize: {
    font10: {
      fontSize: '0.625rem !important', // 10px
    },
    font12: {
      fontSize: '0.75rem !important', // 12px
    },
    font14: { fontSize: '0.875rem !important' }, // 14px
    font16: { fontSize: '1rem !important' }, // 16px
    font18: { fontSize: '1.125rem !important' }, // 18px
    font20: { fontSize: '1.25rem !important' }, //20px
    font22: { fontSize: '1.375rem !important' }, // 22px
    font24: { fontSize: '1.5rem !important' }, // 24px
    font28: { fontSize: '1.75rem !important' }, // 28px
    font30: { fontSize: '1.875rem !important' }, // 30px
    font120: { fontSize: '7.5rem !important' }, // 120px
    font176: { fontSize: '11rem !important' }, // 176px
  },
  fontWeight: {
    font400: {
      fontWeight: '400 !important',
    },
    font500: {
      fontWeight: '500 !important',
    },
    font600: { fontWeight: '600 !important' },
    font700: { fontWeight: '700 !important' },
    font800: { fontWeight: '800 !important' },
  },
};

export const typography = {
  title24Bold: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  title24SemiBold: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  title16BoldDark: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  title16SemiBold: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
  },
  title16Bold: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.lighten1,
  },
  body16ThinDark: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken,
  },
  body18Thin: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.lighten1,
  },
  body18ThinDark: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
  },
  body18SemiBold: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.lighten1,
  },
  body18Bold: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.lighten1,
  },
  body18BoldDark: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font700,
    color: colorPalette.typoText.darken2,
  },
  link: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.primary,
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  description: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  descriptionDark: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  caption: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  captionLighten: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  captionSm: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  label: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  labelDark: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken1,
    fontFamily: 'Montserrat',
  },
  value: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken1,
    fontFamily: 'Montserrat',
  },
  valueLighten: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  stepperTitle: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.lighten1,
    fontFamily: 'Montserrat',
  },
  subTitle: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  bannerTitle: {
    ...fontStyle.fontSize.font28,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  title20: {
    ...fontStyle.fontSize.font20,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  title20SemiBold: {
    ...fontStyle.fontSize.font20,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
    fontFamily: 'Montserrat',
  },
  address: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.base,
    fontFamily: 'Montserrat',
  },
  label18Darken: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
    fontFamily: 'Montserrat',
  },
};
