import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { TPageHeader, TButton, TLoader, TTooltip, colorPalette, theme } from '../../components/myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import UserTabs from '../../components/userTabs/UserTabs';
import {
  getApplicantDetails,
  getApplicationIdProcesses,
  getParentApplicationById,
  resetApplicantDetails,
  selectApplicant,
  setAccountStatusMsg,
  setApplicationUpdatedFlag,
  setCurrentTab,
  setSelectedApplicationId,
  updateApplication,
} from '../../redux/feature/applicant/applicantSlice';
import { ID_PROCESS_REVIEW_STATUS, INVITE_MESSAGE, INVITE_STATUS, USER_TYPE } from '../../constants/constants';
import ConfirmWithReasonDialog from '../../components/common/ConfirmWithReason';
import { PredefinedProcessType } from '../../types/applicantTypes';
import { getNoteSummary } from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { SummaryDataType } from '../../components/userMessages/UserMessageList';
import { titleCase } from '../../utils/utils';
import { getSavedUserTags } from '../../redux/feature/applicant/applicantTagSlice';
import * as ROUTES from '../../constants/routes';

// Styles
const useStyles = makeStyles({
  iconDecline: {
    color: colorPalette.error,
  },
});

// Main Component
const ApplicantDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const { routeTo } = useRouter();

  // Redux Values
  const {
    applicantDetails,
    loading,
    applicantionDetail,
    applicationIdProcessList,
    isApplicationUpdated,
    selectedSection,
  } = useSelector(selectApplicant);

  // Constants
  const selectedInvitationId = searchParams.get('inviteId') || '';
  const selectedUserId = searchParams.get('userId') || '';
  const selectedUserType = searchParams.get('type') || '';

  // State Values
  const [applicationId, setApplicationId] = useState('');
  const [showDenyDialog, setDenyDialogFlag] = useState(false);
  const [showReconsiderDialog, setShowReconsiderDialog] = useState(false);
  const [isProcessRejected, setRejectedProcessFlag] = useState(false);
  const [pageHeader, setPageHeader] = useState(<>''</>);

  // Use Effects
  useEffect(() => {
    setApplicationId(selectedInvitationId || '');
    dispatch(setCurrentTab(0));
    dispatch(getSavedUserTags(`${selectedUserId}`));
    dispatch(setSelectedApplicationId(selectedInvitationId));
    dispatch(getApplicantDetails(selectedInvitationId));
    if (selectedUserType === USER_TYPE.ENTITY) {
      dispatch(getParentApplicationById(selectedInvitationId));
    }
    dispatch(getApplicationIdProcesses(selectedInvitationId));
  }, []);

  useEffect(() => {
    if (isApplicationUpdated) {
      const data: SummaryDataType = {
        applicationId: applicationId,
        section: selectedSection,
      };
      dispatch(getApplicantDetails(applicationId));
      dispatch(setApplicationUpdatedFlag(false));
      dispatch(getNoteSummary(data));
    }
  }, [isApplicationUpdated]);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      const rejectedProcesses = applicationIdProcessList?.id_process_list.filter(
        (process: PredefinedProcessType) => process.merchant_review_status === ID_PROCESS_REVIEW_STATUS.REJECTED,
      );

      if (rejectedProcesses?.length > 0) {
        setRejectedProcessFlag(true);
        dispatch(setAccountStatusMsg('Section(s) have been declined'));
      } else {
        setRejectedProcessFlag(false);
        dispatch(setAccountStatusMsg(INVITE_MESSAGE[applicantionDetail?.status]));
      }
    }
  }, [applicationIdProcessList, applicantionDetail]);
  useEffect(() => {
    const userName = applicantDetails?.id ? applicantDetails?.name : '';
    if (isProcessRejected) {
      const headerTxt = (
        <>
          <Box className="flex-basic-start">
            <Box className="textCapitalize">{userName}</Box>
            {getEntityBadge()}
            <Box sx={{ ml: 1 }} className="marginTP-2">
              <TTooltip title="Section(s) have been declined">
                <span className="icon-forbid cursorPointer error-color-state" />
              </TTooltip>
            </Box>
          </Box>
        </>
      );
      setPageHeader(headerTxt);
    } else {
      setPageHeader(
        <Box className="flex-basic-start">
          <Box className="textCapitalize">{userName}</Box>
          {getEntityBadge()}
        </Box>,
      );
    }
  }, [isProcessRejected, applicantDetails]);

  // Methods
  const getEntityBadge = () => {
    return (
      <>
        {applicantionDetail?.account?.type === USER_TYPE.ENTITY && (
          <Box
            sx={{ ml: theme.spacing(5), px: theme.spacing(4), py: theme.spacing(1) }}
            className="textColor-200 text-large entityBadge"
          >
            {titleCase(USER_TYPE.ENTITY.toLowerCase())}
          </Box>
        )}
      </>
    );
  };
  const updateApplicationStatus = async (id: string, status: string, note: string) => {
    const data = { id: id, status: status, note: note };
    dispatch(updateApplication(data));
  };
  const onCancelDeny = () => {
    setDenyDialogFlag(false);
    setShowReconsiderDialog(false);
  };
  const confirmDeny = async (data: { note: string }) => {
    await updateApplicationStatus(applicationId, INVITE_STATUS.REJECTED, data?.note);
    dispatch(getApplicantDetails(applicationId));
    onCancelDeny();
  };

  const confirmReconsider = async (data: { note: string }) => {
    await updateApplicationStatus(applicationId, INVITE_STATUS.UNDER_REVIEW, data?.note);
    dispatch(getApplicantDetails(applicationId));
    onCancelDeny();
  };

  const navigateTo = () => {
    routeTo(ROUTES.DASHBOARD);
    dispatch(resetApplicantDetails());
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      {!loading && (
        <Container sx={{ py: 3 }}>
          <TPageHeader title={pageHeader} onBack={navigateTo}>
            {[INVITE_STATUS.PENDING_REVIEW, INVITE_STATUS.UNDER_REVIEW, INVITE_STATUS.REJECTED].includes(
              applicantionDetail?.status,
            ) && (
              <Box className="topContainerBtn">
                {applicantionDetail?.status === INVITE_STATUS.REJECTED ? (
                  <TButton
                    variant="outlined"
                    icon={<Box sx={{ pr: 1 }} className="icon-reconsider" />}
                    btnText="Reconsider"
                    onClick={() => {
                      setShowReconsiderDialog(true);
                    }}
                  />
                ) : (
                  <>
                    <TButton
                      variant="outlined"
                      btnText="Decline"
                      icon={
                        <Box
                          sx={{ pr: 1 }}
                          className={clsx({
                            [classes.iconDecline]: true,
                            ['icon-forbid']: true,
                          })}
                        ></Box>
                      }
                      onClick={() => {
                        setDenyDialogFlag(true);
                      }}
                    />
                    <TButton
                      variant="contained"
                      btnText="Approve"
                      icon={
                        <span
                          className={clsx({
                            ['icon-checked']: true,
                          })}
                        ></span>
                      }
                      sx={{ ml: 2 }}
                      disabled={isProcessRejected}
                      onClick={() => {
                        updateApplicationStatus(applicationId, INVITE_STATUS.APPROVED, '');
                      }}
                    />
                  </>
                )}
              </Box>
            )}
          </TPageHeader>
          <UserTabs />
        </Container>
      )}

      <ConfirmWithReasonDialog
        showDialog={showDenyDialog}
        title="Please Confirm"
        yesBtnTitle="Decline"
        noBtnTitle="Cancel"
        classNameDailog="declineBoxOpacity"
        message="Are you sure to decline this application?"
        onCancel={onCancelDeny}
        onConfirm={confirmDeny}
        noteLabel="Decline Reason"
      />
      <ConfirmWithReasonDialog
        showDialog={showReconsiderDialog}
        title="Please Confirm"
        yesBtnTitle="Reconsider"
        noBtnTitle="Cancel"
        classNameDailog="declineBoxOpacity"
        message="Are you sure to reconsider this application?"
        onCancel={onCancelDeny}
        onConfirm={confirmReconsider}
        noteLabel="Reconsider Reason"
      />
    </>
  );
};

export default ApplicantDetails;
