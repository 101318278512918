import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormGroup, Grid } from '@mui/material';

import { EntityIdRequirementsType } from '../../types/inviteTypes';
import { TButton, TCheckbox } from '../myde-react-components';
import AddNoteModal from './AddNoteModal';

interface IndividualIdRequirementsProps {
  entityIdRequirements: EntityIdRequirementsType[];
  updateRequirements?: (data: EntityIdRequirementsType[]) => void;
}

const EntityIdRequirements = ({ entityIdRequirements, updateRequirements }: IndividualIdRequirementsProps) => {
  // Constants
  const { t } = useTranslation('common');

  // State Variables
  const [requirements, setRequirements] = useState([] as EntityIdRequirementsType[]);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [particularRequirement, setParticularRequirement] = useState({} as EntityIdRequirementsType);

  // Use Effects
  useEffect(() => {
    setRequirements(entityIdRequirements);
  }, [entityIdRequirements]);

  // Methods
  const onIdProcessChange = (item: EntityIdRequirementsType) => {
    const data = requirements?.map((element: EntityIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_checked: !item?.is_checked,
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(data);
    updateRequirements && updateRequirements(data);
  };

  const onIsOptionalChange = (item: EntityIdRequirementsType) => {
    const data = requirements?.map((element: EntityIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_mandatory: !item?.is_mandatory,
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(data);
    updateRequirements && updateRequirements(data);
  };

  const handleClickChange = (item: EntityIdRequirementsType) => {
    setParticularRequirement(item);
    setShowNoteModal(true);
  };

  const updateRequirementsData = (data: EntityIdRequirementsType) => {
    const result = requirements?.map((element: EntityIdRequirementsType) => {
      if (element?.process_id === data?.process_id) {
        return {
          ...element,
          note: data?.note,
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(result);
    updateRequirements && updateRequirements(result);
  };

  // HTML
  return (
    <>
      {requirements?.length > 0 ? (
        <FormGroup>
          {requirements?.map((item: EntityIdRequirementsType) => (
            <>
              <Grid container spacing={2} key={item?.process_id}>
                <Grid item sm={5} md={5} lg={5}>
                  <FormControlLabel
                    control={
                      <TCheckbox
                        value={item?.process_id}
                        checked={item?.is_checked}
                        onChange={() => onIdProcessChange(item)}
                      />
                    }
                    label={<Box>{item?.process_name}</Box>}
                  />
                </Grid>
                <Grid item sm={5} md={5} lg={5}>
                  {item?.is_checked && (
                    <FormControlLabel
                      control={
                        <TCheckbox
                          value={item?.is_mandatory}
                          checked={!item?.is_mandatory}
                          onChange={() => onIsOptionalChange(item)}
                        />
                      }
                      label={<Box>Optional</Box>}
                    />
                  )}
                </Grid>
                <Grid item sm={2} md={2} lg={2}>
                  {item?.is_checked && (
                    <TButton
                      sx={{ mr: 1, mb: 1 }}
                      variant="text"
                      btnText={'Note'}
                      icon={<Box sx={{ mr: 1 }} className={item?.note ? 'icon-edit' : 'icon-add'} />}
                      size="small"
                      onClick={() => handleClickChange(item)}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </FormGroup>
      ) : (
        <Box className="flex-basic-center text-medium font-weight-semibold">{t('noIdRequirements')}</Box>
      )}
      <AddNoteModal
        showDialog={showNoteModal}
        requirement={particularRequirement}
        onAdd={updateRequirementsData}
        onCancel={() => setShowNoteModal(false)}
      />
    </>
  );
};

export default EntityIdRequirements;
