import { Box, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { removeUnderscore, titleCase } from '../../utils/utils';
import {
  DEFAULT_TABLE_OPTIONS,
  ENTITY_MEMBER_PERMISSION,
  TAlertDialog,
  TBadge,
  TButton,
  TDialog,
  TLoader,
  TSearchComponent,
  TTooltip,
  colorPalette,
  theme,
} from '../myde-react-components';
import NoDataPlaceHolder from '../myde-react-components/src/components/PlaceholderComponent/NoDataPlaceHolder';
import { EditMemberFormSchemaPayload } from './validation';
import { ENTITY_INVITE_STATUS, ROLES } from '../../constants/constants';
import { useTheme } from '../../providers/custom-theme-provider';
import AddMembersForm from './AddMembersForm';
import {
  deleteMemberById,
  getAllExistingEntityMembers,
  getEntityMembersByRole,
  resetDeleteMemberSuccessMessage,
  resetUpdatedMemberDetails,
  selectEntity,
  sendInviteToAllEntityMembers,
  updateMemberById,
} from '../../redux/feature/id-process/entity/entitySlice';
import { EntityMemberDetails, TemporaryEntityMemberDetails } from '../../types/entityTypes';
import EditMembersForm from './EditMembersForm';
import { selectUser } from '../../redux/feature/user/userSlice';

interface AddMembersLandingProps {
  memberType: string;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  addMembersFormDialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '800px',
        '& .modalHeader': {
          '& .MuiTypography-root': {
            borderBottom: `1px solid ${colorPalette.border.base}`,
            padding: '20px 40px',
          },
        },
      },
    },
  },

  editMembersFormDialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        '& .modalHeader': {
          '& .MuiTypography-root': {
            borderBottom: `1px solid ${colorPalette.border.base}`,
            padding: '20px 40px',
          },
        },
      },
    },
  },

  actionBtnColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const AddMembersLanding = ({ memberType }: AddMembersLandingProps) => {
  //Constants
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  //Redux
  const {
    allEntityMembers,
    updatedMemberDetails,
    deleteMemberSuccessMessage,
    allExistingEntityMembersList,
    loading,
    assignedEntityDetails,
  } = useSelector(selectEntity);
  const { userData } = useSelector(selectUser);

  //State
  const [editableEntityDetails, setEditableEntityDetails] = useState<EntityMemberDetails>({} as EntityMemberDetails);
  const [deleteMemberDetails, setDeleteMemberDetails] = useState<EntityMemberDetails>({} as EntityMemberDetails);
  const [temporaryMemberData, setTemporaryMemberData] = useState([] as TemporaryEntityMemberDetails[]);
  const [entityMembersData, setEntityMembersData] = useState([] as EntityMemberDetails[]);
  const [showAddMembersDialog, setShowAddMembersDialog] = useState(false);
  const [isEditBtnDisabled, setIsEditBtnDisabled] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedMember, setSelectedMember] = useState<TemporaryEntityMemberDetails>(
    {} as TemporaryEntityMemberDetails,
  );
  const [isEdit, setIsEdit] = useState(false);

  //useEffect
  useEffect(() => {
    getAllEntityMembers();
    dispatch(getAllExistingEntityMembers(assignedEntityDetails?.trellis_uid));
  }, []);

  useEffect(() => {
    if (allEntityMembers?.length) {
      setEntityMembersData(allEntityMembers);
    } else {
      setEntityMembersData([]);
    }
  }, [allEntityMembers]);

  useEffect(() => {
    if (updatedMemberDetails?.id) {
      resetUpdatedMemberDetails();
      getAllEntityMembers();
    }
  }, [updatedMemberDetails]);

  useEffect(() => {
    if (deleteMemberSuccessMessage) {
      resetDeleteMemberSuccessMessage();
      getAllEntityMembers();
    }
  }, [deleteMemberSuccessMessage]);

  //Methods
  const getAllEntityMembers = () => {
    const data = {
      entity_uid: assignedEntityDetails?.trellis_uid || 0,
      filters: {
        user_role: memberType,
      },
    };
    dispatch(getEntityMembersByRole(data));
  };

  const handleSearch = debounce((searchText: string) => {
    if (searchText?.length > 0) {
      const searchResult = searchItem(searchText);
      setEntityMembersData(searchResult);
    } else {
      setEntityMembersData(allEntityMembers);
    }
  }, 1000);

  const searchItem = (searchValue: string) => {
    const searchTrim = searchValue?.trim();
    const filterData = allEntityMembers?.filter(
      (item: EntityMemberDetails) =>
        (item?.first_name && item?.first_name?.toLowerCase()?.includes(searchTrim?.toLowerCase())) ||
        (item?.last_name && item?.last_name?.toLowerCase()?.includes(searchTrim?.toLowerCase())) ||
        (item?.first_name &&
          item?.last_name &&
          (item?.first_name + ' ' + item?.last_name)?.toLowerCase()?.includes(searchTrim?.toLowerCase())),
    );
    return filterData;
  };

  const handleAddMore = () => {
    setShowAddMembersDialog(true);
  };

  const handleClose = (action: string) => {
    if (action === 'add') {
      setShowAddMembersDialog(false);
    } else {
      setIsEdit(false);
    }
  };

  const handleSendInvite = async () => {
    const memberData = temporaryMemberData?.map((data) => {
      return {
        first_name: data?.first_name,
        middle_name: '',
        last_name: data?.last_name,
        email: data?.email,
        ownership_percentage: data?.ownership_percentage,
        permission: data?.permission_key,
        user_role: memberType.toUpperCase(),
        is_beneficial_owner: data?.is_beneficial_owner,
      };
    });
    const data = {
      entity_uid: assignedEntityDetails?.trellis_uid || 0,
      payload: { user_list: memberData },
    };
    setShowAddMembersDialog(false);
    setTemporaryMemberData([] as TemporaryEntityMemberDetails[]);
    await dispatch(sendInviteToAllEntityMembers(data));
    getAllEntityMembers();
  };

  const handleAddMember = (formData: any) => {
    const memberData = {
      first_name: formData?.first_name || '',
      last_name: formData?.last_name || '',
      name: `${formData?.first_name} ${formData?.last_name}`,
      email: formData?.email || '',
      ownership_percentage: formData?.ownership_percentage ? parseFloat(formData?.ownership_percentage) : null,
      permission: getPermissionLabel(formData?.permission) || '',
      permission_key: formData?.permission || '',
      is_beneficial_owner: formData?.is_beneficial_owner || false,
    };
    setTemporaryMemberData([...temporaryMemberData, memberData]);
    setIsEditBtnDisabled(false);
  };

  const getPermissionLabel = (permissionKey: string) => {
    const permission = ENTITY_MEMBER_PERMISSION.find((permission) => permission?.value === permissionKey);
    return permission?.label;
  };

  const customBodyRender = (value: string) => {
    if (value?.length > 14) {
      return (
        <TTooltip title={value}>
          <Box className={'textColor-200 text-large text-ellipsis tableCellMaxWidth'}>{value}</Box>
        </TTooltip>
      );
    }
    return <Box className={'textColor-200 text-large'}>{value}</Box>;
  };

  const handleAction = (action: string, inv: any) => {
    if (action === 'edit' && !isEditBtnDisabled) {
      const data: TemporaryEntityMemberDetails = {
        first_name: inv?.rowData[0].split(' ')[0],
        last_name: inv?.rowData[0].split(' ')[1],
        email: inv?.rowData[1],
        ownership_percentage: inv?.rowData[2] ? inv?.rowData[2].toString() : '',
        permission: inv?.rowData[4],
        is_beneficial_owner: inv?.rowData[5],
      };
      setSelectedMember(data);
      handleDelete(inv);
    } else if (action === 'delete') {
      handleDelete(inv);
    }
  };

  const handleDelete = (inv: any) => {
    const updatedMembersData = temporaryMemberData?.filter(
      (data: TemporaryEntityMemberDetails) => data?.email !== inv?.rowData[1],
    );
    setTemporaryMemberData(updatedMembersData);
  };

  const getMemberDetails = (item: EntityMemberDetails) => {
    if (item?.status === ENTITY_INVITE_STATUS.PENDING) return;
    setEditableEntityDetails(item);
    setIsEdit(true);
  };

  const onEdit = (formData: EditMemberFormSchemaPayload) => {
    const data = {
      entity_uid: editableEntityDetails?.entity_trellis_uid,
      trellis_uid: editableEntityDetails?.account_trellis_uid,
      payload: {
        ...formData,
        ownership_percentage: formData?.ownership_percentage ? parseFloat(formData?.ownership_percentage) : null,
        user_role: editableEntityDetails?.user_role,
      },
    };
    setIsEdit(false);
    dispatch(updateMemberById(data));
    setEditableEntityDetails({} as EntityMemberDetails);
  };

  const handleDeleteMemberDetails = (item: EntityMemberDetails) => {
    if (
      item?.is_beneficial_owner ||
      item?.status === ENTITY_INVITE_STATUS.PENDING ||
      userData?.trellis_uid === item?.account_trellis_uid
    )
      return;
    setDeleteMemberDetails(item);
    setShowDeleteAlert(true);
  };

  const handleDeleteCancel = () => {
    setDeleteMemberDetails({} as EntityMemberDetails);
    setShowDeleteAlert(false);
  };

  const handleDeleteConfirm = () => {
    const data = {
      entity_uid: deleteMemberDetails?.entity_trellis_uid,
      trellis_uid: deleteMemberDetails?.account_trellis_uid,
    };
    setShowDeleteAlert(false);
    setDeleteMemberDetails({} as EntityMemberDetails);
    dispatch(deleteMemberById(data));
  };

  //Data Table Constants
  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRender,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: { customBodyRender },
    },
    {
      name: 'ownership_percentage',
      label: 'Stake',
      options: {
        customBodyRender: (value: any) => {
          return <Box className="textColor-200 text-large">{value && `${value}%`}</Box>;
        },
        display: memberType === ROLES.OWNER,
      },
    },
    {
      name: 'permission',
      label: 'Permission',
      options: { customBodyRender },
    },
    {
      name: 'permission_key',
      label: 'Permission Key',
      options: { customBodyRender, display: false },
    },
    {
      name: 'is_beneficial_owner',
      label: 'Beneficial',
      options: {
        customBodyRender: (value: boolean) => {
          return <Box className="textColor-200 text-large">{value ? 'Yes' : 'No'}</Box>;
        },
        display: memberType === ROLES.OWNER,
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        customBodyRender: (_value: any, tableMeta: any) => {
          return (
            <Box className={clsx('flex-basic-start', classes.actionBtnColor)}>
              <Box
                sx={{ mr: 2 }}
                className={clsx('icon-edit icon-font-24', {
                  ['textColor-disabled']: isEditBtnDisabled,
                  ['cursorPointer']: !isEditBtnDisabled,
                })}
                onClick={() => handleAction('edit', tableMeta)}
              ></Box>
              <Box
                className="icon-delete icon-font-24 cursorPointer"
                onClick={() => handleAction('delete', tableMeta)}
              ></Box>
            </Box>
          );
        },
        sort: false,
      },
    },
  ];

  const dataTableOptions: any = {
    ...DEFAULT_TABLE_OPTIONS,
    pagination: false,
    textLabels: {
      body: {
        columnHeaderTooltip: () => '',
        noMatch: (
          <Box sx={{ ml: 2 }}>
            <NoDataPlaceHolder
              icon={'icon-profile-users'}
              isButton={false}
              placeholderText={t('noMemberAdded', { memberType: `${memberType.toLowerCase()}s` })}
            />
          </Box>
        ),
      },
    },
  };

  //HTML
  return (
    <Box sx={{ m: 5 }}>
      <TLoader loading={loading} />
      <Box className="flex-basic-space-between">
        <Box className="textColor-200 text-h3 font-weight-semibold">{`${titleCase(memberType.toLowerCase())}(s)`}</Box>
        {entityMembersData?.length > 0 && (
          <Box className="flex-basic-center">
            <Box sx={{ mr: theme.spacing(3) }}>
              <TButton
                btnText="Add More"
                icon={
                  <>
                    <Box sx={{ mr: 1 }} className="icon-add-circle"></Box>
                  </>
                }
                variant="contained"
                btnVariantClass="buttonWithTextIcon button-small"
                onClick={handleAddMore}
              />
            </Box>
            <Box>
              <TSearchComponent onSearchUpdate={handleSearch} placeholder="Search" hasExtraFilters={true} />
            </Box>
          </Box>
        )}
      </Box>

      {entityMembersData?.length > 0 ? (
        <Box sx={{ mt: 4 }}>
          {entityMembersData?.map((item: EntityMemberDetails, index: number) => (
            <Box key={index} sx={{ p: 2, mb: theme.spacing(4) }} className="boxCardMember flex-basic-space-between">
              <Box>
                <Box className="flex-basic-start">
                  <Box className="text-small font-weight-medium textColor-200" sx={{ pr: theme.spacing(2) }}>
                    {`${item?.first_name || ''} ${item?.last_name || ''}`}
                  </Box>
                  {item?.status === ENTITY_INVITE_STATUS.PENDING && (
                    <TBadge bagdeFontStyle={'text-extra-small'} showBagdeThemeColor={true} label={'Pending'} />
                  )}
                </Box>
                <Box sx={{ mt: theme.spacing(3) }} className="text-extra-small flex-basic-start">
                  {item?.permission && (
                    <Box sx={{ mr: theme.spacing(2) }} className="flex-basic-start">
                      <Box className="icon-lock icon-size-16 textColor-300" sx={{ mr: theme.spacing(1) }} />
                      <Box>{removeUnderscore(item?.permission?.toLowerCase())}</Box>
                    </Box>
                  )}
                  {item?.ownership_percentage > 0 && memberType === ROLES.OWNER && (
                    <Box sx={{ mr: theme.spacing(2) }} className="flex-basic-start">
                      <Box className="icon-stake icon-size-16 textColor-300" sx={{ mr: theme.spacing(1) }} />
                      <Box> {t('ownershipPercentageTag', { ownership_percentage: item?.ownership_percentage })}</Box>
                    </Box>
                  )}

                  {item?.is_beneficial_owner && memberType == ROLES.OWNER && (
                    <Box className="flex-basic-start">
                      <Box className="icon-profile-users icon-size-16 textColor-300" sx={{ mr: theme.spacing(1) }} />
                      <Box>{t('beneficialOwner')}</Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className={clsx('flex-basic-start', classes.actionBtnColor)}>
                <Box
                  sx={{ mr: 2 }}
                  className={clsx('icon-edit icon-font-24', {
                    ['textColor-disabled']: item?.status === ENTITY_INVITE_STATUS.PENDING,
                    ['cursorPointer']: item?.status !== ENTITY_INVITE_STATUS.PENDING,
                  })}
                  onClick={() => getMemberDetails(item)}
                ></Box>
                <Box
                  className={clsx('icon-delete icon-font-24 ', {
                    ['textColor-disabled']:
                      item?.status === ENTITY_INVITE_STATUS.PENDING ||
                      item?.is_beneficial_owner ||
                      userData?.trellis_uid === item?.account_trellis_uid,
                    ['cursorPointer']:
                      item?.status !== ENTITY_INVITE_STATUS.PENDING &&
                      !item?.is_beneficial_owner &&
                      userData?.trellis_uid !== item?.account_trellis_uid,
                  })}
                  onClick={() => handleDeleteMemberDetails(item)}
                ></Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ mt: 4 }}>
          <NoDataPlaceHolder
            icon={'icon-profile-users'}
            isButton={true}
            buttonText="Add"
            buttonWidth="button-w-140"
            placeholderText={t('noMemberAdded', { memberType: `${memberType.toLowerCase()}s` })}
            onButtonClick={handleAddMore}
          />
        </Box>
      )}

      {/* Add Members Form Dialog */}
      <TDialog
        open={showAddMembersDialog}
        title={`Add ${titleCase(memberType.toLowerCase())}(s)`}
        onClose={() => handleClose('add')}
        className={classes.addMembersFormDialog}
      >
        <AddMembersForm
          editMemberDetails={selectedMember}
          allEntityMembersData={allExistingEntityMembersList}
          memberType={memberType}
          onAddBtnClick={handleAddMember}
          setIsEditBtnDisabled={setIsEditBtnDisabled}
          setEditableMemberDetails={setSelectedMember}
        />
        <Box className="text-center">
          <MUIDataTable columns={columns} options={dataTableOptions} data={temporaryMemberData} title="" />
        </Box>
        {temporaryMemberData?.length > 0 && (
          <Box sx={{ mt: 5 }} className="flex-basic-end">
            <TButton
              btnText="Send Invite(s)"
              variant="contained"
              btnWidthSize="button-w-140"
              onClick={handleSendInvite}
            />
          </Box>
        )}
      </TDialog>

      {/* Edit Members Form Dialog */}
      <TDialog
        open={isEdit}
        title={`Edit ${titleCase(memberType.toLowerCase())}`}
        onClose={() => handleClose('edit')}
        className={classes.editMembersFormDialog}
      >
        <EditMembersForm
          editableEntityDetails={editableEntityDetails}
          memberType={memberType}
          handleClose={handleClose}
          handleEdit={onEdit}
        />
      </TDialog>

      {/* Delete Alert Dialog */}
      <TAlertDialog
        showDialog={showDeleteAlert}
        content={t('memberDeleteContent', {
          memberName: `${deleteMemberDetails?.first_name} ${deleteMemberDetails?.last_name}`,
          entityName: assignedEntityDetails?.name || '',
        })}
        title="Remove"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        onClose={handleDeleteCancel}
      />
    </Box>
  );
};

export default AddMembersLanding;
