import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AccreditationResultType, DocumentListType, PredefinedProcessType } from '../../types/applicantTypes';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import {
  ACCREDITATION_STATUS,
  FilePreviewType,
  PROCESS_IDS,
  TButton,
  TDocumentViewModal,
  TLoader,
} from '../myde-react-components';
import TabHeader from '../common/tab-header/TabHeader';
import { getIdProcessReviewStatus } from '../../utils/idProcess';
import {
  getAccreditationDetails,
  selectAccreditationIdProcess,
} from '../../redux/feature/id-process/accreditationSlice';
import { PORTAL } from '../myde-react-components/src/constants/portal';
import UserNoteSummary from '../userNotes/UserNoteSummary';

interface IncomeVerificationProps {
  idProcess: PredefinedProcessType;
}

function AccreditationTab({ idProcess }: IncomeVerificationProps) {
  // constants
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // state variables
  const [accreditationData, setAccreditationData] = useState({} as AccreditationResultType);
  const [file, setFile] = useState({} as FilePreviewType);
  const [status, setStatus] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Redux Values
  const { applicationId, applicantionDetail, applicationIdProcessList, applicantDetails } =
    useSelector(selectApplicant);
  const { loading, accreditationDetail } = useSelector(selectAccreditationIdProcess);

  // useEffect
  useEffect(() => {
    const data = {
      applicationId: applicationId,
      processId: idProcess?.process_id,
    };
    dispatch(getAccreditationDetails(data));
  }, []);

  useEffect(() => {
    if (accreditationDetail && Object.keys(accreditationDetail)?.length > 0) {
      setAccreditationData(accreditationDetail);
    }
  }, [accreditationDetail]);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      const data = applicationIdProcessList?.id_process_list?.find(
        (item) => item.process_id === PROCESS_IDS.ACCREDITATION,
      );
      if (data?.process_id) {
        setStatus(getVerificationStatus(data?.merchant_review_status, accreditationData?.status));
      }
    }
  }, [applicationIdProcessList, accreditationData]);

  // methods
  const setPreviewFiles = (file: DocumentListType) => {
    const data = {
      name: file?.doc_name,
      type: file?.mime_type,
      preview: file?.signed_url,
      size: file?.size_in_kb,
    };
    setFile(data);
    setShowModal(true);
  };

  const getVerificationStatus = (reviewStatus: string, currentStatus: string) => {
    return getIdProcessReviewStatus(reviewStatus, currentStatus);
  };

  const getDescriptionText = () => {
    const accreditation_status = applicantDetails?.details?.accreditation_status;
    if (accreditation_status === ACCREDITATION_STATUS.ACCREDITED) {
      return t('accreditation_text');
    } else if (accreditation_status === ACCREDITATION_STATUS.QUALIFIED_PURCHASER) {
      return t('qp_text');
    } else {
      return t('not_accreditation_qp_text');
    }
  };

  return (
    <>
      <Box>
        <TLoader loading={loading} />
        <Box>
          <TabHeader
            title={'Accreditation'}
            status={status}
            applicationId={applicationId}
            processId={idProcess?.process_id}
            accountReviewStatus={applicantionDetail?.status}
            section={idProcess?.title}
          />
        </Box>
        <Box className="text-medium textColor-300">{getDescriptionText()}</Box>
        <Box>
          <TButton
            variant="contained"
            btnText="View Document"
            sx={{ mt: 4 }}
            disabled={!accreditationData?.data?.signed_document}
            onClick={() => {
              setPreviewFiles(accreditationData?.data?.signed_document);
            }}
          />
        </Box>
        <TDocumentViewModal
          open={showModal}
          file={file}
          closeModal={() => setShowModal(false)}
          portalName={PORTAL.MERCHANT}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <UserNoteSummary />
      </Box>
    </>
  );
}

export default AccreditationTab;
