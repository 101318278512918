import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectEntity, submitEntityIdProcess } from '../../redux/feature/id-process/entity/entitySlice';
import { FilePreviewType } from '../../types/documentTypes';
import { ACCEPTED_IMAGE_MIME_TYPES, ENTITY_PROCESS_IDS, SUPPORTED_IMAGE_TYPES } from '../myde-react-components';
import UploadIdProcessDoc from './components/UploadIdProcessDoc';

const IrsLetterEvidencing = () => {
  //Constant
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux
  const { successfulIdProcessDetails, specificEntityIdProcessDetails, loading, assignedEntityDetails } =
    useSelector(selectEntity);

  //State
  const [resetUploadedFileFlag, setResetUploadedFileFlag] = useState(false);
  const [previouslyUploadedFiles, setPreviouslyUploadedFiles] = useState([] as FilePreviewType[]);

  //useEffect
  useEffect(() => {
    if (specificEntityIdProcessDetails?.data?.tax_document?.doc_id) {
      const file: FilePreviewType = {
        id: specificEntityIdProcessDetails?.data?.tax_document?.doc_id,
        name: specificEntityIdProcessDetails?.data?.tax_document?.doc_name,
        size: specificEntityIdProcessDetails?.data?.tax_document?.size_in_kb,
        type: specificEntityIdProcessDetails?.data?.tax_document?.mime_type,
        preview: specificEntityIdProcessDetails?.data?.tax_document?.signed_url,
        isDeleteAllowed: true,
      };
      setPreviouslyUploadedFiles([file]);
    } else {
      setPreviouslyUploadedFiles([]);
    }
  }, [specificEntityIdProcessDetails]);

  useEffect(() => {
    setResetUploadedFileFlag(Object.keys(successfulIdProcessDetails)?.length > 0);
  }, [successfulIdProcessDetails]);

  //Methods
  const handleSave = (uploadedDocuments: any[]) => {
    //Save API
    const payload = {
      process_id: ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID,
      process_data: {
        tax_document_id: uploadedDocuments[0]?.doc_id || uploadedDocuments[0]?.id,
      },
      action: 'SUBMIT',
    };
    dispatch(submitEntityIdProcess({ entity_uid: assignedEntityDetails?.trellis_uid, payload }));
  };

  // HTML
  return (
    <UploadIdProcessDoc
      pageTitle={t('irsLetterHeading')}
      acceptedFileFormat={ACCEPTED_IMAGE_MIME_TYPES}
      supportedFileFormat={SUPPORTED_IMAGE_TYPES}
      existingFileList={previouslyUploadedFiles}
      loading={loading}
      resetUploadedFile={resetUploadedFileFlag}
      onSave={handleSave}
    />
  );
};

export default IrsLetterEvidencing;
