import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { REGEX, TButton, TTextField } from '../myde-react-components';
import { TAX_ID_VISIBILITY_LEVEL } from '../../constants/constants';
import { EntityDetail } from '../../types/entityTypes';
import { addNewTaxId, getTaxId, selectEntity } from '../../redux/feature/id-process/entity/entitySlice';

const AddTaxId = () => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux values
  const { assignedEntityDetails } = useSelector(selectEntity);

  //State variables
  const [taxId, setTaxId] = useState('');
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);

  //Use Effects
  useEffect(() => {
    if (assignedEntityDetails) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  //Methods
  const handleTaxIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (REGEX?.TAX_ID?.test(event?.target?.value)) {
      setTaxId(event?.target?.value);
    } else {
      return;
    }
  };

  const handleSaveClick = async () => {
    const data = {
      entity_uid: selectedEntity?.trellis_uid,
      formData: {
        tax_id: taxId,
      },
    };
    await dispatch(addNewTaxId(data));
    const getTaxIdData = {
      entity_uid: selectedEntity?.trellis_uid,
      params: {
        visibility_level: TAX_ID_VISIBILITY_LEVEL.SOURCE,
      },
    };
    dispatch(getTaxId(getTaxIdData));
  };

  return (
    <Box>
      <form>
        <Box sx={{ pt: 4, pr: 5 }}>
          <TTextField value={taxId} fullWidth label={t('enterTaxId')} onChange={handleTaxIdChange} />
        </Box>
        <Box sx={{ pt: 4, pr: 5 }} className="flex-basic-end">
          <TButton
            btnText="Save"
            variant="contained"
            disabled={!taxId}
            btnWidthSize="button-w-140"
            onClick={handleSaveClick}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddTaxId;
