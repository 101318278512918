import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { getAccessToken } from '../../utils/storage';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../redux/feature/user/userSlice';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import ApplicantIndex from '../../views/applicants/ApplicantIndex';
import { selectNotification, setIsNotificationRead } from '../../redux/feature/notification/notificationSlice';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authToken } = useSelector(selectUser);
  const { notificationCount } = useSelector(selectNotification);

  useEffect(() => {
    const token = getAccessToken();
    if (token === '') {
      navigate(ROUTES.LOGIN);
    }
  }, [authToken]);

  useEffect(() => {
    if (notificationCount > 0) {
      dispatch(setIsNotificationRead(true));
    }
  }, [notificationCount]);

  return (
    <>
      <Container sx={{ py: 3 }}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <ApplicantIndex />
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
