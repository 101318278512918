import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { BENEFICIAL_OWNER_DOCUMENT_VALUES, ID_PROCESS_REVIEW_STATUS, PROCESS_IDS } from '../../constants/constants';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { PredefinedProcessType } from '../../types/applicantTypes';
import ApplicantDetail from './ApplicantDetail';
import BiometricTab from './BiometricTab';
import IdentityDocTab from './IdentityDocTab';
import KYCTab from './KYCTab';
import ProfileDetail from './ProfileDetail';
import TaxIdDetail from './TaxIdDetail';
import PassportTab from './PassportTab';
import { setReviewStatus } from '../../redux/feature/id-process/idProcessActionsSlice';
import WetSignature from './WetSignature';
import W8Tab from './W8Tab';
import W9Tab from './W9Tab';
import CustomIdTab from './CustomIdTab';
import IncomeVerificationTab from './IncomeVerificationTab';
import { customIdProcess, setIsCustomIdProcess } from '../../redux/feature/id-process/customIdProcessSlice';
import AccreditationTab from './AccreditationTab';
import BeneficialOwnerTab from './BeneficialOwnerTab';
import { ENTITY_PROCESS_IDS } from '../myde-react-components';
import EntityIdProcessDetailsTab from './EntityIdProcessDetailsTab';

// Interface
interface UserTabListProps {
  idProcess?: PredefinedProcessType;
}

// Main Component
const TabPanelIndex = ({ idProcess = {} as PredefinedProcessType }: UserTabListProps) => {
  const dispatch = useDispatch();

  // Redux Values
  const { currentTabId, applicationIdProcessList } = useSelector(selectApplicant);
  const { isCustomIdProcess } = useSelector(customIdProcess);

  // State Values
  const [currentTabComponent, setTabComponent] = useState(<></>);
  const [selectedTabId, setSelectedTabId] = useState(0);

  // Use Effects
  useEffect(() => {
    if (selectedTabId === 0 || selectedTabId !== currentTabId) {
      setTabComponent(getTabComponent());
      setSelectedTabId(currentTabId);
    }
    setProcessReviewStatus();
  }, [currentTabId, selectedTabId, applicationIdProcessList]);

  // Methods
  const getTabComponent = () => {
    dispatch(setIsCustomIdProcess(false));
    switch (currentTabId) {
      case 0:
        return <ApplicantDetail />;
      case PROCESS_IDS.PROFILE_VERIFICATION:
      case ENTITY_PROCESS_IDS.PROFILE_VERIFICATION:
        return <ProfileDetail idProcess={idProcess} />;
      case PROCESS_IDS.SSN_VERIFICATION:
        return <TaxIdDetail idProcess={idProcess} />;
      case PROCESS_IDS.DRIVERS_LICENSE:
        return <IdentityDocTab processDetails={idProcess} />;
      case PROCESS_IDS.PASSPORT:
        return <PassportTab processDetails={idProcess} />;
      case PROCESS_IDS.KYC_AML:
        return <KYCTab processDetails={idProcess} />;
      case PROCESS_IDS.BIOMETRIC_VERIFICATION:
        return <BiometricTab processDetails={idProcess} />;
      case PROCESS_IDS.SIGN_VERIFICATION:
        return <WetSignature processDetails={idProcess} />;
      case PROCESS_IDS.W8_DOCUMENT:
        return <W8Tab idProcess={idProcess} />;
      case PROCESS_IDS.W9_DOCUMENT:
        return <W9Tab idProcess={idProcess} />;
      case PROCESS_IDS.INCOME_VERIFICATION:
        return <IncomeVerificationTab idProcess={idProcess} />;
      case PROCESS_IDS.ACCREDITATION:
        return <AccreditationTab idProcess={idProcess} />;
      case BENEFICIAL_OWNER_DOCUMENT_VALUES.PROCESS_ID:
        return <BeneficialOwnerTab />;
      case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
      case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
      case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT:
      case ENTITY_PROCESS_IDS.ACCREDITATION:
      case ENTITY_PROCESS_IDS.W8_W9_DOCUMENT:
        return <EntityIdProcessDetailsTab idProcess={idProcess} />;
      case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
        return <EntityIdProcessDetailsTab idProcess={idProcess} />;
      default:
        dispatch(setIsCustomIdProcess(true));
        return <CustomIdTab />;
    }
  };

  const setProcessReviewStatus = () => {
    let reviewStatus = ID_PROCESS_REVIEW_STATUS.UNREVIEWED;
    //currentIdProcess kept as any because process id pf predefined id process are of type number whereas for custom id processes it is of type string
    let currentIdProcess = {} as any;

    if (isCustomIdProcess) {
      currentIdProcess =
        applicationIdProcessList?.custom_id_process_list?.find((process) => {
          return process.account_id_process_id === currentTabId;
        }) || {};
    } else {
      currentIdProcess =
        applicationIdProcessList?.id_process_list?.find((process) => {
          return process.process_id === currentTabId;
        }) || {};
    }
    reviewStatus = currentIdProcess?.merchant_review_status || '';
    dispatch(setReviewStatus(reviewStatus));
  };

  // HTML
  return <Box sx={{ p: 3 }}>{currentTabComponent}</Box>;
};

export default TabPanelIndex;
