import { apiGet, apiPost, apiUpload, apiPut } from './axios';

export const createApplicationCommunication = async () => {
  const res = await apiPost('/merchant/communication');
  return res.data;
};

export const getCommunicationCategories = async () => {
  const res = await apiGet('/merchant/document-category');
  return res.data;
};

export const getMerchantTags = async (filters: any) => {
  const res = await apiGet('/merchant/tag', { params: filters });
  return res.data;
};

export const uploadMerchantDoc = async (doc: File) => {
  const file = new FormData();
  file.append('file', doc);
  const res = await apiUpload('/merchant/document', file);
  return res.data;
};

export const draftCommunication = async (communicationId: string, formData: any) => {
  const res = await apiPut(`merchant/communication/${communicationId}`, formData);
  return res.data;
};

export const getMerchantCommunications = async (filters: any) => {
  const res = await apiGet(`merchant/communication`, { params: filters });
  return res.data;
};

export const getSpecificCommunication = async (communicationId: string) => {
  const res = await apiGet(`merchant/communication/${communicationId}`);
  return res.data;
};

export const transmitCommunication = async (communicationId: string) => {
  const res = await apiPost(`merchant/communication/${communicationId}/transmit`);
  return res.data;
};
