import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectKyc } from '../../redux/feature/id-process/kycIdProcessSlice';
import { PredefinedProcessType } from '../../types/applicantTypes';
import KycAmlDetails from './components/KycAmlDetail';
import KycAmlRecords from '../../components/userTabs/components/KycAmlRecords';
import { kycAmlHistory, KycAmlRecordType } from '../../types/idProcessTypes';
import KycAmlReportHistory from './components/KycAmlReportHistory';

interface KYCTabProps {
  processDetails: PredefinedProcessType;
}

// Main Component
const KYCTab = ({ processDetails }: KYCTabProps) => {
  const { kycIdProcessDetail } = useSelector(selectKyc);

  // State Values
  const [showRecords, setRecordSectionFlag] = useState(false);
  const [kycRecord, setKycRecord] = useState<Array<KycAmlRecordType>>([]);
  const [showSecondary, setShowSecondary] = useState(false);
  const [secondaryRecord, setSecondaryRecord] = useState<Array<KycAmlRecordType>>([]);
  const [showReportHistory, setShowReportHistory] = useState(false);
  const [reportHistoryRecord, setReportHistoryRecord] = useState<Array<kycAmlHistory>>([]);

  // Use Effects
  useEffect(() => {
    if (kycIdProcessDetail) {
      setKycRecord(kycIdProcessDetail?.records || []);
      setSecondaryRecord(kycIdProcessDetail?.secondary_records || []);
      setReportHistoryRecord(kycIdProcessDetail?.history_summary || []);
    }
  }, [kycIdProcessDetail]);

  // Methods
  const toggleRecordSection = () => {
    setRecordSectionFlag(!showRecords);
    setShowSecondary(false);
    setShowReportHistory(false);
  };

  const toggleSecondarySection = () => {
    setShowSecondary(!showSecondary);
    setRecordSectionFlag(false);
    setShowReportHistory(false);
  };

  const toggleReportHistorySection = () => {
    setShowReportHistory(!showReportHistory);
    setRecordSectionFlag(false);
    setShowSecondary(false);
  };

  // HTML
  return (
    <>
      {showRecords || showSecondary || showReportHistory ? (
        <>
          {showRecords && <KycAmlRecords kycRecord={kycRecord} onBack={toggleRecordSection} />}
          {showSecondary && <KycAmlRecords secondaryRecord={secondaryRecord} onBack={toggleSecondarySection} />}
          {showReportHistory && (
            <KycAmlReportHistory reportHistoryRecord={reportHistoryRecord} onBack={toggleReportHistorySection} />
          )}
        </>
      ) : (
        <KycAmlDetails
          onShowSecondarySection={toggleSecondarySection}
          processDetails={processDetails}
          onShowRecordSection={toggleRecordSection}
          onShowReportHistorySection={toggleReportHistorySection}
        />
      )}
    </>
  );
};

export default KYCTab;
