import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { fontStyle } from '../../styles/style';
import { useDispatch, useSelector } from 'react-redux';

import {
  createCommunication,
  resetCreateCommunicationResponse,
  selectCommunications,
  setDocumentsList,
  resetUploadedDocument,
  resetSpecificCommunicationData,
  saveSelectedTags,
  saveSelectedUsers,
  setCommunicationFormTitle,
} from '../../redux/feature/communication/communicationSlice';

import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { COMMUNICATION_STATUS } from '../../constants/constants';
import CustomTabs, { TabListType } from '../../components/common/tabs/Tabs';
import { TButton, TCard } from '../../components/myde-react-components';
import CommunicationListing from '../../components/communication/CommunicationListing';
import { toast } from 'react-toastify';
import { setShowAlert } from '../../redux/feature/common/commonSlice';

// styles
const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font600,
  },
  tabHeader: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  tabs: {
    '& .MuiTabPanel-root': {
      padding: 0,
    },
  },
});

const CommunicationLanding = () => {
  // constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  // state variables
  const [tabs, setTabs] = useState<Array<TabListType>>([]);

  // use selectors
  const { currentTabId } = useSelector(selectCommunications);

  useEffect(() => {
    setTabs(tabList);
    dispatch(resetCreateCommunicationResponse());
    dispatch(setDocumentsList([]));
    dispatch(saveSelectedTags([]));
    dispatch(saveSelectedUsers([]));
    dispatch(resetUploadedDocument());
    dispatch(resetSpecificCommunicationData());
    dispatch(setCommunicationFormTitle(''));
    dispatch(setShowAlert(false));
  }, []);

  // methods
  const renderForm = async () => {
    try {
      await dispatch(createCommunication());
      routeTo(ROUTES.COMMUNICATIONS_DRAFT);
    } catch (error) {
      if (error) {
        toast.error('Something went wrong, please try again!');
      }
    }
  };

  const tabList: TabListType[] = [
    {
      label: 'Sent',
      component: <CommunicationListing status={COMMUNICATION_STATUS.SENT} />,
    },

    {
      label: 'Drafts',
      component: <CommunicationListing status={COMMUNICATION_STATUS.DRAFT} />,
    },
  ];

  return (
    <TCard className="tcard-broder " sx={{ p: 5 }}>
      <Box className={classes.header}>
        <Box className="textColor-200 text-h2 font-weight-semibold">Communications</Box>
        <Box>
          <TButton btnText="Add New" btnWidthSize="button-w-140" variant="contained" onClick={renderForm} />
        </Box>
      </Box>
      <Box>
        <CustomTabs tabList={tabs} selectedTab={currentTabId} borderRequired={false} showBottomBorder={false} />
      </Box>
    </TCard>
  );
};

export default CommunicationLanding;
