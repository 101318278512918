import { ApplicationRequestType } from '../types/applicantTypes';
import { apiGet, apiPatch, apiPost, apiPut } from './axios';

export const invitations = async (formData: ApplicationRequestType) => {
  const res = await apiGet(`/merchant/application`, { params: formData });
  return res.data;
};

export const acceptRejctInvitations = async (formData: { id: string; status: string; note?: string }) => {
  const res = await apiPatch(`/merchant/application/${formData.id}`, formData);
  return res.data;
};

export const getPendingInvitations = async (formData: ApplicationRequestType) => {
  const filters = { ordering: '-created_on', ...formData };
  const res = await apiGet('/merchant/invite', { params: filters });
  return res.data;
};

export const resendInvitation = async (inviteId: string) => {
  const res = await apiPost(`/merchant/invite/${inviteId}/resend-email`);
  return res.data;
};

export const revokeInvitation = async (inviteId: string) => {
  const reqData = { action: 'REVOKE' };
  const res = await apiPatch(`/merchant/invite/${inviteId}`, reqData);
  return res.data;
};

export const getIdRequirementsOfMerchant = async () => {
  const response = await apiGet('/merchant/id-requirements');
  return response.data;
};

export const getIdProcessMasterDetails = async (params: object) => {
  const response = await apiGet('/merchant/id-process-master', { params: params });
  return response.data;
};

export const inviteUser = async (formData: object) => {
  const response = await apiPost('/merchant/invite', formData);
  return response.data;
};

export const inviteEntity = async (formData: object) => {
  const response = await apiPost('/merchant/entity/invite', formData);
  return response.data;
};

export const getMerchant = async () => {
  const response = await apiGet('/merchant');
  return response.data;
};

export const getTAppsForMerchant = async (params: object) => {
  const response = await apiGet('/merchant/tapps', { params: params });
  return response.data;
};

export const addCustomRequirement = async (formData: object) => {
  const response = await apiPost('/merchant/id-process/custom', formData);
  return response.data;
};

export const editCustomRequirement = async (id: string, formData: object) => {
  const response = await apiPatch(`/merchant/id-process/custom/${id}`, formData);
  return response.data;
};

export const listCustomRequirements = async (params: object) => {
  const response = await apiGet('/merchant/id-process/custom', { params: params });
  return response.data;
};

export const getCustomRequirementById = async (id: string) => {
  const response = await apiGet(`/merchant/id-process/custom/${id}`);
  return response.data;
};

export const getInviteTemplate = async (params: object) => {
  const response = await apiGet('/merchant/invite/template', { params: params });
  return response.data;
};

export const updateInviteTemplate = async (formData: object) => {
  const response = await apiPost('/merchant/invite/template', formData);
  return response.data;
};

export const getInviteTemplatesListData = async (params: object) => {
  const response = await apiGet('/merchant/invite/template', { params: params });
  return response.data;
};

export const createInviteTemplate = async (formData: object) => {
  const response = await apiPost('/merchant/invite/template', formData);
  return response.data;
};

export const updateInviteTemplateById = async (templateId: string, formData: object) => {
  const response = await apiPut(`/merchant/invite/template/${templateId}`, formData);
  return response.data;
};
