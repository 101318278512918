import React from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { TButton, theme } from '../../..';
import { useTheme } from '../../../../../providers/custom-theme-provider';
import { hexToRgbA } from '../../utils/commonMethods';

interface NoDataPlaceHolderProps {
  icon?: any;
  placeholderText?: string;
  isButton?: boolean;
  buttonText?: string;
  buttonWidth?: string;
  subTitle?: string;
  isPrimaryColor?: boolean;
  onButtonClick?: () => void;
}

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  primaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  primaryBackgorund: {
    background: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.05),
  },
});

const NoDataPlaceHolder = ({
  icon,
  isButton,
  placeholderText,
  buttonText,
  buttonWidth,
  subTitle,
  isPrimaryColor = false,
  onButtonClick,
}: NoDataPlaceHolderProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  return (
    <Box
      sx={{ pt: theme.spacing(8), pb: theme.spacing(8) }}
      className={clsx('flex-basic-center flex-direction-column blankComponentContainer', classes.primaryBackgorund)}
    >
      <Box
        className={clsx('flex-basic-center blankComponentIcon', {
          [classes.primaryColor]: isPrimaryColor,
        })}
        sx={{ mb: theme.spacing(3) }}
      >
        <Box className={`icon-size-40 ${icon}`} />
      </Box>
      <Box
        sx={{ mb: theme.spacing(subTitle ? 3 : 5) }}
        className={clsx('font-weight-semibold text-large', { [classes.primaryColor]: isPrimaryColor })}
      >
        {placeholderText}
      </Box>
      {subTitle && (
        <Box sx={{ mb: theme.spacing(1) }} className="iconWithTextColor text-small">
          {subTitle}
        </Box>
      )}
      {isButton && (
        <Box>
          <TButton btnText={buttonText} variant="contained" btnWidthSize={buttonWidth} onClick={onButtonClick} />
        </Box>
      )}
    </Box>
  );
};

export default NoDataPlaceHolder;
