import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ProfileAddressRequirementsType, OwnerIdRequirementsType } from '../../types/inviteTypes';
import { colorPalette, TCheckbox, TSwitch } from '../myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';

interface IdProcessContentProps {
  individualProfileRequirements?: ProfileAddressRequirementsType[];
  ownerIdRequirements?: OwnerIdRequirementsType[];
  updateProfileRequirements?: (data: ProfileAddressRequirementsType[]) => void;
  updateOwnerRequirements?: (data: OwnerIdRequirementsType[]) => void;
}

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  btnColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  disabledState: {
    color: colorPalette.typoText.disabled,
  },
}));

const IdProcessContent = ({ individualProfileRequirements, updateProfileRequirements }: IdProcessContentProps) => {
  // Constants
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // State Variable
  const [profileRequirements, setProfileRequirements] = useState([] as ProfileAddressRequirementsType[]);

  // Use Effect
  useEffect(() => {
    if (individualProfileRequirements) {
      setProfileRequirements(individualProfileRequirements);
    }
  }, [individualProfileRequirements]);

  // Methods
  const onProfileAddressRequirementChange = (index: number) => {
    const result = profileRequirements?.map((element: ProfileAddressRequirementsType, key: number) => {
      if (key === index) {
        return {
          ...element,
          is_checked: !element?.is_checked,
        };
      }
      return {
        ...element,
      };
    });
    setProfileRequirements(result);
    updateProfileRequirements && updateProfileRequirements(result);
  };

  const onSupportingDocumentChange = (index: number) => {
    const result = profileRequirements?.map((element: ProfileAddressRequirementsType, key: number) => {
      if (key === index) {
        return {
          ...element,
          is_supporting_document_required: !element?.is_supporting_document_required,
        };
      }
      return {
        ...element,
      };
    });
    setProfileRequirements(result);
    updateProfileRequirements && updateProfileRequirements(result);
  };

  // HTML
  return (
    <Box>
      {profileRequirements?.length > 0 && (
        <Box>
          {profileRequirements?.map((element: ProfileAddressRequirementsType, index: number) => (
            <Grid container spacing={2} key={index}>
              <Grid item sm={3} md={3} lg={3}>
                <FormControlLabel
                  control={
                    <TCheckbox
                      value={element?.address_type}
                      checked={element?.is_checked}
                      onChange={() => onProfileAddressRequirementChange(index)}
                    />
                  }
                  label={<Box>{element?.address_type_label}</Box>}
                />
              </Grid>
              <Grid item sm={3} md={3} lg={3}>
                <Box className="flex-basic-center">
                  <TSwitch
                    themed
                    value={element?.is_supporting_document_required}
                    checked={element?.is_supporting_document_required && element?.is_checked}
                    disabled={!element?.is_checked}
                    onChange={() => onSupportingDocumentChange(index)}
                  />
                  <Box
                    className={clsx({
                      ['text-small']: true,
                      ['textColor-300']: element?.is_supporting_document_required && element?.is_checked,
                      [classes.disabledState]: !element?.is_supporting_document_required,
                    })}
                    sx={{ ml: 1 }}
                  >
                    {t('supportingDocLabel')}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default IdProcessContent;
