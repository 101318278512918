import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, FormGroup, Grid, IconButton, Theme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';
import moment from 'moment';

import {
  ACCEPTED_IMAGE_MIME_TYPES,
  FILE_SIZE,
  MultipleFileUpload,
  SUPPORTED_IMAGE_TYPES,
  TAutocomplete,
  TButton,
  TCheckbox,
  TTextField,
  colorPalette,
  TDatePicker,
  TTooltip,
  TLoader,
  theme,
  TDocumentViewModal,
  FilePreviewType,
} from '../myde-react-components';
import { UploadFormSchemaPayload, UploadFormSchema } from './validation';
import { CategoriesResultType, DocumentListType, MerchantDocumentDataType } from '../../types/communicationTypes';
import {
  selectCommunications,
  uploadMerchantDocument,
  setDocumentsList,
  setDocumentDetails,
  resetUploadedDocument,
  setSaveInUserRepositoryFlag,
} from '../../redux/feature/communication/communicationSlice';
import { useTheme } from '../../providers/custom-theme-provider';
import { DEFAULT_TABLE_OPTIONS } from '../myde-react-components/src/constants/constants';
import { DEFAULT_PAGINATION_LIMIT } from '../../constants/constants';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface UploadDocFormProps {
  getSelectedFiles: (file: any[]) => void;
}

interface StyleProps {
  currentThemes: Theme;
}

// styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  uploadFormContainer: {
    paddingLeft: '32px !important',
  },
  label: {
    alignItems: 'start !important',
  },
  checkboxStyle: {
    padding: '3px 10px !important',
  },
  tableCommunicationContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  deleteIcon: {
    color: colorPalette.typoText.lighten1,
  },
  linkText: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  deleteBtn: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  previewImageContainer: {
    height: 'auto',
    width: '100%',
    objectFit: 'fill',
    margin: 'auto',
  },
  previewImage: {
    height: 'auto',
  },
}));

const UploadDocForm = ({ getSelectedFiles }: UploadDocFormProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    rowsPerPage: [DEFAULT_PAGINATION_LIMIT],
  };

  // State Variables
  const [files, setFiles] = useState({} as any);
  const [category, setCategory] = useState({} as CategoriesResultType);
  const [categoriesList, setCategoriesList] = useState<Array<CategoriesResultType>>([]);
  const [saveInUserRepository, setSaveInUserRepository] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [date, setDate] = useState<string | null>('');
  const [showDocDialog, setShowDocDialog] = useState(false);
  const [document, setDocument] = useState({} as MerchantDocumentDataType);
  const [previewDocumentList, setPreviewDocumentList] = useState([] as MerchantDocumentDataType[]);

  // Use Selectors
  const {
    communicationCategories,
    documentList,
    uploadedDocument,
    documentDetails,
    loading,
    specificCommunicationData,
  } = useSelector(selectCommunications);

  // Use Effects
  useEffect(() => {
    if (communicationCategories?.length > 0) {
      setCategoriesList(communicationCategories);
    }
  }, [communicationCategories]);

  useEffect(() => {
    if (files?.name) {
      setValue('file_name', files?.name, { shouldValidate: true });
    }
  }, [files]);

  useEffect(() => {
    if (specificCommunicationData?.id) {
      const docList =
        specificCommunicationData?.document_list?.length > 0 ? specificCommunicationData?.document_list : [];
      const userRepositoryFlag = specificCommunicationData?.save_in_user_repository
        ? specificCommunicationData?.save_in_user_repository
        : false;
      setSaveInUserRepository(userRepositoryFlag);
      dispatch(setDocumentsList(docList));
    }
  }, [specificCommunicationData]);

  useEffect(() => {
    if (uploadedDocument?.doc_id && documentDetails?.file_name) {
      const data = documentList?.length > 0 ? [...documentList] : [];
      const arr: DocumentListType[] = [];
      const newDoc = {
        doc_id: uploadedDocument?.doc_id,
        display_name: documentDetails?.file_name,
        category: documentDetails?.category,
        year_month: documentDetails?.year_month,
        mime_type: documentDetails?.mime_type,
        size_in_kb: documentDetails?.size_in_kb,
        signed_url: URL.createObjectURL(files),
      };
      const doc = {
        ...newDoc,
        doc_name: documentDetails?.file_name,
      };
      const merchantDocuments: MerchantDocumentDataType[] = [doc, ...previewDocumentList];
      setPreviewDocumentList(merchantDocuments);
      arr.push(newDoc);
      const combinedData = [...arr, ...data];
      dispatch(setDocumentsList(combinedData));
      dispatch(resetUploadedDocument());
      handleCancel();
    }
  }, [uploadedDocument, documentDetails]);

  useEffect(() => {
    if (documentList?.length) {
      const merchantData: MerchantDocumentDataType[] = [];
      documentList?.forEach((listItem: DocumentListType) => {
        const newDoc: MerchantDocumentDataType = {
          doc_id: listItem?.doc_id,
          display_name: listItem?.display_name,
          category: listItem?.category,
          year_month: listItem?.year_month,
          mime_type: listItem?.mime_type,
          size_in_kb: listItem?.size_in_kb,
          signed_url: listItem?.signed_url || '',
          doc_name: listItem?.display_name,
        };
        merchantData.push(newDoc);
      });
      setPreviewDocumentList(merchantData);
    }
  }, [documentList]);

  // Methods
  const setUploadedFiles = (file: any) => {
    getSelectedFiles(file);
    setFiles(file);
  };

  const handleCategoryChange = (_event: React.SyntheticEvent, value: any) => {
    if (value) {
      setCategory(value);
      setValue('category', value.id, { shouldValidate: true });
    } else {
      setCategory({} as CategoriesResultType);
      setError('category', { type: 'custom', message: 'Category is required' });
    }
  };

  const onSubmit = async (formData: UploadFormSchemaPayload) => {
    const categoryName = categoriesList?.find((item) => item.id === formData.category) || ({} as CategoriesResultType);
    const doc = {
      file_name: formData.file_name,
      category: categoryName?.category_name,
      year_month: date,
      mime_type: files?.type,
      size_in_kb: files?.size,
    };

    dispatch(setDocumentDetails(doc));
    dispatch(uploadMerchantDocument(files));
    getSelectedFiles([]);
    setCategory({} as CategoriesResultType);
    setDate(null);
    setValue('year_month', null);
    reset();
  };

  const customBodyRender = (value: any) => {
    return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
  };

  const deleteDoc = (event: any) => {
    const data = [...documentList];
    data.splice(event?.rowIndex, 1);
    dispatch(setDocumentsList(data));
  };

  const onSelectDoc = (tableMeta: any) => {
    const rowIndex: number = tableMeta?.rowIndex || 0;
    const doc: MerchantDocumentDataType = previewDocumentList[rowIndex] || ({} as MerchantDocumentDataType);
    setDocument(doc);
    doc?.doc_id && setShowDocDialog(true);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveInUserRepository(event.target.checked);
    dispatch(setSaveInUserRepositoryFlag(event.target.checked));
  };

  const handleDateChange = (value: string) => {
    if (value) {
      const formatDate = moment(value)?.format('MMMM YYYY');
      setDate(formatDate);
      setValue('year_month', formatDate, { shouldValidate: true });
    } else {
      setDate(null);
      setValue('year_month', null, { shouldValidate: true });
    }
  };

  const getDocument = () => {
    const previewDoc: FilePreviewType = {
      name: document?.doc_name,
      preview: document?.signed_url,
      size: document?.size_in_kb,
      type: document?.mime_type,
    };
    return previewDoc;
  };

  const getAction = (tableMeta: any) => {
    return (
      <Box>
        <TTooltip title="Delete">
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              deleteDoc(tableMeta);
            }}
          >
            <span className={clsx(classes.deleteBtn, 'icon-delete icon-size-24')}></span>
          </IconButton>
        </TTooltip>
      </Box>
    );
  };

  // Form Schema Setup
  const { handleSubmit, control, formState, setValue, setError, reset } = useForm<UploadFormSchemaPayload>({
    resolver: zodResolver(UploadFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // Table Column
  const columns = [
    {
      name: 'display_name',
      label: 'File Name',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box
              className={clsx(classes.linkText, 'text-large font-weight-semibold cursorPointer')}
              onClick={() => onSelectDoc(tableMeta)}
            >
              {value ? value : '-'}
            </Box>
          );
        },
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: { customBodyRender },
    },
    {
      name: 'year_month',
      label: 'Year/Month',
      options: {
        customBodyRender: (value: any, _tableMeta: any) => {
          return (
            <Box className="text-large font-weight-regular textColor-200">
              {value ? moment(value)?.format('YYYY MMMM') : '-'}
            </Box>
          );
        },
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        customBodyRender: (_value: any, tableMeta: any) => {
          return getAction(tableMeta);
        },
      },
    },
  ];

  const handleCancel = () => {
    getSelectedFiles([]);
    setCategory({} as CategoriesResultType);
    setDate(null);
    setFiles([] as any);
    reset();
  };

  // HTML
  return (
    <Box>
      <form>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={6}>
            <MultipleFileUpload
              title={'Drag and Drop here'}
              maxFileSize={FILE_SIZE.DOCUMENT_FILE_SIZE}
              setSingleData={setUploadedFiles}
              uploadedFiles={[]}
              acceptedFileFormat={ACCEPTED_IMAGE_MIME_TYPES}
              supportedFileFormat={SUPPORTED_IMAGE_TYPES}
              isMultiple={false}
            />
          </Grid>
          <Grid item sm={12} md={6} lg={6} className={classes.uploadFormContainer}>
            <Controller
              name="category"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TAutocomplete
                  {...field}
                  value={category}
                  disabled={!files?.name}
                  options={categoriesList}
                  disablePortal={true}
                  sx={{ mb: theme.spacing(5) }}
                  onChange={handleCategoryChange}
                  getOptionLabel={(option) => option?.category_name || ''}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TTextField
                      label="Category"
                      variant="outlined"
                      error={Boolean(errors.category)}
                      helperText={errors.category?.message}
                      {...params}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="year_month"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TDatePicker
                  {...field}
                  value={date}
                  open={openDatePicker}
                  openTo="year"
                  views={['year', 'month']}
                  label="Year/Month (optional)"
                  variant="outlined"
                  format="YYYY MMMM"
                  inputFormat="YYYY MMMM"
                  clearable={true}
                  disabled={!files?.name}
                  onOpen={() => setOpenDatePicker(files?.name?.length > 0)}
                  onClose={() => setOpenDatePicker(false)}
                  onChange={(e) => handleDateChange(e)}
                  renderInput={(params) => (
                    <TTextField
                      {...params}
                      sx={{ mb: theme.spacing(5) }}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'yyyy/month',
                      }}
                      error={Boolean(errors?.year_month)}
                      helperText={errors?.year_month?.message}
                      onClick={() => setOpenDatePicker(files?.name?.length > 0)}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="file_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  sx={{ mb: theme.spacing(5) }}
                  label="File Name"
                  fullWidth
                  variant="outlined"
                  disabled={!isValid}
                  error={Boolean(errors.file_name)}
                  helperText={errors.file_name?.message}
                  {...field}
                />
              )}
            />
            <Box className="flex-basic-end">
              <TButton
                sx={{ mr: theme.spacing(5) }}
                btnText="Cancel"
                variant="text"
                disabled={false}
                onClick={handleCancel}
              />
              <TButton
                btnWidthSize="button-w-140"
                btnText="Upload"
                variant="contained"
                disabled={!isValid || isSubmitting}
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                className={classes.label}
                control={
                  <TCheckbox
                    disabled={!documentList?.length}
                    checked={saveInUserRepository && documentList?.length > 0}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t('communicationDocQuestion')}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </form>
      <TLoader loading={loading} />
      {Array.isArray(documentList) && documentList?.length > 0 && (
        <Grid container spacing={2}>
          <Grid sx={{ mt: 4 }} item sm={12} md={6} lg={6}>
            <Box className={clsx(classes.tableCommunicationContainer, 'doc-list-table')}>
              <MUIDataTable title="" data={documentList} columns={columns} options={options} />
            </Box>
          </Grid>
          <TDocumentViewModal
            open={showDocDialog}
            file={getDocument()}
            portalName={PORTAL.MERCHANT}
            closeModal={() => setShowDocDialog(false)}
          />
        </Grid>
      )}
    </Box>
  );
};

export default UploadDocForm;
