import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import clsx from 'clsx';

import { ENTITY_PROCESS_IDS, PandaDocDialog, TButton, TLoader, theme } from '../myde-react-components';
import {
  getSpecificIdProcessDetailsByProcessId,
  selectEntity,
} from '../../redux/feature/id-process/entity/entitySlice';
import { getEntityEsignUrl } from '../../api/idProcesses';
import { removeUnderscore } from '../../utils/utils';
import { ACCREDITATION_DESCRIPTION_HANDLE } from '../../constants/constants';
import { getAccredationIcon } from '../myde-react-components/src/utils/idProcess';
import { useTheme } from '../../providers/custom-theme-provider';

interface StyleProp {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProp>({
  listItemEnabled: {
    '& .MuiButtonBase-root': {
      '& .MuiSvgIcon-root': {
        fontSize: '20px!important',
      },
    },
  },
  accreditationIconBgColor: {
    background: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const AccreditationDetailsIndex = () => {
  //Constants
  const { t } = useTranslation(['common', 'entity']);
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  //Redux
  const { assignedEntityDetails, accreditationStatus } = useSelector(selectEntity);

  //State
  const [openDialog, setOpenDialog] = useState(false);
  const [esign, setEsign] = useState('');
  const [isEsignAPIPending, setIsEsignAPIPending] = useState(false);
  const [showAccreditationBadge, setShowAccreditationBadge] = useState(false);
  const [accreditationDetails, setAccreditationDetails] = useState('');

  //useEffect
  useEffect(() => {
    getAccreditationProcessDetails();
  }, []);

  useEffect(() => {
    if (accreditationStatus) {
      setAccreditationDetails(accreditationStatus);
      setShowAccreditationBadge(true);
      setIsEsignAPIPending(false);
    }
  }, [accreditationStatus]);

  //Methods
  const getAccreditationProcessDetails = debounce(() => {
    const data = {
      entity_uid: assignedEntityDetails?.trellis_uid,
      process_id: ENTITY_PROCESS_IDS.ACCREDITATION,
    };
    dispatch(getSpecificIdProcessDetailsByProcessId(data));
  }, 2000);

  const getESignUrlData = async () => {
    //Esigned url API call
    try {
      const formData = {
        document_type: t('accreditation'),
      };
      setIsEsignAPIPending(true);
      const result = await getEntityEsignUrl(
        ENTITY_PROCESS_IDS.ACCREDITATION,
        assignedEntityDetails?.trellis_uid,
        formData,
      );
      setEsign(result?.url);
      setIsEsignAPIPending(false);
      setOpenDialog(true);
    } catch (error) {
      setIsEsignAPIPending(false);
      toast.error(t('somethingWentWrongText'));
    }
  };

  const handleDialogClose = () => {
    //handle dialog close
    setOpenDialog(false);
    getAccreditationProcessDetails();
  };

  const onSigningComplete = () => {
    //signed complete
    setOpenDialog(false);
    setIsEsignAPIPending(true);
    getAccreditationProcessDetails();
  };

  const handleEdit = () => {
    setShowAccreditationBadge(false);
    setAccreditationDetails('');
  };

  return (
    <>
      <TLoader loading={isEsignAPIPending} />
      <Box className="flex-basic-space-between">
        <Box sx={{ mt: theme.spacing(9), ml: 5 }} className="textColor-200 text-h2 font-weight-semibold">
          {t('accreditation')}
        </Box>
        {accreditationDetails && (
          <Box sx={{ mr: 5, mt: 5 }}>
            <TButton
              variant="text"
              btnText="Edit"
              icon={
                <>
                  <Box sx={{ mr: 1 }} className="icon-edit"></Box>
                </>
              }
              onClick={handleEdit}
            />
          </Box>
        )}
      </Box>
      {!showAccreditationBadge && (
        <>
          <Box sx={{ ml: 5, mt: 7 }} className="textColor-200 text-medium font-weight-semibold">
            {t('accreditationFormInfo')}
          </Box>
          <Box sx={{ mt: 2, mx: 5 }}>
            <List>
              <ListItem
                sx={{ mb: theme.spacing(5), px: theme.spacing(3), py: theme.spacing(5) }}
                className={clsx(
                  'text-large textColor-200 font-weight-medium listItemStyle cursorPointer',
                  classes.listItemEnabled,
                )}
                secondaryAction={
                  <>
                    <Box className="icon-arrow-right icon-size-20"></Box>
                  </>
                }
                onClick={getESignUrlData}
              >
                <Box className="text-medium font-weight-medium textColor-200">Accreditation Form</Box>
              </ListItem>
            </List>
          </Box>
        </>
      )}
      {showAccreditationBadge && (
        <>
          <Box sx={{ ml: 5, mt: 7 }} className="textColor-200 text-medium font-weight-medium">
            {t('accreditationStatus')}
          </Box>
          <Box sx={{ my: theme.spacing(5), mx: 5, p: 4 }} className="accrediatedSection flex-column-center">
            <Box className="accrediationBadgeContainer flex-basic-center">
              <Box className={clsx('accreditationIconContainer flex-basic-center', classes.accreditationIconBgColor)}>
                <Box className={clsx(`text-white icon-size-24 ${getAccredationIcon(accreditationDetails)}`)}></Box>
              </Box>
            </Box>
            <Box sx={{ mt: theme.spacing(3) }} className="textColor-200 text-h3 font-weight-semibold">
              {removeUnderscore(accreditationDetails)}
            </Box>
            <Box sx={{ mt: 1 }} className="text-center">
              {t(ACCREDITATION_DESCRIPTION_HANDLE[accreditationDetails], { ns: 'entity' })}
            </Box>
          </Box>
        </>
      )}
      <PandaDocDialog
        title={`Form Accreditation`}
        open={openDialog}
        onClose={handleDialogClose}
        onComplete={onSigningComplete}
        esign={esign}
      />
    </>
  );
};

export default AccreditationDetailsIndex;
