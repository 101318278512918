import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getSpecificIdProcessDetails, selectProfile } from '../../redux/feature/id-process/profileIdProcessSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import {
  colorPalette,
  FilePreviewType,
  TButton,
  TDocumentViewModal,
  theme,
  TLoader,
  TSelect,
} from '../../components/myde-react-components';
import { PredefinedProcessType } from '../../types/applicantTypes';
import { AddressData, PhoneType, DocumentType, ProfileType } from '../../types/idProcessTypes';
import UserNoteSummary from '../../components/userNotes/UserNoteSummary';
import TabHeader from '../common/tab-header/TabHeader';
import { getIdProcessReviewStatus, getProfileIdProcessIcon } from '../../utils/idProcess';
import { DIALOG_WIDTH, ENTITY_PROCESS_IDS, PhoneCategory } from '../myde-react-components/src/constants/constants';
import { PROCESS_IDS, PROCESS_STATUSES, USER_TYPE } from '../../constants/constants';
import { useTheme } from '../../providers/custom-theme-provider';
import Tooltip from '../myde-react-components/src/components/Tooltip/Tooltip';
import { removeUnderscore } from '../../utils/utils';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface StyleProps {
  currentThemes: Theme;
}

interface ProfileDetailProps {
  idProcess: PredefinedProcessType;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  status: {
    color: colorPalette.typoText.lighten1,
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },

  formStyle: {
    width: '-webkit-fill-available',
    maxWidth: '250px',
  },
  icon: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },

  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: `${DIALOG_WIDTH.MERCHANT_PORTAL_SMALL}px !important`,
        '& .MuiDialogActions-root': {
          padding: '0 !important',
        },
      },
    },
  },

  docView: {
    width: '18.75rem',
  },
});

const ProfileDetail = ({ idProcess }: ProfileDetailProps) => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation(['profile', 'common']);

  // Redux Values
  const { applicationId, applicantionDetail, applicationIdProcessList } = useSelector(selectApplicant);
  const { profileIdProcessDetail, profileLoading } = useSelector(selectProfile);

  // State Values
  const [processId, setProcessId] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState({} as ProfileType);
  const [selectInput, setSelectInput] = useState('');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const addressVerificationStatus = [
    PROCESS_STATUSES.VERIFICATION_FAILED,
    PROCESS_STATUSES.NEEDS_MANUAL_REVIEW,
    PROCESS_STATUSES.VERIFICATION_PENDING,
  ];
  const [files, setFiles] = useState({} as FilePreviewType);

  // Use Effects
  useEffect(() => {
    try {
      if (idProcess?.process_id) {
        setProcessId(idProcess?.process_id);
      }
    } catch (error: any) {}
  }, [idProcess]);
  useEffect(() => {
    if (processId > 0) {
      getProcessDetails();
    }
  }, [processId]);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      const data = applicationIdProcessList?.id_process_list?.find(
        (item) =>
          item.process_id === PROCESS_IDS.PROFILE_VERIFICATION ||
          item.process_id === ENTITY_PROCESS_IDS.PROFILE_VERIFICATION,
      );
      if (data?.process_id) {
        setStatus(getVerificationStatus(data?.merchant_review_status, profileIdProcessDetail?.status));
      }
    }
  }, [applicationIdProcessList, profileIdProcessDetail]);

  useEffect(() => {
    if (profileIdProcessDetail?.data?.length > 0) {
      setSelectedProfile(profileIdProcessDetail?.data[0]);
      setSelectInput(profileIdProcessDetail?.data[0]?.id);
    }
  }, [profileIdProcessDetail]);

  // Methods
  const getProcessDetails = () => {
    const data = {
      applicationId: applicationId,
      processId: processId,
    };
    dispatch(getSpecificIdProcessDetails(data));
  };

  const getVerificationStatus = (reviewStatus: string, currentStatus: string) => {
    return getIdProcessReviewStatus(reviewStatus, currentStatus);
  };

  const getAddress = (address: AddressData, lineNumber: number) => {
    if (lineNumber === 1) {
      return (
        <Box sx={{ mb: theme.spacing(1) }} className="text-large font-weight-semibold textColor-200">
          {address?.address1}
        </Box>
      );
    } else if (lineNumber === 2) {
      return (
        address?.address2 && (
          <Box sx={{ mb: theme.spacing(1) }} className="text-large font-weight-semibold textColor-200">
            {address?.address2}
          </Box>
        )
      );
    } else if (lineNumber === 3) {
      return (
        address?.address3 && (
          <Box sx={{ mb: theme.spacing(1) }} className="text-large font-weight-semibold textColor-200">
            {address?.address3}
          </Box>
        )
      );
    } else if (lineNumber === 4) {
      return (
        <Box sx={{ mb: theme.spacing(1) }} className="text-large font-weight-semibold textColor-200">
          {address?.city + ', ' + address?.state}
        </Box>
      );
    } else {
      return (
        <Box sx={{ mb: theme.spacing(1) }} className="text-large font-weight-semibold textColor-200">
          {address?.country + ', ' + address?.zip}
        </Box>
      );
    }
  };
  const getPhones = (phones: Array<PhoneType>) => {
    const phoneList = JSON.parse(JSON.stringify(phones));
    phoneList?.sort((a: PhoneType, b: PhoneType) => {
      return a?.category < b?.category ? -1 : a?.category > b?.category ? 1 : 0;
    });
    return (
      <>
        {phoneList?.map((element: PhoneType, index: number) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Box sx={{ mb: theme.spacing(1) }} className="text-medium textColor-300">
              {element?.category === PhoneCategory.PRIMARY ? t('primaryPhone') : t('secondaryPhone')}
            </Box>
            <Box
              sx={{ mb: theme.spacing(1) }}
              className="text-large font-weight-semibold textColor-200"
            >{`(${element?.phone_type})\t${element?.phone}`}</Box>
          </Box>
        ))}
      </>
    );
  };

  const handleChange = (event: any) => {
    setSelectedProfile(
      profileIdProcessDetail?.data?.find((profile) => profile.id === event.target.value) || ({} as ProfileType),
    );
    setSelectInput(event.target.value);
    return event.target.value;
  };

  const viewDetails = (doc: DocumentType) => {
    const data = {
      name: doc?.doc_name,
      type: doc?.mime_type,
      preview: doc?.signed_url,
      size: doc?.size_in_kb,
    };

    setFiles(data);
    setOpen(true);
  };

  const getTooltipTitle = (processStatus: string) => {
    if (processStatus === PROCESS_STATUSES.VERIFICATION_FAILED) {
      return t('documetVerificationFailedMsg');
    } else if (processStatus === PROCESS_STATUSES.VERIFICATION_PENDING) {
      return t('documentVerificationPendingMsg');
    } else {
      return t('documetVerificationNeedsReviewMsg');
    }
  };

  const getIconTemplate = (profileProcessStatus: string) => {
    return (
      <Box
        sx={{ ml: 2 }}
        className={clsx(`${getProfileIdProcessIcon(profileProcessStatus)} icon-size-24`, {
          [classes.icon]: profileProcessStatus === PROCESS_STATUSES.VERIFIED,
        })}
      ></Box>
    );
  };

  const getAddressType = () => {
    if (selectedProfile?.address_type && applicantionDetail?.account?.type !== USER_TYPE.ENTITY) {
      return `(${removeUnderscore(selectedProfile?.address_type)})`;
    } else {
      return '';
    }
  };

  // HTML
  return (
    <>
      {profileLoading || processId === 0 ? (
        <TLoader loading={profileLoading || processId === 0} />
      ) : (
        <Box>
          <TabHeader
            title="Profile"
            status={status}
            applicationId={applicationId}
            processId={processId}
            accountReviewStatus={applicantionDetail?.status}
            section={idProcess?.title}
          />
          {profileIdProcessDetail?.data?.length > 1 ? (
            <Box sx={{ mb: 3 }}>
              <FormControl className="w-50">
                <TSelect
                  name="profiles"
                  id="type"
                  value={selectInput}
                  fullWidth
                  options={profileIdProcessDetail?.data}
                  label="Select Profile"
                  itemId="id"
                  itemValue="profile_name"
                  onChange={handleChange}
                />
              </FormControl>
            </Box>
          ) : (
            <Box sx={{ mb: 3 }}>
              <Box sx={{ mb: theme.spacing(1) }} className="text-medium textColor-300">
                {t('profileLabel')}
              </Box>
              <Box sx={{ mb: theme.spacing(1), mr: 1 }} className="text-large font-weight-semibold textColor-200">
                {selectedProfile?.profile_name}
              </Box>
            </Box>
          )}
          <Box sx={{ my: 1 }}>
            <Box className="flex-column-start align-items-start">
              <Box sx={{ mb: theme.spacing(1) }} className="flex-basic-center">
                <Box className="text-medium textColor-300 ">{t('email')}</Box>
                {selectedProfile?.profile_email?.verification_status === PROCESS_STATUSES.VERIFICATION_PENDING ? (
                  <Tooltip title={t('emailVerificationPendingMsg')}>
                    {getIconTemplate(selectedProfile?.profile_email?.verification_status)}
                  </Tooltip>
                ) : (
                  <>{getIconTemplate(selectedProfile?.profile_email?.verification_status)}</>
                )}
              </Box>
              <Box sx={{ mb: theme.spacing(1), mr: 1 }} className="text-large font-weight-semibold textColor-200">
                {selectedProfile?.profile_email?.email}
              </Box>
            </Box>
          </Box>
          {selectedProfile?.phones?.length > 0 && <Box sx={{ my: 3 }}>{getPhones(selectedProfile?.phones)}</Box>}
          <Box sx={{ mt: 4, mb: 3 }}>
            <Box className="flex-column-start align-items-start">
              <Box sx={{ mb: theme.spacing(1) }} className="flex-basic-center">
                <Box className="text-medium textColor-300">
                  {`${t('address', {
                    ns: 'profile',
                  })} ${getAddressType()}`}
                </Box>

                {selectedProfile?.address?.address_verification_status === PROCESS_STATUSES.VERIFICATION_PENDING ? (
                  <Tooltip title={t('AddressVerificationPendingMsg')}>
                    {getIconTemplate(selectedProfile?.address?.address_verification_status)}
                  </Tooltip>
                ) : (
                  <>{getIconTemplate(selectedProfile?.address?.address_verification_status)}</>
                )}
              </Box>
              {getAddress(selectedProfile?.address, 1)}
              {getAddress(selectedProfile?.address, 2)}
              {getAddress(selectedProfile?.address, 3)}
              {getAddress(selectedProfile?.address, 4)}
              {getAddress(selectedProfile?.address, 5)}
            </Box>
          </Box>
          {selectedProfile?.address?.documents?.length > 0 && (
            <Box sx={{ my: 6 }} className={classes.docView}>
              <Box className="text-large font-weight-semibold textColor-200 flex-basic-start" sx={{ mb: 1 }}>
                <Box className="flex-basic-center">
                  <Box className="text-medium textColor-300">
                    {t('addressVerificationDoc', {
                      ns: 'profile',
                    })}
                  </Box>
                </Box>
              </Box>
              {selectedProfile?.address?.documents?.map((element: DocumentType, index: number) => (
                <Box key={index} className="flex-basic-space-between">
                  <Box className="textColor-200 text-large font-weight-semibold">
                    {`${removeUnderscore(element?.document_type || '')}`}
                  </Box>
                  <Box className="flex-basic-start">
                    <Box>
                      <TButton btnText="View" variant="text" onClick={() => viewDetails(element)} />
                    </Box>
                    <Box>
                      {addressVerificationStatus?.includes(element?.verification_status) ? (
                        <Tooltip title={getTooltipTitle(element?.verification_status)}>
                          {getIconTemplate(element?.verification_status)}
                        </Tooltip>
                      ) : (
                        <>{getIconTemplate(element?.verification_status)}</>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}

              <TDocumentViewModal
                portalName={PORTAL.MERCHANT}
                open={open}
                file={files}
                closeModal={() => setOpen(false)}
              />
            </Box>
          )}
          <Grid sx={{ mt: 3 }} lg={12} sm={12} md={12} xs={12}>
            <UserNoteSummary />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProfileDetail;
