import React from 'react';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InviteForm from './InviteForm';

const useStyles = makeStyles({
  cardContainer: {
    padding: '24px !important',
  },
});

const InviteFormIndex = () => {
  // constants
  const classes = useStyles();

  // Template
  return (
    <Container className={classes.cardContainer} sx={{ py: 3 }}>
      <InviteForm />
    </Container>
  );
};

export default InviteFormIndex;
