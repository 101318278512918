import React, { useState } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTheme } from '../../../providers/custom-theme-provider';
import { ClickHandlerType } from '../../../types/commonTypes';
import { kycAmlHistory } from '../../../types/idProcessTypes';
import { formatDate } from '../../../utils/utils';
import { colorPalette } from '../../myde-react-components/src/constants/colors';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplicant } from '../../../redux/feature/applicant/applicantSlice';
import { PROCESS_IDS } from '../../../constants/constants';
import {
  getReportHistoryByRunId,
  resetKycRunId,
  selectKyc,
  setKycRunId,
} from '../../../redux/feature/id-process/kycIdProcessSlice';
import KycAmlRecordsComponent from '../../myde-react-components/src/components/KycAmlRecords/KycAmlRecordsComponent';
import {
  KycAmlRecordType,
  KycAmlRecordDetails,
  RerunRecordType,
  KycRerunDetailsType,
} from '../../myde-react-components/src/types/KycTypes';
import KycAmlRecordEventComponent from '../../myde-react-components/src/components/KycAmlRecords/KycAmlRecordEventComponent';
import { PORTAL } from '../../myde-react-components/src/constants/portal';

interface StyleProps {
  currentThemes: Theme;
}

interface KycAmlReportHistoryProps {
  reportHistoryRecord: kycAmlHistory[];
  onBack: ClickHandlerType;
}

const useStyles = makeStyles<Theme, StyleProps>({
  pageHeader: {
    borderBottom: `1px solid ${colorPalette.border.base}`,
    paddingBottom: 12,
    position: 'relative',
  },
  kycHistoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const KycAmlReportHistory = ({ onBack, reportHistoryRecord }: KycAmlReportHistoryProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const { applicationId } = useSelector(selectApplicant);
  const { kycHistoryRecord } = useSelector(selectKyc);
  const dispatch = useDispatch();

  const [historyDate, setHistoryDate] = useState('');
  const [historyRunId, setHistoryRunId] = useState('');
  const [selectedEvent, setSelectedEvent] = useState({} as KycAmlRecordDetails);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [showSingleData, setSingleDataFlag] = useState(false);
  const [showComments, setCommentSectionFlag] = useState(true);

  const handleHistory = (report: kycAmlHistory) => {
    const data = {
      applicationId: applicationId,
      processId: PROCESS_IDS.KYC_AML,
      kycData: { diff_run_id: report?.kyc_run_id },
    };
    dispatch(getReportHistoryByRunId(data));
    setHistoryDate(report?.date);
    setHistoryRunId(report?.kyc_run_id);
    dispatch(setKycRunId(report?.kyc_run_id));
  };

  const handleRevertBack = () => {
    dispatch(resetKycRunId());
    setHistoryRunId('');
  };

  const selectRecord = (record: KycAmlRecordType, event: KycAmlRecordDetails) => {
    setSelectedEvent(event);
    setSingleDataFlag(true);
  };

  const selectNewRecord = (record: RerunRecordType, event: KycRerunDetailsType) => {
    setIsNewRecord(true);
    selectRecord(record, event);
  };

  const selectOldRecord = (record: RerunRecordType, event: KycAmlRecordDetails) => {
    setIsNewRecord(false);
    selectRecord(record, event);
  };

  const backTORecords = () => {
    setSelectedEvent({} as KycAmlRecordDetails);
    setSingleDataFlag(false);
  };

  const kycHeaderSection = (title: string) => {
    return (
      <Box className={classes.pageHeader}>
        <Box className="p-absolute backArrow cursorPointer" onClick={backTORecords}>
          <span className="icon-arrow-lefts textColor-300"></span>
        </Box>
        <Box pl={3} className="text-large font-weight-semibold textColor-200 flex-basic-start">
          {title}
        </Box>
      </Box>
    );
  };

  const toggleCommentSection = () => {
    setCommentSectionFlag(!showComments);
  };

  return (
    <>
      {!historyRunId && !showSingleData && (
        <>
          <Box className={clsx('flex-basic-start', classes.pageHeader)}>
            <Box className="backArrow cursorPointer" onClick={onBack}>
              <span className="icon-arrow-lefts textColor-300 text-font-26"></span>
            </Box>
            <Box className="text-h2 font-weight-semibold textColor-200" sx={{ ml: 2 }}>
              {t('reportHistory')}
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            {reportHistoryRecord?.map((report) => (
              <Box pb={2} key={report?.kyc_run_id}>
                <Box className={classes.kycHistoryContainer}>
                  <Box className="text-medium font-weight-semibold">{formatDate(report?.date)}</Box>
                  {(report?.new > 0 || report?.removed > 0) && (
                    <Box className={'cursorPointer'} onClick={() => handleHistory(report)}>
                      <ChevronRightIcon />
                    </Box>
                  )}
                </Box>
                <Box>
                  {report?.new > 0 &&
                    (report?.new === 1 ? (
                      <Box pb={2} className={clsx('text-medium font-weight-semibold', classes.historyText)}>
                        {t('newRecordFoundText')} {`(${report?.new})`}
                      </Box>
                    ) : (
                      <Box pb={2} className={clsx('text-medium font-weight-semibold', classes.historyText)}>
                        {t('multipleNewRecords')} {`(${report?.new})`}
                      </Box>
                    ))}
                </Box>
                <Box>
                  {report?.removed > 0 &&
                    (report?.removed === 1 ? (
                      <Box pb={2} className={clsx('text-medium font-weight-semibold', classes.historyText)}>
                        {t('recordErased')} {`(${report?.removed})`}
                      </Box>
                    ) : (
                      <Box pb={2} className={clsx('text-medium font-weight-semibold', classes.historyText)}>
                        {t('multipleRecordsErased')} {`(${report?.removed})`}
                      </Box>
                    ))}
                </Box>
                <Box>
                  {report?.new === 0 && report?.removed === 0 && (
                    <Box pt={1} pb={2} className={clsx('text-medium font-weight-semibold', classes.historyText)}>
                      {t('noNewRecords')}
                    </Box>
                  )}
                </Box>
                {reportHistoryRecord?.length > 1 && <Divider />}
              </Box>
            ))}
          </Box>
        </>
      )}

      {historyRunId && !showSingleData && (
        <KycAmlRecordsComponent
          historyRecords={kycHistoryRecord}
          historyDate={historyDate}
          revertBack={handleRevertBack}
          recordSelect={selectRecord}
          selectNewRecord={selectNewRecord}
          selectOldRecord={selectOldRecord}
          portalType={PORTAL.MERCHANT}
        />
      )}

      {showSingleData && (
        <KycAmlRecordEventComponent
          isNewRecord={isNewRecord}
          headerSection={kycHeaderSection}
          eventSelected={selectedEvent}
          commentSectionToggle={toggleCommentSection}
          commentVisible={showComments}
          commentSectionVisible={false}
          headerText={isNewRecord ? t('newRecord') : t('erasedRecord')}
          portalType={PORTAL.MERCHANT}
        />
      )}
    </>
  );
};

export default KycAmlReportHistory;
