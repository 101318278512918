import React from 'react';
import ApplicantDetails from './ApplicantDetails';

const BeneficialApplicantDetails = () => {
  return (
    <>
      <ApplicantDetails />
    </>
  );
};

export default BeneficialApplicantDetails;
