import React, { createContext, useContext, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colorPalette, theme } from '../components/myde-react-components';
import { defaultBranding } from '../constants/constants';
import { fontStyle } from '../styles/style';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/feature/user/userSlice';
type CustomThemeProviderType = {
  currentThemes: any;
  CustomTheme: any;
  theme: any;
};

export const useThemeProvider = () => {
  const { branding } = useSelector(selectUser);
  const [colors, setColors] = useState(defaultBranding.colors);
  const [drawerColors, setDrawerColors] = useState(defaultBranding.drawer_colors);

  useEffect(() => {
    if (branding && branding?.colors) {
      setColors(branding?.colors);
      branding?.drawer_colors && setDrawerColors(branding?.drawer_colors);
    } else {
      setColors(defaultBranding?.colors);
    }
  }, [branding]);

  const CustomTheme = createTheme({
    palette: {
      error: {
        main: colors?.error,
      },
      info: {
        main: colors?.info,
      },
      primary: {
        main: colors?.primary,
      },
      success: {
        main: colors?.success,
      },
      warning: {
        main: colors?.warning,
      },
      secondary: {
        main: colors?.secondary,
        dark: drawerColors?.drawer_bg,
        contrastText: drawerColors?.drawer_text,
      },
    },

    typography: {
      fontFamily: ['Basier Circle'].join(','),
      subtitle1: {
        ...fontStyle.fontWeight.font500,
        ...fontStyle.fontSize.font14,
        lineHeight: '29px',
        color: colorPalette.typoText.base,
      },
      body2: {
        ...fontStyle.fontWeight.font600,
        ...fontStyle.fontSize.font18,
        lineHeight: '21px',
        color: colorPalette.typoText.lighten1,
      },
      caption: {
        ...fontStyle.fontWeight.font500,
        ...fontStyle.fontSize.font16,
        lineHeight: '15px',
        color: colorPalette.typoText.darken2,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            color: colorPalette.containerBack.lighten3,
            ...fontStyle.fontWeight.font700,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            ...fontStyle.fontSize.font12,
            ...fontStyle.fontWeight.font500,
            color: colorPalette.typoText.base,
            fontFamily: 'Basier Circle',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            ...fontStyle.fontSize.font14,
          },
        },
      },
    },
  });
  const currentThemes = branding ? CustomTheme : theme;
  return {
    currentThemes,
    CustomTheme,
  };
};
const defaultThemeContext = {};

const CustomThemeContext = createContext<CustomThemeProviderType>(defaultThemeContext as any);

export const useTheme = () => {
  return useContext(CustomThemeContext);
};

export const CustomThemeProvider = ({ children }: { children: any }) => {
  const customTheme = useThemeProvider();
  return (
    <CustomThemeContext.Provider value={customTheme as any}>
      <ThemeProvider theme={customTheme?.currentThemes}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};
