import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  getSpecificIdProcessDetails,
  setVisibilityLevel,
  selectTaxId,
} from '../../redux/feature/id-process/taxIdIdProcessSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { colorPalette, TButton, TLoader } from '../../components/myde-react-components';
import { PredefinedProcessType } from '../../types/applicantTypes';
import { getIdProcessReviewStatus } from '../../utils/idProcess';
import { formatDate } from '../../utils/utils';
import UserNoteSummary from '../../components/userNotes/UserNoteSummary';
import { ACCESS_LEVEL, PROCESS_IDS } from '../../constants/constants';
import TabHeader from '../common/tab-header/TabHeader';
import { selectApplicationActions } from '../../redux/feature/id-process/idProcessActionsSlice';

interface TaxIdDetailProps {
  idProcess: PredefinedProcessType;
}

// Styles
const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  status: {
    color: colorPalette.typoText.lighten1,
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
});

const TaxIdDetail = ({ idProcess }: TaxIdDetailProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux Values
  const { applicantionDetail, applicationIdProcessList, applicationId } = useSelector(selectApplicant);
  const { taxIdIdProcessDetail, taxIdLoading, visibilityLevel } = useSelector(selectTaxId);
  const { loading } = useSelector(selectApplicationActions);

  // State Values
  const [status, setStatus] = useState('');
  const [idProcessData, setIdProcessData] = useState({} as PredefinedProcessType);
  const [btnText, setBtnText] = useState('');

  // Use Effects
  useEffect(() => {
    dispatch(setVisibilityLevel(false));
  }, []);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      const data = applicationIdProcessList?.id_process_list?.find(
        (item) => item.process_id === PROCESS_IDS.SSN_VERIFICATION,
      );
      if (data?.process_id) {
        setIdProcessData(data);
        setStatus(getVerificationStatus(data?.merchant_review_status, taxIdIdProcessDetail?.status));
      }
    }
  }, [applicationIdProcessList, taxIdIdProcessDetail]);
  useEffect(() => {
    try {
      if (idProcess?.process_id) {
        const data = {
          applicationId: applicantionDetail?.id,
          processId: idProcess?.process_id,
        };
        dispatch(getSpecificIdProcessDetails(data));
        setStatus(getVerificationStatus(idProcess?.merchant_review_status, taxIdIdProcessDetail?.status));
      }
    } catch (error: any) {}
  }, [idProcess]);
  useEffect(() => {
    if (visibilityLevel) {
      setBtnText('Hide');
    } else {
      setBtnText('Show');
    }
  }, [visibilityLevel]);

  // Methods
  const getVerificationStatus = (reviewStatus: string, currentStatus: string) => {
    return getIdProcessReviewStatus(reviewStatus, currentStatus);
  };
  const getUnMaskedTaxId = () => {
    const data = {
      applicationId: applicationId,
      processId: idProcess?.process_id,
      params: !visibilityLevel
        ? {
            visibility_level: 'source',
          }
        : {},
    };
    dispatch(getSpecificIdProcessDetails(data));
    dispatch(setVisibilityLevel(!visibilityLevel));
  };

  // HTML
  return (
    <>
      {taxIdLoading || loading || !status ? (
        <TLoader loading={taxIdLoading || loading || !status} />
      ) : (
        <Box>
          <TabHeader
            title="Tax ID"
            status={status}
            applicationId={applicationId}
            processId={idProcess?.process_id}
            accountReviewStatus={applicantionDetail?.status}
            section={idProcess?.title}
          />
          <Box className={classes.address} sx={{ my: 3 }}>
            <Box className="text-medium textColor-300">Tax ID:</Box>
            <Box className={classes.header}>
              <Box className="text-large font-weight-semibold textColor-200">{taxIdIdProcessDetail?.data?.ssn}</Box>
              {idProcessData?.access_level === ACCESS_LEVEL.SOURCE && (
                <Box sx={{ mx: 2 }}>
                  <TButton variant="text" btnText={btnText} onClick={getUnMaskedTaxId} />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.address} sx={{ my: 3 }}>
            <Box className="text-medium textColor-300">Birthdate:</Box>
            <Box className="text-large font-weight-semibold textColor-200">
              {formatDate(taxIdIdProcessDetail?.data?.dob) || '-'}
            </Box>
          </Box>
          <Grid sx={{ mt: 3 }} lg={12} sm={12} md={12} xs={12}>
            <UserNoteSummary />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TaxIdDetail;
