import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectEntity } from '../../redux/feature/id-process/entity/entitySlice';
import { EntityDetail } from '../../types/entityTypes';
import { ENTITY_TYPE, TLoader, theme } from '../../components/myde-react-components';

const AddedEntityDetails = () => {
  //Constants
  const { assignedEntityDetails } = useSelector(selectEntity);
  const { t } = useTranslation('common');

  //State variables
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  //Use Effect
  useEffect(() => {
    if (assignedEntityDetails) {
      setSelectedEntity(assignedEntityDetails);
      setIsLoading(false);
    }
  }, [assignedEntityDetails]);

  useEffect(() => {
    const result = ENTITY_TYPE?.find((type) => selectedEntity?.details?.entity_info?.entity_type === type?.id);
    setType(result?.name || '');
  }, [selectedEntity]);

  return (
    <>
      <TLoader loading={isLoading} />
      <Box sx={{ pt: theme.spacing(8), pl: 5 }}>
        <Box className="textColor-400">{t('type')}</Box>
        <Box className="font-weight-medium text-large">{type}</Box>
        <Box sx={{ pt: theme.spacing(8) }} className="textColor-400">
          {t('email')}
        </Box>
        <Box className="font-weight-medium text-large">{selectedEntity?.email}</Box>
        <Box sx={{ pt: theme.spacing(8) }} className="textColor-400">
          {t('legalName')}
        </Box>
        <Box className="font-weight-medium text-large">{selectedEntity?.name}</Box>
        {selectedEntity?.details?.entity_info?.dba_name && (
          <>
            <Box sx={{ pt: theme.spacing(8) }} className="textColor-400">
              {t('dbaNameText')}
            </Box>
            <Box className="font-weight-medium text-large">{selectedEntity?.details?.entity_info?.dba_name}</Box>
          </>
        )}
        {selectedEntity?.details?.entity_info?.website && (
          <>
            <Box sx={{ pt: theme.spacing(8) }} className="textColor-400">
              {t('website')}
            </Box>
            <Box className="font-weight-medium text-large">{selectedEntity?.details?.entity_info?.website}</Box>
          </>
        )}
        <Box sx={{ pt: theme.spacing(8) }} className="textColor-400">
          {t('stateregionofFormation')}
        </Box>
        <Box className="font-weight-medium text-large">
          {selectedEntity?.details?.entity_info?.formation_state_name}
        </Box>
        <Box sx={{ pt: theme.spacing(8) }} className="textColor-400">
          {t('countryOfFormation')}
        </Box>
        <Box className="font-weight-medium text-large">
          {selectedEntity?.details?.entity_info?.formation_country_name}
        </Box>
      </Box>
    </>
  );
};

export default AddedEntityDetails;
