import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { MAX_CHAR_LIMIT, TDialog, theme, TTextArea } from '../myde-react-components';
import { BtnType, ClickHandlerType } from '../../types/commonTypes';
import clsx from 'clsx';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface ConfirmDialogProps {
  showDialog: boolean;
  title?: string;
  message: string;
  noteLabel: string;
  noBtnTitle?: string;
  yesBtnTitle?: string;
  classNameDailog?: string;
  onCancel: ClickHandlerType;
  onConfirm: ClickHandlerType;
  isNoteOptional?: boolean;
}

const useStyles = makeStyles({
  confirmDialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '560px!important',
        boxShadow: 'none !important',
        '& .MuiDialogActions-spacing': {
          display: 'flex !important',
        },
      },
    },
  },
  noteCount: {
    display: 'flex',
    justifyContent: 'end',
  },
});

export const ConfirmWithReasonDialog = ({
  title = 'Confirm Delete',
  message,
  noBtnTitle = 'No',
  yesBtnTitle = 'Yes',
  showDialog = false,
  noteLabel,
  classNameDailog,
  onCancel,
  onConfirm,
  isNoteOptional = true,
}: ConfirmDialogProps) => {
  const classes = useStyles();

  // State Values
  const [formDetail, setFormDetail] = useState({
    note: '',
  });
  const [count, setCount] = useState(0);

  // Constants
  const FormSchema = z.object({
    note: isNoteOptional
      ? z.string().max(300, { message: 'Reason can be 300 characters long' })
      : z
          .string()
          .min(1, { message: 'Decline Reason is required' })
          .max(300, { message: 'Reason can be 300 characters long' }),
  });
  type FormSchemaPayload = z.infer<typeof FormSchema>;
  const { handleSubmit, control, formState, setValue } = useForm<FormSchemaPayload>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;

  // Use Effects
  useEffect(() => {
    setFormDetail({ note: '' });
    setValue('note', '');
  }, [showDialog]);

  // Methods
  const onSubmit = async (formData: FormSchemaPayload) => {
    setFormDetail({
      note: formData.note,
    });
    onConfirm(formData);
  };
  const onCancelAction = () => {
    onCancel();
  };
  const onConfirmAction = () => {
    handleSubmit(onSubmit)();
  };
  const handleMsgChange = (value: string) => {
    setValue('note', value, { shouldValidate: true });
    setCount(value.length);
    return value;
  };

  const confDialogBtns: BtnType[] = [
    {
      id: 1,
      title: noBtnTitle,
      variant: 'text',
      buttonAction: onCancelAction,
      disabled: false,
    },
    {
      id: 2,
      title: yesBtnTitle,
      variant: 'contained',
      buttonAction: onConfirmAction,
      disabled: !isValid,
    },
  ];

  return (
    <TDialog
      className={clsx(classes.confirmDialog, classNameDailog)}
      open={showDialog}
      title={title}
      buttons={confDialogBtns}
      portalName={PORTAL.MERCHANT}
    >
      <Box className="font-weight-medium" sx={{ mb: theme.spacing(3) }}>
        {message}
      </Box>
      <Controller
        name="note"
        defaultValue={formDetail.note}
        control={control}
        render={({ field }) => (
          <TTextArea
            {...field}
            variant="outlined"
            fullWidth
            error={Boolean(errors.note)}
            helperText={errors.note?.message}
            label={noteLabel}
            isOptional={isNoteOptional}
            inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
            onChange={(event) => {
              const { value } = event.target;
              handleMsgChange(value);
            }}
          />
        )}
      />
      <Box
        className={clsx({
          [classes.noteCount]: true,
          ['text-small font-weight-semibold textColor-300']: true,
        })}
        sx={{ pt: 1 }}
      >{`${MAX_CHAR_LIMIT.CHAR_LENGTH - count} character(s) remaining`}</Box>
    </TDialog>
  );
};

export default ConfirmWithReasonDialog;
