import z from 'zod';
import { REGEX } from '../myde-react-components';

export const EditMemberFormSchema = z.object({
  ownership_percentage: z
    .string()
    .nullish()
    .optional()
    .refine((value) => REGEX.NUMBER_DECIMAL.test(value || ''), {
      message: 'Please enter a valid number',
    })
    .or(z.literal('')),
  permission: z.string().min(1, { message: 'Permissions is required' }),
  is_beneficial_owner: z.boolean().optional(),
});

export type EditMemberFormSchemaPayload = z.infer<typeof EditMemberFormSchema>;
