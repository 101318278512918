import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { ID_PROCESS_REVIEW_STATUS, PROCESS_STATUSES_LABEL } from '../../../constants/constants';

// Interface
interface IdProcessIconProps {
  merchantReviewStatus: string;
  processStatus: string;
}

// Main Component
const IdProcessIcon = ({ merchantReviewStatus, processStatus }: IdProcessIconProps) => {
  // State Values
  const [reviewStatus, setReviewStatus] = useState(ID_PROCESS_REVIEW_STATUS.UNREVIEWED);

  // Use Effects
  useEffect(() => {
    if (merchantReviewStatus) {
      setReviewStatus(merchantReviewStatus);
    }
  }, [merchantReviewStatus]);

  // Methods
  const getIcon = () => {
    switch (reviewStatus) {
      case ID_PROCESS_REVIEW_STATUS.UNREVIEWED:
        if (processStatus === PROCESS_STATUSES_LABEL.VERIFIED) {
          return <Box className="icon-verify-but-notapprove textColor-300" sx={{ mt: 2 }} />;
        } else if (processStatus === PROCESS_STATUSES_LABEL.NOT_VERIFIED) {
          return <Box className="icon-ideal-state inprogress-color-state" color="warning" sx={{ mt: 2 }} />;
        } else if (processStatus === PROCESS_STATUSES_LABEL.NEEDS_MANUAL_REVIEW) {
          return <Box className="icon-error-warning success-color-state" color="warning" sx={{ mt: 2 }} />;
        } else {
          return <Box className="icon-ideal-state textColor-300" fontSize="medium" sx={{ mt: 2 }} />;
        }
      case ID_PROCESS_REVIEW_STATUS.APPROVED:
        return <Box className="icon-approved success-color-state" color="primary" sx={{ mt: 2 }} />;
      case ID_PROCESS_REVIEW_STATUS.REJECTED:
        return <Box className="icon-forbid error-color-state" color="error" sx={{ mt: 2 }} />;
    }
  };

  // HTML
  return <>{getIcon()}</>;
};

export default IdProcessIcon;
