import * as z from 'zod';

export const CommunicationFormSchema = z.object({
  to: z.string(),
  title: z.string().min(1, { message: 'Title is required' }),
  message: z.string(),
});

export const UploadFormSchema = z.object({
  category: z.string().min(1, { message: 'Category is required' }),
  year_month: z.string().nullish(),
  file_name: z.string().min(1, { message: 'File Name is required' }),
});

export type CommunicationFormSchemaPayload = z.infer<typeof CommunicationFormSchema>;
export type UploadFormSchemaPayload = z.infer<typeof UploadFormSchema>;
