import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

import { FILE_SIZE, MultipleFileUpload, PDF_MIME_TYPE, PDF_SUPPORTED_TYPE, TLoader } from '../myde-react-components';
import PreviewFileByType from './PreviewFileByType';
import { FilePreviewType } from '../../types/documentTypes';

// Interfaces
interface DocumentUploadAndListingProps {
  label?: string;
  isUploading?: boolean;
  isMultiple?: boolean;
  acceptedFileFormat?: string[];
  supportedFileFormat?: string[];
  submittedFiles: any[];
  setSingleFile?: (file: any) => void;
  setMultipleFiles?: (newFiles: FilePreviewType[], oldFiles: FilePreviewType[]) => void;
}

const DocumentUploadAndListing = ({
  label = '',
  isUploading = false,
  isMultiple = false,
  acceptedFileFormat = PDF_MIME_TYPE,
  supportedFileFormat = PDF_SUPPORTED_TYPE,
  submittedFiles,
  setSingleFile,
  setMultipleFiles,
}: DocumentUploadAndListingProps) => {
  // Constants
  const { t } = useTranslation('common');

  // State Variables
  const [filesData, setFilesData] = useState([] as FilePreviewType[]);

  // Use Effects
  useEffect(() => {
    if (submittedFiles?.length > 0) {
      setFilesData(submittedFiles);
    } else {
      setFilesData([] as FilePreviewType[]);
    }
  }, [submittedFiles]);

  // Methods
  const setData = async (file: any) => {
    if (setSingleFile) {
      setSingleFile([file]);
    }
  };

  const setMultipleFilesData = async (newFiles: FilePreviewType[], oldFiles: FilePreviewType[]) => {
    if (setMultipleFiles) {
      setMultipleFiles(newFiles, oldFiles);
    }
  };

  const handleDeleteFile = async (file: FilePreviewType) => {
    const uploadedFilesData = [...filesData];
    const uploadedFilesIndex = uploadedFilesData?.findIndex((item) => item?.name === file?.name);
    if (uploadedFilesIndex !== -1) {
      uploadedFilesData?.splice(uploadedFilesIndex, 1);
    }
    if (isMultiple) {
      if (setMultipleFiles) {
        setMultipleFiles([], uploadedFilesData);
      }
    } else {
      if (setSingleFile) {
        setSingleFile([] as any[]);
      }
    }
  };

  // HTML
  return (
    <Box>
      <TLoader loading={isUploading} />
      {label && (
        <Box className="text-medium font-weight-semibold textColor-200" sx={{ mb: 2 }}>
          {label}
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} lg={6}>
          <MultipleFileUpload
            title={t('dragNDrop')}
            subtitle={t('uploadFromDirectory')}
            maxFileSize={FILE_SIZE.DOCUMENT_FILE_SIZE}
            setSingleData={setData}
            setMultipleData={setMultipleFilesData}
            uploadedFiles={filesData}
            acceptedFileFormat={acceptedFileFormat}
            supportedFileFormat={supportedFileFormat}
            isMultiple={isMultiple}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          {filesData?.length === 0 ? (
            <Box className="text-small textColor-300">{t('noFiles')}</Box>
          ) : (
            <Box>
              <Box className="text-small textColor-300" sx={{ mb: 2 }}>
                {t('uploaded')}
              </Box>
              <Box className="fileListContainer">
                <PreviewFileByType files={filesData} handleDelete={handleDeleteFile} />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentUploadAndListing;
