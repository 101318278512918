import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  customIdProcess,
  getCustomIdProcessDetails,
  setIsCustomIdProcess,
} from '../../redux/feature/id-process/customIdProcessSlice';
import { CustomIdProcessResultType } from '../../types/applicantTypes';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { FilePreviewType, TLoader } from '../myde-react-components';
import TabHeader from '../common/tab-header/TabHeader';
import PreviewFileByType from './PreviewFileByType';
import UserNoteSummary from '../userNotes/UserNoteSummary';
import { useTranslation } from 'react-i18next';
import { PORTAL } from '../myde-react-components/src/constants/portal';

const CustomIdTab = () => {
  const { customIdProcessData, loading } = useSelector(customIdProcess);
  const { currentTabId, applicantionDetail } = useSelector(selectApplicant);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  //state
  const [customIdData, setCustomIdData] = useState({} as CustomIdProcessResultType);
  const [files, setFiles] = useState([] as FilePreviewType[]);

  useEffect(() => {
    const data = {
      applicationId: applicantionDetail?.id,
      accountId_processId: currentTabId.toString(),
    };
    dispatch(getCustomIdProcessDetails(data));
  }, []);

  useEffect(() => {
    if (Object.keys(customIdProcessData).length > 0) {
      setCustomIdData(customIdProcessData);
      dispatch(setIsCustomIdProcess(true));
    }
  }, [customIdProcessData]);

  useEffect(() => {
    setPreviewFiles(customIdProcessData?.process_data?.document_list);
  }, [customIdProcessData?.process_data?.document_list]);

  const setPreviewFiles = (files: any) => {
    const fileList: any[] = [];
    files?.forEach((file: any) => {
      const data = {
        name: file?.doc_name,
        type: file?.mime_type,
        preview: file?.signed_url,
        size: file?.size_in_kb,
      };
      fileList?.push(data);
    });
    const combineFile = [...fileList];
    setFiles(combineFile);
  };

  return (
    <>
      <Box>
        <TLoader loading={loading} />
        <Box>
          <Box>
            <TabHeader
              title={customIdData?.process_name}
              status={''}
              applicationId={applicantionDetail?.id}
              processId={currentTabId}
              accountReviewStatus={applicantionDetail?.status}
              section={customIdData?.process_data?.process_category}
              customIdProcess={true}
            />
          </Box>
          {customIdData?.process_data?.process_description?.length > 0 && (
            <>
              <Box sx={{ pt: 5 }} className="text-medium textColor-300">
                {t('description')}
              </Box>
              <Box sx={{ pt: 1 }} className="font-weight-semibold text-large textColor-200">
                {customIdData?.process_data?.process_description}
              </Box>
            </>
          )}
          {customIdData?.process_data?.document_list?.length > 0 && (
            <>
              <Box sx={{ pt: 4 }} className="text-medium textColor-300">
                {t('documents')}
              </Box>
              <Box>
                <PreviewFileByType portalName={PORTAL.MERCHANT} files={files} />
              </Box>
            </>
          )}
          <Box sx={{ pt: 5 }}>
            <UserNoteSummary />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomIdTab;
