import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { session } from '../../../api/login';
import { UserSession, Branding } from '../../../types/userTypes';
const initialState = {
  session: <UserSession>{},
  showCard: false,
  branding: <Branding>{},
  loading: false,
  error: false,
  errorObj: <any>{},
  showAlert: false,
  nextRoute: <any>{},
  selectedActiveCardNav: 0,
};

export const selectSession = ({ common }: RootState) => ({
  session: common.session,
  showAlert: common.showAlert,
  nextRoute: common.nextRoute,
  showCard: common.showCard,
});

export const getSessionDetails = createAsyncThunk('common/getSessionDetails', async () => {
  return await session();
});

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},

    setShowAlert: (state, action) => {
      state.showAlert = action.payload;
    },
    setNextRoute: (state, action) => {
      state.nextRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSessionDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.session = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSessionDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { clearResults, setShowAlert, setNextRoute } = commonSlice.actions;

export default commonSlice.reducer;
