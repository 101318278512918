import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
  approveRejectApplication,
  getApplicantDetailById,
  getApplicationIdProcess,
  getParentApplication,
} from '../../../api/applicants';
import { ApplicationIdProcessType, ApplicationType, ApplicantType } from '../../../types/applicantTypes';

interface applicationStatusType {
  id: string;
  status: string;
  note: string;
}

const initialState = {
  loading: false,
  error: false,
  errorObj: <any>{},
  applicantDetails: <ApplicantType>{},
  applicantionDetail: <ApplicationType>{},
  parentApplicationDetailList: [] as ApplicationType[],
  applicationIdProcessList: <ApplicationIdProcessType>{},
  applicationId: '',
  currentTabId: 0,
  accountStatusMsg: '',
  selectedSection: '',
  isApplicationUpdated: false,
  parentApplicationIdProcessList: <ApplicationIdProcessType>{},
};

export const selectApplicant = ({ applicant }: RootState) => ({
  applicantionDetail: applicant.applicantionDetail,
  applicationIdProcessList: applicant.applicationIdProcessList,
  applicantDetails: applicant.applicantDetails,
  loading: applicant.loading,
  error: applicant.error,
  applicationId: applicant.applicationId,
  currentTabId: applicant.currentTabId,
  accountStatusMsg: applicant.accountStatusMsg,
  selectedSection: applicant.selectedSection,
  isApplicationUpdated: applicant.isApplicationUpdated,
  parentApplicationDetailList: applicant.parentApplicationDetailList,
  parentApplicationIdProcessList: applicant.parentApplicationIdProcessList,
});

// Actions
export const getApplicantDetails = createAsyncThunk('applicant/getApplicantDetails', async (id: string) => {
  return await getApplicantDetailById(id);
});

export const getApplicationIdProcesses = createAsyncThunk('applicant/getApplicationIdProcesses', async (id: string) => {
  return await getApplicationIdProcess(id);
});

export const updateApplication = createAsyncThunk(
  'applicant/updateApplication',
  async (data: applicationStatusType) => {
    return await approveRejectApplication(data?.id, data?.status, data?.note);
  },
);

export const getParentApplicationById = createAsyncThunk('applicant/getParentApplicationById', async (id: string) => {
  return await getParentApplication(id);
});

export const getParentApplicationIdProcesses = createAsyncThunk(
  'applicant/getParentApplicationIdProcesses',
  async (id: string) => {
    return await getApplicationIdProcess(id);
  },
);

// Reducers
export const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    setSelectedApplicationId: (state, action) => {
      state.applicationId = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTabId = action.payload;
    },
    setAccountStatusMsg: (state, action) => {
      state.accountStatusMsg = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setApplicationUpdatedFlag: (state, action) => {
      state.isApplicationUpdated = action.payload;
    },
    clearApplicationIdProcessList: (state) => {
      state.applicationIdProcessList.id_process_list = [];
    },
    resetApplicantDetails: (state) => {
      state.applicantDetails = <ApplicantType>{};
      state.applicationId = '';
      state.applicantionDetail = <ApplicationType>{};
      state.applicationIdProcessList = <ApplicationIdProcessType>{};
      state.parentApplicationDetailList = [] as ApplicationType[];
      state.parentApplicationIdProcessList = <ApplicationIdProcessType>{};
      state.accountStatusMsg = '';
      state.selectedSection = '';
    },
    resetParentApplicationDetails: (state) => {
      state.parentApplicationDetailList = [] as ApplicationType[];
      state.parentApplicationIdProcessList = <ApplicationIdProcessType>{};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicantDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicantDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.applicantionDetail = action.payload.results ? action.payload.results[0] : [];
          state.applicantDetails = state.applicantionDetail?.account;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicantDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getApplicationIdProcesses.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationIdProcesses.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.applicationIdProcessList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getApplicationIdProcesses.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateApplication.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateApplication.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.error = false;
          state.isApplicationUpdated = true;
        } else {
          state.error = true;
        }
      })
      .addCase(updateApplication.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getParentApplicationById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getParentApplicationById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.parentApplicationDetailList = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getParentApplicationById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getParentApplicationIdProcesses.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getParentApplicationIdProcesses.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.parentApplicationIdProcessList = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getParentApplicationIdProcesses.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setSelectedApplicationId,
  setCurrentTab,
  setAccountStatusMsg,
  setSelectedSection,
  setApplicationUpdatedFlag,
  clearApplicationIdProcessList,
  resetApplicantDetails,
  resetParentApplicationDetails,
} = applicantSlice.actions;

export default applicantSlice.reducer;
