import {
  MsgApiFormData,
  NoteApiFormData,
  DeleteNoteApiFormData,
  UpdateNoteApiFormData,
  SummaryDataType,
  MsgFilterApiFormData,
} from '../components/userMessages/UserMessageList';
import { AddTagType, SaveTagType, SearchTagType } from '../types/applicantTagTypes';
import { apiPost, apiGet, apiPatch, apiDelete, apiPut } from './axios';

// Applicant Account APIs
export const getApplicantDetailById = async (id: string) => {
  const res = await apiGet(`/merchant/application?id=${id}`);
  return res.data;
};
export const getApplicationIdProcess = async (id: string) => {
  const res = await apiGet(`/merchant/application/${id}/id-process`);
  return res.data;
};
export const approveRejectApplication = async (id: string, status: string, note: string) => {
  const data = { status: status, note: note };
  const res = await apiPatch(`/merchant/application/${id}`, data);
  return res.data;
};
export const getParentApplication = async (id: string) => {
  const res = await apiGet(`/merchant/application?parent_application_id=${id}`);
  return res.data;
};

// User Msg APIs
export const getApplicationMessageList = async (formData: MsgApiFormData) => {
  const res = await apiGet(
    `/merchant/application/${formData.applicationId}/communication?page=${formData.page}&is_archived=${formData.is_archived}&is_resolved=${formData.is_resolved}&limit=${formData.limit}&ordering=${formData.ordering}`,
  );
  return res.data;
};
export const getApplicationMessageSummary = async (applicationId: string) => {
  const res = await apiGet(`/merchant/application/${applicationId}/communication/summary`);
  return res.data;
};
export const getMessageDetailById = async (data: { applicationId: string; messageId: string }) => {
  const res = await apiGet(`/merchant/application/${data.applicationId}/communication/${data.messageId}`);
  return res.data;
};
export const createNewMsg = async (data: { applicationId: string; request: string }) => {
  const reqData = { request: data.request };
  const res = await apiPost(`/merchant/application/${data.applicationId}/communication`, reqData);
  return res.data;
};
export const updateMsgStatus = async (data: { applicationId: string; msgId: string; status: string }) => {
  const reqData = { action: data.status };
  const res = await apiPatch(`/merchant/application/${data.applicationId}/communication/${data.msgId}`, reqData);
  return res.data;
};
export const updateMerchantReply = async (data: { text: string; appId: string; msgId: string }) => {
  const reqData = { text: data.text };
  const res = await apiPatch(`/merchant/application/${data.appId}/communication/${data.msgId}/response`, reqData);
  return res.data;
};
export const filteredRecords = async (filterData: MsgFilterApiFormData) => {
  const res = await apiGet(`/merchant/application/${filterData?.applicationId}/communication`, {
    params: {
      ...filterData.formPayload,
    },
  });
  return res.data;
};

// Notes
export const addNotesToApplication = async (id: string, data: any) => {
  const res = await apiPost(`/merchant/application/${id}/note`, data.data);
  return res.data;
};

export const getApplicationNoteList = async (formData: NoteApiFormData) => {
  const res = await apiGet(
    `/merchant/application/${formData.applicationId}/note?page=${formData.page}&is_archived=${formData.is_archived}&limit=${formData.limit}&section=${formData.section}`,
  );
  return res.data;
};

export const getApplicationNoteHistory = async (applicationId: string, noteId: string) => {
  const res = await apiGet(`/merchant/application/${applicationId}/note/${noteId}`);
  return res.data;
};

export const deleteApplicationNoteList = async (formData: DeleteNoteApiFormData) => {
  const res = await apiDelete(`/merchant/application/${formData.applicationId}/note/${formData.noteId}`);
  return res.data;
};

export const getApplicationNotesById = async (formData: DeleteNoteApiFormData) => {
  const res = await apiGet(`/merchant/application/${formData.applicationId}/note?id=${formData.noteId}`);
  return res.data;
};

export const getApplicationNoteSummary = async (summary: SummaryDataType) => {
  const res = await apiGet(`/merchant/application/${summary.applicationId}/note/summary?section=${summary.section}`);
  return res.data;
};

export const updateNotesToApplication = async (id: string, data: UpdateNoteApiFormData) => {
  const res = await apiPut(`/merchant/application/${id}/note/${data.noteId}`, data.data);
  return res.data;
};

//Tags
//get all the tags
export const getTagsListById = async (id: string) => {
  const res = await apiGet(`/merchant/user/${id}/tag`);
  return res.data;
};

//add a new user tag
export const createUserTag = async (data: AddTagType) => {
  const res = await apiPost(`/merchant/tag`, data);
  return res.data;
};

//get all merchant tags || search tags
export const getMerchantTags = async (data?: SearchTagType) => {
  const url = data ? `merchant/tag?tag_name=${data?.tag_name}&limit=1000` : `merchant/tag?limit=1000`;
  const res = await apiGet(url);
  return res.data;
};

//to save tags
export const saveTags = async (id: string, data: SaveTagType) => {
  const res = await apiPost(`/merchant/user/${id}/tag`, data);
  return res.data;
};

//get tags assigned to a user
export const getSavedTags = async (id: string) => {
  const res = await apiGet(`merchant/user/${id}/tag`);
  return res.data;
};

//delete a user tag
export const deleteUserTag = async (userId: string, tagId: string) => {
  const res = await apiDelete(`/merchant/user/${userId}/tag/${tagId}`);
  return res.data;
};

export const filteredNotes = async (filterData: MsgFilterApiFormData) => {
  const res = await apiGet(`/merchant/application/${filterData?.applicationId}/note`, {
    params: {
      ...filterData.formPayload,
    },
  });
  return res.data;
};

//get custom id process details
export const getApplicantCustomId = async (data: { applicationId: string; accountId_processId: string }) => {
  const res = await apiGet(
    `/merchant/application/${data?.applicationId}/id-process/${data?.accountId_processId}/custom`,
  );
  return res.data;
};
