import { apiPost, apiGet } from './axios';

export const login = async (formData: object) => {
  const res = await apiPost('/merchant/login', formData);
  return res.data;
};

export const session = async () => {
  const res = await apiGet('/merchant/vault-login/session');
  return res.data;
};

export const bankVaultLogin = async (formData: { session: string; token: string; secret: string }) => {
  const res = await apiPost('/merchant/vault-login', formData);
  return res.data;
};
