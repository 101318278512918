export const ACCESS_TOKEN = `ACCESS_TOKEN`;
export const ACCESS_BRANDING = 'ACCESS_BRANDING';

export const DEFAULT_PAGINATION_LIMIT = 1000;

export const ID_PROCESS_WITH_SAFE_DOC = [101, 201, 202];

export const ENTITY_OWNER_ID_PROCESS_HIGH_PRIORITY = [251, 202, 201];

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Constants
export const defaultBranding = {
  colors: {
    primary: '#009F46',
    secondary: '#0075A1',
    success: '#009F46',
    warning: '#F6841B',
    error: '#E55957',
    info: '#f4f4f4',
  },
  drawer_colors: {
    drawer_bg: '#18243D',
    drawer_text: '#ffff',
  },
};

export const PROCESS_IDS = {
  PROFILE_VERIFICATION: 51,
  SSN_VERIFICATION: 101,
  DRIVERS_LICENSE: 201,
  PASSPORT: 202,
  BIOMETRIC_VERIFICATION: 301,
  SIGN_VERIFICATION: 401,
  ADDRESS_VERIFICATION: 601,
  KYC_AML: 251,
  W8_W9_DOCUMENT: 500,
  W9_DOCUMENT: 501,
  W8_DOCUMENT: 502,
  INCOME_VERIFICATION: 701,
  ACCREDITATION: 801,
};

export const PROCESS_IDS_EXCLUSION_LIST = [PROCESS_IDS.ADDRESS_VERIFICATION];

export const TABLE_DIRECTIONS = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const WEB_SOCKET_FOCUS_ITEMS = {
  ALERT_RECEIVED: 'ALERT_RECEIVED',
};

export const ACCESS_LEVEL = {
  SAFE_DOC: 'SafeDoc',
  SOURCE: 'Source',
};

export const ACCESS_LEVEL_VALUE = {
  SAFE_DOC: 2,
  SOURCE: 1,
};

export const DOC_TYPE = {
  DRIVERS_LICENSE: 'driving licence',
  PASSPORT: 'passport',
};

export const USER_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTITY: 'ENTITY',
  ALL: 'ALL',
};

export const PROCESS_TYPE = {
  INDIVIDUAL: 'Individual',
  ENTITY: 'Entity',
  ALL: 'All',
};

export const BENEFICIAL_OWNER_DOCUMENT_VALUES = {
  KEY: 'beneficial_owner_documentation',
  LABEL: 'Beneficial Owner Documentation',
  PROCESS_ID: 9999999,
};

export const BENEFICIAL_OWNER_DOCUMENT = {
  process_id: 9999999,
  parent_process_id: 9999998,
  pre_process_verification: 9999997,
  title: BENEFICIAL_OWNER_DOCUMENT_VALUES.LABEL,
  key: BENEFICIAL_OWNER_DOCUMENT_VALUES.KEY,
  status: '',
  access_level: 'source',
  merchant_review_status: '',
};

export const INVITE_TYPE_FILTER_OPTIONS = [
  {
    label: PROCESS_TYPE.ALL,
    value: USER_TYPE.ALL,
  },
  {
    label: PROCESS_TYPE.INDIVIDUAL,
    value: USER_TYPE.INDIVIDUAL,
  },
  {
    label: PROCESS_TYPE.ENTITY,
    value: USER_TYPE.ENTITY,
  },
];

export const PROFILE_ADDRESS_TYPES = {
  RESIDENTIAL: 'residential',
  NON_RESIDENTIAL: 'non_residential',
};

export const PROFILE_ADDRESS_TYPE_LABEL = {
  RESIDENTIAL: 'Residential Address',
  NON_RESIDENTIAL: 'Non Residential Address',
};

export const DEFAULT_PROFILE_ADDRESS_TYPE = [
  {
    address_type: PROFILE_ADDRESS_TYPES.RESIDENTIAL,
    address_type_label: PROFILE_ADDRESS_TYPE_LABEL.RESIDENTIAL,
    is_checked: false,
    is_supporting_document_required: false,
  },
  {
    address_type: PROFILE_ADDRESS_TYPES.NON_RESIDENTIAL,
    address_type_label: PROFILE_ADDRESS_TYPE_LABEL.NON_RESIDENTIAL,
    is_checked: false,
    is_supporting_document_required: false,
  },
];

export const INVITE_STATUS = {
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  INVITED: 'INVITED',
  IN_PROGRESS: 'IN_PROGRESS',
  STARTED: 'STARTED',
  PENDING_REVIEW: 'PENDING_REVIEW',
  UNDER_REVIEW: 'UNDER_REVIEW',
  UNREVIEWED: 'UNREVIEWED',
};

export const INVITATION_STATUS = {
  CREATED: 'CREATED',
  ACCOUNT_SIGNED_UP: 'ACCOUNT_SIGNED_UP',
  ACCOUNT_ACCEPTED: 'ACCOUNT_ACCEPTED',
  ACCOUNT_DECLINED: 'ACCOUNT_DECLINED',
  REVOKED: 'REVOKED',
};

export const DOCUMENT_UPLOAD_TYPE = {
  OTHER: 'OTHER',
  LIBRARY: 'LIBRARY',
  BASIC: 'BASIC',
  CUSTOM: 'CUSTOM',
};

export const CUSTOM_DOCUMENT_OPTIONS = {
  ESIGN: 'ESIGN',
  VIEW_ONLY: 'VIEW_ONLY',
};

export const ID_PROCESS_STATUS = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  SYSTEM_APPROVED: 'SYSTEM_APPROVED',
  IN_DRAFT: 'IN_DRAFT',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  NEEDS_MANUAL_REVIEW: 'NEEDS_MANUAL_REVIEW',
};

export const NEW_APPLICANT_TAB_STATUS_OPTIONS = [
  { id: INVITE_STATUS.STARTED, name: 'Started' },
  { id: INVITE_STATUS.PENDING_REVIEW, name: 'Pending Review' },
  { id: INVITE_STATUS.UNDER_REVIEW, name: 'Under Review' },
];

export const INVITED_TAB_STATUS_OPTIONS = [
  { id: INVITATION_STATUS.ACCOUNT_DECLINED, name: 'Dismissed' },
  { id: INVITATION_STATUS.REVOKED, name: 'Revoked' },
  { id: `${INVITATION_STATUS.ACCOUNT_SIGNED_UP + ',' + INVITATION_STATUS.CREATED}`, name: 'Unopened' },
];

export const COMMUNICATION_STATUS = {
  DRAFT: 'DRAFT',
  SENT: 'SENT',
};

export const INVITE_MESSAGE: Record<string, string> = {
  READY_FOR_REVIEW: 'Pending Approval',
  IN_PROGRESS: 'In Progress',
  APPROVED: 'Approved',
  REJECTED: 'Declined',
  CREATED: 'Unopened',
  ACCOUNT_SIGNED_UP: 'Unopened',
  ACCOUNT_ACCEPTED: 'Accepted',
  ACCOUNT_DECLINED: 'Dismissed',
  REVOKED: 'Revoked',
  STARTED: 'Started',
  PENDING_REVIEW: 'Pending Review',
  UNDER_REVIEW: 'Under Review',
};

export const INVITE_APP_DATE_LABEL: Record<string, string> = {
  READY_FOR_REVIEW: 'Application Approved',
  APPROVED: 'Application Approved',
  REJECTED: 'Application Declined',
  IN_PROGRESS: 'Application Approved',
};

export const PROCESS_STATUSES_LABEL = {
  NOT_VERIFIED: 'Not Verified',
  VERIFIED: 'Verified',
  VERIFICATION_FAILED: 'Verification Failed',
  VERIFICATION_PENDING: 'Verification Pending',
  NEEDS_MANUAL_REVIEW: 'Needs Review',
};

export const PROCESS_STATUSES = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFIED: 'VERIFIED',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  NEEDS_MANUAL_REVIEW: 'VERIFICATION_NEEDS_MANUAL_REVIEW',
};

export const ID_PROCESS_STATUS_TEXT: Record<string, string> = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
  SYSTEM_APPROVED: 'System Approved',
  PENDING_VERIFICATION: 'Not Verified',
  VERIFICATION_PENDING: 'Verification Pending',
  VERIFICATION_FAILED: 'Verification Failed',
  VERIFIED: 'Verified',
};
export const NOTE_TYPE = {
  QUE: 'Question',
  ANS: 'Answer',
  MSG: 'Message',
};

export const KYC_AML_RESULTS = {
  CLEAR: 'clear',
  CONSIDER: 'consider',
};

export const COMMUNICATION_TYPES = {
  Notes: 'Notes',
  Messages: 'Messages',
  Communication: 'Communications',
};

export const MESSAGE_STATUS_TYPES = {
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  RESOLVE: 'RESOLVE',
};

export const MESSAGE_RESPONDER_TYPES = {
  MERCHANT: 'Merchant',
  USER: 'User',
};

export const SECTION_LIST = {
  ALL: 'All',
  ACCOUNT: 'Account',
  PROFILE: 'Profile(s)',
  TAX_ID: 'Tax ID',
  ADDRESS: 'Address Verification',
  BIOMETRIC: 'Biometric Verification',
  KYC_AML: 'KYC/AML Report',
  ID_WITH_LICENCE: 'Identity Document with License',
  ID_WITH_PASSPORT: 'Identity Document with Passport',
};

export const ID_PROCESS_REVIEW_STATUS = {
  UNREVIEWED: 'UNREVIEWED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const REJECTED_STATUS_LABEL = {
  DECLINED: 'DECLINED',
};

export const SORTING_PARAM = {
  NAME: 'name',
  EMAIL: 'email',
  FLAG: 'flag',
  ACTION: 'action',
  STATUS: 'status',
  TITLE: 'title',
  FILES: 'files_attached',
};

export const MAX_TAG_LENGTH = 30;

export const RESET_PASSWORD_TOKEN = `RESET_PASSWORD_TOKEN`;

export const RESET_PASSWORD_TEXTS = {
  TOKEN_EXPIRED: 'Token has expired',
  TOKEN_ALREADY_USED: 'Token has already been used.',
  TOKEN_INVALID: 'Invalid token',
};

export const W9_W8_BEN_DOCUMENT_DESCRIPTION_HANDLE: Record<string, string> = {
  'W-9': 'w9Description',
  'W-8BEN': 'w8BenDescription',
};

export const ACCREDITATION_DESCRIPTION_HANDLE: Record<string, string> = {
  accredited: 'accreditedDescription',
  qualified_purchaser: 'qualifiedPurchaserDescription',
  qualified_institution_buyer: 'qualifiedInstutionalBuyerDescription',
};

export const NOTIFICATION_TAB = {
  SYSTEM: 'SYSTEM',
  USER: 'USER',
};

export const ADD_DOCUMENT_DIALOG_WIDTH = 650;

export const ENTITY_INVITE_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  DISMISSED: 'DISMISSED',
  COMPLETED: 'COMPLETED',
  INPROGRESS: 'INPROGRESS',
};

export const ROLES = {
  OWNER: 'OWNER',
  USER: 'USER',
};

export const TAX_ID_VISIBILITY_LEVEL = {
  SOURCE: 'source',
};
