import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TButton, TDialog, TTextField, TCheckbox, theme } from '../../myde-react-components';
import { Box, FormControlLabel, Grid, InputAdornment } from '@mui/material';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { BtnType, ClickHandlerType } from '../../../types/commonTypes';
import { ResultTagType } from '../../../types/applicantTagTypes';
import { useSearchParams } from 'react-router-dom';
import {
  createTag,
  getApplicationMerchantTags,
  getSavedUserTags,
  saveCheckedTags,
  SaveTagData,
  selectApplicationTag,
  setIsSaveCalled,
} from '../../../redux/feature/applicant/applicantTagSlice';
import { FormSchema } from './TagFormSchema';
import { titleCase } from '../../../utils/utils';
import { MAX_TAG_LENGTH } from '../../../constants/constants';
import { PORTAL } from '../../myde-react-components/src/constants/portal';

interface TagModalsProps {
  showDialog: boolean;
  title?: string;
  onCancelModal: ClickHandlerType;
  onSubmitForm?: ClickHandlerType;
  noBtnText?: string;
  yesBtnText?: string;
  tagsList: Array<ResultTagType>;
}

const useStyles = makeStyles({
  tagDialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        position: 'relative',
        width: '460px!important',
        '& .MuiBox-root': {
          position: 'unset!important',
        },
        '& #scrollableDiv': {
          '& .MuiBox-root': {
            paddingBottom: '0px!important',
          },
        },
      },
    },
  },
  tagInputBox: {
    '& .MuiFormControl-root': {
      width: '100%!important',
    },
  },
});

export const TagModals = ({
  showDialog = false,
  title = 'Tags',
  onCancelModal,
  noBtnText = 'Cancel',
  yesBtnText = 'Save',
  tagsList,
}: TagModalsProps) => {
  // Constants
  const [searchParams] = useSearchParams();
  const userId: string = searchParams.get('userId') || '';
  const classes = useStyles();
  const dispatch = useDispatch();
  const tags = tagsList;

  //state variables
  const [formDetail, setFormDetail] = useState({
    tag: '',
  });
  const [addTag, setAddTag] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [checkboxes, setCheckboxes] = useState<Array<string>>([]);
  const [showDialogButton, setShowDialogButton] = useState(true);
  const { isSaveCalled, savedTags } = useSelector(selectApplicationTag);
  const [hideCross, setHideCross] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [count, setCount] = useState(0);

  //useEffects
  useEffect(() => {
    reset(formDetail);
  }, []);

  useEffect(() => {
    setHideCross(true);
  }, []);

  useEffect(() => {
    setCheckboxes(saveCheckBoxes());
  }, [savedTags]);

  useEffect(() => {
    if (isSaveCalled) {
      dispatch(getSavedUserTags(userId));
      onCancelModal();
      dispatch(setIsSaveCalled(false));
    }
  }, [isSaveCalled]);

  useEffect(() => {
    getApplicationMerchantTags();
  }, []);

  type FormSchemaPayload = z.infer<typeof FormSchema>;
  const { handleSubmit, control, formState, reset, setValue } = useForm<FormSchemaPayload>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  });
  const { isValid, isSubmitting, errors } = formState;

  //functions
  const onSubmit = (formData: FormSchemaPayload) => {
    dispatch(getApplicationMerchantTags());
    const data = {
      tag_name_list: [formData.tag], //as an array is required as the req obj
    };
    setFormDetail({
      tag: formData.tag,
    });
    dispatch(createTag(data));
    setValue('tag', '');
    setAddTag(false);
    dispatch(getApplicationMerchantTags());
    setShowDialogButton(true);
    setCount(0);
  };

  const saveCheckBoxes = () => {
    let tempArray: string[] = [];
    if (savedTags?.results?.length > 0) {
      tempArray = savedTags?.results?.map((item: ResultTagType) => item.id);
    }
    return tempArray;
  };

  const handleGetTags = (e: any) => {
    setSearchValue(e.target.value);
    setAddTag(false);
    const tagValue = e.target.value;
    const data = {
      tag_name: tagValue,
    };
    if (e.target.value === '') {
      setHideCross(true);
    } else {
      setHideCross(false);
    }
    dispatch(getApplicationMerchantTags(data));
  };

  const handleBackButton = () => {
    setAddTag(false);
    setShowDialogButton(true);
    dispatch(getApplicationMerchantTags());
  };

  const handleCloseIcon = () => {
    setSearchValue('');
    setHideCross(true);
    dispatch(getApplicationMerchantTags());
  };

  const handleAddButton = () => {
    setAddTag(true);
    setShowDialogButton(false);
  };

  const handleCheckbox = (item: ResultTagType) => {
    const tempArray = [...checkboxes];
    if (tempArray.includes(item.id)) {
      const index = tempArray.indexOf(item.id);
      if (index !== -1) {
        tempArray.splice(index, 1);
      }
    } else {
      tempArray.push(item.id);
    }

    const noOfTags = tempArray?.length;
    if (noOfTags > 4) {
      setDisableCheckbox(true);
    } else {
      setDisableCheckbox(false);
      setCheckboxes(tempArray);
    }
    setDisableSave(false);
  };

  const onCancel = () => {
    const tempCheckboxes = [...checkboxes];
    setAddTag(false);
    if (checkboxes.length === 0 && !isSaveCalled) {
      setCheckboxes([]);
    } else if (checkboxes.length !== tempCheckboxes.length && !isSaveCalled) {
      setCheckboxes(tempCheckboxes);
    } else setCheckboxes(checkboxes);
    dispatch(getApplicationMerchantTags());
    onCancelModal();
  };

  const onConfirm = () => {
    const reqData: SaveTagData = {
      id: userId,
      data: { tag_id_list: checkboxes },
    };
    dispatch(saveCheckedTags(reqData));
    setCount(0);
  };

  const handleTextChange = (_event: React.SyntheticEvent, value: string) => {
    setValue('tag', value, { shouldValidate: true });
    setCount(value.length);
    return value;
  };

  const confDialogBtns: BtnType[] = [
    {
      id: 1,
      title: noBtnText,
      variant: 'text',
      buttonAction: onCancel,
      disabled: false,
    },
    {
      id: 2,
      title: yesBtnText,
      variant: 'contained',
      buttonAction: onConfirm,
      disabled: disableSave,
    },
  ];

  return (
    <TDialog
      className={classes.tagDialogStyle}
      open={showDialog}
      title={title}
      portalName={PORTAL.MERCHANT}
      buttons={showDialogButton ? confDialogBtns : []}
    >
      {!addTag && (
        <>
          <span className="p-absolute addTagButton">
            <TButton btnText="+ Add Tag" btnWidthSize="button-w-140" variant="text" onClick={handleAddButton} />
          </span>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box>
                <TTextField
                  fullWidth
                  name="tag"
                  variant="outlined"
                  onChange={handleGetTags}
                  value={searchValue}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="icon-search iconSearchFont-22 textColor-400" />
                      </InputAdornment>
                    ),
                    endAdornment: !hideCross && (
                      <InputAdornment position="end">
                        <TButton icon={<CloseIcon />} onClick={handleCloseIcon} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                ></TTextField>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ mt: theme.spacing(3) }} className={'text-small font-weight-medium textColor-300'}>
                Note: Maximum 4 tags can be assigned
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mb: 4 }}>
            {tags?.length > 0 ? (
              tags.map((item: ResultTagType) => (
                <Box key={item.id}>
                  <span>
                    <FormControlLabel
                      control={
                        <TCheckbox
                          checked={checkboxes?.includes(item.id)}
                          onChange={() => handleCheckbox(item)}
                          disabled={
                            (!checkboxes?.includes(item.id) && disableCheckbox) ||
                            (!checkboxes?.includes(item.id) && checkboxes.length === 4)
                          }
                        />
                      }
                      label={titleCase(item?.tag_name)}
                    />
                  </span>
                </Box>
              ))
            ) : (
              <Box>
                <Box sx={{ mt: 1 }} className="text-h3 textColor-300 font-weight-semibold">
                  No record found
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
      {addTag ? (
        <form>
          <Box className="flex-basic-start">
            <Box
              sx={{ pr: 1 }}
              className="text-h2 icon-arrow-lefts textcolor-100 cursorPointer textcolor-100"
              onClick={handleBackButton}
            ></Box>
            <Box className="text-large font-weight-semibold textcolor-100"> Add Tag </Box>
          </Box>
          <Box className="w-100" sx={{ mt: 2 }}>
            <Box>
              <Box className={classes.tagInputBox}>
                <Controller
                  name="tag"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TTextField
                      error={!!errors.tag?.message}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_TAG_LENGTH }}
                      id="tag"
                      label="Tag Name"
                      helperText={errors.tag?.message}
                      {...field}
                      onChange={(event) => {
                        const { value } = event.target;
                        handleTextChange(event, value);
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{ mt: theme.spacing(3), mb: 4 }}
                className="flex-basic-end text-small font-weight-semibold textColor-300"
              >
                {`${MAX_TAG_LENGTH - count} character(s) remaining`}
              </Box>
            </Box>
            <Box sx={{ mt: 3 }} className="flex-basic-end">
              <TButton
                btnText="Add"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || isSubmitting}
                btnWidthSize="button-w-140"
              />
            </Box>
          </Box>
        </form>
      ) : (
        ''
      )}
    </TDialog>
  );
};
