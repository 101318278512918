import React, { useEffect, useState } from 'react';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from './../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicantDetails, selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import AccountSummaryCard, { SummaryListType } from '../common/messageNotes/AccountSummaryCard';
import {
  getMessageSummary,
  selectApplicationMessage,
  setMsgUpdatedFlag,
  setNewMsgFlag,
} from '../../redux/feature/applicant/applicantMessageSlice';
import UserMessageModal from './UserMessageModal';

const UserMessageSummary = () => {
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { applicationId } = useSelector(selectApplicant);
  const { newMsgAdded, messageSummary, msgUpdated } = useSelector(selectApplicationMessage);

  // Constants
  const defaultSummaryList: SummaryListType[] = [
    {
      label: 'All',
      value: 0,
      badge: 0,
    },
    {
      label: 'Pending',
      value: 0,
    },
    {
      label: 'Answered',
      value: 0,
    },
  ];

  // State Values
  const [showDialog, setDialogFlag] = useState(false);
  const [summaryList, setSummaryList] = useState(defaultSummaryList);

  // Use Effects
  useEffect(() => {
    if (applicationId) {
      dispatch(getMessageSummary(applicationId));
    }
    if (newMsgAdded || msgUpdated) {
      dispatch(setNewMsgFlag(false));
      dispatch(getApplicantDetails(applicationId));
      dispatch(setMsgUpdatedFlag(false));
    }
  }, [applicationId, newMsgAdded, msgUpdated]);

  useEffect(() => {
    const newSummaryList = [
      {
        label: 'All',
        value: messageSummary?.total_count || 0,
        badge: messageSummary?.total_unread_response_count || 0,
      },
      {
        label: 'Pending',
        value: messageSummary?.pending_count || 0,
      },
      {
        label: 'Answered',
        value: messageSummary?.resolved_count || 0,
      },
    ];
    setSummaryList(newSummaryList);
  }, [messageSummary]);

  // Methods
  const viewMsgs = () => {
    const query = { inviteId: applicationId };
    routeTo(ROUTES.USER_MESSAGES, false, '', query);
  };

  return (
    <>
      <AccountSummaryCard
        title="Message Details"
        showBadge={messageSummary?.total_unread_response_count > 0}
        summaryList={summaryList}
        headerBtnTxt="+ Add Message"
        onHeaderClick={viewMsgs}
        onHeaderBtnClick={() => {
          setDialogFlag(true);
        }}
      />
      {showDialog && (
        <UserMessageModal
          openDialog={showDialog}
          onClose={() => {
            setDialogFlag(false);
          }}
        />
      )}
    </>
  );
};

export default UserMessageSummary;
