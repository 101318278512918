import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { updateIdProcessStatus } from '../../../api/idProcesses';

const initialState = {
  loading: false,
  error: false,
  errorObj: <any>{},
  statusUpdated: false,
  currentProcessReviewStatus: '',
};

// Selector
export const selectApplicationActions = ({ applicationActions }: RootState) => ({
  loading: applicationActions.loading,
  error: applicationActions.error,
  statusUpdated: applicationActions.statusUpdated,
  currentProcessReviewStatus: applicationActions.currentProcessReviewStatus,
});

// Actions
export const reviewIdProcess = createAsyncThunk(
  'applicationActions/reviewIdProcess',
  async (data: { applicationId: string; processId: number; status: string, note?: string }) => {
    return await updateIdProcessStatus(data);
  },
);

// Reducers
export const applicationActionsSlice = createSlice({
  name: 'applicationActions',
  initialState,
  reducers: {
    setStatusFlag: (state, action) => {
      state.statusUpdated = action.payload;
    },
    setReviewStatus: (state, action) => {
      state.currentProcessReviewStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // reviewIdProcess
      .addCase(reviewIdProcess.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(reviewIdProcess.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.statusUpdated = true;
        } else {
          state.error = true;
        }
      })
      .addCase(reviewIdProcess.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setStatusFlag, setReviewStatus } = applicationActionsSlice.actions;

export default applicationActionsSlice.reducer;
