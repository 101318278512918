import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addMsg, getMessageSummary } from '../../redux/feature/applicant/applicantMessageSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { ClickHandlerType } from '../../types/commonTypes';
import MessageNoteModal from '../common/messageNotes/MessageNoteModals';
import { MAX_CHAR_LIMIT } from '../myde-react-components';

interface UserMessgaeModalProps {
  openDialog: boolean;
  onClose: ClickHandlerType;
}

const UserMessageModal = ({ openDialog, onClose }: UserMessgaeModalProps) => {
  const dispatch = useDispatch();
  const { applicationId } = useSelector(selectApplicant);

  // Methods
  const onCancelNewMsg = () => {
    onClose();
  };
  const addNewMsg = (data: { note: string; section: string }) => {
    const reqData = {
      applicationId: applicationId,
      request: data.note,
    };
    dispatch(addMsg(reqData));
    dispatch(getMessageSummary(applicationId));
    onCancelNewMsg();
  };

  return (
    <MessageNoteModal
      title="New Message"
      showDialog={openDialog}
      onCancelModal={onCancelNewMsg}
      onSubmitForm={addNewMsg}
      noBtnText="Cancel"
      yesBtnText="Send"
      label="Type your message here"
      charLimit={MAX_CHAR_LIMIT.CHAR_LENGTH}
    />
  );
};

export default UserMessageModal;
