import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SECTION_LIST } from '../../constants/constants';
import {
  addApplicationNotes,
  getApplicationNotes,
  getNoteSummary,
} from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { ClickHandlerType } from '../../types/commonTypes';
import MessageNoteModal from '../common/messageNotes/MessageNoteModals';
import { MAX_CHAR_LIMIT } from '../myde-react-components';
import { NoteApiFormData } from './UserNoteList';

interface UserNoteModalProps {
  openDialog: boolean;
  onClose: ClickHandlerType;
}

const UserNoteModal = ({ openDialog, onClose }: UserNoteModalProps) => {
  const dispatch = useDispatch();
  const { applicationId, applicationIdProcessList, selectedSection } = useSelector(selectApplicant);
  // Constants
  const defaultSection = {
    label: 'Account',
    id: 'Account',
  };

  // State Values
  const [sectionList, setSectionList] = useState([defaultSection]);

  // Use Effects
  useEffect(() => {
    const arr = [defaultSection];
    applicationIdProcessList?.id_process_list?.forEach((idProcess) => {
      const obj = {
        label: idProcess?.title,
        id: idProcess?.title,
      };
      arr.push(obj);
    });
    setSectionList(arr);
  }, [applicationIdProcessList]);

  // Methods
  const onCancelNewNote = () => {
    onClose();
  };
  const addNewNote = (data: { note: string; section: string }) => {
    const reqData = {
      id: applicationId,
      data: data,
    };
    dispatch(addApplicationNotes(reqData));
    getNoteList();
    const summaryData = {
      applicationId: applicationId,
      section: '',
    };
    if (selectedSection !== SECTION_LIST.ACCOUNT) {
      summaryData.section = selectedSection;
    }
    dispatch(getNoteSummary(summaryData));
    onCancelNewNote();
  };
  const getNoteList = () => {
    const fData: NoteApiFormData = {
      page: 1,
      limit: 1000,
      applicationId: applicationId,
      is_archived: false,
      section: '',
    };
    dispatch(getApplicationNotes(fData));
  };
  return (
    <>
      {openDialog && (
        <MessageNoteModal
          title="Add Note"
          showDialog={openDialog}
          onCancelModal={onCancelNewNote}
          onSubmitForm={addNewNote}
          options={sectionList}
          noBtnText="Cancel"
          yesBtnText="Save"
          label="Type your note here"
          charLimit={MAX_CHAR_LIMIT.CHAR_LENGTH}
          selectedValue={{ section: selectedSection, note: '' }}
        />
      )}
    </>
  );
};

export default UserNoteModal;
