import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { WIdProcessType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  w8Loading: false,
  error: false,
  errorObj: <any>{},
  w8IdProcessDetail: <WIdProcessType>{},
};

export const selectW8IdProcess = ({ w8IdProcess }: RootState) => ({
  w8IdProcessDetail: w8IdProcess.w8IdProcessDetail,
  w8Loading: w8IdProcess.w8Loading,
  error: w8IdProcess.error,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'w8/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const w8IdProcessSlice = createSlice({
  name: 'w8',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.w8Loading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.w8Loading = false;
        state.error = false;
        if (action.payload) {
          state.w8IdProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.w8Loading = false;
        state.error = true;
      });
  },
});

export default w8IdProcessSlice.reducer;
