import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PredefinedProcessType } from '../../../types/applicantTypes';
import { colorPalette, TSwitch, theme, TSkeletonLoader } from '../../myde-react-components';
import { ACCESS_LEVEL, DOC_TYPE } from '../../../constants/constants';
import { DocTypes } from '../../../types/idProcessTypes';
import { useTheme } from '../../../providers/custom-theme-provider';
import clsx from 'clsx';

interface ViewDocModalProps {
  processDetails: PredefinedProcessType;
  status: string;
  loading: boolean;
  safeImages: DocTypes;
  sourceImages: DocTypes;
  type: string;
}

export interface StyleProps {
  isSafeDoc: boolean;
  primaryColor: string;
  loadingImage: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  previewImageContainer: {
    height: 'auto',
    width: '100%',
    objectFit: 'fill',
    margin: 'auto',
  },
  previewImage: {
    height: 'auto',
    width: '100%',
  },
  switchLabel: {
    color: ({ isSafeDoc, primaryColor }) => (isSafeDoc ? primaryColor : colorPalette.iconColor.light),
  },
  imageContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  loadingState: {
    display: ({ loadingImage }) => (loadingImage ? 'block' : 'none'),
  },
  imageState: {
    display: ({ loadingImage }) => (loadingImage ? 'none' : 'block'),
  },
}));

const ViewDocModal = ({ safeImages, sourceImages, loading, status, type }: ViewDocModalProps) => {
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;

  const [isSafeDoc, setIsSafeDoc] = useState(true);
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');
  const [loadingImage, setLoadingImage] = useState(true);
  const styledProps = {
    primaryColor,
    isSafeDoc,
    loadingImage,
  };
  const classes = useStyles(styledProps);

  useEffect(() => {
    if (isSafeDoc) {
      setFrontImage(safeImages?.front?.signed_url);
      setBackImage(safeImages?.back?.signed_url || '');
    } else {
      setFrontImage(sourceImages?.front?.signed_url);
      setBackImage(sourceImages?.back?.signed_url || '');
    }
  }, [isSafeDoc]);

  const toggleSwitch = () => {
    setIsSafeDoc(!isSafeDoc);
    setLoadingImage(true);
  };
  const imageLoaded = () => {
    if (frontImage?.length > 0 || backImage?.length > 0) {
      setLoadingImage(false);
    }
  };
  const noViewHandler = () => {
    return (
      <>
        <Box className="text-medium font-weight-semibold" sx={{ p: 8, textAlign: 'center' }}>
          {isSafeDoc ? ACCESS_LEVEL.SAFE_DOC : ACCESS_LEVEL.SOURCE} version is unavailable
        </Box>
      </>
    );
  };

  return (
    <>
      {loading || !status ? (
        <Box className="flex-basic-center">
          <TSkeletonLoader skeletonHeight={400} loading={true} />
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 3 }} className="flex-basic-end">
            <Box>
              <TSwitch
                themed
                value={isSafeDoc}
                checked={isSafeDoc}
                disabled={!sourceImages?.hasOwnProperty('front')}
                onChange={toggleSwitch}
              />
            </Box>
            <Box
              className={clsx({
                [classes.switchLabel]: true,
                ['text-medium font-weight-semibold']: true,
              })}
            >
              Safe Doc
            </Box>
          </Box>
          {type === DOC_TYPE.DRIVERS_LICENSE && (
            <>
              {frontImage && backImage ? (
                <>
                  <Box className={classes.loadingState}>
                    <Box className={classes.imageContainer}>
                      <>
                        <Box className={classes.previewImageContainer}>
                          <TSkeletonLoader skeletonHeight={400} loading={true} />
                        </Box>
                      </>
                    </Box>
                  </Box>
                  <Box className={classes.imageState}>
                    <Box className={classes.imageContainer}>
                      <>
                        <Box sx={{ mr: frontImage ? theme.spacing(5) : '' }} className={classes.previewImageContainer}>
                          <img className={classes.previewImage} onLoad={imageLoaded} src={frontImage} alt={type} />
                        </Box>
                        <Box sx={{ ml: backImage ? theme.spacing(5) : '' }} className={classes.previewImageContainer}>
                          <img className={classes.previewImage} onLoad={imageLoaded} src={backImage} alt={type} />
                        </Box>
                      </>
                    </Box>
                  </Box>
                </>
              ) : (
                <> {noViewHandler()}</>
              )}
            </>
          )}
          {type === DOC_TYPE.PASSPORT && (
            <>
              {frontImage ? (
                <>
                  <Box className={classes.loadingState}>
                    <TSkeletonLoader skeletonHeight={300} loading={true} />
                  </Box>
                  <Box className={classes.imageState}>
                    <Box className={classes.imageContainer}>
                      <Box className={classes.previewImageContainer}>
                        <Box>
                          <img className={classes.previewImage} onLoad={imageLoaded} src={frontImage} alt={type} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <> {noViewHandler()}</>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewDocModal;
