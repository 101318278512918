import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormGroup, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { OwnerIdRequirementsType, ProfileAddressRequirementsType } from '../../types/inviteTypes';
import { colorPalette, PROCESS_IDS, TButton, TCheckbox, TSwitch } from '../myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';
import AddNoteModal from './AddNoteModal';
import IdProcessContent from './IdProcessContent';
import { DEFAULT_PROFILE_ADDRESS_TYPE, ID_PROCESS_WITH_SAFE_DOC } from '../../constants/constants';

interface OwnerIdRequirementsProps {
  ownerIdRequirements?: OwnerIdRequirementsType[];
  updateOwnerRequirements?: (data: OwnerIdRequirementsType[]) => void;
}

interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  btnColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  disabledState: {
    color: colorPalette.typoText.disabled,
  },
}));

const OwnerIdRequirements = ({ ownerIdRequirements, updateOwnerRequirements }: OwnerIdRequirementsProps) => {
  // Constants
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // State Variable
  const [ownerRequirements, setOwnerRequirements] = useState([] as OwnerIdRequirementsType[]);
  const [highPriorityOwnerProcess, setHighPriorityOwnerProcess] = useState([] as OwnerIdRequirementsType[]);
  const [lowPriorityOwnerProcess, setLowPriorityOwnerProcess] = useState([] as OwnerIdRequirementsType[]);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [particularRequirement, setParticularRequirement] = useState({} as OwnerIdRequirementsType);
  const [isShowMore, setIsShowMore] = useState(false);

  // Use Effects
  useEffect(() => {
    if (ownerIdRequirements) {
      const highProirityProcesses = ownerIdRequirements?.filter((item) => item?.has_high_priority);
      const lowProirityProcesses = ownerIdRequirements?.filter((item) => !item?.has_high_priority);
      setHighPriorityOwnerProcess(highProirityProcesses);
      setLowPriorityOwnerProcess(lowProirityProcesses);
      setOwnerRequirements(ownerIdRequirements);
    }
  }, [ownerIdRequirements]);

  // Methods
  const onIdProcessChange = (item: OwnerIdRequirementsType) => {
    const data = ownerRequirements?.map((element: OwnerIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_checked: !item?.is_checked,
          access_level: ID_PROCESS_WITH_SAFE_DOC.includes(item?.process_id) && !item?.is_checked ? true : false,
          has_children: item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION ? true : false,
          profile_address_requirement_list:
            item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION ? DEFAULT_PROFILE_ADDRESS_TYPE : [],
        };
      }
      return {
        ...element,
      };
    });
    setOwnerRequirements(data);
    updateOwnerRequirements && updateOwnerRequirements(data);
  };

  const onSafeDocChange = (item: OwnerIdRequirementsType) => {
    const data = ownerRequirements?.map((element: OwnerIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          access_level: !item?.access_level,
        };
      }
      return {
        ...element,
      };
    });
    setOwnerRequirements(data);
    updateOwnerRequirements && updateOwnerRequirements(data);
  };

  const onIsOptionalChange = (item: OwnerIdRequirementsType) => {
    const data = ownerRequirements?.map((element: OwnerIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_mandatory: !item?.is_mandatory,
        };
      }
      return {
        ...element,
      };
    });
    setOwnerRequirements(data);
    updateOwnerRequirements && updateOwnerRequirements(data);
  };

  const handleClickChange = (item: OwnerIdRequirementsType) => {
    setParticularRequirement(item);
    setShowNoteModal(true);
  };

  const updateRequirementsData = (data: OwnerIdRequirementsType) => {
    const result = ownerRequirements?.map((element: OwnerIdRequirementsType) => {
      if (element?.process_id === data?.process_id) {
        return {
          ...element,
          note: data?.note,
        };
      }
      return {
        ...element,
      };
    });
    setOwnerRequirements(result);
    updateOwnerRequirements && updateOwnerRequirements(result);
  };

  const updateProfileRequirements = (data: ProfileAddressRequirementsType[]) => {
    const result: Array<OwnerIdRequirementsType> = ownerRequirements?.map((item: OwnerIdRequirementsType) => {
      if (PROCESS_IDS.PROFILE_VERIFICATION === item?.process_id) {
        return {
          ...item,
          profile_address_requirement_list: data,
        };
      }
      return {
        ...item,
      };
    });
    setOwnerRequirements(result);
    updateOwnerRequirements && updateOwnerRequirements(result);
  };

  const basicIdProcessTemplate = (idProcesses: OwnerIdRequirementsType[]) => {
    return (
      <FormGroup>
        {idProcesses?.map((item) => (
          <>
            <Grid container spacing={2} key={item?.process_id}>
              <Grid item sm={3} md={3} lg={3}>
                <FormControlLabel
                  control={
                    <TCheckbox
                      value={item?.process_id}
                      checked={item?.is_checked}
                      onChange={() => onIdProcessChange(item)}
                    />
                  }
                  label={<Box>{item?.process_name}</Box>}
                />
              </Grid>
              <Grid item sm={3} md={3} lg={3}>
                {item?.has_access_level && (
                  <Box className="flex-basic-center">
                    <TSwitch
                      themed
                      value={item?.access_level}
                      checked={item?.access_level && item?.is_checked}
                      disabled={!item?.is_checked}
                      onChange={() => onSafeDocChange(item)}
                    />
                    <Box
                      className={clsx({
                        ['text-medium font-weight-semibold']: true,
                        [classes.btnColor]: item?.access_level && item?.is_checked,
                        ['textColor-400']: !item?.access_level,
                        [classes.disabledState]: !item?.is_checked,
                      })}
                      sx={{ ml: 1 }}
                    >
                      {t('safeDocTitle')}
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item sm={5} md={5} lg={5}>
                {item?.is_checked && (
                  <FormControlLabel
                    control={
                      <TCheckbox
                        value={item?.is_mandatory}
                        checked={!item?.is_mandatory}
                        onChange={() => onIsOptionalChange(item)}
                      />
                    }
                    label={<Box>Optional</Box>}
                  />
                )}
              </Grid>
              <Grid item sm={1} md={1} lg={1}>
                {item?.is_checked && (
                  <TButton
                    sx={{ mr: 1, mb: 1 }}
                    variant="text"
                    btnText={'Note'}
                    icon={<Box sx={{ mr: 1 }} className={item?.note ? 'icon-edit' : 'icon-add'} />}
                    size="small"
                    onClick={() => handleClickChange(item)}
                  />
                )}
              </Grid>
            </Grid>
            {item?.has_children && item?.is_checked && item?.profile_address_requirement_list?.length > 0 && (
              <Box sx={{ mx: 5, my: 1 }}>
                <IdProcessContent
                  individualProfileRequirements={item?.profile_address_requirement_list}
                  updateProfileRequirements={updateProfileRequirements}
                />
              </Box>
            )}
          </>
        ))}
      </FormGroup>
    );
  };

  // HTML
  return (
    <>
      <Box>{basicIdProcessTemplate(highPriorityOwnerProcess)}</Box>
      <Grid container spacing={2}>
        <Grid item sm={3} md={3} lg={3} sx={{ ml: 16 }}>
          <TButton
            btnText={isShowMore ? 'Show Less' : 'Show More'}
            variant={'text'}
            onClick={() => setIsShowMore(!isShowMore)}
          />
        </Grid>
      </Grid>
      {isShowMore && <Box>{basicIdProcessTemplate(lowPriorityOwnerProcess)}</Box>}
      <AddNoteModal
        showDialog={showNoteModal}
        requirement={particularRequirement}
        onAdd={updateRequirementsData}
        onCancel={() => setShowNoteModal(false)}
      />
    </>
  );
};

export default OwnerIdRequirements;
