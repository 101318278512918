import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import InviteListings from './InviteListings';
import { TAlertDialog, TButton, TCard, TRadio } from '../../components/myde-react-components';
import { INVITE_STATUS, USER_TYPE } from '../../constants/constants';
import CustomTabs, { TabListType } from '../../components/common/tabs/Tabs';
import {
  selectInvitation,
  setEmailData,
  setFilters,
  setStatusData,
} from '../../redux/feature/merchantInvite/inviteSlice';
import { resetApplicantDetails } from '../../redux/feature/applicant/applicantSlice';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { setIsEntityInvite } from '../../redux/feature/merchantInvite/merchantInviteSlice';
import { PORTAL } from '../../components/myde-react-components/src/constants/portal';

const ApplicantIndex = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  const { currentTabId } = useSelector(selectInvitation);

  const [tabs, setTabs] = useState([] as TabListType[]);
  const [showSelectionDialog, setShowSelectionDialog] = useState(false);
  const [inviteType, setInviteType] = useState('');

  useEffect(() => {
    const allTabs: TabListType[] = [
      {
        label: 'Applicants',
        component: <InviteListings status={INVITE_STATUS.READY_FOR_REVIEW} />,
      },
      {
        label: 'Approved',
        component: <InviteListings status={INVITE_STATUS.APPROVED} />,
      },
      {
        label: 'Declined',
        component: <InviteListings status={INVITE_STATUS.REJECTED} />,
      },
      {
        label: 'Invited',
        component: <InviteListings status={INVITE_STATUS.INVITED} />,
      },
    ];
    setTabs(allTabs);
  }, []);

  useEffect(() => {
    dispatch(setStatusData([]));
    dispatch(setEmailData(''));
    dispatch(setFilters({}));
    dispatch(resetApplicantDetails());
  }, [currentTabId]);

  const renderForm = () => {
    setShowSelectionDialog(true);
  };

  const handleInviteTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInviteType(event?.target?.value);
    if (event?.target?.value === USER_TYPE.INDIVIDUAL) {
      dispatch(setIsEntityInvite(false));
    } else {
      dispatch(setIsEntityInvite(true));
    }
  };

  const onConfirm = () => {
    routeTo(ROUTES.INVITE_FORM);
  };

  const onCancel = () => {
    setInviteType('');
    dispatch(setIsEntityInvite(null));
    setShowSelectionDialog(false);
  };

  return (
    <>
      <TCard>
        <Box sx={{ pt: 4 }} className="flex-basic-space-between">
          <Box sx={{ px: 5 }} className="text-h2 font-weight-semibold textColor-200">
            {t('applicantsTitle')}
          </Box>
          <Box sx={{ px: 4 }}>
            <TButton btnText="New Invite" btnWidthSize="button-w-140" variant="contained" onClick={renderForm} />
          </Box>
        </Box>
        <Box sx={{ px: 4, pb: 3 }}>
          <CustomTabs
            tabList={tabs}
            selectedTab={currentTabId}
            isVertical={false}
            borderRequired={false}
            showBottomBorder={false}
          />
        </Box>
      </TCard>
      <TAlertDialog
        showDialog={showSelectionDialog}
        title={t('selectTypeTitle')}
        isButtonDisabled={!inviteType}
        portalName={PORTAL.MERCHANT}
        content={''}
        extraContent={
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={inviteType}
            onChange={handleInviteTypeChange}
          >
            <FormControlLabel value={USER_TYPE.INDIVIDUAL} control={<TRadio />} label="Individual User" />
            <FormControlLabel value={USER_TYPE.ENTITY} control={<TRadio />} label="Entity" />
          </RadioGroup>
        }
        noBtnTitle={'Cancel'}
        yesBtnTitle={'Next'}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default ApplicantIndex;
