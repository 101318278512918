import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getRepositories, uploadLibraryDocument } from '../../../api/repository';
import { RepositoryResultType } from '../../../types/repositoryTypes';

type NotificationState = {
  loading: boolean;
  error: boolean;
  repositoryListCount: number;
  repositoryResults: RepositoryResultType[];
  searchedDocTitle: RepositoryResultType[];
};

const initialState: NotificationState = {
  loading: false,
  error: false,
  repositoryListCount: 0,
  repositoryResults: [] as RepositoryResultType[],
  searchedDocTitle: [] as RepositoryResultType[],
};

//selector
export const selectRepository = ({ repository }: RootState) => ({
  loading: repository.loading,
  repositoryListCount: repository.repositoryListCount,
  repositoryResults: repository.repositoryResults,
  searchedDocTitle: repository.searchedDocTitle,
});

//actions
export const getRepositoryList = createAsyncThunk('repository/getRepositoryList', async (params?: object) => {
  return await getRepositories(params || {});
});

export const uploadLibraryDocuments = createAsyncThunk('repository/uploadLibraryDocument', async (data: any) => {
  return await uploadLibraryDocument(data);
});

export const getSearchedTitle = createAsyncThunk('repository/getSearchedTitle', async (params?: object) => {
  return await getRepositories(params || {});
});

//reducer
export const repositorySlice = createSlice({
  name: 'repository',
  initialState,
  reducers: {
    setSearchedDocTitle: (state, action) => {
      state.searchedDocTitle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRepositoryList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getRepositoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.repositoryListCount = action?.payload?.count;
          state.repositoryResults = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getRepositoryList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSearchedTitle.pending, (state) => {
        state.error = false;
      })
      .addCase(getSearchedTitle.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.searchedDocTitle = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSearchedTitle.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setSearchedDocTitle } = repositorySlice.actions;

export default repositorySlice.reducer;
