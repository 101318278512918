import { Box, FormControl, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ENTITY_MEMBER_PERMISSION, TButton, TCheckbox, TSelect, TTextField, theme } from '../myde-react-components';
import { EntityMemberDetails } from '../../types/entityTypes';
import { Controller, useForm } from 'react-hook-form';
import { ROLES } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { EditMemberFormSchema, EditMemberFormSchemaPayload } from './validation';
import { zodResolver } from '@hookform/resolvers/zod';

interface EditMembersFormProps {
  editableEntityDetails: EntityMemberDetails;
  memberType: string;
  handleClose: (action: string) => void;
  handleEdit: (formData: EditMemberFormSchemaPayload) => void;
}

const EditMembersForm = ({ editableEntityDetails, memberType, handleClose, handleEdit }: EditMembersFormProps) => {
  //Constants
  const { t } = useTranslation('common');

  //State
  const [permissionValue, setPermissionValue] = useState('');

  //Form Variables
  const { control, formState, setValue, handleSubmit, reset } = useForm<EditMemberFormSchemaPayload>({
    resolver: zodResolver(EditMemberFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isDirty } = formState;

  //useEffect
  useEffect(() => {
    if (editableEntityDetails && Object.keys(editableEntityDetails)?.length) {
      setPermissionValue(editableEntityDetails?.permission);
      setValue('permission', editableEntityDetails?.permission, { shouldValidate: true });
      setValue(
        'ownership_percentage',
        editableEntityDetails?.ownership_percentage ? editableEntityDetails?.ownership_percentage?.toString() : '',
        {
          shouldValidate: true,
        },
      );
      setValue('is_beneficial_owner', editableEntityDetails?.is_beneficial_owner, { shouldValidate: true });
    }
  }, [editableEntityDetails]);

  //Methods
  const onSubmit = (formData: EditMemberFormSchemaPayload) => {
    reset();
    handleEdit(formData);
  };

  const onClose = () => {
    reset();
    handleClose('edit');
  };

  //HTML
  return (
    <Box sx={{ mt: 4 }}>
      <Box>
        <Box sx={{ mb: theme.spacing(3) }}>
          <Box className="text-mdiumn font-weight-semibold textColor-200">Name</Box>
        </Box>
        <Box sx={{ mb: theme.spacing(3) }} className="flex-basic-start text-mdiumn">
          <Box className="icon-profile-users icon-size-20 textColor-300" sx={{ mr: 1 }} />
          <Box> {`${editableEntityDetails?.first_name}  ${editableEntityDetails?.last_name}`} </Box>
        </Box>
        <Box className="flex-basic-start text-mdiumn">
          <Box className="icon-email-details icon-size-16 textColor-300" sx={{ mr: 1 }} />
          <Box>{editableEntityDetails?.email} </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box sx={{ mb: theme.spacing(3) }} className="text-mdiumn font-weight-semibold textColor-200">
          {t('detailsText')}
        </Box>
        <form>
          <Box sx={{ mb: theme.spacing(5) }}>
            <Controller
              name={'ownership_percentage'}
              defaultValue={null}
              control={control}
              render={({ field }) => (
                <>
                  {memberType === ROLES.OWNER && (
                    <TTextField
                      {...field}
                      label="Ownership Entity Stake % (Optional)"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.ownership_percentage)}
                      helperText={errors.ownership_percentage?.message}
                    />
                  )}
                </>
              )}
            />
          </Box>
          <Box>
            <Box sx={{ mt: theme.spacing(5) }}>
              <Controller
                name={'permission'}
                defaultValue={permissionValue}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl className="w-100">
                    <TSelect
                      id="type"
                      value={value}
                      fullWidth
                      options={ENTITY_MEMBER_PERMISSION}
                      label="Permission"
                      itemId="value"
                      itemValue="label"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Controller
              name="is_beneficial_owner"
              defaultValue={false}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {memberType === ROLES.OWNER && (
                    <FormControlLabel
                      control={<TCheckbox checked={value} onChange={onChange} />}
                      label={t('beneficialOwner')}
                    />
                  )}
                </>
              )}
            />
            {memberType === ROLES.OWNER && (
              <Box sx={{ ml: theme.spacing(7) }} className="text-extra-small">
                {t('benificaialOwnerNote')}
              </Box>
            )}
          </Box>

          <Box className="flex-basic-end" sx={{ mt: 5 }}>
            <TButton sx={{ mr: 2 }} btnText="Cancel" btnWidthSize="button-w-140" variant="text" onClick={onClose} />
            <TButton
              btnText={'Save'}
              variant="contained"
              btnWidthSize="button-w-140"
              disabled={!isValid || !isDirty}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditMembersForm;
