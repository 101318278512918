import { apiGet, apiPost } from './axios';

export const register = async (formData: object) => {
  const res = await apiPost('/sign-up', formData);
  return res.data;
};

export const inviteViaToken = async (token: string) => {
  const res = await apiGet(`/merchant/invite/${token}`);
  return res.data;
};
