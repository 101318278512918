import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
  sendLinkForForgotPassword,
  sendResetPassword,
  validatingResetPasswordToken,
} from '../../../api/forgot-password';
import { bankVaultLogin, login } from '../../../api/login';
import { inviteViaToken, register } from '../../../api/register';
import { RESET_PASSWORD_TEXTS } from '../../../constants/constants';
import {
  UserData,
  IInviteData,
  MerchantData,
  MerchantType,
  BrandingType,
  UserStateType,
} from '../../../types/userTypes';
import { setAccessToken, setBranding } from '../../../utils/storage';

const initialState: UserStateType = {
  userData: <UserData>{},
  merchantData: <MerchantData>{},
  authToken: '',
  loading: false,
  error: false,
  loginSuccess: false,
  merchantInviteData: <IInviteData>{},
  selectedMerchant: <MerchantType>{},
  branding: <BrandingType>{},
  customErrorMessage: '',
  linkSentForForgotPassword: false,
  passwordResetSuccessfully: false,
  resetTokenExpired: false,
  resetTokenAlreadyUsed: false,
  validResetEmail: '',
  userRoles: [],
};

export const selectUser = ({ user }: RootState) => ({
  userData: user.userData,
  merchantData: user.merchantData,
  authToken: user.authToken,
  loading: user.loading,
  error: user.error,
  merchantInviteData: user.merchantInviteData,
  selectedMerchant: user.selectedMerchant,
  branding: user.branding,
  loginSuccess: user.loginSuccess,
  customErrorMessage: user.customErrorMessage,
  linkSentForForgotPassword: user.linkSentForForgotPassword,
  passwordResetSuccessfully: user.passwordResetSuccessfully,
  resetTokenExpired: user.resetTokenExpired,
  resetTokenAlreadyUsed: user.resetTokenAlreadyUsed,
  validResetEmail: user.validResetEmail,
  userRoles: user.userRoles,
});

export const getLoginDetails = createAsyncThunk('user/getLoginDetails', async (formData: object) => {
  return await login(formData);
});

export const loginUsingBankVault = createAsyncThunk(
  'user/loginUsingBankVault',
  async (formData: { session: string; token: string; secret: string }) => {
    return await bankVaultLogin(formData);
  },
);

export const getRegisterDetails = createAsyncThunk('user/getRegisterDetails', async (formData: object) => {
  return await register(formData);
});

export const getInviteViaToken = createAsyncThunk('user/getInviteViaToken', async (token: string) => {
  return await inviteViaToken(token);
});

export const setSelectedMerchant = createAsyncThunk('user/setSelectedMerchant', async (formData: any) => {
  return formData;
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (formData: { email: string }) => {
  return sendLinkForForgotPassword(formData);
});

export const resetPassword = createAsyncThunk('user/resetPassword', async (formData: { password: string }) => {
  return sendResetPassword(formData);
});

export const validateResetPasswordToken = createAsyncThunk('user/validateResetPasswordToken', async (token: string) => {
  return await validatingResetPasswordToken(token);
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.authToken = action.payload;
    },
    setData: (state, action) => {
      state.userData = action.payload;
    },
    resetData: (state) => {
      state.userData = <UserData>{};
      state.authToken = '';
      state.selectedMerchant = <MerchantType>{};
      state.merchantData = <MerchantData>{};
    },
    setLoginFlag: (state, action) => {
      state.loginSuccess = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRoles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getLoginDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userData = action.payload.account;
          state.merchantData = action.payload;
          if (action?.payload?.merchants.length === 1) {
            setAccessToken(action.payload.merchants[0].access);
            state.authToken = action.payload.merchants[0].access;
            state.branding = action.payload.merchants[0].branding;
            setBranding(action.payload.merchant?.merchants[0].branding);
          }
        } else {
          state.error = true;
        }
      })
      .addCase(getLoginDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getRegisterDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getRegisterDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userData = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getRegisterDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(loginUsingBankVault.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loginUsingBankVault.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userData = action.payload.account;
          state.merchantData = action.payload;
          if (action?.payload?.merchants.length === 1) {
            setAccessToken(action.payload.merchants[0].access);
            state.authToken = action.payload.merchants[0].access;
            state.branding = action.payload.merchants[0].branding;
            setBranding(action.payload.merchant?.merchants[0].branding);
          }
        } else {
          state.error = true;
        }
      })
      .addCase(loginUsingBankVault.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getInviteViaToken.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInviteViaToken.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.merchantInviteData = action.payload;
          state.branding = action?.payload?.merchant?.branding;
          setBranding(action?.payload?.merchant?.branding);
        } else {
          state.error = true;
        }
      })
      .addCase(getInviteViaToken.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(setSelectedMerchant.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.selectedMerchant = action.payload;
          if (action?.payload?.merchant?.access) {
            state.loginSuccess = true;
          }
          state.branding = action?.payload?.merchant?.branding;
          setBranding(action.payload.merchant?.branding);
          setAccessToken(action.payload.access);
          state.authToken = action.payload.access;
        } else {
          state.error = true;
        }
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
        state.linkSentForForgotPassword = true;
      })
      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.customErrorMessage = action?.payload?.detail;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = true;
        state.passwordResetSuccessfully = true;
      })
      .addCase(resetPassword.rejected, (state, action: any) => {
        state.loading = false;
        if (action?.payload?.detail === RESET_PASSWORD_TEXTS.TOKEN_EXPIRED) {
          state.resetTokenExpired = true;
        } else if (action?.payload?.detail === RESET_PASSWORD_TEXTS.TOKEN_ALREADY_USED) {
          state.resetTokenAlreadyUsed = true;
        } else {
          state.resetTokenExpired = true;
        }
      })
      .addCase(validateResetPasswordToken.pending, (state) => {
        state.loading = true;
        state.error = true;
      })
      .addCase(validateResetPasswordToken.fulfilled, (state, action) => {
        state.loading = false;
        state.error = true;
        if (action.payload) {
          state.validResetEmail = action?.payload?.email;
        }
      })
      .addCase(validateResetPasswordToken.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

const { actions, reducer } = userSlice;
export const { setData, resetData, setToken, setLoginFlag, setUserRole } = actions;
export default reducer;
