import { SearchTagType, SaveTagType } from './../../../types/applicantTagTypes';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getTagsListById, createUserTag, getMerchantTags, saveTags, getSavedTags } from '../../../api/applicants';
import { AddTagType, ApplicantTagType } from '../../../types/applicantTagTypes';

const initialState = {
  loading: false,
  error: false,
  errObj: <any>{},
  newTagAdded: false,
  tagUpdated: false,
  selectedTagId: '',
  tagList: <ApplicantTagType>{},
  merchantTagList: <ApplicantTagType>{},
  savedTags: [],
  isSaveCalled: false,
};

export interface SaveTagData {
  id: string,
  data: SaveTagType
}

export const selectApplicationTag = ({ applicationTag }: any) => ({
  loading: applicationTag.loading,
  error: applicationTag.error,
  errorObj: applicationTag.errorObj,
  newTagAdded: applicationTag.newTagAdded,
  tagUpdated: applicationTag.tagUpdated,
  selectedTagId: applicationTag.selectedTagId,
  tagList: applicationTag.tagList,
  merchantTagList: applicationTag.merchantTagList,
  savedTags: applicationTag.savedTags,
  isSaveCalled: applicationTag.isSaveCalled,
});

//actions
export const getTagDetails = createAsyncThunk('applicationTag/getTagDetails', async (userId: string) => {
  return await getTagsListById(userId);
});

export const createTag = createAsyncThunk(
  'applicationTag/createTag',
  async (data: AddTagType) => {
    return await createUserTag(data);
  },
);

export const getApplicationMerchantTags = createAsyncThunk('applicationTag/getApplicationMerchantTags', async (data?: SearchTagType) => {
  return await getMerchantTags(data);
});

export const saveCheckedTags = createAsyncThunk('applicationTag/saveCheckedTags', async (reqData:SaveTagData) => {
  return await saveTags(reqData.id, reqData.data)
})

export const getSavedUserTags = createAsyncThunk("applicationTag/getSavedUserTags", async(id: string)=>{
  return await getSavedTags(id)
}) 


//reducer
export const applicationTagSlice = createSlice({
  name: 'applicationTag',
  initialState: initialState,
  reducers: {
    setNewTagFlag: (state, action) => {
      state.newTagAdded = action.payload;
    },
    setTagUpdatedFlag: (state, action) => {
      state.tagUpdated = action.payload;
    },
    setSelectedTagFlag: (state, action) => {
      state.selectedTagId = action.payload;
    },
    setSavedTags: (state, action) => {
      state.savedTags = action.payload
    },
    setIsSaveCalled: (state, action) => {
      state.isSaveCalled = action.payload
    },
   
  },
  extraReducers: (builder) => {
    builder
      //getTagsById
      .addCase(getTagDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTagDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.tagList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getTagDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      //createUserTag
      .addCase(createTag.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createTag.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.newTagAdded = true;
          toast.success('New Tag Added');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to add new tag. Please try after sometime.');
        }
      })
      .addCase(createTag.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      //getApplicationMerchantTags
      .addCase(getApplicationMerchantTags.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getApplicationMerchantTags.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.merchantTagList = action.payload;
        } else {
          state.error = true;
        }
      })
      //save the selected tags
      .addCase(saveCheckedTags.pending, (state)=>{
        state.loading = true;
        state.error = false;
      })
      .addCase(saveCheckedTags.fulfilled, (state, action:any)=>{
        state.loading = false;
        state.error = false;
        if(action.payload){
          state.isSaveCalled = true
          toast.success("Tags updated.")
        }else{
          state.error=true
        }
      })
      .addCase(saveCheckedTags.rejected, (state)=>{
        state.loading = false;
        state.error = true;
        toast.error("Something went wrong. Please try again after some time.")
      })
      //get saved tags
      .addCase(getSavedUserTags.pending,(state) =>{
        state.loading = true;
        state.error = false;
      })
      .addCase(getSavedUserTags.rejected, (state, action:any)=>{
        state.loading = false;
        state.error = true;
        toast.error(action?.payload)
      })
      .addCase(getSavedUserTags.fulfilled, (state, action :any)=>{
        state.loading = false;
        state.error = false;
        if(action?.payload){
          state.savedTags = action?.payload
        }else{
        state.error = false;
        }
      })
  },
});

export const { setNewTagFlag, setSelectedTagFlag, setTagUpdatedFlag, setSavedTags,setIsSaveCalled } = applicationTagSlice.actions;

export default applicationTagSlice.reducer;
