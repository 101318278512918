import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { WIdProcessType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  w9Loading: false,
  error: false,
  errorObj: <any>{},
  w9IdProcessDetail: <WIdProcessType>{},
};

export const selectW9IdProcess = ({ w9IdProcess }: RootState) => ({
  w9IdProcessDetail: w9IdProcess.w9IdProcessDetail,
  w9Loading: w9IdProcess.w9Loading,
  error: w9IdProcess.error,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'w9/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const w9IdProcessSlice = createSlice({
  name: 'w9',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.w9Loading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.w9Loading = false;
        state.error = false;
        if (action.payload) {
          state.w9IdProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.w9Loading = false;
        state.error = true;
      });
  },
});

export default w9IdProcessSlice.reducer;
