import React, { useEffect, useState, FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, GridDirection, Icon, Theme } from '@mui/material';
import { colorPalette, TButton } from '../../myde-react-components';
import { ClickHandlerType } from '../../../types/commonTypes';
import { useTheme } from '../../../providers/custom-theme-provider';
import { titleCase } from '../../../utils/utils';
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx';
import clsx from 'clsx';
import { hexToRgbA } from '../../myde-react-components/src/utils/commonMethods';
import { useTranslation } from 'react-i18next';
export interface AccountSummaryCardProps {
  title: string;
  isCursorPointer?: boolean;
  headerIcon?: string;
  summaryList: SummaryListType[];
  headerBtnTxt?: string;
  onHeaderClick?: ClickHandlerType;
  onHeaderBtnClick?: ClickHandlerType;
  showBadge?: boolean;
  isScrollable?: boolean;
  isTagsList?: boolean;
}
export interface SummaryListType {
  label: string;
  value?: number;
  badge?: number;
}

export interface StyleProps {
  primaryColor: string;
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  headerArrowIcon: {
    verticalAlign: 'middle',
  },
  dataBox: {
    width: '100%',
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: 4,
    textAlign: 'center',
    height: '72px',
  },
  alldata: {
    display: 'flex',
  },
  badge: {
    marginLeft: '10px',
    border: '1px solid',
    borderColor: ({ primaryColor }) => primaryColor,
    color: ({ primaryColor }) => primaryColor,
    backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
  },
  headSec: {
    textAlign: 'initial',
  },
}));

const AccountSummaryCard: FC<AccountSummaryCardProps> = ({
  title,
  isCursorPointer = true,
  headerIcon,
  summaryList,
  headerBtnTxt,
  onHeaderClick,
  onHeaderBtnClick,
  showBadge = false,
  isScrollable,
  isTagsList = false,
}: AccountSummaryCardProps) => {
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const styleProps = {
    primaryColor,
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const [isNoSummaryData, setIsNoSummaryData] = useState(false);
  const headerClickHandler = () => {
    if (onHeaderClick) {
      onHeaderClick();
    }
  };
  const headerBtnClickHandler = () => {
    if (onHeaderBtnClick) {
      onHeaderBtnClick();
    }
  };
  useEffect(() => {
    if (summaryList?.length > 0) {
      const data = summaryList.every((item) => {
        if (item.value === 0) {
          return true;
        }
      });

      setIsNoSummaryData(data);
    }
  }, [summaryList]);
  const getAccountSummaryData = (
    isNoSummary: boolean,
    direction: ResponsiveStyleValue<GridDirection>,
    justifyContent: string,
    alignItems: string,
  ) => {
    const { t } = useTranslation('common');
    return (
      <>
        {summaryList[0]?.label === 'No Tags Available' ? (
          <>
            <Box className="noTagsAvailable text-large font-weight-semibold textColor-300" sx={{ py: 3, px: 2 }}>
              {t('noTagsAvailable')}
            </Box>
          </>
        ) : (
          <Grid
            container
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
            className={clsx({ [classes.dataBox]: true, ['tagListAlignment']: isTagsList })}
            sx={{ pl: 3 }}
          >
            {!isNoSummary ? (
              summaryList?.map((summary, index) => (
                <Grid md={4} lg={4} item key={index} className={clsx(classes.headSec, 'tagSection')}>
                  {isScrollable ? (
                    <Box
                      className={clsx({
                        [classes.badge]: isTagsList,
                        ['customChips flex-basic-start']: isTagsList,
                      })}
                    >
                      <Box sx={{ mt: isTagsList ? 0 : 1 }} className="text-large font-weight-semibold textColor-300">
                        {titleCase(summary?.label)}
                      </Box>
                    </Box>
                  ) : (
                    <Box className="text-medium font-weight-regular textColor-300 bcbb">{summary?.label}</Box>
                  )}
                  <Box className={classes.alldata}>
                    <Box className="text-large font-weight-semibold textColor-200">{summary?.value}</Box>

                    {(index === 0 || (summary?.badge && summary?.badge > 0)) && showBadge ? (
                      <Box
                        className={clsx({
                          [classes.badge]: true,
                          ['customChips text-center flex-basic-center text-medium font-weight-semibold']: true,
                        })}
                      >
                        <Box className="text-medium font-weight-semibold"> {summary?.badge} new</Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              ))
            ) : (
              <Box>
                <Box className="text-large font-weight-semibold textColor-300">None</Box>
              </Box>
            )}
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        <Grid item onClick={headerClickHandler}>
          <label
            className={clsx({
              ['text-h3 textColor-200 font-weight-semibold ']: true,
              ['cursorPointer']: isCursorPointer,
            })}
          >
            {title}
          </label>
          {onHeaderClick && (
            <Icon sx={{ ml: 1 }} className={clsx(classes.headerArrowIcon, 'cursorPointer textColor-200')}>
              chevron_right_icon
            </Icon>
          )}
        </Grid>
        {onHeaderBtnClick && (
          <Grid item>
            <TButton
              icon={<Box sx={{ pr: 1 }} className={headerIcon} />}
              variant="text"
              btnText={headerBtnTxt || '+ Add'}
              onClick={headerBtnClickHandler}
            />
          </Grid>
        )}
      </Grid>

      {isScrollable
        ? getAccountSummaryData(false, 'column', 'start', 'start')
        : getAccountSummaryData(isNoSummaryData, 'row', 'space-between', 'center')}
    </>
  );
};

export default AccountSummaryCard;
