import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';

import * as ROUTES from '../../constants/routes';
import { useTheme } from '../../providers/custom-theme-provider';
import {
  DEFAULT_TABLE_OPTIONS,
  ENTITY_INVITE_STATUS_LABEL,
  ITEMS_PER_PAGE_LIST,
  MERCHANT_USER_ROLES,
  TButton,
  TLoader,
  TPagination,
  TTooltip,
} from '../../components/myde-react-components';
import NoDataPlaceHolder from '../../components/myde-react-components/src/components/PlaceholderComponent/NoDataPlaceHolder';
import {
  getManageEntityList,
  resetAddedEntity,
  selectEntity,
  setAssignedEntityDetails,
  setMerchantEntityDetails,
  setMerchantEntityDetailsCount,
  setTaxId,
} from '../../redux/feature/id-process/entity/entitySlice';
import { useRouter } from '../../providers/custom-router-provider';
import { EntityDetail, TableDataTypes } from '../../types/entityTypes';
import { ENTITY_INVITE_STATUS, TABLE_DIRECTIONS } from '../../constants/constants';
import { formatDateToUtc } from '../myde-react-components/src/utils/commonMethods';
import useQuery from '../../hooks/useQuery';
import { selectUser } from '../../redux/feature/user/userSlice';
import CommonAvatar from '../myde-react-components/src/components/Avatar/Avatar';
import { selectInvitation } from '../../redux/feature/merchantInvite/inviteSlice';

// Interfaces
interface EntityViewListProps {
  searchValue: string;
}

interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  textPrimaryColor: {
    color: ({ primaryColor }) => `${primaryColor} !important`,
  },
});

const EntityViewList = ({ searchValue }: EntityViewListProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes?.palette?.primary?.main;
  const styleProps: StyleProps = {
    primaryColor,
  };
  const { routeTo } = useRouter();
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const query = useQuery();
  const applicationId = query.get('applicationId') || '';

  // State Variables
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [entityListData, setEntityListData] = useState([] as TableDataTypes[]);

  // Redux Values
  const { manageEntityListCount, manageEntityList, loading } = useSelector(selectEntity);
  const { applicantFilters } = useSelector(selectInvitation);
  const { userRoles } = useSelector(selectUser);

  //Use Effects
  useEffect(() => {
    dispatch(resetAddedEntity());
    dispatch(setAssignedEntityDetails({}));
    dispatch(setTaxId(''));
    return () => {
      dispatch(setMerchantEntityDetails([]));
      dispatch(setMerchantEntityDetailsCount(0));
    };
  }, []);

  useEffect(() => {
    let params = {} as any;
    if (searchValue) {
      params = {
        entity_name: searchValue,
      };
    }
    if (applicationId) {
      params = {
        ...params,
        application_id: applicationId,
      };
    }
    if (applicantFilters && Object.keys(applicantFilters).length) {
      params = {
        ...params,
        ...applicantFilters,
      };
    }
    getManageEntityListing(page, pageSize, params);
  }, [searchValue, applicationId, applicantFilters, page, pageSize]);

  useEffect(() => {
    const result = manageEntityList?.map((item: EntityDetail) => {
      return {
        id: item?.id,
        name: item?.entity_name,
        email: item?.application?.account?.email,
        invited_on: item?.application?.invitation?.invited_on,
        coordinator: `${item?.coordinator?.first_name} ${item?.coordinator?.last_name}`,
        status: item?.status,
        action: item?.status,
        avatar_url: item?.entity?.avatar_url,
      };
    });
    setEntityListData(result);
  }, [manageEntityList]);

  // Methods
  const getManageEntityListing = (pageNumber: number, limit: number, filters?: object) => {
    const extraFilters = filters ? filters : {};
    const formData = {
      page: pageNumber,
      limit: limit,
      ...extraFilters,
    };
    dispatch(getManageEntityList(formData));
  };

  const onRowChange = (value: any) => {
    setPageSize(value);
  };

  const onPageChange = (value: any) => {
    setPage(value);
  };

  const onColumnSortChange = (changedColumn: string, direction: string) => {
    let params: any = {
      ordering: direction === TABLE_DIRECTIONS.DESCENDING ? `-${changedColumn}` : changedColumn,
    };
    if (applicationId) {
      params = {
        ...params,
        application_id: applicationId,
      };
    }
    if (applicantFilters && Object.keys(applicantFilters).length) {
      params = {
        ...params,
        ...applicantFilters,
      };
    }
    getManageEntityListing(page, pageSize, params);
  };

  const navigateTo = (tableData: any) => {
    const rowIndex: number = tableData?.rowIndex || 0;
    const assignmentId = manageEntityList[rowIndex]?.id;
    const entityName = tableData?.rowData[1];
    const entityEmailId = tableData?.rowData[2];
    const entityId = manageEntityList[rowIndex]?.entity?.trellis_uid;
    const query: any = { entityName: entityName };
    if (entityId) {
      query.entityId = entityId;
    } else {
      query.assignmentId = assignmentId;
      query.entityEmailId = entityEmailId;
    }
    routeTo(ROUTES.ENTITY_DETAILS, false, '', query);
  };

  // Table constants
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: { display: false },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        setCellProps: () => ({ style: { maxWidth: '40rem', width: '40rem' } }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box className="flex-basic-start">
              <Box sx={{ mr: 3 }}>
                <CommonAvatar
                  icon="icon-profile-users"
                  imageUrl={tableMeta?.rowData[7]}
                  avatarText={value}
                  iconSize="32px"
                />
              </Box>
              <Box className="flex-column-start align-items-start manageEntitiesTableCellMaxWidth">
                {value?.length > 60 ? (
                  <TTooltip title={value}>
                    <Box
                      className={clsx(
                        classes.textPrimaryColor,
                        'text-large font-weight-medium text-ellipsis manageEntitiesTableCellMaxWidth',
                      )}
                    >
                      {value}
                    </Box>
                  </TTooltip>
                ) : (
                  <Box
                    className={clsx(
                      classes.textPrimaryColor,
                      'text-large font-weight-medium text-ellipsis manageEntitiesTableCellMaxWidth',
                    )}
                  >
                    {value}
                  </Box>
                )}
                <Box className="textColor-200 text-large">{tableMeta?.rowData[2]}</Box>
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (value: any) => {
          return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
        },
        display: false,
      },
    },
    {
      name: 'invited_on',
      label: 'Assigned On',
      options: {
        customBodyRender: (value: any) => {
          return <Box className="text-large font-weight-regular textColor-200">{formatDateToUtc(value)}</Box>;
        },
      },
    },
    {
      name: 'coordinator',
      label: 'Coordinator',
      options: {
        customBodyRender: (value: any) => {
          return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
        },
        display: userRoles?.includes(MERCHANT_USER_ROLES.ADMIN),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value: any) => {
          return (
            <Box className="text-large font-weight-regular textColor-200">{ENTITY_INVITE_STATUS_LABEL[value]}</Box>
          );
        },
      },
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {value === ENTITY_INVITE_STATUS.COMPLETED && (
                <Box>
                  <TButton
                    btnText="View"
                    icon={<Box className="icon-eye"></Box>}
                    variant="contained"
                    btnVariantClass="buttonWithTextIcon button-small"
                    onClick={() => navigateTo(tableMeta)}
                  />
                </Box>
              )}
              {value === ENTITY_INVITE_STATUS.INPROGRESS && (
                <Box>
                  <TButton
                    btnText="Continue"
                    icon={<Box className="icon-arrow-up iconArrowUp"></Box>}
                    variant="contained"
                    btnVariantClass="buttonWithTextIcon button-small"
                    onClick={() => navigateTo(tableMeta)}
                  />
                </Box>
              )}
            </>
          );
        },
        sort: false,
        display: userRoles?.includes(MERCHANT_USER_ROLES.COORDINATOR),
      },
    },
    {
      name: 'avatar_url',
      label: 'Avatar URL',
      options: {
        customBodyRender: (value: any) => {
          return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
        },
        display: false,
      },
    },
  ];

  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    textLabels: {
      body: {
        columnHeaderTooltip: () => '',
        noMatch: (
          <Box sx={{ px: 10 }}>
            <NoDataPlaceHolder
              icon={'icon-document'}
              isButton={false}
              placeholderText={t('noEntities')}
              isPrimaryColor={true}
            />
          </Box>
        ),
      },
    },
    rowsPerPage: pageSize,
    rowsPerPageOptions: { ITEMS_PER_PAGE_LIST },
    onColumnSortChange: onColumnSortChange,
  };

  return (
    <>
      <TLoader loading={loading} />
      <Box className={classes.tableContainer}>
        <MUIDataTable title="" data={entityListData} columns={columns} options={options} />
        {manageEntityList?.length > 0 && (
          <Box className="flex-basic-end">
            <TPagination
              primaryColor={primaryColor}
              rowsPerPageOptions={ITEMS_PER_PAGE_LIST}
              rowsPerPage={pageSize}
              count={Math.ceil(manageEntityListCount / pageSize)}
              onRowChange={onRowChange}
              onPageChange={onPageChange}
              page={page}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default EntityViewList;
