import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { TaxIdIdProcessType } from '../../../types/idProcessTypes';

const initialState = {
  taxIdLoading: false,
  error: false,
  errorObj: <any>{},
  taxIdIdProcessDetail: <TaxIdIdProcessType>{},
  visibilityLevel: false,
};

export const selectTaxId = ({ taxId }: RootState) => ({
  taxIdIdProcessDetail: taxId.taxIdIdProcessDetail,
  taxIdLoading: taxId.taxIdLoading,
  error: taxId.error,
  visibilityLevel: taxId.visibilityLevel,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'taxId/getSpecificIdProcessDetails', async (data: any) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId, data.params);
  }
);

// Reducers
export const taxIdIdProcessSlice = createSlice({
  name: 'taxId',
  initialState,
  reducers: {
    setVisibilityLevel: (state, action) => {
      state.visibilityLevel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.taxIdLoading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.taxIdLoading = false;
        state.error = false;
        if (action.payload) {
          state.taxIdIdProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.taxIdLoading = false;
        state.error = true;
      });
  },
});

export const {
  setVisibilityLevel,
} = taxIdIdProcessSlice.actions;

export default taxIdIdProcessSlice.reducer;
