import { Box, FormControl, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import z from 'zod';

import {
  ClickHandlerType,
  ENTITY_MEMBER_PERMISSION,
  MAX_CHAR_LIMIT,
  REGEX,
  TButton,
  TCheckbox,
  TSelect,
  TTextField,
  theme,
} from '../myde-react-components';
import { ROLES } from '../../constants/constants';
import { EntityMemberDetails, TemporaryEntityMemberDetails } from '../../types/entityTypes';

interface AddMembersFormProps {
  editMemberDetails?: TemporaryEntityMemberDetails;
  allEntityMembersData: EntityMemberDetails[];
  memberType: string;
  onAddBtnClick: (FormData: any) => void;
  setIsEditBtnDisabled: ClickHandlerType;
  setEditableMemberDetails: ClickHandlerType;
}

const AddMembersForm = ({
  editMemberDetails,
  allEntityMembersData,
  memberType,
  onAddBtnClick,
  setIsEditBtnDisabled,
  setEditableMemberDetails,
}: AddMembersFormProps) => {
  //Constants
  const { t } = useTranslation('common');

  //State
  const [selectedPermission, setSelectedPermission] = useState('');

  //Form variables
  const AddOwnerFormSchema = z.object({
    first_name: z
      .string()
      .min(1, { message: 'First name is required' })
      .max(MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY, {
        message: `First name should be less than ${MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY} characters`,
      }),
    last_name: z
      .string()
      .min(1, { message: 'Last name is required' })
      .max(MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY, {
        message: `Last name should be less than ${MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY} characters`,
      }),
    email: z
      .string()
      .min(1, { message: 'Email is required' })
      .email()
      .max(MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY, {
        message: `Email should be less than ${MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY} characters`,
      })
      .refine((value) => !allEntityMembersData?.find((member) => value === member?.email), {
        message: `This email already exists as a member of this entity.`,
      }),
    ownership_percentage: z
      .string()
      .nullish()
      .optional()
      .refine((value) => REGEX.NUMBER_DECIMAL.test(value || ''), {
        message: 'Please enter a valid number',
      })
      .or(z.literal('')),
    permission: z.string().min(1, { message: 'Permission is required' }),
    is_beneficial_owner: z.boolean().optional(),
  });

  type AddOwnersFormSchemaPayload = z.infer<typeof AddOwnerFormSchema>;

  //Form Variables
  const { control, formState, setValue, reset, handleSubmit } = useForm<AddOwnersFormSchemaPayload>({
    resolver: zodResolver(AddOwnerFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;

  //useEffects
  useEffect(() => {
    if (editMemberDetails && Object.keys(editMemberDetails)?.length) {
      setSelectedPermission(editMemberDetails?.permission);
      setValue('first_name', editMemberDetails?.first_name, { shouldValidate: true });
      setValue('last_name', editMemberDetails?.last_name, { shouldValidate: true });
      setValue('email', editMemberDetails?.email, { shouldValidate: true });
      setValue(
        'ownership_percentage',
        editMemberDetails?.ownership_percentage ? editMemberDetails?.ownership_percentage.toString() : '',
        { shouldValidate: true },
      );
      setValue('permission', editMemberDetails?.permission, { shouldValidate: true });
      setValue('is_beneficial_owner', editMemberDetails?.is_beneficial_owner, { shouldValidate: true });
    }
  }, [editMemberDetails]);

  useEffect(() => {
    setIsEditBtnDisabled(isValid);
  }, [isValid]);

  //Methods
  const onSubmit = (formData: AddOwnersFormSchemaPayload) => {
    onAddBtnClick(formData);
    reset();
    setSelectedPermission('');
    setEditableMemberDetails({} as TemporaryEntityMemberDetails);
  };

  const handlePermissionChange = (event: any) => {
    setSelectedPermission(event?.target?.value);
    setValue('permission', event.target.value, { shouldValidate: true });
  };

  //HTML
  return (
    <Box sx={{ mt: 4 }}>
      <form>
        <Box className="flex-basic-center align-items-start">
          <Box sx={{ mr: 3 }} className="w-50">
            <Controller
              name="first_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  {...field}
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  error={Boolean(errors?.first_name)}
                  helperText={errors?.first_name?.message}
                />
              )}
            />
          </Box>
          <Box className="w-50">
            <Controller
              name="last_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  {...field}
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  error={Boolean(errors?.last_name)}
                  helperText={errors?.last_name?.message}
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 3 }} className="flex-basic-center align-items-start">
          <Box
            sx={{ mr: memberType === ROLES.OWNER ? 3 : 0 }}
            className={clsx({ ['w-50']: memberType === ROLES.OWNER, ['w-100']: memberType === ROLES.USER })}
          >
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  {...field}
                  fullWidth
                  label="Email"
                  variant="outlined"
                  error={Boolean(errors?.email)}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </Box>
          <Box className={clsx({ ['w-50']: memberType === ROLES.OWNER })}>
            <Controller
              name="ownership_percentage"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <>
                  {memberType === ROLES.OWNER && (
                    <TTextField
                      {...field}
                      fullWidth
                      label="Ownership Entity Stake %"
                      isOptional={true}
                      variant="outlined"
                      error={Boolean(errors?.ownership_percentage)}
                      helperText={errors?.ownership_percentage?.message}
                    />
                  )}
                </>
              )}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Controller
            name="permission"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <FormControl className="w-100">
                <TSelect
                  {...field}
                  name="permission"
                  id="type"
                  fullWidth
                  options={ENTITY_MEMBER_PERMISSION}
                  label="Permission"
                  itemId="value"
                  itemValue="label"
                  value={selectedPermission}
                  onChange={(event) => handlePermissionChange(event)}
                />
              </FormControl>
            )}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Controller
            name="is_beneficial_owner"
            defaultValue={false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                {memberType === ROLES.OWNER && (
                  <FormControlLabel
                    control={<TCheckbox checked={value} onChange={onChange} />}
                    label={t('beneficialOwner')}
                  />
                )}
              </>
            )}
          />
        </Box>
        {memberType === ROLES.OWNER && (
          <Box sx={{ ml: theme.spacing(7) }} className="text-extra-small">
            {t('benificaialOwnerNote')}
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <TButton
            btnText={editMemberDetails?.email ? 'Save' : 'Add'}
            icon={
              <>
                {
                  <Box
                    className={clsx({
                      ['icon-add-circle']: !editMemberDetails?.email,
                      ['icon-checked']: editMemberDetails?.email,
                    })}
                  ></Box>
                }
              </>
            }
            disabled={!isValid}
            variant="contained"
            btnVariantClass="buttonWithTextIcon button-small"
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddMembersForm;
