import userReducer from './feature/user/userSlice';
import commonReducer from './feature/common/commonSlice';
import inviteReducer from './feature/merchantInvite/inviteSlice';
import applicantReducer from './feature/applicant/applicantSlice';
import profileIdProcessReducer from './feature/id-process/profileIdProcessSlice';
import idDocProcessReducer from './feature/id-process/idDocProcessSlice';
import passportProcessReducer from './feature/id-process/passportProcessSlice';
import biometricIdProcessReducer from './feature/id-process/biometricIdProcessSlice';
import kycIdProcessReducer from './feature/id-process/kycIdProcessSlice';
import taxIdReducer from './feature/id-process/taxIdIdProcessSlice';
import applicantNotesReducer from './feature/applicant/applicantAccountNotesSlice';
import applicationMessageReducer from './feature/applicant/applicantMessageSlice';
import applicationActionsReducer from './feature/id-process/idProcessActionsSlice';
import communicationReducer from './feature/communication/communicationSlice';
import applicationTagReducer from './feature/applicant/applicantTagSlice';
import wetSignatureIdProcessReducer from './feature/id-process/wetSignatureSlice';
import w8IdProcessReducer from './feature/id-process/w8IdProcessSlice';
import w9IdProcessReducer from './feature/id-process/w9IdProcessSlice';
import merchantInvitesReducer from './feature/merchantInvite/merchantInviteSlice';
import customIdProcessReducer from './feature/id-process/customIdProcessSlice';
import incomeVerificationReducer from './feature/id-process/incomeVerificationSlice';
import accreditationReducer from './feature/id-process/accreditationSlice';
import entityReducer from './feature/id-process/entity/entitySlice';
import notificationReducer from './feature/notification/notificationSlice';
import repositoryReducer from './feature/repositories/repositorySlice';
import documentReducer from './feature/document/documentSlice';

import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  merchantInvite: inviteReducer,
  applicant: applicantReducer,
  applicationMessage: applicationMessageReducer,
  applicationTag: applicationTagReducer,
  profile: profileIdProcessReducer,
  taxId: taxIdReducer,
  applicantNotes: applicantNotesReducer,
  idDocProcess: idDocProcessReducer,
  passportProcess: passportProcessReducer,
  kycIdProcess: kycIdProcessReducer,
  biometricIdProcess: biometricIdProcessReducer,
  applicationActions: applicationActionsReducer,
  communication: communicationReducer,
  wetSignatureIdProcess: wetSignatureIdProcessReducer,
  w8IdProcess: w8IdProcessReducer,
  w9IdProcess: w9IdProcessReducer,
  invites: merchantInvitesReducer,
  customIdProcess: customIdProcessReducer,
  incomeVerification: incomeVerificationReducer,
  accreditation: accreditationReducer,
  entity: entityReducer,
  notification: notificationReducer,
  repository: repositoryReducer,
  document: documentReducer,
});

const rootReducer: any = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');

    state = {} as RootState;
  }
  return reducer(state, action);
};

export type RootState = ReturnType<typeof reducer>;

export default rootReducer;
