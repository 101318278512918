import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  NotificationsResultType,
  NotificationTabType,
} from '../../components/myde-react-components/src/components/Notifications/notificationsType';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectNotification,
  getMerchantSystemNotifications,
  getMerchantUserNotifications,
  markAsRead,
  setIsNotificationRead,
  clearSystemNotifications,
  clearUserNotifications,
  getNotificationsCount,
} from '../../redux/feature/notification/notificationSlice';
import NotificationsIndex from '../../components/myde-react-components/src/components/Notifications/NotificationsIndex';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import Popover from '@mui/material/Popover';
import { ClickHandlerType } from '../../types/commonTypes';
import { NOTIFICATION_TAB, TLoader } from '../../components/myde-react-components';
import { getApplicantDetailById } from '../../api/applicants';

//Styles
const useStyles = makeStyles({
  boxContainer: {
    width: '433px',
    borderRadius: '0px',
    top: '80px',
    left: '767px',
  },
  containerPopOver: {
    '& .MuiPaper-root': {
      width: '433px',
      borderRadius: '0px !important',
    },
    '& >.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
      boxShadow: '0px 24px 50px rgba(163, 165, 174, 0.2) !important',
    },
  },
});

interface NotificationsLandingProps {
  onClose: ClickHandlerType;
  open: boolean;
}

const NotificationsLanding = ({ onClose, open }: NotificationsLandingProps) => {
  //Constants
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  //State variables
  const [notificationData, setNotificationData] = useState([] as NotificationTabType[]);
  const [hasNext, setHasNext] = useState(false);
  const [systemPage, setSystemPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const [systemNotificationsData, setSystemNotificationsData] = useState([] as NotificationsResultType[]);
  const [prevSystemNotificationsData, setPrevSystemNotificationsData] = useState([] as NotificationsResultType[]);
  const [userNotificationData, setUserNotificationData] = useState([] as NotificationsResultType[]);
  const [prevUserNotificationData, setPrevUserNotificationData] = useState([] as NotificationsResultType[]);

  //Redux values
  const { systemNotificationSet, userNotificationSet, loading } = useSelector(selectNotification);

  //UseEffects
  useEffect(() => {
    if (open) {
      getUserData();
      getSystemData();
      dispatch(setIsNotificationRead(false));
      dispatch(getNotificationsCount());
    }
  }, [open]);

  useEffect(() => {
    return () => {
      dispatch(clearUserNotifications());
      dispatch(clearSystemNotifications());
      resetDataStateValues();
    };
  }, []);

  useEffect(() => {
    createNotificationData();
  }, [systemNotificationSet, userNotificationSet, systemNotificationsData, userNotificationData]);

  useEffect(() => {
    if (userPage > 1) {
      setPrevUserNotificationData(userNotificationData);
      getUserData();
    }
  }, [userPage]);

  useEffect(() => {
    if (systemPage > 1) {
      setPrevSystemNotificationsData(systemNotificationsData);
      getSystemData();
    }
  }, [systemPage]);

  useEffect(() => {
    if (systemNotificationSet?.next) {
      setHasNext(true);
    }
    const notifications = systemNotificationSet?.results || [];
    let combinedNotifications = [] as NotificationsResultType[];
    if (prevSystemNotificationsData?.length > 0) {
      combinedNotifications = [...prevSystemNotificationsData, ...notifications];
    } else {
      combinedNotifications = [...notifications];
    }
    setSystemNotificationsData(combinedNotifications);
  }, [systemNotificationSet, prevSystemNotificationsData]);

  useEffect(() => {
    if (userNotificationSet?.next) {
      setHasNext(true);
    }
    const notifications = userNotificationSet?.results || [];
    let combinedNotifications = [] as NotificationsResultType[];
    if (prevUserNotificationData?.length > 0) {
      combinedNotifications = [...prevUserNotificationData, ...notifications];
    } else {
      combinedNotifications = [...notifications];
    }
    setUserNotificationData(combinedNotifications);
  }, [userNotificationSet, prevUserNotificationData]);

  //Methods
  const createNotificationData = () => {
    const notificationsList = [];
    const userNotification: NotificationTabType = {
      id: NOTIFICATION_TAB.USER,
      label: 'User',
      data: userNotificationData,
      count: userNotificationSet?.unread_count,
      heightClass: 'merchantNotifications',
    };
    notificationsList.push(userNotification);
    const systemNotification: NotificationTabType = {
      id: NOTIFICATION_TAB.SYSTEM,
      label: 'System',
      data: systemNotificationsData,
      count: systemNotificationSet?.unread_count,
      heightClass: 'merchantNotifications',
    };
    notificationsList.push(systemNotification);
    setNotificationData(notificationsList);
  };

  const getUpdatedUserNotifications = async () => {
    dispatch(clearUserNotifications());
    setUserPage(1);
    await dispatch(
      getMerchantUserNotifications({
        page: 1,
        limit: 10,
      }),
    );
  };

  const resetDataStateValues = () => {
    setUserNotificationData([]);
    setSystemNotificationsData([]);
    setPrevSystemNotificationsData([]);
    setPrevUserNotificationData([]);
    setSystemPage(1);
    setUserPage(1);
  };

  const getUpdatedSystemNotifications = async () => {
    dispatch(clearSystemNotifications());
    setSystemPage(1);
    await dispatch(
      getMerchantSystemNotifications({
        page: 1,
        limit: 10,
      }),
    );
  };

  const getUpdatedData = async (tab: string) => {
    if (tab === NOTIFICATION_TAB.USER) {
      getUpdatedUserNotifications();
    }
    if (tab === NOTIFICATION_TAB.SYSTEM) {
      getUpdatedSystemNotifications();
    }
  };

  const handleMarkAsReadClick = async (data: string[], tab: string) => {
    await dispatch(markAsRead({ tab: tab }));
    tab === NOTIFICATION_TAB.USER ? setUserNotificationData([]) : setSystemNotificationsData([]);
    getUpdatedData(tab);
    dispatch(getNotificationsCount());
    resetDataStateValues();
  };

  const getUserData = async () => {
    await dispatch(
      getMerchantUserNotifications({
        page: userPage,
        limit: 10,
      }),
    );
  };

  const getSystemData = async () => {
    await dispatch(
      getMerchantSystemNotifications({
        page: systemPage,
        limit: 10,
      }),
    );
  };

  const fetchMoreData = async (tab: string) => {
    if (tab === NOTIFICATION_TAB.SYSTEM && systemNotificationSet?.next) {
      setSystemPage(systemPage + 1);
    }
    if (tab === NOTIFICATION_TAB.USER && userNotificationSet?.next) {
      setUserPage(userPage + 1);
    }
  };

  const onClosePopOver = async () => {
    onClose(false);
    resetDataStateValues();
  };

  const handleNotificationClick = async (notification: NotificationsResultType) => {
    const applicationDetailList = (await getApplicantDetailById(notification?.application_id)) || ({} as any);
    const application = applicationDetailList?.results[0];
    const stateObj = {
      inviteId: application?.id,
      type: application?.account?.type,
      userId: application?.account?.trellis_uid,
      navigateTo: notification?.navigate_to,
    };
    routeTo(ROUTES.NOTIFICATION_ROUTING, true, '', stateObj);
    onClosePopOver();
  };

  return (
    <>
      <Box className={clsx(classes.boxContainer)}>
        <TLoader loading={loading} />
        <Popover
          className={clsx(classes.containerPopOver, 'w-100')}
          open={open}
          onClose={() => onClose(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={10}
          PaperProps={{ sx: { height: '100%', mt: 6, p: 2 } }}
        >
          <Box>
            <NotificationsIndex
              handleNotificationClick={handleNotificationClick}
              notificationData={notificationData}
              handleMarkAsReadClick={handleMarkAsReadClick}
              fetchMoreData={fetchMoreData}
              hasMore={hasNext}
              onClose={onClosePopOver}
              defaultTab={NOTIFICATION_TAB.USER}
            />
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default NotificationsLanding;
