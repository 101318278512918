import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { ApplicationType } from '../../types/applicantTypes';
import { INVITE_APP_DATE_LABEL, INVITE_MESSAGE, INVITE_STATUS } from '../../constants/constants';
import { formatDate } from '../../utils/utils';
import UserMessageSummary from '../userMessages/UserMessageSummary';
import UserNoteSummary from '../userNotes/UserNoteSummary';

import UserTagSummary from '../userTags/UserTagSummary';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { useSelector } from 'react-redux';
import UserEntitiesIndex from '../userEntities/UserEntitiesIndex';

//Main Component
const ApplicantDetail = () => {
  //constants
  const { applicantionDetail } = useSelector(selectApplicant);
  const [applicationInfo, setApplicationInfo] = useState({} as ApplicationType);

  //useEffect
  useEffect(() => {
    setApplicationInfo(applicantionDetail);
  }, [applicantionDetail]);

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <label className="text-h2 font-weight-semibold textColor-200">Account Status: </label>
          <span className="text-h2 font-weight-semibold textStatusColor-100">
            {INVITE_MESSAGE[applicationInfo.status]}
          </span>
        </Grid>
        <Grid item>
          <label className="text-medium font-weight-regular textColor-300">Updated </label>
          <span className="text-medium font-weight-regular textColor-200">
            {applicationInfo.modified_on ? formatDate(applicationInfo.modified_on) : 'NA'}
          </span>
        </Grid>
      </Grid>
      <Grid container direction="column" sx={{ mt: 4 }}>
        <Grid item>
          <Box className="text-medium font-weight-regular textColor-300">Application Received</Box>
          <Box className="text-large font-weight-semibold textColor-200">
            {applicationInfo.submitted_on ? formatDate(applicationInfo.submitted_on) : 'NA'}
          </Box>
        </Grid>
        {applicationInfo?.reviewed_on && applicationInfo?.status !== INVITE_STATUS.UNDER_REVIEW && (
          <>
            <Grid item sx={{ mt: 3 }}>
              <Box className="text-medium font-weight-regular textColor-300">
                {INVITE_APP_DATE_LABEL[applicationInfo?.status]}
              </Box>
              <Box className="text-large font-weight-semibold textColor-200">
                {formatDate(applicationInfo?.reviewed_on)}
              </Box>
            </Grid>
          </>
        )}
        <Grid item sx={{ mt: 3 }}>
          <UserMessageSummary />
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <UserNoteSummary />
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <UserTagSummary />
        </Grid>
        {applicationInfo?.status === INVITE_STATUS.APPROVED && (
          <Grid item sx={{ mt: 3 }}>
            <UserEntitiesIndex applicationInfo={applicationInfo} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ApplicantDetail;
