import { apiGet, apiPost } from './axios';

export const sendLinkForForgotPassword = async (formData: { email: string }) => {
  const res = await apiPost('/merchant/forget-password', formData);
  return res.data;
};

export const sendResetPassword = async (formData: { password: string }) => {
  const res = await apiPost('/merchant/reset-password', formData, {
    custom: {
      excludeTokenIdFromHeader: true,
      APITokenIdFromHeader: false,
      resetPasswordTokenIdFromHeader: true,
    },
  });
  return res.data;
};

export const validatingResetPasswordToken = async (token: string) => {
  const res = await apiGet(`/reset-password/${token}`);
  return res.data;
};
