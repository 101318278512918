import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { IdDocProcessType, IdDocResultType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  passportLoading: false,
  error: false,
  errorObj: <any>{},
  results: <IdDocResultType>{},
  passportProcessDetail: <IdDocProcessType>{},
};

export const selectPassport = ({ passportProcess }: RootState) => ({
  passportProcessDetail: passportProcess.passportProcessDetail,
  passportLoading: passportProcess.passportLoading,
  error: passportProcess.error,
  results: passportProcess.results,
});

// Actions
export const getPassportIdProcessDetails = createAsyncThunk(
  'passport/getPassportIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const passportProcessSlice = createSlice({
  name: 'passport',
  initialState,
  reducers: {
    setResults: (state, action) => {
      state.results = action.payload;
    },
    resetResults: (state) => {
      state.results = <IdDocResultType>{};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPassportIdProcessDetails.pending, (state) => {
        state.passportLoading = true;
        state.error = false;
      })
      .addCase(getPassportIdProcessDetails.fulfilled, (state, action: any) => {
        state.passportLoading = false;
        state.error = false;
        if (action.payload) {
          state.passportProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getPassportIdProcessDetails.rejected, (state) => {
        state.passportLoading = false;
        state.error = true;
      });
  },
});

export const {
  setResults,
  resetResults
} = passportProcessSlice.actions;

export default passportProcessSlice.reducer;
