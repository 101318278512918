import React, { useEffect } from 'react';
import useQuery from '../../hooks/useQuery';
import { useDispatch } from 'react-redux';

import { NAVIGATE_TO_NOTIFICATION, NOTIFICATION_NAVIGATIONS } from '../../constants/notifications';
import { useRouter } from '../../providers/custom-router-provider';
import { TLoader } from '../../components/myde-react-components';
import { setCurrentTabId } from '../../redux/feature/merchantInvite/inviteSlice';

const NotificationRouting = () => {
  // Constants
  const query = useQuery();
  const inviteId = query?.get('inviteId') || '';
  const type = query?.get('type') || '';
  const userId = query?.get('userId') || '';
  const navigateTo = query?.get('navigateTo') || '';
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  //useEffect
  useEffect(() => {
    const query = { inviteId: inviteId, type: type, userId: userId };
    if (navigateTo === NOTIFICATION_NAVIGATIONS.INVITATION_LISTING) {
      dispatch(setCurrentTabId(3));
    }
    routeTo(NAVIGATE_TO_NOTIFICATION[navigateTo], false, '', query);
  }, []);

  return (
    <>
      <TLoader loading={true} />
    </>
  );
};

export default NotificationRouting;
