import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BUILD_NUMBER, SUPPORT_EMAILS } from '../myde-react-components/src/constants/constants';
const CommonFooter = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box className="d-flex flex-basic-space-between">
          <Box className="flex-basic-center">
            <Box sx={{ pr: 2 }} className="text-medium font-weight-semibold textColor-200">
              <a className="anchorTagStyle" href={`mailto: ${SUPPORT_EMAILS.CONTACT_US}`}>
                {t('contactUs')}
              </a>
            </Box>
            <Box sx={{ pr: 2 }} className="text-medium font-weight-semibold textColor-200">
              <a className="anchorTagStyle" href={process.env.REACT_APP_PRIVACY_POLICY}>
                {t('privacyPolicy')}
              </a>
            </Box>
            <Box className="text-medium font-weight-semibold textColor-200">
              <a className="anchorTagStyle" href={process.env.REACT_APP_TERMS_CONDITION}>
                {t('termsandConditions')}
              </a>
            </Box>
          </Box>
          <Box className="flex-basic-space-between">
            <Box sx={{ pr: 3 }} className="text-medium textColor-300">
              {t('poweredByText')} {BUILD_NUMBER}
            </Box>
            <Box className="text-medium textColor-300">{t('allRightReserved')}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CommonFooter;
