import { apiUpload } from './axios';

export const uploadDocument = async (data: File, progressCallBack?: (progress: number) => any) => {
  const file = new FormData();
  file.append('file', data);
  const response = await apiUpload(
    '/document',
    file,
    { headers: { 'Content-Type': 'multipart/form-data' } },
    progressCallBack,
  );
  return response.data;
};
