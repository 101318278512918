import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';

import { AuthProvider } from '../auth/auth-guard';
import { GuardedRoute } from './GuardedRoute';
import { CustomRouterProvider } from '../providers/custom-router-provider';

import * as ROUTES from '../constants/routes';
import AppLayout from '../layout/AppLayout';
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import ApplicantDetailsIndex from '../views/applicants/ApplicantDetails';
import CommunicationIndex from '../views/communication/CommunicationIndex';
import CommunicationFormIndex from '../components/communication/CommunicationFormIndex';
import CommunicationDetail from '../components/communication/CommunicationDetail';
import Settings from '../pages/Settings/Settings';
import UserMessageListIndex from '../views/applicantMessage/UserMessageListIndex';
import UserMessageDetail from '../components/userMessages/UserMessageDetail';
import UserNoteListIndex from '../views/applicantNotes/UserNoteListIndex';
import IndividualNoteDetails from '../components/userNotes/IndividualNoteDetails';
import CommonFooter from '../components/common/Footer';
import InviteFormIndex from '../components/invites/InviteFormIndex';
import IdleLogout from '../components/myde-react-components/src/components/IdleTimeLogout/IdleLogout';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ResetPasswordSuccessfull from '../pages/ResetPassword/ResetPasswordSuccessfull';
import { PORTAL } from '../components/myde-react-components/src/constants/portal';
import BeneficialApplicantDetails from '../views/applicants/BeneficialApplicantDetails';
import NotificationRouting from '../pages/NotificationsLanding/NotificationRouting';
import HeaderLayout from '../layout/HeaderLayout';
import EntityIndex from '../pages/Entity/EntityIndex';
import EntityDetails from '../pages/Entity/EntityDetails';
import ManageRespositoriesIndex from '../views/repositories/ManageRepositoriesIndex';

export default () => {
  const AuthWrappedAppLayout = () => {
    return (
      <AppLayout>
        <HeaderLayout />
        <Box className="minHeightContainer">
          <Outlet />
        </Box>
        <Container className="mainContainer">
          <Grid item sm={12} md={12} lg={12} xs={12}>
            <CommonFooter />
          </Grid>
        </Container>
      </AppLayout>
    );
  };

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <CustomRouterProvider>
        <AuthProvider>
          <IdleLogout portalName={PORTAL.MERCHANT} />
          <Routes>
            <Route path={'/'} element={<Login />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.FORGET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccessfull />} />
            <Route path="/" element={<AuthWrappedAppLayout />}>
              <Route path={ROUTES.DASHBOARD} element={<GuardedRoute component={Dashboard} />} />
              <Route path={ROUTES.INVITE_FORM} element={<GuardedRoute component={InviteFormIndex} />} />
              <Route path={ROUTES.USER} element={<GuardedRoute component={ApplicantDetailsIndex} />} />
              <Route path={ROUTES.BENEFICIAL_USER} element={<GuardedRoute component={BeneficialApplicantDetails} />} />
              <Route path={ROUTES.COMMUNICATIONS}>
                <Route path={ROUTES.COMMUNICATIONS} element={<GuardedRoute component={CommunicationIndex} />} />
                <Route
                  path={ROUTES.COMMUNICATIONS_DRAFT}
                  element={<GuardedRoute component={CommunicationFormIndex} />}
                />
                <Route path={ROUTES.COMMUNICATIONS_DETAIL} element={<GuardedRoute component={CommunicationDetail} />} />
              </Route>
              <Route path={ROUTES.USER_MESSAGES} element={<GuardedRoute component={UserMessageListIndex} />} />
              <Route path={ROUTES.USER_MESSAGE_DETAIL} element={<GuardedRoute component={UserMessageDetail} />} />
              <Route path={ROUTES.USER_NOTES} element={<GuardedRoute component={UserNoteListIndex} />} />
              <Route path={ROUTES.NOTE} element={<GuardedRoute component={IndividualNoteDetails} />} />
              <Route path={ROUTES.NOTIFICATION_ROUTING} element={<GuardedRoute component={NotificationRouting} />} />
              <Route path={ROUTES.PROFILE} element={<Settings />} />
              <Route path={ROUTES.ENTITIES} element={<EntityIndex />} />
              <Route path={ROUTES.ENTITY_DETAILS} element={<EntityDetails />} />
              <Route path={ROUTES.MANAGE_REPOSITORIES} element={<ManageRespositoriesIndex />} />
            </Route>
          </Routes>
        </AuthProvider>
      </CustomRouterProvider>
    </Router>
  );
};
