import * as z from 'zod';

const trimString = (u: unknown) => (typeof u === 'string' ? u.trim() : u);

export const FormSchema = z.object({
  tag: z.preprocess(
    trimString,
    z.string().min(1, { message: 'Tag is required' }).max(30, { message: 'Tag can be 30 characters long' }),
  ),
});
