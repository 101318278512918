import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { FilePreviewType } from '../../../types/documentTypes';

type NotificationState = {
  loading: boolean;
  error: boolean;
  documentList: FilePreviewType[];
};

const initialState: NotificationState = {
  loading: false,
  error: false,
  documentList: [] as FilePreviewType[],
};

//selector
export const selectDocument = ({ document }: RootState) => ({
  loading: document.loading,
  documentList: document.documentList,
});

//reducer
export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocumentList: (state, action) => {
      state.documentList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder;
  },
});

export const { setDocumentList } = documentSlice.actions;

export default documentSlice.reducer;
