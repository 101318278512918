import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import * as communicationApi from '../../../api/communication';
import {
  CommunicationStateType,
  UploadedDocumentType,
  CategoryYearType,
  MerchantCommunicationListType,
  SpecificMerchantCommunicationType,
} from '../../../types/communicationTypes';

const initialState: CommunicationStateType = {
  loading: false,
  error: false,
  createCommunicationResponse: <MerchantCommunicationListType>{},
  communicationCategories: [],
  merchantTags: [],
  selectedUsers: [],
  selectedTags: [],
  uploadedDocument: <UploadedDocumentType>{},
  documentDetails: <CategoryYearType>{},
  documentList: [],
  saveInUserRepository: false,
  merchantCommunications: [],
  merchantCommunicationsCount: 0,
  specificCommunicationData: <SpecificMerchantCommunicationType>{},
  currentTabId: 0,
  communicationFormTitle: '',
  allSelectedUsers: false,
  tagsLoading: false,
  tagsCount: 0,
  hasNextTags: '',
};

const setData = (data: SpecificMerchantCommunicationType) => {
  const payload = {
    ...data,
    title: data?.title ? data?.title : '',
    message: data?.message ? data?.message : '',
  };
  return payload;
};

export const selectCommunications = ({ communication }: RootState) => ({
  loading: communication.loading,
  error: communication.error,
  createCommunicationResponse: communication.createCommunicationResponse,
  communicationCategories: communication.communicationCategories,
  merchantTags: communication.merchantTags,
  selectedUsers: communication.selectedUsers,
  selectedTags: communication.selectedTags,
  uploadedDocument: communication.uploadedDocument,
  documentDetails: communication.documentDetails,
  documentList: communication.documentList,
  saveInUserRepository: communication.saveInUserRepository,
  merchantCommunications: communication.merchantCommunications,
  merchantCommunicationsCount: communication.merchantCommunicationsCount,
  specificCommunicationData: communication.specificCommunicationData,
  currentTabId: communication.currentTabId,
  communicationFormTitle: communication.communicationFormTitle,
  allSelectedUsers: communication.allSelectedUsers,
  tagsLoading: communication.tagsLoading,
  tagsCount: communication.tagsCount,
  hasNextTags: communication.hasNextTags,
});

// Actions
export const createCommunication = createAsyncThunk('communication/createCommunication', async () => {
  return await communicationApi.createApplicationCommunication();
});

export const getCommunicationCategoriesData = createAsyncThunk(
  'communication/getCommunicationCategoriesData',
  async () => {
    return await communicationApi.getCommunicationCategories();
  },
);

export const getMerchantTagsData = createAsyncThunk('communication/getMerchantTagsData', async (filters: any) => {
  return await communicationApi.getMerchantTags(filters);
});

export const uploadMerchantDocument = createAsyncThunk(
  'communication/uploadMerchantDocument',
  async (document: File) => {
    return await communicationApi.uploadMerchantDoc(document);
  },
);

export const getMerchantCommunicationsList = createAsyncThunk(
  'communication/getMerchantCommunicationsList',
  async (filters: any) => {
    return await communicationApi.getMerchantCommunications(filters);
  },
);

export const getSpecificCommunicationsData = createAsyncThunk(
  'communication/getSpecificCommunicationsData',
  async (communicationId: string) => {
    return await communicationApi.getSpecificCommunication(communicationId);
  },
);

// Reducers
export const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    setCommunicationFormTitle: (state, action) => {
      state.communicationFormTitle = action.payload;
    },
    resetCreateCommunicationResponse: (state) => {
      state.createCommunicationResponse = <MerchantCommunicationListType>{};
    },
    saveSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    saveSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    resetUploadedDocument: (state) => {
      state.uploadedDocument = <UploadedDocumentType>{};
    },
    setDocumentDetails: (state, action) => {
      state.documentDetails = action.payload;
    },
    setDocumentsList: (state, action) => {
      state.documentList = action.payload;
    },
    setSaveInUserRepositoryFlag: (state, action) => {
      state.saveInUserRepository = action.payload;
    },
    resetSpecificCommunicationData: (state) => {
      state.specificCommunicationData = <SpecificMerchantCommunicationType>{};
    },
    setCurrentTabId: (state, action) => {
      state.currentTabId = action.payload;
    },
    setAllSelectedUsers: (state, action) => {
      state.allSelectedUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCommunication.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createCommunication.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          const data = setData(action.payload);
          state.createCommunicationResponse = data;
        } else {
          state.error = true;
        }
      })
      .addCase(createCommunication.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getCommunicationCategoriesData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCommunicationCategoriesData.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.communicationCategories = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getCommunicationCategoriesData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantTagsData.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.tagsLoading = true;
      })
      .addCase(getMerchantTagsData.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.tagsLoading = false;
        if (action?.payload?.results) {
          state.merchantTags = action.payload.results;
          state.tagsCount = action.payload.count;
          state.hasNextTags = action.payload.next;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantTagsData.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.tagsLoading = false;
      })
      .addCase(uploadMerchantDocument.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(uploadMerchantDocument.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.uploadedDocument = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(uploadMerchantDocument.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantCommunicationsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMerchantCommunicationsList.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.merchantCommunicationsCount = action?.payload?.count;
          state.merchantCommunications = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantCommunicationsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSpecificCommunicationsData.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecificCommunicationsData.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          const data = setData(action.payload);
          state.specificCommunicationData = data;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificCommunicationsData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  resetCreateCommunicationResponse,
  saveSelectedUsers,
  saveSelectedTags,
  setDocumentsList,
  setDocumentDetails,
  resetUploadedDocument,
  setSaveInUserRepositoryFlag,
  resetSpecificCommunicationData,
  setCurrentTabId,
  setCommunicationFormTitle,
  setAllSelectedUsers,
} = communicationSlice.actions;

export default communicationSlice.reducer;
