import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, IconButton, Theme, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { TConfirmDialog, TLoader, TPagination } from '../myde-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import {
  deleteApplicationNotes,
  getApplicationNotes,
  selectApplicantNotes,
  setNotesIsArchived,
  setNoteUpdated,
} from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { formatDate } from '../../utils/utils';
import { ApplicantNoteType } from '../../types/applicantTypes';
import * as ROUTES from './../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { SECTION_LIST } from '../../constants/constants';
import { useTheme } from '../../providers/custom-theme-provider';
import { DEFAULT_TABLE_OPTIONS, ITEMS_PER_PAGE_LIST } from '../myde-react-components/src/constants/constants';
import { PORTAL } from '../myde-react-components/src/constants/portal';

// Interfaces
interface TableMsgData {
  request: string;
  section: string;
  modified_on: string;
  action?: any;
}

export interface FilteredRecordsParams {
  note?: string;
  is_archived?: boolean;
  modified_on_gte?: string;
  modified_on_lte?: string;
}

export interface NotesFilterApiFormData {
  applicationId: string;
  formPayload: FilteredRecordsParams;
}

export interface NoteApiFormData {
  page: number;
  limit: number;
  applicationId: string;
  is_archived?: boolean;
  section?: any;
}
interface UserNoteListProps {
  isArchived?: boolean;
}
interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyle = makeStyles<Theme, StyleProps>({
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  setPrimaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

// Main Component
const UserNoteList = ({ isArchived }: UserNoteListProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyle(styleProps);
  const primaryColor = currentThemes.palette.primary.main;
  const dispatch = useDispatch();

  const { applicationId, selectedSection } = useSelector(selectApplicant);
  const { loading, noteList, count, noteUpdated } = useSelector(selectApplicantNotes);

  const defaultTableMsg = {} as TableMsgData;

  // State Values
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [msgList, setMsgList] = useState([defaultTableMsg]);
  const [showDialog, setDialogFlag] = useState(false);
  const [selectedNote, setSelectedNote] = useState({} as ApplicantNoteType);
  const { routeTo } = useRouter();

  // Use Effects
  useEffect(() => {
    if (applicationId) {
      getNoteList();
    }
  }, [applicationId, pageSize, selectedSection]);

  useEffect(() => {
    const arr: TableMsgData[] = [];
    noteList.forEach((note) => {
      if (note.note) {
        const obj: TableMsgData = {
          request: note.note,
          section: note.section || 'NA',
          modified_on: formatDate(note.modified_on) || 'NA',
          action: note,
        };
        arr.push(obj);
      }
    });
    setMsgList(arr);
  }, [noteList]);

  useEffect(() => {
    if (page) {
      changePage();
    }
  }, [page, pageSize]);
  useEffect(() => {
    if (noteUpdated) {
      getNoteList();
      dispatch(setNoteUpdated(false));
    }
  }, [noteUpdated]);
  useEffect(() => {
    dispatch(setNotesIsArchived(isArchived));
  }, [isArchived]);

  // Methods
  const getNoteList = () => {
    const fData: NoteApiFormData = {
      page: page,
      limit: pageSize || 1000,
      applicationId: applicationId,
      is_archived: isArchived,
      section: selectedSection !== SECTION_LIST.ACCOUNT ? selectedSection : '',
    };
    dispatch(getApplicationNotes(fData));
  };
  const customBodyRender = (value: string) => {
    return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
  };
  const changePage = () => {
    getNoteList();
  };
  const confirmArchiveUnArchive = (noteItem: ApplicantNoteType) => {
    if (noteItem) {
      setSelectedNote(noteItem);
      setDialogFlag(true);
    }
  };
  const selectUser = (user: any) => {
    const rowIndex: number = user?.rowIndex || 0;
    routeTo(ROUTES.NOTE, false, '', null, { noteId: noteList[rowIndex]?.id });
  };
  const handleClose = () => {
    setSelectedNote({} as ApplicantNoteType);
    setDialogFlag(false);
    getNoteList();
  };

  const updateNote = (noteItem: any) => {
    if (noteItem) {
      updateNoteStatus(noteItem.id);
    }
    handleClose();
  };
  const updateNoteStatus = (noteId: string) => {
    const reqData = { applicationId: applicationId, noteId: noteId };
    dispatch(deleteApplicationNotes(reqData));
    getNoteList();
  };
  const onRowChange = (value: any) => {
    setPageSize(value);
  };
  const onPageChange = (value: any) => {
    setPage(value);
  };

  // Table constants
  const columns = [
    {
      name: 'request',
      label: 'Description',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box
              sx={{ color: currentThemes.palette.primary.main }}
              className="cursorPointer text-large font-weight-semibold textColor-200"
              onClick={() => {
                selectUser(tableMeta);
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: 'section',
      label: 'Section',
      options: { customBodyRender },
    },
    {
      name: 'modified_on',
      label: 'Date',
      options: { customBodyRender },
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        display: isArchived === false,
        customBodyRender: (value: any) => {
          return (
            <>
              <Box>
                <Tooltip title={value?.is_archived ? 'Un-archive' : 'Archive'}>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      confirmArchiveUnArchive(value);
                    }}
                  >
                    {value?.is_archived ? (
                      <UnarchiveIcon className={classes.setPrimaryColor} />
                    ) : (
                      <ArchiveOutlinedIcon className={classes.setPrimaryColor} />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              <TConfirmDialog
                showDialog={showDialog}
                classNameConfirmDailog="confirmDialogBoxOpacityList"
                onCancelClick={handleClose}
                onConfirmClick={() => {
                  updateNote(selectedNote);
                }}
                portalName={PORTAL.MERCHANT}
                confirmationMessage={
                  selectedNote?.is_archived
                    ? `Are you sure you want to un-archive the note?`
                    : `Are you sure you want to archive the note ?`
                }
                title={selectedNote?.is_archived ? 'Confirm Un-archive Note' : 'Confirm Archive Note'}
              />
            </>
          );
        },
      },
    },
  ];

  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
  };
  // HTML
  return (
    <>
      <Box className={classes.tableContainer}>
        <TLoader loading={loading} />
        <MUIDataTable title="" data={msgList} columns={columns} options={options} />
        <Box className={classes.pageContainer}>
          <TPagination
            primaryColor={primaryColor}
            rowsPerPageOptions={ITEMS_PER_PAGE_LIST}
            rowsPerPage={pageSize}
            count={Math.ceil(count / pageSize)}
            onRowChange={onRowChange}
            onPageChange={onPageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default UserNoteList;
