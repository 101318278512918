import { makeStyles } from '@material-ui/styles';
import { Box, Theme } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { theme } from '../../components/myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';

export interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  imgBg: {
    width: '8rem',
    height: '8rem',
    borderRadius: '100%',
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const ResetPasswordTokenExpired = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const { t } = useTranslation(['common', 'forgotPassword']);
  const classes = useStyles(styleProps);

  return (
    <Box className={'flex-column-center'}>
      <Box sx={{ my: 4 }} className={clsx(classes.imgBg, 'flex-basic-center')}>
        <span className="icon-broken-link broken-icon text-white icon-size-60"></span>
      </Box>

      <Box sx={{ mb: theme.spacing(3) }} className="text-center font-weight-semibold textColor-200 text-h2">
        {t('resetPasswordLinkInvalidTitle', { ns: 'forgotPassword' })}
      </Box>

      <Box sx={{ mb: 3 }} className="text-center textColor-200 text-medium">
        {t('resetPasswordLinkInvalidDesc', { ns: 'forgotPassword' })}
      </Box>
    </Box>
  );
};

export default ResetPasswordTokenExpired;
