import { apiGet, apiPatch, apiPost, apiPut, apiUpload } from './axios';

import { AssignCoordinatorFormDataType, EditEntityType, EntityFormType, TaxIdType } from '../types/entityTypes';

export const getIdProcessDetailsByProcessId = async (
  applicationId: string,
  processId: number | string,
  params?: object,
) => {
  const res = await apiGet(`/merchant/application/${applicationId}/id-process/${processId}`, { params: params });
  return res.data;
};

// ID Process Action
export const updateIdProcessStatus = async (data: {
  applicationId: string;
  processId: number;
  status: string;
  note?: string;
}) => {
  const reqData = { merchant_review_status: data?.status, note: data?.note };
  const res = await apiPatch(
    `/merchant/application/${data.applicationId}/id-process/${data.processId}/review`,
    reqData,
  );
  return res.data;
};

//create new entity
export const createNewEntity = async (data: EntityFormType) => {
  const res = await apiPost(`/merchant/entity`, data);
  return res.data;
};

//list entities
export const getAllEntities = async (params?: object) => {
  const res = await apiGet('/merchant/entity', params);
  return res.data;
};

export const getAllAssignedEntitiesList = async (params?: object) => {
  const res = await apiGet('/merchant/entity-assignment', { params: params });
  return res.data;
};

export const getAllAssignedEntityDetails = async (entityId: number) => {
  const res = await apiGet(`/merchant/entity/${entityId}`);
  return res.data;
};

//edit entitie details
export const editAnEntity = async (data: { entity_uid: number; formData: EditEntityType }) => {
  const res = await apiPut(`/merchant/entity/${data.entity_uid}`, data?.formData);
  return res.data;
};

//add tax id
export const addTaxId = async (data: { entity_uid: number; formData: TaxIdType }) => {
  const res = await apiPost(`/merchant/entity/${data.entity_uid}/tax-id`, data.formData);
  return res.data;
};

//get tax id
export const getEnteredTaxId = async (data: { entity_uid: number; params: object }) => {
  const res = await apiGet(`/merchant/entity/${data.entity_uid}/tax-id`, { params: data.params });
  return res.data;
};

//profile entite upload
export const updateEntityProfileDetails = async (entity_uid: number, formData: object) => {
  const res = await apiPatch(`/entity/${entity_uid}/entity-profile`, formData);
  return res.data;
};

export const uploadAvatar = async (doc: any) => {
  const file = new FormData();
  file.append('file', doc);
  const res = await apiUpload('/static-assets', file);
  return res.data;
};

//Get esign url
export const getEntityEsignUrl = async (processId: number, entityUid: number, formData: object) => {
  const res = await apiPost(`/entity/${entityUid}/id-process/${processId}/esign`, formData);
  return res.data;
};

//id process
export const submitIdProcess = async (entity_uid: number, payload: object) => {
  const res = await apiPost(`/entity/${entity_uid}/id-process`, payload);
  return res.data;
};

//Get specific ID process data for entity
export const getEntityIdProcessDetailsByProcessId = async (entity_uid: number, process_id: number) => {
  const res = await apiGet(`/entity/${entity_uid}/id-process/${process_id}`);
  return res.data;
};

//assign a coordintor
export const assignCoordiantor = async (data: AssignCoordinatorFormDataType) => {
  const res = await apiPost('/merchant/entity-assignment', data);
  return res.data;
};

//get admin user list
export const getListOfAdminUser = async (params?: object) => {
  const res = await apiGet('/merchant/user', { params: params });
  return res.data;
};
