import { MERCHANT_USER_ROLES } from "./constants";

export const MERCHANT_PORTAL_FEATURE_LIST = {
  APPLICANTS: 'APPLICANTS',
  COMMUNICATIONS: 'COMMUNICATIONS',
  MANAGE_ENTITIES: 'MANAGE_ENTITIES',
  MANAGE_REPOSITORIES: 'MANAGE_REPOSITORIES'
}

export const FEATURE_ROLES = [
  {
    featureName: MERCHANT_PORTAL_FEATURE_LIST.APPLICANTS,
    allowedRoles: [MERCHANT_USER_ROLES.ADMIN]
  }, {
    featureName: MERCHANT_PORTAL_FEATURE_LIST.COMMUNICATIONS,
    allowedRoles: [MERCHANT_USER_ROLES.ADMIN, MERCHANT_USER_ROLES.COORDINATOR]
  }, {
    featureName: MERCHANT_PORTAL_FEATURE_LIST.MANAGE_ENTITIES,
    allowedRoles: [MERCHANT_USER_ROLES.ADMIN, MERCHANT_USER_ROLES.COORDINATOR]
  }, {
    featureName: MERCHANT_PORTAL_FEATURE_LIST.MANAGE_REPOSITORIES,
    allowedRoles: [MERCHANT_USER_ROLES.ADMIN]
  }
];