import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { UploadedDocumentDetailsType } from '../../../types/entityTypes';
import DocumentUploadAndListing from '../../common/DocumentUploadAndListing';
import { TLoader, theme, TButton } from '../../myde-react-components';
import { uploadDocument } from '../../../api/documents';
import { FilePreviewType } from '../../../types/documentTypes';
import { selectDocument, setDocumentList } from '../../../redux/feature/document/documentSlice';
import { resetSuccessIdProcessMessage } from '../../../redux/feature/id-process/entity/entitySlice';

interface UploadIdProcessDocProps {
  pageTitle: string;
  acceptedFileFormat: string[];
  supportedFileFormat: string[];
  existingFileList: FilePreviewType[];
  loading: boolean;
  resetUploadedFile: boolean;
  isMultiple?: boolean;
  onSave: (data: UploadedDocumentDetailsType[]) => void;
}

const UploadIdProcessDoc = ({
  pageTitle,
  acceptedFileFormat,
  supportedFileFormat,
  existingFileList,
  loading,
  resetUploadedFile = false,
  isMultiple = false,
  onSave,
}: UploadIdProcessDocProps) => {
  // Constants
  const { t } = useTranslation(['common', 'entity']);
  const dispatch = useDispatch();

  // State Values
  const [uploadedDocuments, setUploadedDocuments] = useState([] as any[]);
  const [isUploading, setIsUploading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Redux Values
  const { documentList } = useSelector(selectDocument);

  // Use Effects
  useEffect(() => {
    dispatch(setDocumentList([] as FilePreviewType[]));
  }, []);

  useEffect(() => {
    if (resetUploadedFile) {
      setIsButtonDisabled(false);
      dispatch(resetSuccessIdProcessMessage());
    }
  }, [resetUploadedFile]);

  useEffect(() => {
    if (documentList?.length > 0) {
      setUploadedDocuments(documentList);
      setIsButtonDisabled(true);
    } else {
      setUploadedDocuments([] as any[]);
      setIsButtonDisabled(false);
    }
  }, [documentList]);

  useEffect(() => {
    if (existingFileList?.length > 0) {
      setUploadedDocuments(existingFileList);
    }
  }, [existingFileList]);

  //Methods
  const handleSingleFile = async (file: any[]) => {
    const specificFile = {} as FilePreviewType;
    if (file?.length > 0) {
      setIsUploading(true);
      const result = await uploadDocument(file[0]);
      specificFile.id = result?.doc_id;
      specificFile.name = result?.doc_name;
      specificFile.preview = file[0]?.preview;
      specificFile.size = result?.size_in_kb;
      specificFile.type = result?.mime_type;
      specificFile.isDeleteAllowed = true;
      dispatch(setDocumentList([specificFile]));
      setIsUploading(false);
    } else {
      dispatch(setDocumentList([] as FilePreviewType[]));
    }
  };

  const handleMultipleFiles = async (newFiles: any[], oldFiles: any[]) => {
    let documentList: FilePreviewType[] = oldFiles || [];
    if (newFiles?.length > 0) {
      setIsUploading(true);
      await Promise.all(
        newFiles?.map(async (file) => {
          const specificFile = {} as FilePreviewType;
          const result = await uploadDocument(file);
          specificFile.id = result?.doc_id;
          specificFile.name = result?.doc_name;
          specificFile.preview = file?.preview;
          specificFile.size = result?.size_in_kb;
          specificFile.isDeleteAllowed = true;
          specificFile.type = result?.mime_type;
          documentList = Object.assign([], documentList);
          documentList.push(specificFile);
        }),
      );
      setIsUploading(false);
    }
    dispatch(setDocumentList(documentList));
  };

  const save = () => {
    onSave(uploadedDocuments);
  };

  // HTML
  return (
    <>
      <TLoader loading={loading} />
      <Box sx={{ mt: theme.spacing(9), ml: 5 }} className="textColor-200 text-h2 font-weight-semibold">
        {pageTitle}
      </Box>
      <Box sx={{ ml: 5, mt: theme.spacing(15) }} className="textColor-200 text-medium font-weight-semibold">
        {t('uploadDocument')}
      </Box>
      <Box sx={{ mx: 5, mt: theme.spacing(3) }}>
        <DocumentUploadAndListing
          acceptedFileFormat={acceptedFileFormat}
          supportedFileFormat={supportedFileFormat}
          submittedFiles={uploadedDocuments}
          setSingleFile={handleSingleFile}
          setMultipleFiles={handleMultipleFiles}
          isMultiple={isMultiple}
          isUploading={isUploading}
        />
      </Box>
      <Box sx={{ mt: 5, mr: 5 }} className="flex-basic-end">
        <TButton
          btnText="Save"
          variant="contained"
          btnWidthSize="button-w-140"
          disabled={!uploadedDocuments?.length || !isButtonDisabled}
          onClick={save}
        />
      </Box>
    </>
  );
};

export default UploadIdProcessDoc;
