import React, { } from 'react';
import { Box } from '@mui/material';

const Settings = () => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        Settings
      </Box>
    </>
  );
};

export default Settings;
