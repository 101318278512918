import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormFieldType, FieldType, TDynamicForm } from '../myde-react-components';
import { TAppSelectionType } from '../../types/inviteTypes';
import {
  setIsFormValid,
  selectMerchantInvite,
  setTAppFieldData,
  setTAppFormData,
} from '../../redux/feature/merchantInvite/merchantInviteSlice';

interface TAppFormProps {
  tAppData: TAppSelectionType;
}

const TAppForm = ({ tAppData }: TAppFormProps) => {
  // Constants
  const dispatch = useDispatch();

  // Redux Values
  const { tAppFieldData } = useSelector(selectMerchantInvite);

  // State Variables
  const [formDetail, setFormDetail] = useState([] as FormFieldType[]);

  // Use Effects
  useEffect(() => {
    const result =
      tAppData?.invite_fields?.map((item) => {
        return {
          type: 'text' as FieldType,
          label: item?.label,
          name: item?.key,
          defaultValue: item?.defaultValue,
          rules: {
            required: { value: true, message: `${item?.label} is required` },
          },
        };
      }) || ([] as FormFieldType[]);
    setFormDetail(result);
    const tAppFields = tAppData?.invite_fields?.map((field) => {
      if (field?.defaultValue) {
        return {
          key: field?.key,
          value: field?.defaultValue,
        };
      }
    });
    dispatch(setTAppFieldData(tAppFields));
  }, [tAppData]);

  useEffect(() => {
    if (tAppFieldData?.length > 0) {
      const isValid = tAppFieldData?.every((item) => item?.value !== '');
      dispatch(setIsFormValid(isValid));
    }
  }, [tAppFieldData]);

  useEffect(() => {
    if (tAppData?.invite_fields?.length === tAppFieldData?.length) {
      const payload = [
        {
          id: tAppData?.id,
          invite_fields: tAppFieldData,
        },
      ];
      dispatch(setTAppFormData(payload));
    }
  }, [tAppData, tAppFieldData]);

  // Methods
  const splitKeyValue = (obj: any) => {
    const keys = Object.keys(obj);
    const res = [];
    for (let i = 0; i < keys.length; i++) {
      res.push({
        key: keys[i],
        value: obj[keys[i]],
      });
    }
    return res;
  };

  const handleTAppFormData = (data: any) => {
    const result = splitKeyValue(data);
    dispatch(setTAppFieldData(result));
  };

  return <TDynamicForm fieldSize={'w-50'} fields={formDetail} isAutoSubmitMode={true} onSubmit={handleTAppFormData} />;
};

export default TAppForm;
