import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SvgIconProps, List, ListItem, Drawer as MuiDrawer, DrawerProps, CssBaseline, Box } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { TCommonAvatar, TFeatureRbac, colorPalette, theme } from '../../components/myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';
import { getInitials } from '../../utils/utils';
import { selectUser, setLoginFlag } from '../../redux/feature/user/userSlice';
import { UserData } from '../../types/userTypes';
import { useRouter } from '../../providers/custom-router-provider';
import { selectSession } from '../../redux/feature/common/commonSlice';
import { hexToRgbA } from '../myde-react-components/src/utils/commonMethods';

const drawerWidth = 260;

export interface TDrawerProps extends DrawerProps {
  listItems: {
    id: number;
    title: string;
    icon: React.ReactElement<SvgIconProps>;
    to: string;
    feature: string;
  }[];
}
export interface StyleProps {
  menuItemCss?: string;
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  //Drawer Css
  root: {
    display: 'flex',
    '& .MuiPaper-root.MuiDrawer-paper': {
      overflowY: 'visible',
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.secondary.dark}`,
    },
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },

  drawerOpen: {
    width: drawerWidth,
    overflow: 'unset',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.12)',
    height: '100vh',
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.12)',
    height: '100vh',
    overflow: 'unset',
    width: drawerWidth / 2,
  },

  drawerMenu: {
    padding: '0 20px',
    width: ({ menuItemCss }) => menuItemCss,
    '& .MuiListItem-root': {
      borderRadius: '4px',
      marginBottom: '20px',
      color: ({ currentThemes }) => `${currentThemes.palette.secondary.contrastText}`,
      '&.Mui-selected': {
        position: 'relative',
        '& .text-large': {
          fontWeight: '600',
        },

        backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
        '&::before': {
          content: "''",
          position: 'absolute',
          width: '4px',
          height: '28px',
          background: colorPalette.typoText.lighten3,
          borderRadius: '12px',
          left: '0px',
        },
      },
      '&:hover': {
        backgroundColor: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.08),
      },
    },
  },

  //Menu List Css
  menuListItem: {
    minHeight: '35px',
    padding: '7px 0px',
    borderRadius: '0px',
  },

  menuIcon: {
    minWidth: '34px !important',
    color: ({ currentThemes }) => `${currentThemes.palette.secondary.contrastText} !important`,
  },

  toggleIcon: {
    position: 'absolute',
    right: '-44px',
    top: '26px',
    zIndex: '999999',
  },

  listItem: {
    '& MuiListItem': {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
        color: colorPalette.info,
      },
    },
  },

  selected: {
    '&$selected': {
      color: theme.palette.primary.main,
      backgroundColor: colorPalette.backgroundFadeColors.primary,
    },
    '&:hover': {
      backgroundColor: colorPalette.menu.base,
    },
  },

  profileSection: {
    backgroundColor: ({ currentThemes }) => `${currentThemes.palette.secondary.dark}`,
  },

  drawerTextColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.secondary.contrastText}`,
  },

  brandIcon: {
    height: '40px',
    width: '65px',
  },
}));

export const Drawer = (drawerProps: TDrawerProps) => {
  // constants
  const menuItems = drawerProps.listItems;
  const { routeTo } = useRouter();
  const { currentThemes } = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentSelectedItem = menuItems?.find((item) => location?.pathname?.includes(item?.to));

  // state variables
  const [drawerToggle, drawerToggleHandler] = useState(true);
  const [activeItem, setActiveItem] = useState(currentSelectedItem?.id);
  const [brandingUrl, setBrandingUrl] = useState('');
  const [userDetail, setUserDetail] = useState({} as UserData);
  const [merchantName, setMerchantName] = useState('');
  const props = {
    menuItemCss: drawerToggle ? '260px' : '120px',
    currentThemes,
  };
  const classes = useStyles(props);

  // use selectors
  const { selectedMerchant, userData } = useSelector(selectUser);
  const { showAlert } = useSelector(selectSession);

  // use effects
  useEffect(() => {
    if (selectedMerchant?.merchant?.id) {
      setBrandingUrl(selectedMerchant?.merchant?.branding?.logo_thumbnail_url);
      setMerchantName(selectedMerchant?.merchant?.name);
      dispatch(setLoginFlag(true));
    }
  }, [selectedMerchant]);

  useEffect(() => {
    if (userData?.id) {
      setUserDetail(userData);
    }
  }, [userData]);

  useEffect(() => {
    getActiveItem();
  }, [location]);

  // methods
  const getActiveItem = () => {
    const findIndex = menuItems?.findIndex((item) => location?.pathname?.includes(item?.to));
    setActiveItem(findIndex);
  };

  const getUserName = () => {
    return `${userDetail?.first_name}\t${getInitials(userDetail?.last_name)}.`;
  };

  const toggleDrawer = () => {
    drawerToggleHandler(!drawerToggle);
  };

  const handleListItemClick = (to: string) => {
    getActiveItem();
    routeTo(to, false, '', null, null, !!showAlert);
  };

  const renderMenu = (item: any) => {
    return (
      <TFeatureRbac currentFeature={item?.feature}>
        <ListItem
          selected={activeItem === item.id}
          button
          key={item.id}
          className={clsx({
            [classes.menuListItem]: true,
            [classes.selected]: activeItem === item.id,
          })}
          onClick={() => handleListItemClick(item.to)}
        >
          <ListItemIcon
            sx={{ pl: drawerToggle ? '' : theme.spacing(3) }}
            className={clsx({
              [classes.menuIcon]: true,
              [classes.icon]: activeItem === item.id,
            })}
          >
            {item.icon}
          </ListItemIcon>
          {drawerToggle && (
            <ListItemText sx={{ my: 0 }}>
              <Box
                className={clsx({
                  ['text-large font-weight-medium']: true,
                  [classes.menuLabel]: activeItem === item.id,
                })}
              >
                {item.title}
              </Box>
            </ListItemText>
          )}
        </ListItem>
      </TFeatureRbac>
    );
  };

  // HTML
  return (
    <Box className={classes.root}>
      <CssBaseline />
      <MuiDrawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerToggle,
          [classes.drawerClose]: !drawerToggle,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerToggle,
            [classes.drawerClose]: !drawerToggle,
          }),
        }}
      >
        <Box
          className={clsx({ ['collapseView']: !drawerToggle }, classes.profileSection, 'profileSection')}
          sx={{ mx: theme.spacing(5), mt: drawerToggle ? 9 : 7, mb: theme.spacing(15) }}
        >
          <Box>
            <Box className={clsx(classes.profileSection, 'profileCircle flex-basic-center')}>
              <TCommonAvatar
                imageUrl={userDetail?.avatar_url || ''}
                avatarText={`${userDetail?.first_name || ''} ${userDetail?.last_name || ''}`}
                iconSize={'68px'}
                textSize={'2rem'}
              />
            </Box>
            {drawerToggle && (
              <Box
                sx={{ px: theme.spacing(3), mt: theme.spacing(13) }}
                className={clsx(
                  'text-large font-weight-semibold text-center text-ellipsis w-100',
                  classes.drawerTextColor,
                )}
              >
                {getUserName()}
              </Box>
            )}
          </Box>
        </Box>

        <Box onClick={toggleDrawer} className={classes.toggleIcon}>
          <Box className={classes.drawerIcon}>
            <Box
              className={clsx(
                { ['leftArrowCollapse']: !drawerToggle },
                'icon-back-arrow cursorPointer iconWithTextColor icon-size-24',
              )}
            ></Box>
          </Box>
        </Box>
        <Box className={classes.drawerMenu}>
          <List>{menuItems.map((item) => renderMenu(item))}</List>
        </Box>

        <Box className="text-center merchantName w-100">
          <Box>
            <img src={brandingUrl} loading="lazy" alt="Branding Logo" className={classes.brandIcon} />
          </Box>
          <Box className={clsx('text-large font-weight-medium', classes.drawerTextColor)}>{merchantName}</Box>
        </Box>
      </MuiDrawer>
    </Box>
  );
};
export default Drawer;
