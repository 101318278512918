import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TButton, theme } from '../myde-react-components';
import EntityOnboardingForm from './EntityOnboardingForm';
import { EntityDetail } from '../../types/entityTypes';
import { selectEntity, setIsEditable } from '../../redux/feature/id-process/entity/entitySlice';
import AddedEntityDetails from './AddedEntityDetails';

const EntityDetailsIndex = () => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { assignedEntityDetails } = useSelector(selectEntity);

  //State variables
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);
  const [isEditClicked, setIsEditClicked] = useState(false);

  //Use Effects
  useEffect(() => {
    dispatch(setIsEditable(false));
  }, []);

  useEffect(() => {
    if (assignedEntityDetails) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  const handleEditClick = async () => {
    setIsEditClicked(true);
    await dispatch(setIsEditable(true));
  };

  return (
    <>
      <Box>
        <Box className="flex-basic-space-between">
          <Box sx={{ pt: theme.spacing(9), pl: 5 }} className="font-weight-semibold text-h2">
            {t('entityDetails')}
          </Box>
          {selectedEntity?.trellis_uid && !isEditClicked && (
            <Box sx={{ pt: theme.spacing(9), pr: 5 }}>
              <TButton
                btnText="Edit"
                variant="text"
                icon={<Box sx={{ pr: theme.spacing(1) }} className="icon-edit" />}
                onClick={handleEditClick}
              />
            </Box>
          )}
        </Box>
        <Box>
          {selectedEntity?.trellis_uid && !isEditClicked ? (
            <AddedEntityDetails />
          ) : (
            <EntityOnboardingForm setIsEditClicked={setIsEditClicked} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default EntityDetailsIndex;
