import React from 'react';

import { ClickHandlerType } from '../../types/commonTypes';
import { TagModals } from '../common/tagNotes/TagModals';
import { ResultTagType } from '../../types/applicantTagTypes';

interface UserTagModalProps {
  openDialog: boolean;
  onClose: ClickHandlerType;
  tagsList: Array<ResultTagType>;
}

const UserTagModal = ({ openDialog, onClose, tagsList }: UserTagModalProps) => {
  // Methods
  const onCancelNewMsg = () => {
    onClose();
  };

  return (
    <>
      {openDialog && (
        <TagModals
          tagsList={tagsList}
          title="Tags"
          showDialog={openDialog}
          onCancelModal={onCancelNewMsg}
          noBtnText="Cancel"
          yesBtnText="Save"
        />
      )}
    </>
  );
};

export default UserTagModal;
