import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Theme } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TButton, TCard, theme, TTextField } from '../../components/myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import { ResetPasswordFormSchema, ResetPasswordFormSchemaPayload } from './validation';
import * as ROUTES from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLoginDetails,
  resetPassword,
  selectUser,
  validateResetPasswordToken,
} from '../../redux/feature/user/userSlice';
import { useTheme } from '../../providers/custom-theme-provider';
import { setResetPasswordToken } from '../../utils/storage';
import ResetPasswordTokenExpired from './ResetPasswordTokenExpired';
import { toast } from 'react-toastify';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  resetPasswordContainer: {
    width: '26rem',
    height: 'auto',
    background: 'transparent',
  },
});

const ResetPassword = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes: currentThemes,
  };
  const { t } = useTranslation(['common', 'forgotPassword']);
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { routeTo } = useRouter();
  const { handleSubmit, control, formState, setError, getValues } = useForm<ResetPasswordFormSchemaPayload>({
    resolver: zodResolver(ResetPasswordFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;
  const { passwordResetSuccessfully, resetTokenAlreadyUsed, resetTokenExpired, validResetEmail } =
    useSelector(selectUser);

  useEffect(() => {
    if (token) {
      dispatch(validateResetPasswordToken(token));
    } else {
      toast.error('The token does not exist');
    }
  }, []);

  useEffect(() => {
    setResetPasswordToken(token || '');
  }, [token]);

  useEffect(() => {
    if (passwordResetSuccessfully) {
      const resetPasswordData = getValues();
      const loginPyload = {
        email: validResetEmail,
        password: resetPasswordData?.password,
      };
      dispatch(getLoginDetails(loginPyload));
      routeTo(ROUTES.LOGIN);
    }
  }, [passwordResetSuccessfully]);

  //methods
  const onSubmit = () => {
    try {
      const resetPasswordData = getValues();
      const requestPayload = {
        password: resetPasswordData?.password,
      };
      dispatch(resetPassword(requestPayload));
    } catch (err: any) {
      setError('password', { type: 'manual', message: err.detail });
    }
  };

  const navigateTo = (path: string) => {
    routeTo(path);
  };

  const getResetPasswordForm = () => {
    return (
      <>
        <Box className="textColor-200 text-h2 font-weight-semibold text-center">
          {t('resetPasswordTitle', { ns: 'forgotPassword' })}
        </Box>
        <Box sx={{ mt: theme.spacing(5) }} className="textColor-200 text-medium text-center">
          {t('resetPasswordSubTitle', { ns: 'forgotPassword' })}
        </Box>
        <form className="w-100">
          <Box sx={{ mt: 5 }}>
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label="New Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={{ mt: theme.spacing(5) }}>
            <Controller
              name="confirm_password"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.confirm_password)}
                  helperText={errors.confirm_password?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Grid sx={{ mt: 4 }} container spacing={0} className={classes.resetPasswordContainer}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TButton variant="text" fullWidth btnText={'Back'} onClick={() => navigateTo(ROUTES.LOGIN)}></TButton>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TButton
                fullWidth
                variant={'contained'}
                type="submit"
                btnWidthSize="button-w-240"
                disabled={!isValid || isSubmitting}
                btnText={'Submit'}
                onClick={handleSubmit(onSubmit)}
              ></TButton>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  return (
    <Box className="flex-basic-center">
      <TCard sx={{ m: 5 }} className="flex-basic-center height-vh-100 forgotPasswordOuterContainer">
        <Box className={classes.resetPasswordContainer}>
          <Box className="flex-column-center height-vh-100">
            {resetTokenExpired || resetTokenAlreadyUsed ? <ResetPasswordTokenExpired /> : getResetPasswordForm()}
          </Box>
        </Box>
      </TCard>
    </Box>
  );
};

export default ResetPassword;
