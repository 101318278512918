import React from 'react';

import { ENTITY_PROCESS_IDS } from '../myde-react-components';
import IrsLetterEvidencing from './IrsLetterEvidencing';
import LetterOfGoodStanding from './LetterOfGoodStanding';
import OwnershipDocument from './OwnershipDocument';
import FormationDocument from './FormationDocument';

interface CommonDocumentIndexProps {
  entityProcessId: number;
}

const CommonDocumentIndex = ({ entityProcessId }: CommonDocumentIndexProps) => {
  //Methods
  const renderIdProcessComponent = (processId: number) => {
    switch (processId) {
      case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
        return <IrsLetterEvidencing />;

      case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
        return <LetterOfGoodStanding />;

      case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT:
        return <OwnershipDocument />;

      case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
        return <FormationDocument />;

      default:
        return <></>;
    }
  };

  return <>{renderIdProcessComponent(entityProcessId)}</>;
};

export default CommonDocumentIndex;
