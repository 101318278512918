import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormGroup, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { IndividualIdRequirementsType, ProfileAddressRequirementsType } from '../../types/inviteTypes';
import { colorPalette, PROCESS_IDS, TButton, TCheckbox, TSwitch } from '../myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';
import AddNoteModal from './AddNoteModal';
import IdProcessContent from './IdProcessContent';
import { DEFAULT_PROFILE_ADDRESS_TYPE, ID_PROCESS_WITH_SAFE_DOC } from '../../constants/constants';

interface IndividualIdRequirementsProps {
  individualIdRequirements: IndividualIdRequirementsType[];
  updateRequirements?: (data: IndividualIdRequirementsType[]) => void;
}

interface StyleProps {
  currentThemes: Theme;
}

// styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  btnColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  disabledState: {
    color: colorPalette.typoText.disabled,
  },
}));

const IndividualIdRequirements = ({ individualIdRequirements, updateRequirements }: IndividualIdRequirementsProps) => {
  // Constants
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // State Variables
  const [requirements, setRequirements] = useState([] as IndividualIdRequirementsType[]);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [particularRequirement, setParticularRequirement] = useState({} as IndividualIdRequirementsType);

  // Use Effects
  useEffect(() => {
    setRequirements(individualIdRequirements);
  }, [individualIdRequirements]);

  // Methods
  const onIdProcessChange = (item: IndividualIdRequirementsType) => {
    const data = requirements?.map((element: IndividualIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_checked: !item?.is_checked,
          access_level: ID_PROCESS_WITH_SAFE_DOC.includes(item?.process_id) && !item?.is_checked ? true : false,
          has_children: item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION ? true : false,
          profile_address_requirement_list:
            item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION ? DEFAULT_PROFILE_ADDRESS_TYPE : [],
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(data);
    updateRequirements && updateRequirements(data);
  };

  const onSafeDocChange = (item: IndividualIdRequirementsType) => {
    const data = requirements?.map((element: IndividualIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          access_level: !item?.access_level,
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(data);
    updateRequirements && updateRequirements(data);
  };

  const onIsOptionalChange = (item: IndividualIdRequirementsType) => {
    const data = requirements?.map((element: IndividualIdRequirementsType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_mandatory: !item?.is_mandatory,
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(data);
    updateRequirements && updateRequirements(data);
  };

  const handleClickChange = (item: IndividualIdRequirementsType) => {
    setParticularRequirement(item);
    setShowNoteModal(true);
  };

  const updateRequirementsData = (data: IndividualIdRequirementsType) => {
    const result = requirements?.map((element: IndividualIdRequirementsType) => {
      if (element?.process_id === data?.process_id) {
        return {
          ...element,
          note: data?.note,
        };
      }
      return {
        ...element,
      };
    });
    setRequirements(result);
    updateRequirements && updateRequirements(result);
  };

  const updateProfileRequirements = (data: ProfileAddressRequirementsType[]) => {
    const result: Array<IndividualIdRequirementsType> = requirements?.map((item: IndividualIdRequirementsType) => {
      if (PROCESS_IDS.PROFILE_VERIFICATION === item?.process_id) {
        return {
          ...item,
          profile_address_requirement_list: data,
        };
      }
      return {
        ...item,
      };
    });
    setRequirements(result);
    updateRequirements && updateRequirements(result);
  };

  // HTML
  return (
    <>
      {requirements?.length > 0 ? (
        <FormGroup>
          {requirements?.map((item: IndividualIdRequirementsType) => (
            <>
              <Grid container spacing={2} key={item?.process_id}>
                <Grid item sm={3} md={3} lg={3}>
                  <FormControlLabel
                    control={
                      <TCheckbox
                        value={item?.process_id}
                        checked={item?.is_checked}
                        onChange={() => onIdProcessChange(item)}
                      />
                    }
                    label={<Box>{item?.process_name}</Box>}
                  />
                </Grid>
                <Grid item sm={3} md={3} lg={3}>
                  {item?.has_access_level && (
                    <Box className="flex-basic-center">
                      <TSwitch
                        themed
                        value={item?.access_level}
                        checked={item?.access_level && item?.is_checked}
                        disabled={!item?.is_checked}
                        onChange={() => onSafeDocChange(item)}
                      />
                      <Box
                        className={clsx({
                          ['text-medium font-weight-semibold']: true,
                          [classes.btnColor]: item?.access_level && item?.is_checked,
                          ['textColor-400']: !item?.access_level,
                          [classes.disabledState]: !item?.is_checked,
                        })}
                        sx={{ ml: 1 }}
                      >
                        {t('safeDocTitle')}
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid item sm={5} md={5} lg={5}>
                  {item?.is_checked && (
                    <FormControlLabel
                      control={
                        <TCheckbox
                          value={item?.is_mandatory}
                          checked={!item?.is_mandatory}
                          onChange={() => onIsOptionalChange(item)}
                        />
                      }
                      label={<Box>Optional</Box>}
                    />
                  )}
                </Grid>
                <Grid item sm={1} md={1} lg={1}>
                  {item?.is_checked && (
                    <TButton
                      sx={{ mr: 1, mb: 1 }}
                      variant="text"
                      btnText={'Note'}
                      icon={<Box sx={{ mr: 1 }} className={item?.note ? 'icon-edit' : 'icon-add'} />}
                      size="small"
                      onClick={() => handleClickChange(item)}
                    />
                  )}
                </Grid>
              </Grid>
              {item?.has_children && item?.is_checked && item?.profile_address_requirement_list?.length > 0 && (
                <Box sx={{ mx: 5, my: 1 }}>
                  <IdProcessContent
                    individualProfileRequirements={item?.profile_address_requirement_list}
                    updateProfileRequirements={updateProfileRequirements}
                  />
                </Box>
              )}
            </>
          ))}
        </FormGroup>
      ) : (
        <Box className="flex-basic-center text-medium font-weight-semibold">{t('noIdRequirements')}</Box>
      )}
      <AddNoteModal
        showDialog={showNoteModal}
        requirement={particularRequirement}
        onAdd={updateRequirementsData}
        onCancel={() => setShowNoteModal(false)}
      />
    </>
  );
};

export default IndividualIdRequirements;
