import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getApplicantCustomId } from '../../../api/applicants';
import { CustomIdProcessResultType } from '../../../types/applicantTypes';

export const getCustomIdProcessDetails = createAsyncThunk('customIdProcess/getCustomIdProcessDetails',
async(data: {applicationId: string, accountId_processId: string})=>{
    return await getApplicantCustomId(data)
})

const initialState = {
    loading: false,
    error: false,
    customIdProcessData : <CustomIdProcessResultType>{},
    isCustomIdProcess: false,
}

export const customIdProcess = ({customIdProcess}: RootState) => ({
    loading: customIdProcess.loading,
    error: customIdProcess.error,
    customIdProcessData : customIdProcess.customIdProcessData,
    isCustomIdProcess: customIdProcess.isCustomIdProcess
})


export const customIdProcessSlice = createSlice({
    name:'customIdProcess',
    initialState, 
    reducers:{
        setIsCustomIdProcess: (state,action) => {
            state.isCustomIdProcess = action.payload
        }
    },
    extraReducers:(builder) =>{
        builder
        .addCase(getCustomIdProcessDetails.pending,(state)=>{
           state.loading = true;
           state.error = false;
        })
        .addCase(getCustomIdProcessDetails.fulfilled, (state, action)=>{
            state.loading = false;
            state.error = false;
        if(action.payload){
            state.customIdProcessData = action.payload
        } else {
            state.error=true
        }
        })
        .addCase(getCustomIdProcessDetails.rejected, (state)=>{
            state.loading = false;
            state.error = true;
        })
    }
})

const {  reducer, actions } = customIdProcessSlice
export const {setIsCustomIdProcess} = actions
export default reducer