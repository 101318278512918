import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { ProfileIdProcessType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  profileLoading: false,
  error: false,
  errorObj: <any>{},
  profileIdProcessDetail: <ProfileIdProcessType>{},
};

export const selectProfile = ({ profile }: RootState) => ({
  profileIdProcessDetail: profile.profileIdProcessDetail,
  profileLoading: profile.profileLoading,
  error: profile.error,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'profile/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const profileIdProcessSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.profileLoading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.profileLoading = false;
        state.error = false;
        if (action.payload) {
          state.profileIdProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.profileLoading = false;
        state.error = true;
      });
  },
});

export default profileIdProcessSlice.reducer;
