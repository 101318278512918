import React, { useEffect, useState } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { PredefinedProcessType } from '../../types/applicantTypes';
import TabHeader from '../../components/common/tab-header/TabHeader';
import { colorPalette, TButton, TDialog, TLoader } from '../../components/myde-react-components';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { getSpecificIdProcessDetails, setResults } from '../../redux/feature/id-process/idDocProcessSlice';
import { fontStyle } from '../../styles/style';
import { selectIdDoc } from '../../redux/feature/id-process/idDocProcessSlice';
import { DOC_TYPE } from '../../constants/constants';
import UserNoteSummary from '../../components/userNotes/UserNoteSummary';
import { removeUnderscore, titleCase } from '../../utils/utils';
import { IdDocResultType } from '../../types/idProcessTypes';
import { useTheme } from '../../providers/custom-theme-provider';
import { useTranslation } from 'react-i18next';
import { getAction } from '../../utils/idProcess';
import ViewDocModal from './components/ViewDocModal';
import clsx from 'clsx';
import { PORTAL } from '../myde-react-components/src/constants/portal';
interface IdentityDocTabProps {
  processDetails: PredefinedProcessType;
}

export interface StyleProps {
  primaryColor: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  label: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken2,
  },
  score: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  words: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
  },
  titleText: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken1,
  },
  frame: {
    margin: '30px 0px',
  },
  icon: {
    fontSize: fontStyle.fontSize.font24.fontSize,
  },
  title: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken1,
  },
  description: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.darken1,
  },
  startIcon: {
    color: colorPalette.typoText.darken2,
    fontSize: fontStyle.fontSize.font28.fontSize,
  },
  confirmDialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '560px!important',
        boxShadow: 'none !important',
      },
    },
  },
  docDialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: '1100px!important',
        maxWidth: '1100px!important',
      },
    },
  },
}));
const IdentityDocTab = ({ processDetails }: IdentityDocTabProps) => {
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const { t } = useTranslation('common');
  const primaryColor = currentThemes.palette.primary.main;
  const { applicantionDetail, applicationId, loading } = useSelector(selectApplicant);
  const { idDocProcessDetail, results, idDocLoading } = useSelector(selectIdDoc);

  const [open, setOpen] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [resultSet, setResultSet] = useState({} as IdDocResultType);
  const styledProps = {
    primaryColor,
  };
  const classes = useStyles(styledProps);
  const handleChange = () => {
    setOpen(false);
    setOpenResult(false);
  };

  const handleOpenResult = (item: IdDocResultType) => {
    dispatch(setResults(item));
    setOpenResult(true);
  };

  const [status, setStatus] = useState('');

  // Use Effects
  useEffect(() => {
    if (results && Object.keys(results)?.length > 0) {
      setResultSet(results);
    }
  }, [results]);

  useEffect(() => {
    try {
      if (processDetails?.process_id) {
        const data = {
          applicationId: applicationId,
          processId: processDetails?.process_id,
        };
        dispatch(getSpecificIdProcessDetails(data));
      }
    } catch (error: any) {}
  }, [processDetails, open]);

  useEffect(() => {
    if (idDocProcessDetail?.status_label) {
      setStatus(idDocProcessDetail?.status_label);
    }
  }, [idDocProcessDetail]);

  const viewDetails = () => {
    setOpen(true);
  };

  return (
    <>
      {idDocLoading ? (
        <TLoader loading={idDocLoading} />
      ) : (
        <>
          {Object.keys(idDocProcessDetail)?.length > 0 && (
            <>
              <Box>
                <TabHeader
                  title={processDetails?.title}
                  status={status}
                  applicationId={applicationId}
                  processId={processDetails?.process_id}
                  accountReviewStatus={applicantionDetail?.status}
                  section={processDetails?.title}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                {idDocProcessDetail?.result?.length > 0 && (
                  <List>
                    {idDocProcessDetail?.result?.map((item: IdDocResultType) => (
                      <ListItem
                        key={item.id}
                        sx={{ px: 0 }}
                        secondaryAction={
                          <Box
                            className={classes.icon}
                            onClick={() => {
                              handleOpenResult(item);
                            }}
                          >
                            {getAction(item.result)}
                          </Box>
                        }
                      >
                        <ListItemText className="text-large textColor-200 listItemID" primary={item.name} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
              <Box sx={{ py: 3 }}>
                <TButton btnText="View Documents" variant="contained" onClick={viewDetails} />
              </Box>

              {Object.keys(resultSet)?.length > 0 && (
                <TDialog
                  open={openResult}
                  onClose={handleChange}
                  className={clsx(classes.confirmDialog, 'idModals')}
                  hasStartIcon={true}
                  portalName={PORTAL.MERCHANT}
                  startIcon={
                    resultSet?.result === 'consider' ? (
                      <span className="icon-alignment icon-error-warning error-color-state actionIconFont-24"></span>
                    ) : (
                      <span className="icon-alignment icon-approved success-color-state actionIconFont-24" />
                    )
                  }
                  title={`'${titleCase(resultSet?.name)}' Details`}
                >
                  {resultSet?.details?.length > 0 ? (
                    <Box>
                      {resultSet?.details?.map((item: any, index: number) => (
                        <>
                          {item?.name && item?.description && (
                            <Box sx={{ py: 2 }} key={index}>
                              <Box className="text-medium font-weight-semibold textColor-200">
                                {removeUnderscore(item?.name)}
                              </Box>
                              <Box className="text-small textColor-300">{item?.description || '-'}</Box>
                            </Box>
                          )}
                        </>
                      ))}
                    </Box>
                  ) : (
                    <Box sx={{ py: 10 }} className="text-center text-h2 font-weight-semibold textColor-300">
                      {t('noDetailsAvailable') + '\t' + titleCase(resultSet?.name)}
                    </Box>
                  )}
                </TDialog>
              )}
            </>
          )}
          <TDialog
            open={open}
            onClose={handleChange}
            className={classes.docDialogStyle}
            title={processDetails?.title}
            transparentTitle
            portalName={PORTAL.MERCHANT}
          >
            <ViewDocModal
              processDetails={processDetails}
              safeImages={idDocProcessDetail?.data?.safe_document}
              sourceImages={idDocProcessDetail?.data?.source_document}
              loading={loading}
              status={status}
              type={DOC_TYPE.DRIVERS_LICENSE}
            />
          </TDialog>
          <Grid sx={{ mt: 3 }} lg={12} sm={12} md={12} xs={12}>
            <UserNoteSummary />
          </Grid>
        </>
      )}
    </>
  );
};

export default IdentityDocTab;
