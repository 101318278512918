import * as common from './en.json';
import * as forgotPassword from './forgot-password.json';
import * as profile from './profile.json';
import * as entity from './entity.json';

const allEn = {
  common,
  forgotPassword,
  profile,
  entity,
};

export default allEn;
