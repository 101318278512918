import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PdfThumbnail from '../../components/myde-react-components/src/common/FileType/Pdf_Thumbnail.svg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import clsx from 'clsx';

import { colorPalette, FilePreviewType, TDocumentViewModal, theme } from '../myde-react-components';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface PreviewFileByTypeProps {
  files: FilePreviewType[];
  portalName?: string;
}

const useStyles = makeStyles({
  previewContainer: {
    width: '5.625rem',
    height: '4.375rem',
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: '4px !important',
  },
  imgPreview: {
    width: '50%',
    height: '100%',
  },
  truncateText: {
    width: '5.625rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const PreviewFileByType = ({ files, portalName = PORTAL.MYDE }: PreviewFileByTypeProps) => {
  // Constants
  const classes = useStyles();

  // State Variables
  const [filePreview, setFilePreview] = useState([] as FilePreviewType[]);
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState({} as FilePreviewType);

  // Use Effects
  useEffect(() => {
    if (files?.length > 0) {
      setFilePreview(files);
    } else {
      setFilePreview([] as FilePreviewType[]);
    }
  }, [files]);

  // Methods
  const showDocument = (item: FilePreviewType) => {
    setPreview(item);
    setShowModal(true);
  };

  return (
    <Box sx={{ mt: 2 }} className="flex-basic-start category-scroll">
      {filePreview?.map((item: FilePreviewType, index: number) => (
        <Box className="flex-basic-center" key={index}>
          <Box>
            {item?.type?.includes('video') ? (
              <Box sx={{ mr: theme.spacing(5) }} className="cursorPointer" onClick={() => showDocument(item)}>
                <Box className={clsx(classes.previewContainer, 'flex-basic-center')} sx={{ mt: 1 }}>
                  <PlayCircleOutlineIcon className="textColor-200" />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Box className={clsx('text-extra-small textColor-200 text-center', classes.truncateText)}>
                    {item?.name}
                  </Box>
                </Box>
              </Box>
            ) : item?.type?.includes('pdf') ? (
              <Box sx={{ ml: 1 }} className="cursorPointer" onClick={() => showDocument(item)}>
                <Box className="flex-basic-center" sx={{ mt: 1 }}>
                  <img src={PdfThumbnail} className="textColor-200" />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Box className={clsx('text-extra-small textColor-200 text-center', classes.truncateText)}>
                    {item?.name}
                  </Box>
                  <Box className="text-extra-small textColor-300">{item?.file?.lastModified}</Box>
                </Box>
              </Box>
            ) : item?.type?.includes('image') ? (
              <Box sx={{ mr: theme.spacing(5) }} className="cursorPointer" onClick={() => showDocument(item)}>
                <Box className={clsx(classes.previewContainer, 'flex-basic-center')} sx={{ mt: 1 }}>
                  <img src={item?.preview} className={classes.imgPreview} alt="Uploaded Image" />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Box className={clsx('text-extra-small textColor-200 text-center', classes.truncateText)}>
                    {item?.name}
                  </Box>
                  <Box className="text-extra-small textColor-300">{item?.file?.lastModified}</Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      ))}
      <TDocumentViewModal
        portalName={portalName}
        open={showModal}
        file={preview}
        closeModal={() => setShowModal(false)}
      />
    </Box>
  );
};

export default PreviewFileByType;
