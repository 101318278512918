import { makeStyles } from '@material-ui/styles';
import { Box, Icon, Theme } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { TButton, TCard, theme } from '../../components/myde-react-components';
import successImgBg from '../../components/myde-react-components/src/common/assets/success-placeholder.svg';
import { useRouter } from '../../providers/custom-router-provider';
import { useTheme } from '../../providers/custom-theme-provider';
import * as ROUTES from '../../constants/routes';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  resetPasswordSuccessContainer: {
    width: '26rem',
    height: 'auto',
    background: 'transparent',
  },
  iconContainer: {
    backgroundColor: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
});

const ResetPasswordSuccessfull = () => {
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation(['common', 'forgotPassword']);
  const { routeTo } = useRouter();

  const navigateTo = (path: string) => {
    routeTo(path);
  };

  return (
    <Box className="flex-basic-center">
      <TCard sx={{ m: 5 }} className="flex-basic-center height-vh-100 forgotPasswordOuterContainer">
        <Box className={classes.resetPasswordSuccessContainer}>
          <Box className="flex-column-center height-vh-100">
            <Box className="flex-column-center text-center">
              <Box className="flex-basic-center" sx={{ my: 4 }}>
                <Box className="p-relative">
                  <img src={successImgBg} alt="Success" />
                  <div className={clsx('circularIcon flex-basic-center', classes.iconContainer)}>
                    <Icon className="text-white">check_icon</Icon>
                  </div>
                </Box>
              </Box>
              <Box sx={{ pb: theme.spacing(5) }} className="text-center textColor-200 text-h2 font-weight-semibold">
                <Box>{t('congratulations', { ns: 'forgotPassword' })}</Box>
                <Box>{t('passwordVerified', { ns: 'forgotPassword' })}</Box>
              </Box>
              <Box className="text-center textColor-200 text-medium">
                {t('passwordVerifiedRecommendation', { ns: 'forgotPassword' })}
              </Box>
              <Box sx={{ mt: 5, mb: 3 }}>
                <TButton
                  btnWidthSize="button-w-240"
                  onClick={() => navigateTo(ROUTES.LOGIN)}
                  variant="contained"
                  btnText={'Sign In'}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </TCard>
    </Box>
  );
};

export default ResetPasswordSuccessfull;
