import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Container, Grid, Icon, Theme } from '@mui/material';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import {
  getMsgDetailById,
  selectApplicationMessage,
  setMsgUpdatedFlag,
  setNewMsgFlag,
  setSelectedMessageId,
  updateMerchantMsg,
  updateMsg,
} from '../../redux/feature/applicant/applicantMessageSlice';
import {
  TCard,
  TButton,
  colorPalette,
  TConfirmDialog,
  TTextArea,
  TLoader,
  MAX_CHAR_LIMIT,
} from '../myde-react-components';
import { makeStyles } from '@mui/styles';
import { useRouter } from '../../providers/custom-router-provider';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import MessageNoteDetailCard, { MessageNoteDetailCardProps } from '../common/messageNotes/MessageNoteDetailCards';
import { MESSAGE_RESPONDER_TYPES, MESSAGE_STATUS_TYPES, NOTE_TYPE } from '../../constants/constants';
import { formatDate, getTimeStamp } from '../../utils/utils';
import { MessageResponseType } from '../../types/applicantTypes';
import { useTheme } from '../../providers/custom-theme-provider';
import clsx from 'clsx';
import { PORTAL } from '../myde-react-components/src/constants/portal';
interface StyleProps {
  currentThemes: Theme;
}
// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  msgThreads: {
    height: 'calc(100vh - 373px)',
    paddingBottom: 16,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 5px ${colorPalette.containerBack.lighten3} !important`,
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: `${colorPalette.containerBack.dark} !important`,
      borderRadius: '10px',
    },
  },
  setPrimaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  replyBox: {
    position: 'sticky',
    top: '90%',
    marginTop: '25px',
    marginBottom: '25px',
  },
  replyTextArea: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      padding: '10px',
    },
  },
  btnWrapper: {
    textAlign: 'right',
  },
  noteCount: {
    display: 'flex',
    justifyContent: 'end',
    position: 'sticky',
    top: '95%',
  },
  horizontalLine: {
    borderTop: ({ currentThemes }) => `2px solid ${currentThemes.palette.primary.main}`,
  },
  btnStyles: {
    '& .MuiButton-root': {
      '& .MuiButton-startIcon': {
        paddingRight: '13px !important',
      },
    },
  },
});

// Main Component
const UserMessageDetail = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const { goBack } = useRouter();
  const { applicationId } = useSelector(selectApplicant);
  const { selectedMessageDetail, msgUpdated, newMsgAdded, loading } = useSelector(selectApplicationMessage);

  // Constants
  const messageId = searchParams.get('messageId');
  const messageQues = {} as MessageNoteDetailCardProps;
  const maxLength = MAX_CHAR_LIMIT.CHAR_LENGTH;

  // State Values
  const [selectedMessageId] = useState(messageId || '');
  const [messageList, setMessageList] = useState([messageQues]);
  const [showDialog, setDialogFlag] = React.useState(false);
  const [newMerchantMsg, setNewMsg] = useState('');
  const [unreadMsgIdx, setUnreadMsgIndex] = useState('');
  const [count, setCount] = useState(0);

  // Use Effects
  useEffect(() => {
    if (selectedMessageId) {
      dispatch(setSelectedMessageId(selectedMessageId));
      getMessageDetail();
    }
  }, []);
  useEffect(() => {
    if (selectedMessageDetail) {
      const ques = {} as MessageNoteDetailCardProps;
      ques.message = selectedMessageDetail?.request;
      ques.messageType = NOTE_TYPE.QUE;
      ques.messageDate = formatDate(selectedMessageDetail?.created_on) || 'NA';
      ques.messageTime = getTimeStamp(selectedMessageDetail?.modified_on) || 'NA';
      ques.responder = `${selectedMessageDetail?.account?.first_name}  ${selectedMessageDetail?.account?.last_name}`;
      const threads = getMessageThreads(selectedMessageDetail?.response);
      const updatedMsgList = [ques, ...threads];
      setMessageList(updatedMsgList);
    }
  }, [selectedMessageDetail]);
  useEffect(() => {
    if (msgUpdated || newMsgAdded) {
      getMessageDetail();
      dispatch(setMsgUpdatedFlag(false));
      dispatch(setNewMsgFlag(false));
    }
  }, [msgUpdated, newMsgAdded]);

  // Methods
  const getMessageDetail = () => {
    const data = { applicationId: applicationId, messageId: selectedMessageId };
    dispatch(getMsgDetailById(data));
  };

  const getMessageThreads = (responseList: MessageResponseType[]) => {
    const threadList: MessageNoteDetailCardProps[] = [];
    let unreadIdx = '';
    if (responseList?.length > 0) {
      for (const responseIdx in responseList) {
        const response = responseList[responseIdx];
        const thread = {} as MessageNoteDetailCardProps;
        thread.message = response.text;
        thread.messageType = response.responder === MESSAGE_RESPONDER_TYPES.MERCHANT ? NOTE_TYPE.QUE : NOTE_TYPE.ANS;
        thread.messageDate = formatDate(response.timestamp) || 'NA';
        thread.messageTime = getTimeStamp(response.timestamp) || 'NA';
        thread.responder = response.name;
        threadList.push(thread);
        if (!unreadIdx && !response.is_read && response.responder !== MESSAGE_RESPONDER_TYPES.MERCHANT) {
          unreadIdx = (parseInt(responseIdx) + 1).toString();
        }
      }
      setUnreadMsgIndex(unreadIdx);
    }
    return threadList;
  };
  const handleClose = () => {
    setDialogFlag(false);
  };
  const updateMessage = (actionType?: string) => {
    const status = actionType
      ? actionType
      : selectedMessageDetail.is_archived
      ? MESSAGE_STATUS_TYPES.UNARCHIVE
      : MESSAGE_STATUS_TYPES.ARCHIVE;
    const reqData = { applicationId: applicationId, msgId: selectedMessageDetail.id, status: status };
    dispatch(updateMsg(reqData));
    handleClose();
  };
  const handleReplyTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || '';
    setCount(value.length);
    if (value && value.length > maxLength) {
      return;
    }
    setNewMsg(value);
  };
  const reply = () => {
    const data = {
      text: newMerchantMsg,
      appId: applicationId,
      msgId: selectedMessageId,
    };
    dispatch(updateMerchantMsg(data));
    setNewMsg('');
    setCount(0);
  };

  // HTML
  return (
    <Container sx={{ py: 3 }}>
      <TCard sx={{ p: 3 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <TButton
              sx={{ mb: 1, mr: 1 }}
              variant="text"
              icon={<Icon className="textColor-200">arrow_back_ios_new_icon</Icon>}
              size="small"
              onClick={() => goBack()}
            />
            <span className="text-h2 font-weight-semibold textColor-200">Messages</span>
          </Grid>
          <Grid item className={classes.btnStyles}>
            {!selectedMessageDetail?.is_resolved && (
              <TButton
                variant="text"
                btnText="Mark as Answered"
                sx={{ ml: 1 }}
                color="primary"
                icon={<Icon className={classes.setPrimaryColor}>check_circle_outline_icon</Icon>}
                onClick={() => {
                  updateMessage(MESSAGE_STATUS_TYPES.RESOLVE);
                }}
              />
            )}
            <TButton
              variant="text"
              btnText={selectedMessageDetail.is_archived ? 'Un-archive' : 'Archive'}
              sx={{ ml: 1 }}
              color="primary"
              icon={
                selectedMessageDetail.is_archived ? (
                  <UnarchiveOutlinedIcon className={classes.setPrimaryColor} />
                ) : (
                  <ArchiveOutlinedIcon className={classes.setPrimaryColor} />
                )
              }
              onClick={() => {
                setDialogFlag(true);
              }}
            />
          </Grid>
        </Grid>
        <TLoader loading={loading} />
        <Box>
          <Box sx={{ pr: 3 }} className={classes.msgThreads}>
            {messageList.map((message, index) => (
              <Grid
                container
                sx={{ mt: 2 }}
                key={index}
                direction="row"
                justifyContent={message.messageType === NOTE_TYPE.ANS ? 'flex-start' : 'flex-end'}
                alignItems="center"
              >
                {unreadMsgIdx && unreadMsgIdx === index.toString() && (
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 4, mt: 2 }}>
                    <Box
                      className={clsx(
                        classes.setPrimaryColor,
                        classes.horizontalLine,
                        'text-center text-medium font-weight-semibold',
                      )}
                    >
                      Unread
                    </Box>
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={5} lg={5}>
                  <Box>
                    <MessageNoteDetailCard
                      isOnMessageScreen={false}
                      message={message?.message}
                      messageDate={message?.messageDate}
                      messageTime={message?.messageTime}
                      messageType={message?.messageType}
                      responder={message?.responder}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.replyBox}
            >
              <Grid className="p-relative textReplyArea" item xs={12} sm={12} md={12} lg={12}>
                <TTextArea
                  fullWidth={true}
                  value={newMerchantMsg}
                  onChange={handleReplyTextChange}
                  minRows={1}
                  className={classes.replyTextArea}
                  inputProps={{ maxLength: maxLength }}
                />
                <Box className="replyButton">
                  <TButton
                    variant="text"
                    icon={<Box sx={{ pr: 1 }} className="icon-Frame-81 icon-size-24"></Box>}
                    size="small"
                    btnText="Reply"
                    onClick={reply}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box
              className={clsx(classes.noteCount, 'text-small font-weight-semibold textColor-300')}
              sx={{ pt: 1 }}
            >{`${maxLength - count} character(s) remaining`}</Box>
          </Box>
        </Box>
      </TCard>
      <TConfirmDialog
        portalName={PORTAL.MERCHANT}
        showDialog={showDialog}
        classNameConfirmDailog="confirmDialogBoxOpacityNote"
        onCancelClick={handleClose}
        onConfirmClick={() => {
          updateMessage();
        }}
        confirmationMessage={
          selectedMessageDetail?.is_archived
            ? `Are you sure you want to un-archive the message ${selectedMessageDetail.request}?`
            : `Are you sure you want to archive the message?`
        }
        title={selectedMessageDetail?.is_archived ? 'Confirm Un-archive Message' : 'Confirm Archive Message'}
      />
    </Container>
  );
};

export default UserMessageDetail;
