import React, { useRef, useState } from 'react';
import { ExitToApp } from '@mui/icons-material';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { useAuth } from '../auth/auth-guard';
import { TAppHeader } from '../components/myde-react-components';
import NotificationsLanding from '../pages/NotificationsLanding/NotificationsLanding';
import { selectNotification } from '../redux/feature/notification/notificationSlice';
import { useTheme } from '../providers/custom-theme-provider';
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  notificationIndicatorBgColor: {
    '&:after': {
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.error.main}!important`,
    },
  },
});

const HeaderLayout = () => {
  // Constants
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);
  const { logout } = useAuth();
  const iconButtonRef = useRef(null);

  // Redux values
  const { isNotificationRead } = useSelector(selectNotification);

  // State Variables
  const [notificationClick, setNotificationClick] = useState(false);

  // Methods
  const handleClick = (e: any) => {
    setNotificationClick(true);
  };

  // HTML
  return (
    <>
      <TAppHeader backgroundColor="#FFFF">
        <Box
          sx={{ pr: 2 }}
          onClick={handleClick}
          ref={iconButtonRef}
          className={clsx({ [classes.notificationIndicatorBgColor]: isNotificationRead }, 'notificationIndicator')}
        >
          <Box className={clsx('cursorPointer icon-with-dot-notification textColor-300 icon-size-24')} />
        </Box>
        <Box onClick={logout}>
          <ExitToApp className={clsx('cursorPointer textColor-300 icon-size-24')} />
        </Box>
      </TAppHeader>
      <NotificationsLanding onClose={setNotificationClick} open={notificationClick} />
    </>
  );
};

export default HeaderLayout;
