import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TButton, TCard, TDialog, TSearchComponent } from '../../components/myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import { Box, Container, Grid, Icon, Theme } from '@mui/material';
import CustomTabs, { TabListType } from '../../components/common/tabs/Tabs';
import UserMessageList, { MsgApiFormData } from '../../components/userMessages/UserMessageList';
import UserMessageModal from '../../components/userMessages/UserMessageModal';
import { FilterFormSchemaPayload } from '../../components/common/messageNotes/validation';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilteredRecords,
  getMessageDetails,
  selectApplicationMessage,
  setFilterSet,
} from '../../redux/feature/applicant/applicantMessageSlice';
import { selectApplicant, setSelectedApplicationId } from '../../redux/feature/applicant/applicantSlice';
import RangeFilterForm from '../../components/common/messageNotes/RangeFilterForm';
import { getDate } from '../../utils/utils';
import { useTheme } from '../../providers/custom-theme-provider';
import useQuery from '../../hooks/useQuery';
import * as ROUTES from '../../constants/routes';

interface StyleProps {
  currentThemes: Theme;
}
// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  searchSection: {
    display: 'flex',
    justifyContent: 'right',
  },
  dialogStyle: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '460px!important',
      paddingBottom: '12px',
      borderRadius: 0,
      '& .MuiDialogContent-root': {
        overflowY: 'unset',
      },
    },
  },
});

// Main Component
const UserMessageListIndex = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { routeTo } = useRouter();
  const { applicationId } = useSelector(selectApplicant);
  const { filterSet, isArchived, isResolved } = useSelector(selectApplicationMessage);
  const dispatch = useDispatch();
  const query = useQuery();
  const selectedApplicationId = query.get('inviteId') || '';
  const selectedUserId = query.get('userId') || '';
  const selectedUserType = query.get('type') || '';

  // State Values
  const [showAddMsgDialog, setAddDialogFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tabs, setTabs] = useState<Array<TabListType>>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const tabList: TabListType[] = [
    {
      label: 'All',
      component: <UserMessageList isArchived={false} />,
    },
    {
      label: 'Pending',
      component: <UserMessageList isArchived={false} isResolved={false} />,
    },
    {
      label: 'Answered',
      component: <UserMessageList isArchived={false} isResolved={true} />,
    },
    {
      label: 'Archived',
      component: <UserMessageList isArchived={true} />,
    },
  ];

  //  useEffect
  useEffect(() => {
    setTabs(tabList);
    dispatch(setSelectedApplicationId(selectedApplicationId));
    dispatch(setFilterSet({}));
  }, []);

  // Functions
  const filterRecords = (searchValue: string) => {
    setSearchText(searchValue);
    const data = {
      applicationId: applicationId,
      formPayload: {
        request: searchValue,
        is_archived: isArchived,
      },
    };
    if (Object.keys(filterSet).length > 0) {
      Object.assign(data.formPayload, {
        modified_on_gte: filterSet.modified_on_gte,
        modified_on_lte: filterSet.modified_on_lte,
      });
    }
    dispatch(getFilteredRecords(data));
  };

  const handleChange = () => {
    setOpen(false);
  };

  const extraFilterHandler = () => {
    setOpen(true);
  };

  const onSubmit = async (formData: FilterFormSchemaPayload) => {
    const payload = {
      formPayload: {
        is_archived: isArchived,
        is_resolved: isResolved,
      },
    };
    if (searchText !== '') {
      Object.assign(payload.formPayload, {
        request: searchText,
      });
    }
    if (formData.dateFrom === getDate()) {
      Object.assign(payload.formPayload, {
        modified_on_gte: formData.dateFrom,
      });
    } else {
      Object.assign(payload.formPayload, {
        modified_on_gte: formData.dateFrom,
        modified_on_lte: formData.dateTill,
      });
    }

    dispatch(setFilterSet(payload.formPayload));

    const data = {
      applicationId: applicationId,
      ...payload,
    };
    dispatch(getFilteredRecords(data));
    setOpen(false);
  };

  const resetFilterSet = () => {
    let isResolved = false;
    if (selectedTab === 2) {
      isResolved = true;
    }
    const data: MsgApiFormData = {
      page: 1,
      limit: 10,
      applicationId: applicationId,
      is_archived: isArchived,
      is_resolved: isResolved,
    };
    dispatch(getMessageDetails(data));
    dispatch(setFilterSet({}));
  };

  const goBack = () => {
    const query = { inviteId: selectedApplicationId, userId: selectedUserId, type: selectedUserType };
    routeTo(ROUTES.USER, false, '', query);
  };

  // HTML
  return (
    <Container sx={{ py: 3 }}>
      <TCard sx={{ p: 3 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <TButton
              sx={{ mb: 1, mr: 1 }}
              variant="text"
              icon={<Icon className="textColor-200">arrow_back_ios_new_icon</Icon>}
              size="small"
              onClick={goBack}
            />
            <span className="text-h2 font-weight-semibold textColor-200">Messages</span>
          </Grid>
          <Grid item className={classes.searchSection}>
            <Box className="flex-basic-center align-items-end">
              <TSearchComponent
                placeholder="Search Question"
                onSearchUpdate={filterRecords}
                extraFilterHandler={extraFilterHandler}
                resetFilters={resetFilterSet}
                appliedFilterCount={Object.keys(filterSet).length > 0 ? 1 : null}
              />

              <TButton
                variant="contained"
                btnText="Add Message"
                sx={{ ml: 1 }}
                onClick={() => {
                  setAddDialogFlag(true);
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomTabs
              selectedTab={selectedTab}
              tabSelected={setSelectedTab}
              tabList={tabs}
              borderRequired={false}
              showBottomBorder={false}
            />
          </Grid>
        </Grid>
      </TCard>
      {showAddMsgDialog && (
        <UserMessageModal
          openDialog={showAddMsgDialog}
          onClose={() => {
            setAddDialogFlag(false);
          }}
        />
      )}

      <TDialog open={open} maxWidth={'xs'} className={classes.dialogStyle}>
        <RangeFilterForm onSubmit={onSubmit} handleClose={handleChange} />
      </TDialog>
    </Container>
  );
};

export default UserMessageListIndex;
