import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import clsx from 'clsx';

import { colorPalette, TButton, TTooltip } from '../../myde-react-components';
import { ID_PROCESS_REVIEW_STATUS } from '../../../constants/constants';
import {
  reviewIdProcess,
  selectApplicationActions,
  setReviewStatus,
  setStatusFlag,
} from '../../../redux/feature/id-process/idProcessActionsSlice';
import { getApplicantDetails, getApplicationIdProcesses } from '../../../redux/feature/applicant/applicantSlice';
import { ConfirmWithReasonDialog } from '../../common/ConfirmWithReason';
import { useTheme } from '../../../providers/custom-theme-provider';
import {
  getNoteSummary,
  selectApplicantNotes,
  setNoteUpdated,
} from '../../../redux/feature/applicant/applicantAccountNotesSlice';
import { customIdProcess, setIsCustomIdProcess } from '../../../redux/feature/id-process/customIdProcessSlice';
import { ReviewProcessType } from '../../../types/idProcessTypes';

// Interface
interface IdProcessActionProps {
  applicationId: string;
  processId: number;
  section: string;
}
export interface StyleProps {
  primaryColor: string;
}
// Styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  defaultActionBtn: {
    color: `${colorPalette.typoText.darken3} !important`,
  },
  rejectedActionBtn: {
    color: `${colorPalette.stateError} !important`,
  },
  approvedActionBtn: {
    color: `${colorPalette.approved} !important`,
  },
  disabledActionBtn: {
    color: `${colorPalette.stateDisabled} !important`,
  },
}));

// Main Component
const IdProcessActions = ({ applicationId, processId, section }: IdProcessActionProps) => {
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const styleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  // Redux Values
  const { statusUpdated, currentProcessReviewStatus } = useSelector(selectApplicationActions);
  const { noteUpdated } = useSelector(selectApplicantNotes);
  const { isCustomIdProcess, customIdProcessData } = useSelector(customIdProcess);

  // State Values
  const [currentReviewStatus, setCurrentReviewStatus] = useState(currentProcessReviewStatus);
  const [showConfirmDialog, setConfirmDialogFlag] = useState(false);

  // Use Effects
  useEffect(() => {
    if (statusUpdated) {
      dispatch(setReviewStatus(currentReviewStatus));
      dispatch(setStatusFlag(false));
      dispatch(setIsCustomIdProcess(false));
      dispatch(getApplicationIdProcesses(applicationId));
      dispatch(getApplicantDetails(applicationId));
    }
  }, [statusUpdated]);

  useEffect(() => {
    setCurrentReviewStatus(currentProcessReviewStatus);
  }, [currentProcessReviewStatus]);

  useEffect(() => {
    if (noteUpdated) {
      const summaryData = {
        applicationId: applicationId,
        section: section,
      };
      dispatch(getNoteSummary(summaryData));
      dispatch(setNoteUpdated(false));
    }
  }, [noteUpdated]);

  // Methods
  const reviewProcess = async (status: string, note: string) => {
    let data: ReviewProcessType;
    if (isCustomIdProcess) {
      data = { applicationId: applicationId, processId: customIdProcessData?.id, status: status, note: note };
    } else {
      data = { applicationId: applicationId, processId: processId, status: status, note: note };
    }
    setCurrentReviewStatus(status);
    await dispatch(reviewIdProcess(data));
  };

  const confirmRejection = (data: { note: string }) => {
    reviewProcess(ID_PROCESS_REVIEW_STATUS.REJECTED, data?.note);
    cancelReject();
  };
  const cancelReject = () => {
    setConfirmDialogFlag(false);
  };

  // HTML
  return (
    <>
      <Box className="flex-basic-center">
        <TTooltip title="Decline">
          <Box>
            <TButton
              variant="text"
              icon={<span className="icon-forbid actionIconFont-24" />}
              disabled={currentReviewStatus === ID_PROCESS_REVIEW_STATUS.REJECTED}
              className={clsx({
                [classes.defaultActionBtn]: currentReviewStatus === ID_PROCESS_REVIEW_STATUS.UNREVIEWED,
                [classes.disabledActionBtn]: currentReviewStatus === ID_PROCESS_REVIEW_STATUS.REJECTED,
                [classes.rejectedActionBtn]: currentReviewStatus === ID_PROCESS_REVIEW_STATUS.APPROVED,
              })}
              onClick={() => {
                setConfirmDialogFlag(true);
              }}
            />
          </Box>
        </TTooltip>
        <TTooltip title="Approve">
          <Box>
            <TButton
              variant="text"
              icon={<span className="actionIconFont-24 icon-checked" />}
              disabled={currentReviewStatus === ID_PROCESS_REVIEW_STATUS.APPROVED}
              className={clsx({
                [classes.defaultActionBtn]: currentReviewStatus === ID_PROCESS_REVIEW_STATUS.UNREVIEWED,
                [classes.approvedActionBtn]: currentReviewStatus === ID_PROCESS_REVIEW_STATUS.REJECTED,
                [classes.disabledActionBtn]: currentReviewStatus === ID_PROCESS_REVIEW_STATUS.APPROVED,
              })}
              onClick={() => {
                reviewProcess(ID_PROCESS_REVIEW_STATUS.APPROVED, '');
              }}
            />
          </Box>
        </TTooltip>
      </Box>
      <ConfirmWithReasonDialog
        title="Decline ID Process"
        yesBtnTitle="Decline"
        noBtnTitle="Cancel"
        showDialog={showConfirmDialog}
        message="Are you sure you want to decline this ID Process?"
        noteLabel="Decline Reason"
        onCancel={cancelReject}
        onConfirm={confirmRejection}
      />
    </>
  );
};

export default IdProcessActions;
