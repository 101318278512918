import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getParentApplicationIdProcesses,
  selectApplicant,
  setCurrentTab,
  setSelectedSection,
} from '../../redux/feature/applicant/applicantSlice';
import CustomTabs, { TabListType } from '../common/tabs/Tabs';
import { ID_PROCESS_STATUS, TUserInfo } from '../myde-react-components';
import { ApplicantType, ApplicationType, CustomIdProcessType, PredefinedProcessType } from '../../types/applicantTypes';
import TabPanelIndex from './TabPanelIndex';
import IdProcessIcon from './components/IdProcessIcon';
import { getIdProcessStatus } from '../../utils/idProcess';
import AccountStatusMsg from './components/AccountStatusMsg';
import {
  BENEFICIAL_OWNER_DOCUMENT,
  BENEFICIAL_OWNER_DOCUMENT_VALUES,
  ID_PROCESS_REVIEW_STATUS,
} from '../../constants/constants';

// Main Component
const UserTabs = () => {
  const dispatch = useDispatch();

  // Redux Values
  const { applicantDetails, applicationIdProcessList, parentApplicationDetailList, currentTabId } =
    useSelector(selectApplicant);

  // State Values
  const [tabs, setTabs] = useState([] as TabListType[]);
  const [userName, setUserName] = useState('');
  const [defaultTab, setDefaultTab] = useState({} as TabListType);
  const [userDetails, setUserDetails] = useState({} as ApplicantType);
  const [idProcessList, setIdProcessList] = useState([] as PredefinedProcessType[]);
  const [customIdProcessList, setCustomIdProcessList] = useState([] as CustomIdProcessType[]);
  const [parentApplications, setParentApplications] = useState([] as ApplicationType[]);
  const [currentTab] = useState(currentTabId);

  // Use Effects

  useEffect(() => {
    const name = applicantDetails?.id ? applicantDetails?.name : '';
    setUserName(name);
    setUserDetails(applicantDetails);
  }, [applicantDetails]);

  useEffect(() => {
    if (userName && userDetails?.id) {
      const userTab: TabListType = {
        label: 'User data',
        component: <TabPanelIndex />,
        id: 0,
        tabChild: (
          <TUserInfo
            label={userName}
            imageUrl={userDetails?.avatar_url ? userDetails?.avatar_url : userDetails?.alternate_avatar_url}
            id={userDetails?.trellis_uid?.toString()}
            status={userDetails?.details?.accreditation_status}
          >
            <AccountStatusMsg />
          </TUserInfo>
        ),
      };
      setDefaultTab(userTab);
    }
  }, [userName, userDetails]);

  useEffect(() => {
    if (Object.keys(defaultTab)?.length > 0) {
      setTabs([defaultTab]);
      updateTabs();
    }
  }, [defaultTab]);

  useEffect(() => {
    if (applicationIdProcessList?.custom_id_process_list?.length > 0) {
      setCustomIdProcessList(applicationIdProcessList?.custom_id_process_list);
    }
  }, [applicationIdProcessList]);

  useEffect(() => {
    if (parentApplicationDetailList?.length > 0) {
      setParentApplications(parentApplicationDetailList);
    }
  }, [parentApplicationDetailList]);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      let idProcesses = applicationIdProcessList?.id_process_list;
      if (parentApplications?.length > 0) {
        idProcesses = [...idProcesses, BENEFICIAL_OWNER_DOCUMENT];
      }
      setIdProcessList(idProcesses);
    }
  }, [applicationIdProcessList, parentApplications]);

  useEffect(() => {
    updateTabs();
    //after adding note pre selected section name
    let selectedSectionName = 'Account';
    if (currentTab > 0) {
      selectedSectionName =
        idProcessList.find((section: PredefinedProcessType) => section.process_id === currentTab)?.title || '';
    }
    dispatch(setSelectedSection(selectedSectionName));
  }, [idProcessList]);

  // Methods
  const checkAreAllApplicationsApproved = (data: ApplicationType[]) => {
    return data?.every((item) => ID_PROCESS_REVIEW_STATUS.APPROVED === item?.status);
  };

  const getReviewStatusForBeneficialOwners = (applications: ApplicationType[]) => {
    const areAllReviewed = checkAreAllApplicationsApproved(applications);
    return areAllReviewed ? ID_PROCESS_REVIEW_STATUS.APPROVED : ID_PROCESS_REVIEW_STATUS.UNREVIEWED;
  };

  const getProcessStatusForBeneficialOwners = (applications: ApplicationType[]) => {
    const areAllAppsApproved = checkAreAllApplicationsApproved(applications);
    return areAllAppsApproved ? ID_PROCESS_STATUS.PASSED : '';
  };

  const getReviewStatusByProcessId = (applications: ApplicationType[], idProcess: PredefinedProcessType) => {
    if (applications?.length > 0 && idProcess?.process_id === BENEFICIAL_OWNER_DOCUMENT_VALUES.PROCESS_ID) {
      return getReviewStatusForBeneficialOwners(applications);
    } else {
      return idProcess?.merchant_review_status;
    }
  };

  const getProcessStatusByProcessId = (applications: ApplicationType[], idProcess: PredefinedProcessType) => {
    if (applications?.length > 0 && idProcess?.process_id === BENEFICIAL_OWNER_DOCUMENT_VALUES.PROCESS_ID) {
      return getProcessStatusForBeneficialOwners(applications);
    } else {
      return idProcess?.status;
    }
  };

  const updateTabs = () => {
    if (idProcessList?.length > 0 && Object.keys(defaultTab)?.length > 0) {
      const tabList: TabListType[] = [defaultTab];
      idProcessList?.forEach((idProcess: PredefinedProcessType) => {
        const currentTab = {} as TabListType;
        currentTab.label = idProcess?.title;
        currentTab.id = idProcess?.process_id;
        currentTab.component = <TabPanelIndex idProcess={idProcess} />;
        currentTab.icon = (
          <IdProcessIcon
            merchantReviewStatus={getReviewStatusByProcessId(parentApplications, idProcess)}
            processStatus={getIdProcessStatus(getProcessStatusByProcessId(parentApplications, idProcess))}
          />
        );
        tabList.push(currentTab);
      });
      setTabs(tabList);
    }
    if (customIdProcessList?.length > 0 && Object.keys(defaultTab)?.length > 0) {
      const tabList: TabListType[] = [];
      customIdProcessList?.forEach((customIdProcess: CustomIdProcessType) => {
        const currentTab = {} as TabListType;
        currentTab.label = customIdProcess?.process_name;
        currentTab.id = customIdProcess?.account_id_process_id;
        currentTab.component = <TabPanelIndex />;
        currentTab.icon = (
          <IdProcessIcon
            merchantReviewStatus={customIdProcess?.merchant_review_status}
            processStatus={getIdProcessStatus(customIdProcess?.status)}
          />
        );
        tabList.push(currentTab);
      });
      setTabs((prevState) => [...prevState, ...tabList]);
    }
  };

  const getSelectedTab = (selectedTab: number) => {
    dispatch(setCurrentTab(selectedTab));
    let selectedSectionName = 'Account';
    if (selectedTab > 0) {
      selectedSectionName =
        idProcessList.find((section: PredefinedProcessType) => section.process_id === selectedTab)?.title || '';
    }
    dispatch(setSelectedSection(selectedSectionName));
    if (parentApplications?.length > 0 && selectedTab === BENEFICIAL_OWNER_DOCUMENT_VALUES.PROCESS_ID) {
      dispatch(getParentApplicationIdProcesses(parentApplications[0]?.id));
    }
  };

  // HTML
  return (
    <CustomTabs
      tabList={tabs}
      isVertical={true}
      borderRequired={true}
      showBottomBorder={true}
      tabSelected={getSelectedTab}
      selectedTab={currentTab}
    ></CustomTabs>
  );
};

export default UserTabs;
