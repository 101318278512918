import { Box, FormControl, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { TDialog, TSelect, TTextArea } from '../../myde-react-components';
import { BtnType, ClickHandlerType } from '../../../types/commonTypes';
import { useSelector } from 'react-redux';
import { selectApplicant } from '../../../redux/feature/applicant/applicantSlice';
import clsx from 'clsx';
import { PORTAL } from '../../myde-react-components/src/constants/portal';

interface MessageNoteModalProps {
  showDialog: boolean;
  title?: string;
  options?: optionsType[];
  onCancelModal: ClickHandlerType;
  onSubmitForm: ClickHandlerType;
  noBtnText?: string;
  yesBtnText?: string;
  selectedValue?: any;
  label?: string;
  charLimit?: number;
}
interface optionsType {
  label: string;
  id: string;
}

const useStyles = makeStyles({
  formStyle: {
    width: '-webkit-fill-available',
  },
  noteCount: {
    display: 'flex',
    justifyContent: 'end',
  },
  msgDialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: '560px!important',
      },
    },
  },
});
export const MessageNoteModal = ({
  options = [],
  title = 'Add Note',
  showDialog = false,
  onCancelModal,
  onSubmitForm,
  noBtnText = 'Cancel',
  yesBtnText = 'Save',
  selectedValue,
  label = 'Type your message here',
  charLimit = 0,
}: MessageNoteModalProps) => {
  const classes = useStyles();

  type FormSchemaPayload = z.infer<typeof FormSchema>;

  const FormSchema = z.object({
    note: z.string().nonempty('Note is required').max(300, { message: 'Note can be 300 characters long' }),
    section: options?.length > 0 ? z.string().nonempty('Section is required') : z.string(),
  });
  const { handleSubmit, control, formState, reset, setValue } = useForm<FormSchemaPayload>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  });

  const { errors, isValid } = formState;
  const { selectedSection } = useSelector(selectApplicant);

  const [formDetail, setFormDetail] = useState({
    note: '',
    section: '',
  });
  const [count, setCount] = useState(0);
  const [docValue, setDocValue] = useState('');

  // Use Effects
  useEffect(() => {
    reset(formDetail);
    setDocValue('');
    setValue('section', selectedSection || '');
    setValue('note', '');
  }, [formDetail]);

  useEffect(() => {
    if (selectedValue) {
      setDocValue(selectedValue?.section);
      setValue('section', selectedValue?.section, { shouldValidate: true });
      setValue('note', selectedValue?.note, { shouldValidate: false });
      setCount(selectedValue?.note?.length);
    }
  }, [selectedValue]);
  // Methods
  const onSubmit = async (formData: FormSchemaPayload) => {
    setFormDetail({
      note: formData?.note,
      section: formData?.section,
    });
    onSubmitForm(formData);
    setCount(0);
  };

  const handleTextChange = (_event: React.SyntheticEvent, value: string) => {
    setValue('note', value, { shouldValidate: true });
    setCount(value?.length);
    return value;
  };

  const onCancel = () => {
    onCancelModal();
  };
  const onConfirm = () => {
    handleSubmit(onSubmit)();
    setCount(0);
  };

  const handleChange = (event: any) => {
    setDocValue(event.target.value);
    setValue('section', event.target.value, { shouldValidate: options?.length > 0 });
    return event.target.value;
  };

  const confDialogBtns: BtnType[] = [
    {
      id: 1,
      title: noBtnText,
      variant: 'text',
      buttonAction: onCancel,
      disabled: false,
    },
    {
      id: 2,
      title: yesBtnText,
      variant: 'contained',
      buttonAction: onConfirm,
      disabled: !isValid,
    },
  ];

  return (
    <TDialog
      portalName={PORTAL.MERCHANT}
      className={classes.msgDialogStyle}
      open={showDialog}
      title={title}
      buttons={confDialogBtns}
    >
      <form>
        <Grid container>
          {options?.length > 0 && (
            <Grid item xs={8} md={8} sx={{ mt: 1, mb: 3 }}>
              <Controller
                name="section"
                control={control}
                render={({ field }) => (
                  <FormControl className="w-100">
                    <TSelect
                      {...field}
                      name="section"
                      id="type"
                      fullWidth
                      value={docValue}
                      options={options}
                      label="Section"
                      itemId="id"
                      itemValue="label"
                      onChange={handleChange}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="note"
              defaultValue={formDetail?.note}
              control={control}
              render={({ field }) => (
                <TTextArea
                  {...field}
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.note)}
                  helperText={errors?.note?.message}
                  label={label}
                  inputProps={{ maxLength: charLimit }}
                  onChange={(event) => {
                    const { value } = event.target;
                    handleTextChange(event, value);
                  }}
                />
              )}
            />
            <Box
              className={clsx({
                [classes.noteCount]: true,
                ['text-small font-weight-semibold textColor-300']: true,
              })}
              sx={{ pt: 1 }}
            >{`${charLimit - count} character(s) remaining`}</Box>
          </Grid>
        </Grid>
      </form>
    </TDialog>
  );
};

export default MessageNoteModal;
