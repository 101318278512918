import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Container, Grid, Icon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import MessageNoteDetailCard from '../common/messageNotes/MessageNoteDetailCards';
import { TButton, TCard, TConfirmDialog, theme } from '../myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import {
  deleteApplicationNotes,
  getApplicationNoteById,
  selectApplicantNotes,
} from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { formatDate, getInitials, getTimeStamp } from '../../utils/utils';
import EditUserNoteModal from './EditUserNoteModal';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { useTheme } from '../../providers/custom-theme-provider';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  cardStyle: {
    minHeight: '100%',
  },
  containerHeight: {
    height: '90%',
  },
  btnStyles: {
    '& .MuiButton-root': {
      '& .MuiButton-startIcon': {
        paddingRight: '13px !important',
      },
    },
  },
});
const IndividualNoteDetails = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const params: any = useLocation();
  const noteId = params.state.noteId;
  const dispatch = useDispatch();
  const { goBack } = useRouter();
  const [showEditMsgDialog, setEditDialogFlag] = useState(false);
  const [selectedNoteId] = useState(noteId || '');
  const { applicationId } = useSelector(selectApplicant);
  const { selectedNote } = useSelector(selectApplicantNotes);
  const [showDialog, setDialogFlag] = React.useState(false);
  useEffect(() => {
    const formData = { applicationId: applicationId, noteId: selectedNoteId };
    dispatch(getApplicationNoteById(formData));
  }, []);
  const handleClose = () => {
    setDialogFlag(false);
  };
  const updateMessage = (selectedNoteIdI: string) => {
    const reqData = { applicationId: applicationId, noteId: selectedNoteIdI };
    dispatch(deleteApplicationNotes(reqData));
    handleClose();
    goBack();
  };
  return (
    <Container sx={{ py: 3 }} className={classes.containerHeight}>
      <TCard className={classes.cardStyle} sx={{ p: 3 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <TButton
              sx={{ mr: 1, mb: 1 }}
              variant="text"
              icon={<Icon className="textColor-200">arrow_back_ios_new_icon</Icon>}
              size="small"
              onClick={() => goBack()}
            />
            <span className="text-h2 font-weight-semibold textColor-200">Notes</span>
          </Grid>
          {!selectedNote.is_archived && (
            <Grid item className={classes.btnStyles}>
              <TButton
                variant="text"
                btnText="Edit"
                sx={{ ml: 1 }}
                onClick={() => {
                  setEditDialogFlag(true);
                }}
                icon={<BorderColorOutlinedIcon />}
              />
              <TButton
                variant="text"
                btnText={'Archive'}
                sx={{ ml: 1 }}
                icon={<ArchiveOutlinedIcon />}
                onClick={() => {
                  setDialogFlag(true);
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ px: theme.spacing(4) }} className="noteDetailsBoxHeight">
              <MessageNoteDetailCard
                isOnMessageScreen={true}
                messageType={'Message'}
                messageDate={formatDate(selectedNote?.modified_on)}
                messageTime={getTimeStamp(selectedNote?.modified_on)}
                responder={`${selectedNote?.account?.first_name} ${getInitials(selectedNote?.account?.last_name)}`}
                message={selectedNote?.note || ''}
                section={selectedNote?.section || ''}
              />
            </Box>
          </Grid>
        </Grid>
      </TCard>
      <TConfirmDialog
        classNameConfirmDailog="confirmDialogBoxOpacityNote"
        showDialog={showDialog}
        onCancelClick={handleClose}
        portalName={PORTAL.MERCHANT}
        onConfirmClick={() => {
          updateMessage(selectedNote?.id);
        }}
        confirmationMessage={`Are you sure you want to archive the note ?`}
        title={'Confirm Archive Note'}
      />
      <EditUserNoteModal
        openDialog={showEditMsgDialog}
        onClose={() => {
          setEditDialogFlag(false);
        }}
      />
    </Container>
  );
};

export default IndividualNoteDetails;
