import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import jwtDecode from 'jwt-decode';

import {
  getLoginDetails,
  loginUsingBankVault,
  selectUser,
  setSelectedMerchant,
  setUserRole,
} from '../../redux/feature/user/userSlice';
import { selectSession, clearResults, getSessionDetails } from '../../redux/feature/common/commonSlice';
import { LoginFormSchema, LoginFormSchemaPayload } from './validation';
import * as ROUTES from '../../constants/routes';
import AuthLayout from '../../layout/AuthLayout';
import PublicFooter from '../../components/common/PublicFooter';
import {
  BANKVAULT_HOST_URL,
  TButton,
  TTextField,
  colorPalette,
  TCard,
  theme,
  MERCHANT_USER_ROLES,
} from '../../components/myde-react-components';
import { useAuth } from '../../auth/auth-guard';
import { clearStorage } from '../../utils/storage';
import LoginAs from './LoginAs';
import { UserSession } from '../../types/userTypes';
import { useRouter } from '../../providers/custom-router-provider';
import { getNotificationsCount } from '../../redux/feature/notification/notificationSlice';

const useStyles = makeStyles({
  qrCode: {
    width: '190px',
    height: '190px',
  },
  qrCodeSection: {
    background: colorPalette.border.success,
  },
  qrCodeContainer: {
    background: colorPalette.containerBack.lighten3,
    width: '240px',
    height: '240px',
  },
  forgotPassword: {
    color: colorPalette.border.success,
  },
});

const Login = () => {
  // Constants
  const formRef = useRef<null | HTMLFormElement>(null);
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const { handleSubmit, control, formState, setError } = useForm<LoginFormSchemaPayload>({
    resolver: zodResolver(LoginFormSchema),
    mode: 'onChange',
  });
  const { login } = useAuth();

  // Redux Values
  const { merchantData, authToken, userRoles } = useSelector(selectUser);
  const { session } = useSelector(selectSession);

  // State Values
  const [multipleMerchants, setMultipleMerchants] = useState(false);
  const [isBankVaultCallBack, setIsBankVaultCallBack] = useState(false);
  const [bankVaultSession, setBankVaultSession] = useState({} as UserSession);
  const { errors, isValid, isSubmitting } = formState;

  // Use Effects
  useEffect(() => {
    clearStorage();
    dispatch(clearResults());
  }, []);

  useEffect(() => {
    if (merchantData && merchantData?.merchants?.length > 0) {
      if (merchantData?.merchants?.length === 1) {
        dispatch(setSelectedMerchant(merchantData?.merchants[0]));
        const token = merchantData?.merchants[0]?.access;
        const decoded: any = jwtDecode(token);
        dispatch(setUserRole(decoded?.claims?.roles));
        login(token);
      } else {
        setMultipleMerchants(true);
      }
    }
  }, [merchantData]);

  useEffect(() => {
    if (authToken) {
      dispatch(getNotificationsCount());
    }
  }, [authToken]);

  useEffect(() => {
    if (Object.keys(session)?.length > 0) {
      setBankVaultSession(session);
    } else {
      dispatch(getSessionDetails());
    }
  }, [session]);

  useEffect(() => {
    if (Object.keys(bankVaultSession)?.length > 0) {
      loadScript(bankVaultSession?.session);
      if (isBankVaultCallBack) {
        loginWithBankVault();
      }
    }
  }, [bankVaultSession, isBankVaultCallBack]);

  useEffect(() => {
    if (formRef?.current?.submit) {
      formRef.current.submit = () => {
        loginWithBankVault();
      };
    }
  }, [formRef]);

  useEffect(() => {
    if (userRoles?.length > 0) {
      navigateToDashboard(userRoles);
    }
  }, [userRoles]);

  // Methods
  const navigateToDashboard = (userRole: string[]) => {
    if (userRole?.includes(MERCHANT_USER_ROLES.COORDINATOR)) {
      routeTo(ROUTES.ENTITIES);
    } else {
      routeTo(ROUTES.DASHBOARD);
    }
  }
  const loadScript = (sessionId: string) => {
    (window as any).BankVaultApi?.init({ session: sessionId, host: BANKVAULT_HOST_URL });
  };

  const onSubmit = async (formData: LoginFormSchemaPayload) => {
    try {
      dispatch(getLoginDetails(formData));
    } catch (err: any) {
      setError('password', { type: 'manual', message: err.password });
    }
  };

  const loginWithBankVault = () => {
    if (Object.keys(bankVaultSession)?.length > 0) {
      const data = {
        session: bankVaultSession?.session,
        token: bankVaultSession?.token,
        secret: bankVaultSession?.secret,
      };
      try {
        dispatch(loginUsingBankVault(data));
        setIsBankVaultCallBack(false);
      } catch (err: any) {
        toast.error('Failed to login');
      }
    } else {
      setIsBankVaultCallBack(true);
      dispatch(getSessionDetails());
    }
  };

  const goToForgotPassword = () => {
    routeTo(ROUTES.FORGOT_PASSWORD);
  };

  // HTML
  return (
    <>
      <AuthLayout>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={6} className={clsx('flex-column-center', classes.qrCodeSection)}>
            <Box className="flex-column-center" sx={{ py: 3 }}>
              <Box sx={{ mt: theme.spacing(5) }} className={clsx('text-h2 font-weight-semibold text-white')}>
                {t('scanToLogin')}
              </Box>
              <TCard sx={{ p: 1, mt: 4 }} className={clsx('flex-basic-center', classes.qrCodeContainer)}>
                <img
                  src={'data:image/png;base64, ' + bankVaultSession?.image}
                  alt="QR code"
                  className={classes.qrCode}
                />
              </TCard>
              <Box sx={{ mt: 3 }} className="text-center">
                <Box className={clsx('text-medium font-weight-semibold text-white')}>{t('securedBy')}</Box>
                <Box className={clsx('text-medium font-weight-semibold text-white')}>{t('trellisSoftInc')}</Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ px: 5, py: 3 }}>
              {!multipleMerchants ? (
                <>
                  <Box sx={{ mt: 12 }} className={clsx('textColor-200 text-h2 font-weight-semibold')}>
                    {t('signIn')}
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <form ref={formRef}>
                      <Box>
                        <Controller
                          name="email"
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <TTextField
                              label="Your Email"
                              variant="outlined"
                              fullWidth
                              error={Boolean(errors.email)}
                              helperText={errors.email?.message}
                              {...field}
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ mt: theme.spacing(5) }}>
                        <Controller
                          name="password"
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <TTextField
                              label="Password"
                              variant="outlined"
                              type="password"
                              fullWidth
                              error={Boolean(errors.password)}
                              helperText={errors.password?.message}
                              {...field}
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Box
                          className={clsx('text-medium font-weight-semibold flex-basic-end', classes.forgotPassword)}
                        >
                          <span className="cursorPointer" onClick={() => goToForgotPassword()}>
                            {t('forgotPassword')}
                          </span>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 5 }}>
                        <TButton
                          variant={'contained'}
                          type="submit"
                          fullWidth
                          disabled={!isValid || isSubmitting}
                          btnText={'Sign In'}
                          onClick={handleSubmit(onSubmit)}
                        ></TButton>
                      </Box>
                    </form>
                  </Box>
                  <Box sx={{ mt: 10, mb: 4 }}>
                    <PublicFooter />
                  </Box>
                </>
              ) : (
                <Grid className="loginBox-min-height" xs={12}>
                  <Box sx={{ pt: theme.spacing(18) }} className={'text-h2 font-weight-semibold textColor-200'}>
                    <p>{t('signIn')} as</p>
                  </Box>
                  <Box>
                    <LoginAs />
                  </Box>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </AuthLayout>
    </>
  );
};

export default Login;
