import React, { useState } from 'react';
import { Box, Icon, Chip, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatDate } from '../../../utils/utils';
import { TButton, CommentList, colorPalette } from '../../myde-react-components';
import { KycAmlRecordType, KycAmlRecordDetails } from '../../../types/idProcessTypes';
import { fontStyle } from '../../../styles/style';
import { ClickHandlerType } from '../../../types/commonTypes';
import clsx from 'clsx';
import { useTheme } from '../../../providers/custom-theme-provider';

// Interface
interface KycAmlReportsProps {
  kycRecord?: KycAmlRecordType[];
  onBack: ClickHandlerType;
  secondaryRecord?: KycAmlRecordType[];
}
interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  cardWrapper: {
    marginBottom: '100px !important',
    borderRadius: 10,
  },
  pageHeader: {
    borderBottom: `1px solid ${colorPalette.border.card}`,
    paddingBottom: 24,
    position: 'relative',
  },
  backBtn: {
    position: 'absolute !important' as any,
    left: '-25px',
    top: 7,
    '& .MuiIcon-root': {
      ...fontStyle.fontSize.font12,
      ...fontStyle.fontWeight.font600,
    },
  },
  fullNameSection: {
    padding: '20px 0 0',
  },

  reportSection: {
    padding: '16px 0',
    borderBottom: `1px solid ${colorPalette.border.card}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  noBorder: {
    borderBottom: 'none',
  },
  aliasWrapper: {
    '& .MuiChip-root': {
      margin: 5,
      border: '1px solid',
      borderColor: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
    },
    '& .MuiChip-label': {
      color: ({ currentThemes }) => `${currentThemes.palette.primary.main}!important`,
    },
  },
  viewBtn: {
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font600,
    textTransform: 'capitalize !important' as any,
  },
  commentsHeader: {
    '& .MuiIcon-root': {
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
  },
  newCommentLabel: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.darken2,
    marginBottom: 16,
  },
});

const KycAmlRecords = ({ kycRecord, onBack, secondaryRecord }: KycAmlReportsProps) => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };

  const classes = useStyles(styleProps);

  // Constants
  const summaryStringLength = 100;

  // State Values
  const [selectedEvent, setSelectedEvent] = useState({} as KycAmlRecordDetails);
  const [showSingleData, setSingleDataFlag] = useState(false);
  const [showComments, setCommentSectionFlag] = useState(true);

  // Methods
  const selectRecord = (record: KycAmlRecordType, event: KycAmlRecordDetails) => {
    setSelectedEvent(event);
    setSingleDataFlag(true);
  };
  const goPrevious = () => {
    setSelectedEvent({} as KycAmlRecordDetails);
    setSingleDataFlag(false);
  };
  const toggleCommentSection = (numberOfComments: number) => {
    if (numberOfComments > 0) {
      setCommentSectionFlag(!showComments);
    }
  };

  // HTML
  return (
    <>
      {kycRecord &&
        (!showSingleData ? (
          <Box className={classes.cardWrapper}>
            <Box className={clsx(classes.pageHeader, 'text-h3 font-weight-semibold textColor-200')}>
              <TButton sx={{ pr: 2 }} variant="text" icon={<Icon>arrow_back_ios_icon</Icon>} onClick={onBack} />
              Records Found
            </Box>
            {kycRecord?.map((record, index) => (
              <Box key={index}>
                <Box className={clsx(classes.fullNameSection, 'text-h3 font-weight-semibold textColor-200')}>
                  {record?.full_name}
                </Box>
                {record?.alias?.length > 0 && (
                  <Box>
                    <span className="font-weight-semibold text-medium textColor-200">Alias: </span>
                    {record.alias.map((alias, idx) => (
                      <span
                        className={clsx({
                          [classes.aliasWrapper]: true,
                          ['customChips']: true,
                        })}
                        key={idx}
                      >
                        <Chip label={alias?.alias_name} />
                      </span>
                    ))}
                  </Box>
                )}
                {record?.details?.length > 0 &&
                  record.details.map((data, idx) => (
                    <Box className={clsx(classes.reportSection, 'text-medium textColor-300')} key={idx}>
                      <div>Category: {data?.event_category}</div>
                      {data?.event_sub_category && <div>Sub Category: {data?.event_sub_category}</div>}
                      <p>
                        {data?.event_description?.substring(0, summaryStringLength)}
                        {data?.event_description?.length > summaryStringLength && <span>..</span>}
                      </p>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid
                          item
                          className={clsx(
                            classes.commentsHeader,
                            'cursorPointer text-medium font-weight-semibold textColor-200',
                          )}
                        >
                          ({data?.comments?.length}) Comments
                        </Grid>
                        <Grid item>
                          <TButton
                            btnText="View"
                            variant="text"
                            onClick={() => {
                              selectRecord(record, data);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>
        ) : (
          <Box className={classes.cardWrapper}>
            <Box className={clsx(classes.pageHeader, 'text-h3 font-weight-semibold textColor-200')}>
              <TButton variant="text" icon={<Icon>arrow_back_ios_icon</Icon>} onClick={goPrevious} />
              Records Found
            </Box>
            <Box className={clsx(classes.reportSection, classes.noBorder, 'text-medium textColor-300')}>
              <div>Category: {selectedEvent?.event_category}</div>
              {selectedEvent?.event_sub_category && (
                <Box sx={{ pb: 2 }}>Sub Category: {selectedEvent?.event_sub_category}</Box>
              )}
              <div>
                {selectedEvent?.event_name}
                <span> Felony record found: {formatDate(selectedEvent?.event_date) || 'NA'}</span>
              </div>
              {selectedEvent?.source_name && <div>Source: {selectedEvent?.source_name}</div>}
              <p>{selectedEvent?.event_description}</p>
            </Box>
            <Box>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid
                  item
                  className={clsx(
                    classes.commentsHeader,
                    'cursorPointer text-medium font-weight-semibold textColor-200',
                  )}
                  onClick={() => toggleCommentSection(selectedEvent?.comments?.length)}
                >
                  {!showComments && selectedEvent?.comments?.length > 0 && <Icon>add_icon</Icon>}
                  {showComments && selectedEvent?.comments?.length > 0 && <Icon>remove_icon</Icon>}(
                  {selectedEvent?.comments?.length}) Comments
                </Grid>
              </Grid>
              {showComments && (
                <Box sx={{ mt: 3 }}>
                  {selectedEvent?.comments?.length > 0 && <CommentList list={selectedEvent?.comments} />}
                  {selectedEvent?.comments?.length === 0 && (
                    <Box className="text-medium textColor-300 font-weight-semibold text-center">
                      No Comments Available
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ))}

      {secondaryRecord &&
        (!showSingleData ? (
          <Box className={classes.cardWrapper}>
            <Box className={clsx(classes.pageHeader, 'text-h3 font-weight-semibold textColor-200')}>
              <TButton variant="text" icon={<Icon>arrow_back_ios_icon</Icon>} onClick={onBack} />
              Secondary Checks
            </Box>
            {secondaryRecord?.map((record, index) => (
              <Box key={index}>
                <Box className={clsx(classes.fullNameSection, 'text-h3 font-weight-semibold textColor-200')}>
                  {record?.full_name}
                </Box>
                {record?.alias?.length > 0 && (
                  <Box>
                    <span className="font-weight-semibold text-medium textColor-200">Alias: </span>
                    {record.alias.map((alias, idx) => (
                      <span
                        className={clsx({
                          [classes.aliasWrapper]: true,
                          ['customChips']: true,
                        })}
                        key={idx}
                      >
                        <Chip label={alias?.alias_name} />
                      </span>
                    ))}
                  </Box>
                )}
                {record?.details?.length > 0 &&
                  record.details.map((data, idx) => (
                    <Box className={clsx(classes.reportSection, 'text-medium textColor-300')} key={idx}>
                      <div>Category: {data?.event_category}</div>
                      {data?.event_sub_category && <div>Sub Category: {data?.event_sub_category}</div>}
                      <p>
                        {data?.event_description?.substring(0, summaryStringLength)}
                        {data?.event_description?.length > summaryStringLength && <span>..</span>}
                      </p>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid
                          item
                          className={clsx(
                            classes.commentsHeader,
                            'cursorPointer text-medium font-weight-semibold textColor-200',
                          )}
                        >
                          ({data?.comments?.length}) Comments
                        </Grid>
                        <Grid item>
                          <TButton
                            btnText="View"
                            variant="text"
                            onClick={() => {
                              selectRecord(record, data);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>
        ) : (
          <Box className={classes.cardWrapper}>
            <Box className={clsx(classes.pageHeader, 'text-h3 font-weight-semibold textColor-200')}>
              <TButton variant="text" icon={<Icon>arrow_back_ios_icon</Icon>} onClick={goPrevious} />
              Secondary Checks
            </Box>
            <Box className={clsx(classes.reportSection, classes.noBorder, 'text-medium textColor-300')}>
              <div>Category: {selectedEvent?.event_category}</div>
              {selectedEvent?.event_sub_category && <div>Sub Category: {selectedEvent.event_sub_category}</div>}
              <div>
                {selectedEvent?.event_name}
                <span> Felony record found: {formatDate(selectedEvent?.event_date) || 'NA'}</span>
              </div>
              {selectedEvent?.source_name && <div>Source: {selectedEvent?.source_name}</div>}
              <p>{selectedEvent?.event_description}</p>
            </Box>
            <Box>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid
                  item
                  className={clsx(
                    classes.commentsHeader,
                    'cursorPointer text-medium font-weight-semibold textColor-200',
                  )}
                  onClick={() => toggleCommentSection(selectedEvent?.comments?.length)}
                >
                  {!showComments && selectedEvent?.comments?.length > 0 && <Icon>add_icon</Icon>}
                  {showComments && selectedEvent?.comments?.length > 0 && <Icon>remove_icon</Icon>}(
                  {selectedEvent?.comments?.length}) Comments
                </Grid>
              </Grid>
              {showComments && (
                <Box sx={{ mt: 5 }}>
                  {selectedEvent?.comments?.length > 0 && <CommentList list={selectedEvent?.comments} />}
                  {selectedEvent?.comments?.length === 0 && (
                    <Box className="text-medium textColor-300 font-weight-semibold text-center">
                      No Comments Available
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

export default KycAmlRecords;
