import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, IconButton, Theme, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { TConfirmDialog, TLoader, TPagination } from '../myde-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import {
  getMessageDetails,
  selectApplicationMessage,
  setIsArchived,
  setIsResolved,
  setMsgUpdatedFlag,
  setNewMsgFlag,
  updateMsg,
} from '../../redux/feature/applicant/applicantMessageSlice';
import { formatDate } from '../../utils/utils';
import { MESSAGE_STATUS_TYPES } from '../../constants/constants';
import { ApplicantMessageType } from '../../types/applicantTypes';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { useTheme } from '../../providers/custom-theme-provider';

import clsx from 'clsx';
import { DEFAULT_TABLE_OPTIONS, ITEMS_PER_PAGE_LIST } from '../myde-react-components/src/constants/constants';
import { PORTAL } from '../myde-react-components/src/constants/portal';

// Interfaces
interface TableMsgData {
  request: ApplicantMessageType;
  last_replied_on: string;
  num_responses: string;
  unread_response_count: number;
  id: string;
  action?: ApplicantMessageType;
}
export interface MsgApiFormData {
  page: number;
  limit: number;
  applicationId: string;
  is_archived?: boolean;
  is_resolved?: boolean;
  ordering?: string;
}

export interface FilteredRecordsParams {
  request?: string;
  is_archived?: boolean;
  modified_on_gte?: string;
  modified_on_lte?: string;
}

export interface MsgFilterApiFormData {
  applicationId: string;
  formPayload: FilteredRecordsParams;
}
export interface NoteApiFormData {
  page: number;
  limit: number;
  applicationId: string;
  is_archived?: boolean;
  section?: string;
}
export interface DeleteNoteApiFormData {
  applicationId: string;
  noteId: string;
}

export interface SummaryDataType {
  applicationId: string;
  section: string;
}
export interface UpdateNoteApiFormData {
  id: string;
  noteId: string;
  data?: NoteDataType;
}
export interface NoteDataType {
  note?: string;
  section?: string;
}
interface UserMsgListProps {
  isArchived?: boolean;
  isResolved?: boolean;
}

export interface HistoryDataType {
  applicationId: string;
  noteId: string;
}
interface StyleProps {
  currentThemes: Theme;
}

// Styles
const useStyle = makeStyles<Theme, StyleProps>({
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  linkText: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    maxWidth: 340,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  setPrimaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

// Main Component
const UserMessageList = ({ isArchived, isResolved }: UserMsgListProps) => {
  // State Values
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const defaultTableMsg = {} as TableMsgData;
  const [msgList, setMsgList] = useState([defaultTableMsg]);
  const [showDialog, setDialogFlag] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState({} as ApplicantMessageType);

  //redux val
  const { applicationId } = useSelector(selectApplicant);
  const { loading, messageList, newMsgAdded, msgUpdated, count } = useSelector(selectApplicationMessage);

  //constant
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyle(styleProps);
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const primaryColor = currentThemes.palette.primary.main;

  const formData: MsgApiFormData = {
    page: page,
    limit: pageSize || 1000,
    applicationId: applicationId,
    is_archived: isArchived,
    is_resolved: isResolved,
    ordering: '-created_on',
  };

  // Use Effects
  useEffect(() => {
    dispatch(setIsArchived(isArchived));
  }, [isArchived]);

  useEffect(() => {
    dispatch(setIsResolved(isResolved));
  }, [isResolved]);

  useEffect(() => {
    if (applicationId) {
      getMsgList();
    }
  }, [applicationId, pageSize]);

  useEffect(() => {
    const arr: TableMsgData[] = [];
    messageList.forEach((msg) => {
      if (msg.request) {
        const obj: TableMsgData = {
          id: msg.id,
          request: msg,
          last_replied_on: formatDate(msg?.last_replied_on) || 'NA',
          num_responses: msg?.response?.length > 0 ? `${msg?.response.length} message(s)` : '0 message(s)',
          action: msg,
          unread_response_count: msg?.unread_response_count,
        };
        arr.push(obj);
      }
    });
    arr.sort((msg1, msg2) => {
      return msg1?.unread_response_count > 0 ? -1 : msg2?.unread_response_count === 0 ? 0 : 1;
    });
    setMsgList(arr);
  }, [messageList]);

  useEffect(() => {
    if (newMsgAdded || msgUpdated) {
      getMsgList();
      dispatch(setNewMsgFlag(false));
      dispatch(setMsgUpdatedFlag(false));
    }
  }, [newMsgAdded, msgUpdated]);

  // Methods
  const getMsgList = () => {
    dispatch(getMessageDetails(formData));
  };
  const customBodyRender = (value: string) => {
    return <Box className="text-large font-weight-regular textColor-200">{value}</Box>;
  };
  const changePage = () => {
    getMsgList();
  };
  const confirmArchiveUnArchive = (msgItem: ApplicantMessageType) => {
    if (msgItem) {
      setSelectedMsg(msgItem);
      setDialogFlag(true);
    }
  };
  const handleClose = () => {
    setDialogFlag(false);
    setSelectedMsg({} as ApplicantMessageType);
  };
  const updateMessage = (msgItem: ApplicantMessageType) => {
    if (msgItem) {
      const status = msgItem?.is_archived ? MESSAGE_STATUS_TYPES.UNARCHIVE : MESSAGE_STATUS_TYPES.ARCHIVE;
      updateMsgStatus(msgItem?.id, status);
    }
    handleClose();
  };
  const updateMsgStatus = (msgId: string, status: string) => {
    const reqData = { applicationId: applicationId, msgId: msgId, status: status };
    dispatch(updateMsg(reqData));
    getMsgList();
  };
  const selectMessage = (msgItem: any) => {
    const rowIndex: number = msgItem?.rowIndex || 0;
    const query = { messageId: msgList[rowIndex]?.id };
    routeTo(ROUTES.USER_MESSAGE_DETAIL, false, '', query);
  };
  const onRowChange = (value: any) => {
    setPageSize(value);
  };
  useEffect(() => {
    if (page) {
      changePage();
    }
  }, [page, pageSize]);

  const onPageChange = (value: any) => {
    setPage(value);
  };

  const onColumnSortChange = (changedColumn: string, direction: string) => {
    if (changedColumn === 'action') {
      return;
    } else {
      const allFilters = {
        ...formData,
        ordering: direction === 'desc' ? '-' + changedColumn : changedColumn,
      };
      dispatch(getMessageDetails(allFilters));
    }
  };

  // Table constants
  const columns = [
    {
      name: 'request',
      label: 'Question',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          // any as type kept as row data can be object or string.
          return (
            <Box
              className={clsx([
                {
                  [classes.linkText]: true,
                  ['cursorPointer text-large']: true,
                  ['font-weight-semibold']: value?.unread_response_count > 0,
                  ['font-weight-regular']: value?.unread_response_count === 0,
                },
              ])}
              onClick={() => {
                selectMessage(tableMeta);
              }}
            >
              {value?.request}
            </Box>
          );
        },
      },
    },
    {
      name: 'last_replied_on',
      label: 'Last Replied',
      options: { customBodyRender },
    },
    {
      name: 'num_responses',
      label: 'Thread',
      options: { customBodyRender },
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        customBodyRender: (value: ApplicantMessageType) => {
          return (
            <>
              <Box>
                <Tooltip title={value?.is_archived ? 'Un-archive' : 'Archive'}>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      confirmArchiveUnArchive(value);
                    }}
                  >
                    {value?.is_archived ? (
                      <UnarchiveOutlinedIcon className={classes.setPrimaryColor} />
                    ) : (
                      <ArchiveOutlinedIcon className={classes.setPrimaryColor} />
                    )}
                  </IconButton>
                </Tooltip>
                {!value?.is_resolved && (
                  <Tooltip title="Mark as answered">
                    <IconButton
                      className={classes.setPrimaryColor}
                      onClick={() => {
                        updateMsgStatus(value?.id || '', MESSAGE_STATUS_TYPES.RESOLVE);
                      }}
                    >
                      <CheckCircleOutlineIcon className={classes.setPrimaryColor} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <TConfirmDialog
                portalName={PORTAL.MERCHANT}
                showDialog={showDialog}
                classNameConfirmDailog="confirmDialogBoxOpacityList"
                onCancelClick={handleClose}
                onConfirmClick={() => {
                  updateMessage(selectedMsg);
                }}
                confirmationMessage={
                  selectedMsg?.is_archived
                    ? `Are you sure you want to un-archive the message ${selectedMsg.request}?`
                    : `Are you sure you want to archive the message?`
                }
                title={selectedMsg?.is_archived ? 'Confirm Un-archive Message' : 'Confirm Archive Message'}
              />
            </>
          );
        },
      },
    },
  ];

  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    rowsPerPage: pageSize,
    rowsPerPageOptions: { ITEMS_PER_PAGE_LIST },
    onColumnSortChange: onColumnSortChange,
    onTableChange: (action: any, tableState: any) => {
      if (action === 'changePage') {
        setPage(tableState.page);
        changePage();
      } else if (action === 'changeRowsPerPage') {
        setPageSize(tableState.rowsPerPage);
      }
    },
  };

  // HTML
  return (
    <>
      <Box className={classes.tableContainer}>
        <TLoader loading={loading} />
        <MUIDataTable title="" data={msgList} columns={columns} options={options} />
        <Box className={classes.pageContainer}>
          <TPagination
            primaryColor={primaryColor}
            rowsPerPageOptions={ITEMS_PER_PAGE_LIST}
            rowsPerPage={pageSize}
            count={Math.ceil(count / pageSize)}
            onRowChange={onRowChange}
            onPageChange={onPageChange}
            page={page}
          />
        </Box>
      </Box>
    </>
  );
};

export default UserMessageList;
