import React from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

//Interface
interface TaxIdDetailsProps {
  taxId: string;
}

const TaxIdDetails = ({ taxId }: TaxIdDetailsProps) => {
  //Constants
  const { t } = useTranslation('common');

  return (
    <Box>
      <Box sx={{ pt: 6 }} className="text-medium textColor-400">
        {t('taxId')}
      </Box>
      <Box className="font-weight-medium text-large">{taxId}</Box>
    </Box>
  );
};

export default TaxIdDetails;
