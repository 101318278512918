import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ENTITY_TYPE,
  GPlacesAddressType,
  GPlacesEntityAddressDataType,
  TAutocomplete,
  TButton,
  TGPlaces,
  TTextField,
  REGEX,
  theme,
  MAX_CHAR_LIMIT,
} from '../myde-react-components';
import { getAddressData } from '../myde-react-components/src/utils/commonMethods';
import {
  createEntity,
  editEntity,
  getEntityDetailById,
  selectEntity,
  setIsEditable,
} from '../../redux/feature/id-process/entity/entitySlice';
import { CountryType, EditEntityType, EntityDetail } from '../../types/entityTypes';
import { ClickHandlerType } from '../../types/commonTypes';
import useQuery from '../../hooks/useQuery';

//Interface
interface EntityOnboardingFormProps {
  setIsEditClicked: ClickHandlerType;
}

const EntityOnboardingForm = ({ setIsEditClicked }: EntityOnboardingFormProps) => {
  //Constants
  const query = useQuery();
  const assignmentId = query.get('assignmentId') || '';
  const entityName = query.get('entityName') || '';
  const entityEmailId = query.get('entityEmailId') || '';
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const EntityOnboardingFormSchema = z.object({
    entity_type: z.string().min(1, { message: 'Entity Type is required' }),
    email: z.string().min(1, { message: 'Email is required' }).email({ message: 'Please enter a valid email' }),
    entity_name: z
      .string()
      .min(1, { message: 'Entity Name is required' })
      .max(MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY, { message: 'Entity name should be less than 100 characters' }),
    dba_name: z.string(),
    website: z
      .string()
      .nullish()
      .optional()
      .refine((value) => REGEX.WEBSITE.test(value || ''), {
        message: 'Please enter a valid URL',
      })
      .or(z.literal('')),
    formation_country: z.string().min(1, { message: 'Country is required' }),
    formation_state: z.string().min(1, { message: 'State is required' }),
  });

  type EntityOnboardingFormSchemaPayload = z.infer<typeof EntityOnboardingFormSchema>;
  const { handleSubmit, control, formState, setValue, setError } = useForm<EntityOnboardingFormSchemaPayload>({
    resolver: zodResolver(EntityOnboardingFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;

  //State variables
  const [type, setType] = useState({
    id: '',
    name: '',
  });
  const [gPlacesAddressData, setGPlacesAddressData] = useState({} as GPlacesEntityAddressDataType);
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);
  const [disableSave, setDisableSave] = useState(false);

  //Redux
  const { assignedEntityDetails, addedEntity, isEditable } = useSelector(selectEntity);

  //UseEffect
  useEffect(() => {
    if (assignmentId) {
      setValue('entity_name', entityName, { shouldValidate: true });
      setValue('email', entityEmailId, { shouldValidate: true });
    }
  }, []);

  useEffect(() => {
    if (assignedEntityDetails) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  useEffect(() => {
    if (addedEntity?.trellis_uid) {
      dispatch(getEntityDetailById(addedEntity?.trellis_uid));
    }
  }, [addedEntity]);

  useEffect(() => {
    if (isEditable && selectedEntity) {
      const entType =
        ENTITY_TYPE.find((item) => item?.id === selectedEntity?.details?.entity_info?.entity_type) ||
        ({} as CountryType);
      setType(entType);
      setValue('entity_type', entType?.id, { shouldValidate: true });
      setValue('dba_name', selectedEntity?.details?.entity_info?.dba_name, { shouldValidate: true });
      setValue('email', selectedEntity?.email, { shouldValidate: true });
      setValue('entity_name', selectedEntity?.name || selectedEntity?.entity_name, { shouldValidate: true });
      setValue('website', selectedEntity?.details?.entity_info?.website, { shouldValidate: true });
      const address = {
        country: selectedEntity?.details?.entity_info?.formation_country,
        country_name: selectedEntity?.details?.entity_info?.formation_country_name,
        name: selectedEntity?.details?.entity_info?.formation_state_name,
        state: selectedEntity?.details?.entity_info?.formation_state,
        state_name: selectedEntity?.details?.entity_info?.formation_state_name,
      };
      setGPlacesAddressData(address);
      setValue('formation_country', address?.country_name, { shouldValidate: true });
      setValue('formation_state', address?.state_name, { shouldValidate: true });
    }
  }, [selectedEntity, isEditable]);

  useEffect(() => {
    if (isEditable) {
      setDisableSave(true);
    }
  }, [isEditable]);

  //Methods
  const handleTypeChange = (_event: React.SyntheticEvent, value: CountryType) => {
    if (value) {
      setType(value);
      setValue('entity_type', value?.id, { shouldValidate: true });
    } else {
      setType({ id: '', name: '' });
      setError('entity_type', { type: 'custom', message: 'Entity Type is required' });
    }
    setDisableSave(false);
  };

  const inputChange = () => {
    setDisableSave(false);
  };

  const onSubmit = async (formData: EntityOnboardingFormSchemaPayload) => {
    const payload: any = isEditable ? getEditPayload(formData) : getPayload(formData);
    isEditable
      ? await dispatch(editEntity({ entity_uid: selectedEntity?.trellis_uid, formData: payload }))
      : await dispatch(createEntity(payload));

    dispatch(setIsEditable(false));
    setIsEditClicked(false);
  };

  const getPayload = (formData: EntityOnboardingFormSchemaPayload) => {
    const payload = {
      entity_type: formData?.entity_type,
      email: formData?.email,
      entity_name: formData?.entity_name,
      dba_name: formData?.dba_name,
      website: formData?.website || '',
      formation_country: gPlacesAddressData?.country,
      formation_country_name: gPlacesAddressData?.country_name,
      formation_state: gPlacesAddressData?.state,
      formation_state_name: gPlacesAddressData?.state_name,
      merchant_entity_assignment_id: assignmentId,
    };
    return payload;
  };

  const getEditPayload = (formData: EntityOnboardingFormSchemaPayload) => {
    const payload: EditEntityType = {
      entity_info: {
        website: formData?.website || '',
        dba_name: formData?.dba_name,
        entity_type: formData?.entity_type,
        formation_country: gPlacesAddressData?.country,
        formation_country_name: gPlacesAddressData?.country_name,
        formation_state: gPlacesAddressData?.state,
        formation_state_name: gPlacesAddressData?.state_name,
      },
      name: formData?.entity_name,
      email: formData?.email,
    };
    return payload;
  };

  const setPlacesDetails = (data: GPlacesAddressType) => {
    const address = getAddressData(data) || ({} as GPlacesAddressType);
    setGPlacesAddressData(address);
    setValue('formation_country', address?.country_name, { shouldValidate: true });
    setValue('formation_state', address?.state_name, { shouldValidate: true });
  };

  return (
    <Box sx={{ py: theme.spacing(9), px: 5 }}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="entity_type"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TAutocomplete
                  {...field}
                  value={type}
                  options={ENTITY_TYPE || []}
                  disablePortal={true}
                  getOptionLabel={(option) => option?.name || ''}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  onChange={handleTypeChange}
                  renderInput={(params) => (
                    <TTextField
                      label={t('entityType')}
                      variant="outlined"
                      error={!!errors?.entity_type}
                      helperText={errors?.entity_type?.message}
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <>
                  <TTextField
                    label={t('email')}
                    variant="outlined"
                    fullWidth
                    // disabled={isEditable}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    {...field}
                    onKeyDown={() => setDisableSave(false)}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="entity_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <>
                  <TTextField
                    label={t('legalName')}
                    variant="outlined"
                    fullWidth
                    // disabled={isEditable}
                    error={!!errors?.entity_name}
                    helperText={errors?.entity_name?.message}
                    {...field}
                    onKeyUp={inputChange}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="dba_name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('dbaName')}
                  variant="outlined"
                  fullWidth
                  error={!!errors?.dba_name}
                  helperText={errors?.dba_name?.message}
                  {...field}
                  onKeyDown={() => setDisableSave(false)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="website"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('website')}
                  variant="outlined"
                  fullWidth
                  error={!!errors?.website}
                  helperText={errors?.website?.message}
                  {...field}
                  onKeyDown={() => setDisableSave(false)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="formation_state"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TGPlaces
                  fieldId={'state'}
                  placeholder={t('stateregionofFormation')}
                  fieldType={[]}
                  label={t('stateregionofFormation')}
                  variant="outlined"
                  fullWidth
                  sx={{ my: 1 }}
                  error={!!errors.formation_state}
                  helperText={errors.formation_state?.message}
                  onPlaceSelection={setPlacesDetails}
                  {...field}
                  onKeyDown={() => setDisableSave(false)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Controller
              name="formation_country"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label={t('countryOfFormation')}
                  variant="outlined"
                  fullWidth
                  sx={{ my: 1 }}
                  disabled
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box className="flex-basic-end" sx={{ mt: 3 }}>
          <TButton
            sx={{ mb: 2 }}
            btnText={'Save'}
            variant="contained"
            btnWidthSize="button-w-140"
            disabled={!isValid || disableSave}
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EntityOnboardingForm;
