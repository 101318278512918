import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NoDataPlaceHolder from '../myde-react-components/src/components/PlaceholderComponent/NoDataPlaceHolder';
import { theme } from '../myde-react-components';

const ProfileDetailsIndex = () => {
  //Constants
  const { t } = useTranslation(['common']);

  //State variables
  const [showProfileFoem, setShowProfileForm] = useState(false);

  //Methods
  const handleAddClick = () => {
    setShowProfileForm(true);
  };
  return (
    <Box sx={{ pt: theme.spacing(9), pl: 5 }} className="font-weight-semibold text-h2">
      Profile Details
      <NoDataPlaceHolder
        icon={'icon-profile-users'}
        isButton={true}
        buttonText="Add"
        placeholderText={t('noProfilesAdded')}
        onButtonClick={handleAddClick}
      />
    </Box>
  );
};

export default ProfileDetailsIndex;
