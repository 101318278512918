import React from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TCard, theme } from './../components/myde-react-components';
import clsx from 'clsx';

type AuthLayoutProp = {
  children: React.ReactNode;
};

export interface StyleProps {
  width: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  paper: {
    height: 'auto',
    minWidth: ({ width }) => width,
  },
}));

const AuthLayout = (props: AuthLayoutProp) => {
  const { children } = props;
  const match = useMediaQuery(theme.breakpoints.up('md'));
  const styleProps = {
    width: match ? '766px' : 'auto',
  };

  const classes = useStyles(styleProps);

  return (
    <>
      <Grid container spacing={0} className="flex-basic-center">
        <TCard sx={{ my: 5 }} className={clsx('border-none', classes.paper)}>
          {children}
        </TCard>
      </Grid>
    </>
  );
};

export default AuthLayout;
