import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControlLabel, Grid, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { debounce } from 'lodash';

import {
  DIALOG_WIDTH,
  MAX_CHAR_LIMIT,
  PdfViewer,
  PORTAL,
  TAlertDialog,
  TAutocomplete,
  TButton,
  TDialog,
  TRadio,
  TTextField,
} from '../myde-react-components';
import DocumentUploadAndListing from '../common/DocumentUploadAndListing';
import { CUSTOM_DOCUMENT_OPTIONS, DOCUMENT_UPLOAD_TYPE } from '../../constants/constants';
import {
  addCustomLibraryDocument,
  editCustomLibraryDocument,
  getLibraryDocumentList,
  selectMerchantInvite,
  setAddCustomLibraryDocument,
  setEditLibraryData,
} from '../../redux/feature/merchantInvite/merchantInviteSlice';
import { RepositoryResultType, UploadRepositoryResultType } from '../../types/repositoryTypes';
import { getRepositoryById, uploadLibraryDocument } from '../../api/repository';
import { FilePreviewType } from '../../types/documentTypes';
import {
  CustomLibraryDocumentResult,
  LibraryDocumentSelectionType,
  LibraryDocumentsListType,
} from '../../types/inviteTypes';
import { titleCase } from '../../utils/utils';
import { getCustomRequirementById } from '../../api/invitations';

// Interfaces
interface LibraryDocumentFormProps {
  open: boolean;
  libraryIdRequirements: LibraryDocumentSelectionType[];
  editLibraryRequirement: LibraryDocumentSelectionType;
  closeForm: () => void;
  openForm: () => void;
  addRequirement: (data: LibraryDocumentSelectionType) => void;
  updateRequirements: (data: LibraryDocumentSelectionType) => void;
}

// Styles
const useStyles = makeStyles({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '650px!important', // TODO: To be updated once new style guide changes will be done
        maxHeight: '843px!important',
        '& .MuiDialogActions-root': {
          padding: '0 !important',
        },
      },
    },
  },
});

const LibraryDocumentForm = ({
  open,
  libraryIdRequirements,
  editLibraryRequirement,
  closeForm,
  addRequirement,
  updateRequirements,
  openForm,
}: LibraryDocumentFormProps) => {
  // Constants
  const classes = useStyles();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  // Redux Values
  const { libraryDocumentList, addLibraryDocument, editLibraryData } = useSelector(selectMerchantInvite);

  // State Variables
  const [option, setOption] = useState('');
  const [libraryDocument, setLibraryDocument] = useState({} as RepositoryResultType);
  const [selectedLibraryDocument, setSelectedLibraryDocument] = useState([] as FilePreviewType[]);
  const [libraryInput, setLibraryInput] = useState('');
  const [title, setTitle] = useState('');
  const [titleCount, setTitleCount] = useState(0);
  const [documentIdList, setDocumentIdList] = useState([] as string[]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Form Schema Setup
  const LibraryFormSchema = z.object({
    document: z.string().min(1, { message: 'Library Document is required' }),
    title: editLibraryRequirement?.process_id
      ? z.string().min(1, { message: 'Title is required' })
      : z
          .string()
          .min(1, { message: 'Title is required' })
          .refine(
            (value) =>
              !libraryIdRequirements?.some(
                (item: LibraryDocumentSelectionType) =>
                  titleCase(item?.process_name).toUpperCase() === titleCase(value).toUpperCase(),
              ),
            { message: 'Title should be unique' },
          ),
  });

  type LibraryFormSchemaPayload = z.infer<typeof LibraryFormSchema>;

  const { handleSubmit, control, formState, reset, setValue, setError } = useForm<LibraryFormSchemaPayload>({
    resolver: zodResolver(LibraryFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // Use Effect
  useEffect(() => {
    clearStates();
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(getLibraryDocumentList({ category: DOCUMENT_UPLOAD_TYPE.LIBRARY }));
      reset();
      clearStates();
    }
  }, [open]);

  useEffect(() => {
    editLibraryRequirementData(editLibraryRequirement);
  }, [editLibraryRequirement]);

  // Methods
  const clearStates = () => {
    setLibraryDocument({} as RepositoryResultType);
    setTitle('');
    setTitleCount(0);
    setValue('title', '', { shouldValidate: false });
    setValue('document', '', { shouldValidate: false });
    setOption('');
    setDocumentIdList([]);
    setSelectedLibraryDocument([]);
  };

  const editLibraryRequirementData = async (data: LibraryDocumentSelectionType) => {
    if (data?.process_id) {
      const specificLibraryRequirement = await getCustomRequirement(data?.process_id);
      setFormValues(specificLibraryRequirement, data?.process_name);
    }
  };

  const getCustomRequirement = async (id: string) => {
    return (await getCustomRequirementById(id)) || ({} as CustomLibraryDocumentResult);
  };
  const setUploadedFileData = async (data: any[]) => {
    setIsUploading(true);
    if (data?.length > 0) {
      const formData = new FormData();
      formData?.append('file', data[0]);
      formData?.append('category', DOCUMENT_UPLOAD_TYPE.OTHER);
      const result = (await uploadLibraryDocument(formData)) || ({} as UploadRepositoryResultType);
      const fileData: FilePreviewType = {
        id: result?.id,
        name: result?.doc_name,
        size: result?.size_in_kb,
        type: result?.mime_type,
        preview: data[0]?.preview,
        isDeleteAllowed: true,
      };
      setSelectedLibraryDocument((previousDocument) => [...previousDocument, fileData]);
      setDocumentIdList((previousIdList) => [...previousIdList, result?.id]);
    } else {
      const previousDocumentData = [...selectedLibraryDocument];
      const previousIdList = [...documentIdList];
      previousDocumentData.splice(1, 1); // As we are uploading and deleting the last doc id
      previousIdList.splice(1, 1); // As we are uploading and deleting the last doc id
      setSelectedLibraryDocument([...previousDocumentData]);
      setDocumentIdList([...previousIdList]);
    }
    setIsUploading(false);
  };

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption(event?.target?.value);
  };

  const handleChange = async (_event: React.SyntheticEvent, value: RepositoryResultType) => {
    if (value?.id) {
      setLibraryDocument(value);
      handleTitleChange(value?.doc_name);
      const specificLibraryDocument = (await getRepositoryById(value?.id)) || ({} as RepositoryResultType);
      const payload = {
        id: specificLibraryDocument?.document?.doc_id,
        name: specificLibraryDocument?.document?.doc_name,
        type: specificLibraryDocument?.document?.mime_type,
        preview: specificLibraryDocument?.document?.signed_url,
        size: specificLibraryDocument?.document?.size_in_kb,
        isDeleteAllowed: false,
      };
      setDocumentIdList([specificLibraryDocument?.id]);
      setSelectedLibraryDocument([payload]);
      setValue('document', value?.id, { shouldValidate: true });
    } else {
      setLibraryDocument({} as RepositoryResultType);
      handleTitleChange('');
      setError('document', { type: 'custom', message: 'Library Document is required' });
    }
  };

  const handleLibraryInput = debounce((_event: React.SyntheticEvent, newValue: string) => {
    setLibraryInput(newValue);
    const payload = {
      doc_name: newValue,
      category: DOCUMENT_UPLOAD_TYPE.LIBRARY,
    };
    dispatch(getLibraryDocumentList(payload));
  }, 50);

  const handleTitleChange = (value: string) => {
    setTitle(value);
    setTitleCount(value?.length);
    setValue('title', value, { shouldValidate: true });
  };

  const setDocumentDetails = async (documentDetails: LibraryDocumentsListType[]) => {
    const documentsData = [] as FilePreviewType[];
    await Promise.all(
      documentDetails?.map(async (item, index) => {
        const result = (await getRepositoryById(item?.id)) || ({} as RepositoryResultType);
        const payload = {
          id: result?.id,
          name: result?.document?.doc_name,
          type: result?.document?.mime_type,
          preview: result?.document?.signed_url,
          size: result?.document?.size_in_kb,
          isDeleteAllowed: index === 0 ? false : true,
        };
        documentsData.push(payload);
      }),
    );
    const idList = documentDetails?.map((item: LibraryDocumentsListType) => item?.id) || ([] as string[]);
    setDocumentIdList(idList);
    setSelectedLibraryDocument([...documentsData]);
  };

  const setFormValues = async (data: CustomLibraryDocumentResult, postfixTitle: string) => {
    setDocumentDetails(data?.library_documents);
    const specificLibraryDocument =
      (await getRepositoryById(data?.library_document_id_list[0])) || ({} as RepositoryResultType);
    setLibraryDocument(specificLibraryDocument);
    handleTitleChange(postfixTitle);
    setValue('document', specificLibraryDocument?.doc_name, { shouldValidate: true });
    const selectedOption = data?.is_esign_required ? CUSTOM_DOCUMENT_OPTIONS.ESIGN : CUSTOM_DOCUMENT_OPTIONS.VIEW_ONLY;
    setOption(selectedOption);
  };

  const onSubmit = async () => {
    const formData = {
      process_name: title,
      process_description: '',
      process_type: DOCUMENT_UPLOAD_TYPE.LIBRARY,
      is_esign_required: option === CUSTOM_DOCUMENT_OPTIONS.ESIGN,
      is_view_required: option === CUSTOM_DOCUMENT_OPTIONS.VIEW_ONLY,
      library_document_id_list: documentIdList,
    };
    if (isEdit || editLibraryData?.process_id) {
      const editCustomPayload = {
        id: addLibraryDocument?.id || editLibraryRequirement?.process_id,
        payload: formData,
      };
      await dispatch(editCustomLibraryDocument(editCustomPayload));
    } else {
      await dispatch(addCustomLibraryDocument(formData));
    }
    reset();
    closeForm();
    setShowPreviewModal(true);
  };

  const submitLibraryDocument = () => {
    const payload = {
      process_id: addLibraryDocument?.id,
      process_name: addLibraryDocument?.process_name,
      access_level: false,
      library_doc_id: addLibraryDocument?.library_document_id_list[0],
      document_name_list: addLibraryDocument?.library_documents?.map((item) => item?.doc_name).reverse() || [],
      is_checked: true,
    };
    if (isEdit || editLibraryData?.process_id) {
      updateRequirements(payload);
    } else {
      addRequirement(payload);
    }
    dispatch(setAddCustomLibraryDocument({} as CustomLibraryDocumentResult));
    setIsEdit(false);
    dispatch(setEditLibraryData({} as LibraryDocumentSelectionType));
    setShowPreviewModal(false);
  };

  const goToEdit = async () => {
    setShowPreviewModal(false);
    setIsEdit(true);
    openForm();
    setFormValues(addLibraryDocument, addLibraryDocument?.process_name);
  };

  // HTML
  return (
    <>
      <TDialog
        className={classes.dialogStyle}
        open={open}
        portalName={PORTAL.MERCHANT}
        title={t('libraryDocumentLabel')}
        maxWidth={'xs'}
        onClose={closeForm}
      >
        <Box sx={{ my: 2 }}>
          <form>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12}>
                <Controller
                  name="document"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TAutocomplete
                      {...field}
                      value={libraryDocument}
                      inputValue={libraryInput}
                      options={libraryDocumentList}
                      disablePortal={true}
                      onChange={handleChange}
                      onInputChange={handleLibraryInput}
                      getOptionLabel={(option) => option?.doc_name || ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => (
                        <TTextField
                          label="Select a document from the library"
                          variant="outlined"
                          error={!!errors?.document}
                          helperText={errors?.document?.message}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <Controller
                  name="title"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TTextField
                      {...field}
                      value={title}
                      fullWidth
                      label="Merchant Standard"
                      variant="outlined"
                      inputProps={{ maxLength: MAX_CHAR_LIMIT.BASIC_CHAR_LENGTH }}
                      error={!!errors.title}
                      helperText={errors.title?.message}
                      onChange={(event) => handleTitleChange(event?.target?.value)}
                    />
                  )}
                />
                <Box sx={{ mt: 1 }} className=" flex-basic-end">
                  <Box className="text-small font-weight-semibold textColor-300">{`${
                    MAX_CHAR_LIMIT.BASIC_CHAR_LENGTH - titleCount
                  } character(s) remaining`}</Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ my: 2 }}>
              <DocumentUploadAndListing
                label={t('uploadDocument')}
                submittedFiles={selectedLibraryDocument}
                setSingleFile={setUploadedFileData}
                isUploading={isUploading}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <Box className="text-medium font-weight-semibold textColor-200" sx={{ mb: 1 }}>
                {t('optionSelectionText')}
              </Box>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={option}
                onChange={handleSelectionChange}
              >
                <FormControlLabel
                  value={CUSTOM_DOCUMENT_OPTIONS.ESIGN}
                  control={<TRadio />}
                  label={t('esignDocument')}
                />
                <FormControlLabel
                  value={CUSTOM_DOCUMENT_OPTIONS.VIEW_ONLY}
                  control={<TRadio />}
                  label={t('readOnlyDocument')}
                />
              </RadioGroup>
            </Box>
            <Box className="flex-basic-end">
              <TButton btnText="Cancel" btnWidthSize="button-w-140" variant="text" onClick={closeForm} sx={{ mr: 3 }} />
              <TButton
                btnText={'Next'}
                btnWidthSize="button-w-140"
                variant="contained"
                disabled={!isValid || isSubmitting || isUploading || !option || documentIdList?.length === 0}
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          </form>
        </Box>
      </TDialog>
      <TAlertDialog
        showDialog={showPreviewModal}
        onCancel={goToEdit}
        onConfirm={submitLibraryDocument}
        noBtnTitle={t('editLabel')}
        yesBtnTitle={t('submitLabel')}
        title={t('previewLabel')}
        content={''}
        portalName={PORTAL.MERCHANT}
        dialogWidth={`${DIALOG_WIDTH.MERCHANT_PORTAL_MEDIUM}px !important`}
        onClose={() => setShowPreviewModal(false)}
        extraContent={
          <PdfViewer maxArea={true} domain={PORTAL.MERCHANT} uri={addLibraryDocument?.merged_document?.signed_url} />
        }
      />
    </>
  );
};

export default LibraryDocumentForm;
