import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { selectUser } from '../../../../../redux/feature/user/userSlice';
import { FEATURE_ROLES } from '../../constants/features';

interface FeatureRbacProps {
  currentFeature: string;
  children: any;
}

export const FeatureRbac = ({ currentFeature, children }: FeatureRbacProps) => {
  // Redux Values
  const { userRoles } = useSelector(selectUser);

  // State Values
  const [currentRoles, setCurrentRoles] = useState([] as string[]);
  const [isFeatureAllowed, setIsFeatureAllowed] = useState(false);

  // Use Effects
  useEffect(() => {
    if (currentFeature && currentRoles?.length > 0) {
      checkFeatureAllowed();
    }
  }, [currentFeature, currentRoles]);
  useEffect(() => {
    setCurrentRoles(userRoles);
  }, [userRoles]);

  // Methods
  const checkFeatureAllowed = () => {
    const currentFeatureDetails = FEATURE_ROLES.find((feature) => feature.featureName === currentFeature);
    const flag = currentFeatureDetails?.allowedRoles.some((featureRole) =>
      currentRoles.includes(featureRole?.toLowerCase()),
    );
    setIsFeatureAllowed(flag || false);
  };

  return <>{isFeatureAllowed && <Box>{children}</Box>}</>;
};

export default FeatureRbac;
