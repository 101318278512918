import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import * as z from 'zod';

import { TButton, TDialog, TTextField } from '../myde-react-components';
import { InviteRecipientsType } from '../../types/inviteTypes';
import { titleCase } from '../../utils/utils';

interface RecipientFormProps {
  open: boolean;
  isEntityInvite: boolean;
  closeForm: () => void;
  recipients: Array<InviteRecipientsType>;
  addRecipient: (data: InviteRecipientsType) => void;
}

const useStyles = makeStyles({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        '& .MuiDialogActions-root': {
          padding: '0 !important',
        },
      },
    },
  },
});

const RecipientForm = ({ open, isEntityInvite, closeForm, recipients, addRecipient }: RecipientFormProps) => {
  // Form Schema Setup
  const RecipientFormSchema = z.object({
    first_name: z.string(),
    middle_name: z.string(),
    last_name: z.string(),
    name: z.string().min(1, { message: 'Entity Name is required' }),
    email: z
      .string()
      .min(1, { message: 'Email is required' })
      .email()
      .refine(
        (value) =>
          !recipients?.some(
            (item: InviteRecipientsType) => titleCase(item?.email).toUpperCase() === titleCase(value).toUpperCase(),
          ),
        {
          message: 'Email should be unique',
        },
      ),
  });
  type RecipientFormSchemaPayload = z.infer<typeof RecipientFormSchema>;

  const { handleSubmit, control, formState, reset } = useForm<RecipientFormSchemaPayload>({
    resolver: zodResolver(RecipientFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // constants
  const classes = useStyles();
  const { t } = useTranslation('common');

  // Methods
  const onSubmit = async (formData: RecipientFormSchemaPayload) => {
    addRecipient(formData);
    reset();
    closeForm();
  };

  // use effect
  useEffect(() => {
    reset();
  }, [open]);

  return (
    <TDialog
      className={classes.dialogStyle}
      open={open}
      title={isEntityInvite ? t('addEntityLabel') : t('addIndividualUser')}
      maxWidth={'xs'}
    >
      <Box>
        <form>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 1 }}>
              <Controller
                name="first_name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    isOptional
                    label="First Name"
                    variant="outlined"
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 1 }}>
              <Controller
                name="middle_name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    isOptional
                    label="Middle Name"
                    variant="outlined"
                    error={Boolean(errors.middle_name)}
                    helperText={errors.middle_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 1 }}>
              <Controller
                name="last_name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    isOptional
                    label="Last Name"
                    variant="outlined"
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 1 }} className={!isEntityInvite ? 'showNone' : ''}>
              <Controller
                name="name"
                defaultValue={!isEntityInvite ? 'Entity Name' : ''}
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    label="Entity Name"
                    variant="outlined"
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 4 }}>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    label="Email Address"
                    variant="outlined"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box className="flex-basic-end" sx={{ mb: 1 }}>
            <TButton btnText="Cancel" btnWidthSize="button-w-140" variant="text" onClick={closeForm} sx={{ mr: 3 }} />
            <TButton
              btnText="Add"
              btnWidthSize="button-w-140"
              variant="contained"
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </Box>
    </TDialog>
  );
};

export default RecipientForm;
