import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';

import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { AccreditationResultType } from '../../../types/applicantTypes';

const initialState = {
  loading: false,
  error: false,
  accreditationDetail: <AccreditationResultType>{},
};

export const selectAccreditationIdProcess = ({ accreditation }: RootState) => ({
  accreditationDetail: accreditation.accreditationDetail,
  loading: accreditation.loading,
  error: accreditation.error,
});

//actions
export const getAccreditationDetails = createAsyncThunk(
  'accreditation/getAccreditationDetails',
  async (data: { applicationId: string; processId: number }) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

//reducer
export const accreditationSlice = createSlice({
  name: 'accreditation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccreditationDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAccreditationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.accreditationDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getAccreditationDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default accreditationSlice.reducer;
