import moment from 'moment';
import { ENTITY_PROCESS_IDS } from '../components/myde-react-components';
import { ACCREDITATION_DESCRIPTION_HANDLE, W9_W8_BEN_DOCUMENT_DESCRIPTION_HANDLE } from '../constants/constants';
import { ApplicationType } from '../types/applicantTypes';

export const titleCase = (value: string) => {
  if (!value) {
    return '';
  }
  return value.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));
};

export const formatDate = (date: string) => {
  return date && moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : '';
};

export const getTimeStamp = (date: string) => {
  return moment(date).isValid() ? moment(date).format('hh:mm a') : '';
};

export const getInitials = (text: string) => {
  return text
    ? text
        .split(' ')
        .map((v) => v.slice(0, 1))
        .join('')
        .toUpperCase()
    : '';
};

export const removeUnderscore = (text: string) => {
  return text
    ? text.replace(/(?:_| |\b)(\w)/g, function ($1) {
        return $1.toUpperCase().replace('_', ' ');
      })
    : '';
};

export const convertBytesToKb = (value: number) => {
  return value ? Math.round(value / Math.pow(1024, 1)) : 0;
};

export const getDate = () => {
  const today = new Date();
  const dayNum = today.getDate();
  const monthNum = today.getMonth() + 1;
  const yearNum = today.getFullYear();
  let day = dayNum.toString();
  let month = monthNum.toString();
  if (dayNum < 10) {
    day = '0' + dayNum;
  }

  if (monthNum < 10) {
    month = '0' + monthNum;
  }
  return `${yearNum}-${month}-${day}`;
};

export const getDescriptionHandle = (processId: number, idProcessDetails: any, applicantionDetail: ApplicationType) => {
  switch (processId) {
    case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
      if (applicantionDetail?.account?.is_premium) {
        return 'irsLetterDiscriptionForPremiumUser';
      } else {
        return 'irsLetterDiscription';
      }

    case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
      return 'formationDocumentDiscription';

    case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
      return 'letterOfGoodStandingDescription';

    case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT:
      return 'ownershipDocumentDescription';

    case ENTITY_PROCESS_IDS.ACCREDITATION:
      if (idProcessDetails?.data?.accreditation_status) {
        return ACCREDITATION_DESCRIPTION_HANDLE[idProcessDetails?.data?.accreditation_status];
      } else {
        return 'accreditationDescription';
      }

    case ENTITY_PROCESS_IDS.W8_W9_DOCUMENT:
      return W9_W8_BEN_DOCUMENT_DESCRIPTION_HANDLE[idProcessDetails?.data?.document_type];

    default:
      return '';
  }
};
