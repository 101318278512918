import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getAccessToken } from '../utils/storage';
import { clearResults } from '../redux/feature/common/commonSlice';
import { clearStorage } from '../utils/storage';
import * as ROUTES from '../constants/routes';
import { useNavigate } from 'react-router-dom';

type AuthProviderType = {
  accessToken: string;
  login: (authToken: string) => void;
  logout: () => any;
};

export const useAuthProvider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string>(getAccessToken());

  const login = (authToken: string) => {
    setAccessToken(authToken);
  };

  const logout = () => {
    clearStorage();
    dispatch(clearResults());
    navigate(ROUTES.LOGIN);
  };
  return {
    accessToken,
    login,
    logout,
  };
};
const defaultAuthContext = {};

const AuthContext = createContext<AuthProviderType>(defaultAuthContext as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: any }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth as any}>{children}</AuthContext.Provider>;
};
