import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PDF_MIME_TYPE, PDF_SUPPORTED_TYPE, ENTITY_PROCESS_IDS, APPLICATION_STATUS } from '../myde-react-components';
import { selectEntity, submitEntityIdProcess } from '../../redux/feature/id-process/entity/entitySlice';
import { useTranslation } from 'react-i18next';
import UploadIdProcessDoc from './components/UploadIdProcessDoc';
import { FilePreviewType } from '../../types/documentTypes';

const LetterOfGoodStanding = () => {
  // Constants
  const { t } = useTranslation(['common', 'entity']);
  const dispatch = useDispatch();

  //Redux Values
  const { specificEntityIdProcessDetails, loading, successfulIdProcessDetails, assignedEntityDetails } =
    useSelector(selectEntity);

  // State Values
  const [resetUploadedFileFlag, setResetUploadedFileFlag] = useState(false);
  const [existingFileList, setExistingFileList] = useState([] as FilePreviewType[]);

  // Use Effects
  useEffect(() => {
    if (
      specificEntityIdProcessDetails?.data?.document_id_list &&
      specificEntityIdProcessDetails?.data?.document_id_list[0]?.doc_id
    ) {
      const previousUploadedFile = specificEntityIdProcessDetails?.data?.document_id_list[0];
      const file: FilePreviewType = {
        id: previousUploadedFile?.doc_id,
        name: previousUploadedFile?.doc_name,
        size: previousUploadedFile?.size_in_kb,
        type: previousUploadedFile?.mime_type,
        preview: previousUploadedFile?.signed_url,
        isDeleteAllowed: true,
      };
      setExistingFileList([file]);
    } else {
      setExistingFileList([]);
    }
  }, [specificEntityIdProcessDetails]);
  useEffect(() => {
    setResetUploadedFileFlag(Object.keys(successfulIdProcessDetails)?.length > 0);
  }, [successfulIdProcessDetails]);

  //Methods
  const handleSave = (docList: any[]) => {
    //Save API
    const documentId = docList[0]?.doc_id || docList[0]?.id;
    const payload = {
      process_id: ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING,
      process_data: {
        document_id_list: [documentId],
      },
      action: APPLICATION_STATUS.SUBMIT,
    };
    // TODO:: Need to update entity_uid
    dispatch(submitEntityIdProcess({ entity_uid: assignedEntityDetails?.trellis_uid, payload }));
  };

  // HTML
  return (
    <UploadIdProcessDoc
      pageTitle={t('letterOfGoodStandingHeading', { ns: 'entity' })}
      acceptedFileFormat={PDF_MIME_TYPE}
      supportedFileFormat={PDF_SUPPORTED_TYPE}
      existingFileList={existingFileList}
      loading={loading}
      resetUploadedFile={resetUploadedFileFlag}
      onSave={handleSave}
    />
  );
};

export default LetterOfGoodStanding;
