import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider, Grid, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';

import { PORTAL, TAlertDialog, TButton, TCard, TTextField } from '../../components/myde-react-components';
import RespositoriesList from '../../components/repositories/RepositoriesList';
import { ADD_DOCUMENT_DIALOG_WIDTH, DOCUMENT_UPLOAD_TYPE, MAX_TAG_LENGTH } from '../../constants/constants';
import DocumentUploadAndListing from '../../components/common/DocumentUploadAndListing';
import {
  getRepositoryList,
  getSearchedTitle,
  selectRepository,
  setSearchedDocTitle,
  uploadLibraryDocuments,
} from '../../redux/feature/repositories/repositorySlice';
import { RepositoryResultType } from '../../types/repositoryTypes';
import { titleCase } from '../../utils/utils';
import { FilePreviewType } from '../../types/documentTypes';

const ManageRespositoriesIndex = () => {
  // Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux values
  const { searchedDocTitle } = useSelector(selectRepository);

  // State Variables
  const [searchValue, setSearchValue] = useState('');
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const [document, setDocument] = useState([] as any[]);
  const [isListUpdated, setIsListUpdated] = useState(false);
  const [searchedDocumentList, setSearchedDoumentList] = useState([] as RepositoryResultType[]);

  //Use Effects
  useEffect(() => {
    dispatch(getSearchedTitle());
  }, []);

  useEffect(() => {
    if (searchedDocTitle?.length > 0) {
      setSearchedDoumentList(searchedDocTitle);
    }
  }, [searchedDocTitle]);

  //Schema
  const ManageRespositoriesSchema = z.object({
    doc_name: z
      .string()
      .min(1, { message: 'Title is required' })
      .refine(
        (value) =>
          !searchedDocumentList.some(
            (el: RepositoryResultType) =>
              titleCase(el?.doc_name).toUpperCase().trim() === titleCase(value).toUpperCase().trim(),
          ),
        {
          message: 'Title should be unique',
        },
      ),
  });

  type ManageRespositoriesSchemaPayload = z.infer<typeof ManageRespositoriesSchema>;
  const { control, formState, reset, setValue } = useForm<ManageRespositoriesSchemaPayload>({
    resolver: zodResolver(ManageRespositoriesSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;

  // Methods
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('doc_name', event?.target?.value, { shouldValidate: true });
    setDocumentTitle(event?.target?.value);
    handleTitleChange(event?.target?.value);
  };

  const handleTitleChange = (value: string) => {
    const data = {
      doc_name: value,
    };
    dispatch(getSearchedTitle(data));
  };

  const handleFilterChange = debounce((value: string) => {
    setSearchValue(value);
  }, 100);

  const setUploadedFileData = (data: any[]) => {
    if (data?.length > 0) {
      setDocument(data);
    } else {
      setDocument([] as FilePreviewType[]);
    }
  };

  const handleAddNewClick = () => {
    setShowDocumentDialog(true);
    setIsListUpdated(false);
  };

  const onCancel = async () => {
    setDocumentTitle('');
    setShowDocumentDialog(false);
    setDocument([]);
    reset();
    dispatch(setSearchedDocTitle([]));
  };

  const getLibraryDocumentPayload = () => {
    const formData = new FormData();
    formData?.append('file', document[0]);
    formData?.append('category', DOCUMENT_UPLOAD_TYPE.LIBRARY);
    formData?.append('doc_name', documentTitle);
    return formData;
  };

  const onConfirm = async () => {
    const payload = getLibraryDocumentPayload();
    await dispatch(uploadLibraryDocuments(payload));
    setDocumentTitle('');
    setShowDocumentDialog(false);
    setDocument([]);
    const data = {
      page: 1,
      limit: 10,
    };
    await dispatch(getRepositoryList(data));
    reset();
    dispatch(setSearchedDocTitle([]));
    setIsListUpdated(true);
  };

  const onClose = () => {
    onCancel();
  };

  return (
    <Container sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <TCard>
            <Box sx={{ py: 4 }} className="flex-basic-space-between">
              <Box sx={{ px: 5 }} className="text-h2 font-weight-semibold textColor-200">
                {t('manageRepository')}
              </Box>
              <Box sx={{ px: 4 }}>
                <Box className="flex-basic-center">
                  <Box sx={{ mr: 2 }}>
                    <TTextField
                      variant="outlined"
                      value={searchValue}
                      placeholder="Search by Title"
                      onChange={(event) => handleFilterChange(event?.target?.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span className="icon-search textColor-400" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <TButton
                    btnText="Add New"
                    btnWidthSize="button-w-140"
                    variant="contained"
                    onClick={handleAddNewClick}
                  />
                </Box>
                <TAlertDialog
                  showDialog={showDocumentDialog}
                  title={t('addLibraryDocuments')}
                  portalName={PORTAL.MERCHANT}
                  content=""
                  onCancel={onCancel}
                  onConfirm={onConfirm}
                  noBtnTitle="Cancel"
                  yesBtnTitle="Add"
                  onClose={onClose}
                  isButtonDisabled={!document?.length || !isValid}
                  dialogWidth={`${ADD_DOCUMENT_DIALOG_WIDTH}px!important`}
                  extraContent={
                    <Box>
                      <Box sx={{ pb: 1 }} className="text-medium font-weight-semibold">
                        {t('titleLabel')}
                      </Box>
                      <Controller
                        name="doc_name"
                        control={control}
                        render={({ field }) => (
                          <TTextField
                            {...field}
                            fullWidth
                            value={documentTitle}
                            label="Add Text"
                            error={Boolean(errors.doc_name)}
                            helperText={errors.doc_name?.message}
                            onChange={handleTextFieldChange}
                            inputProps={{ maxLength: MAX_TAG_LENGTH }}
                          />
                        )}
                      />
                      <Box sx={{ my: 3 }}>
                        <DocumentUploadAndListing
                          label={t('uploadDocument')}
                          setSingleFile={setUploadedFileData}
                          submittedFiles={document}
                        />
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 4 }}>
              <RespositoriesList isListUpdated={isListUpdated} searchValue={searchValue} />
            </Box>
          </TCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManageRespositoriesIndex;
