import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { getDate } from '../../../utils/utils';
import { TDatePicker, TTextField, TButton, theme } from '../../myde-react-components';
import { FilterFormSchemaPayload, FilterFormSchema } from './validation';
import { makeStyles } from '@mui/styles';
import { fontStyle } from '../../../styles/style';
import { useSelector } from 'react-redux';
import { selectApplicationMessage } from '../../../redux/feature/applicant/applicantMessageSlice';
import clsx from 'clsx';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  dialogTitle: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font500,
  },
});

export interface SearchComponentProps {
  onSubmit: (formData: FilterFormSchemaPayload) => void;
  handleClose: () => void;
}

const RangeFilterForm = ({ onSubmit, handleClose }: SearchComponentProps) => {
  const classes = useStyles();
  const { handleSubmit, control, formState, setValue, watch } = useForm<FilterFormSchemaPayload>({
    resolver: zodResolver(FilterFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;
  const { filterSet } = useSelector(selectApplicationMessage);

  const currentDate = getDate();

  const dateFromHolder = watch('dateFrom');

  //  useEffect
  useEffect(() => {
    if (filterSet.modified_on_gte) {
      setValue('dateFrom', filterSet.modified_on_gte);
    }
    if (filterSet.modified_on_lte) {
      setValue('dateTill', filterSet.modified_on_lte);
    }
  }, []);

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Box className="flex-basic-space-between" sx={{ pb: theme.spacing(3), pt: 1 }}>
          <Box className="text-h2 font-weight-semibold textColor-200">Filters</Box>
        </Box>
        <form>
          <Box sx={{ mt: 1, mb: 3 }}>
            <Controller
              name="dateFrom"
              defaultValue={currentDate}
              control={control}
              render={({ field }) => (
                <TDatePicker
                  label="Date from"
                  variant="outlined"
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  clearable={true}
                  renderInput={(params) => (
                    <TTextField
                      {...params}
                      fullWidth={true}
                      error={Boolean(errors.dateFrom)}
                      helperText={errors.dateFrom?.message}
                    />
                  )}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Controller
              name="dateTill"
              defaultValue={currentDate}
              control={control}
              render={({ field }) => (
                <TDatePicker
                  label="Date till"
                  variant="outlined"
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  disabled={dateFromHolder === currentDate}
                  clearable={true}
                  renderInput={(params) => (
                    <TTextField
                      {...params}
                      fullWidth={true}
                      error={Boolean(errors.dateTill)}
                      helperText={errors.dateTill?.message}
                    />
                  )}
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            sx={{ mt: 1, pb: 2 }}
            className={clsx({
              [classes.buttonContainer]: true,
              ['customModalFooter']: true,
            })}
          >
            <TButton btnText="Cancel" btnWidthSize="button-w-140" variant="text" onClick={() => handleClose()} />
            <TButton
              btnText="Apply"
              btnWidthSize="button-w-140"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

RangeFilterForm.propTypes = {};

export default RangeFilterForm;
