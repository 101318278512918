import React, { useEffect, useState } from 'react';
import AccountSummaryCard, { SummaryListType } from '../common/messageNotes/AccountSummaryCard';
import UserTagModal from './UserTagModal';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationMerchantTags, selectApplicationTag } from '../../redux/feature/applicant/applicantTagSlice';
import { ResultTagType } from '../../types/applicantTagTypes';

const UserTagSummary = () => {
  //constants
  const dispatch = useDispatch();
  const [showDialog, setDialogFlag] = useState(false);
  const [summaryList, setSummaryList] = useState<Array<SummaryListType>>([]);
  const { merchantTagList, savedTags } = useSelector(selectApplicationTag);
  const tags = merchantTagList?.results;
  const savedUserTags = savedTags?.results;

  const defaultSummary: SummaryListType[] = [
    {
      label: 'No Tags Available',
    },
  ];

  //useEffects
  useEffect(() => {
    setSummaryList(tempArray);
  }, [savedTags]);

  useEffect(() => {
    dispatch(getApplicationMerchantTags());
  }, []);

  let tempArray =
    savedUserTags?.map((item: ResultTagType) => ({
      label: item.tag_name,
    })) || defaultSummary;

  if (tempArray.length === 0) {
    tempArray = defaultSummary;
  }

  return (
    <>
      <AccountSummaryCard
        isScrollable={true}
        title="Tags"
        isCursorPointer={false}
        summaryList={summaryList}
        isTagsList={true}
        headerBtnTxt="Manage Tags"
        headerIcon="icon-Property font-size-16"
        onHeaderBtnClick={() => {
          setDialogFlag(true);
        }}
      />

      <UserTagModal
        tagsList={tags}
        openDialog={showDialog}
        onClose={() => {
          setDialogFlag(false);
        }}
      />
    </>
  );
};

export default UserTagSummary;
