import React from 'react';
import { Box, Grid } from '@mui/material';

import { TBadge } from '../myde-react-components';
import { EntityDetail } from '../../types/entityTypes';
import { ENTITY_INVITE_STATUS } from '../../constants/constants';

interface EntitySummaryCardProps {
  entitiesList: EntityDetail[];
}

const EntitySummaryCard = ({ entitiesList }: EntitySummaryCardProps) => {
  return (
    <Box sx={{ p: 2 }} className="entitySummaryContainer">
      {entitiesList?.map((item: EntityDetail) => {
        return (
          <Box sx={{ my: 2 }} key={item?.id}>
            <Grid container direction="row">
              <Grid item md={9}>
                <Box className="text-large text-ellipsis">{item?.entity_name}</Box>
              </Grid>
              <Grid item md={3}>
                {item?.status === ENTITY_INVITE_STATUS.INPROGRESS && (
                  <Box>
                    <Box className="flex-basic-center align-items-end">
                      <TBadge
                        bagdeFontStyle={'text-small font-weight-medium'}
                        showBagdeThemeColor={true}
                        label={'In Progress'}
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default EntitySummaryCard;
