import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, FormControl } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { getDate } from '../../../utils/utils';
import { TDatePicker, TTextField, TButton, TSelect, theme } from '../../myde-react-components';
import { makeStyles } from '@mui/styles';
import { fontStyle } from '../../../styles/style';
import { useSelector } from 'react-redux';
import { selectApplicant } from '../../../redux/feature/applicant/applicantSlice';
import { NotesFilterFormSchemaPayload, NotesFilterFormSchema } from './notesFilterValidation';
import { FilterFormSchemaPayload } from './validation';
import { selectApplicantNotes } from '../../../redux/feature/applicant/applicantAccountNotesSlice';
import clsx from 'clsx';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  dialogTitle: {
    ...fontStyle.fontSize.font18,
    ...fontStyle.fontWeight.font500,
  },
});

export interface SearchComponentProps {
  onSubmit: (formData: FilterFormSchemaPayload) => void;
  handleClose: () => void;
}

const defaultSection = {
  label: 'All',
  id: 'All',
};

const NotesFilterForm = ({ onSubmit, handleClose }: SearchComponentProps) => {
  const classes = useStyles();
  const { handleSubmit, control, formState, watch, setValue } = useForm<NotesFilterFormSchemaPayload>({
    resolver: zodResolver(NotesFilterFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid } = formState;
  const { applicationIdProcessList } = useSelector(selectApplicant);
  const currentDate = getDate();
  const [sectionList, setSectionList] = React.useState([defaultSection]);
  const dateFromHolder = watch('dateFrom');
  const { allNotesList, noteFilterSet } = useSelector(selectApplicantNotes);

  useEffect(() => {
    const arr = [defaultSection];
    const unique = [...new Set(allNotesList.map((item) => item.section))];
    unique?.filter((note) => {
      const obj = {
        label: note,
        id: note,
      };
      arr.push(obj);
    });

    setSectionList(arr);
  }, [applicationIdProcessList, allNotesList]);

  useEffect(() => {
    const sectionHolder = noteFilterSet?.section === '' ? 'All' : noteFilterSet?.section;
    setValue('section', sectionHolder || 'All');
    if (noteFilterSet?.modified_on_gte) {
      setValue('dateFrom', noteFilterSet?.modified_on_gte);
    }
    if (noteFilterSet?.modified_on_lte) {
      setValue('dateTill', noteFilterSet?.modified_on_lte);
    }
  }, []);

  return (
    <>
      <Box sx={{ mt: 3, pb: theme.spacing(3) }}>
        <Box className="flex-basic-space-between" sx={{ pb: theme.spacing(5), pt: 1 }}>
          <Box className="text-h2 font-weight-semibold textColor-200">Filters</Box>
        </Box>
        <form>
          <Box sx={{ mb: 3 }}>
            <Controller
              name="section"
              control={control}
              defaultValue={sectionList[0].label}
              render={({ field }) => (
                <FormControl className="w-100">
                  <TSelect
                    fullWidth
                    options={sectionList}
                    placeholder="Please select section"
                    label="Section"
                    itemId="id"
                    itemValue="id"
                    {...field}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Controller
              name="dateFrom"
              defaultValue={currentDate}
              control={control}
              render={({ field }) => (
                <TDatePicker
                  label="Date from"
                  variant="outlined"
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  clearable={true}
                  renderInput={(params) => (
                    <TTextField
                      {...params}
                      fullWidth={true}
                      error={Boolean(errors.dateFrom)}
                      helperText={errors.dateFrom?.message}
                    />
                  )}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Controller
              name="dateTill"
              defaultValue={currentDate}
              control={control}
              render={({ field }) => (
                <TDatePicker
                  label="Date till"
                  variant="outlined"
                  format="YYYY-MM-DD"
                  disableFuture={true}
                  disabled={dateFromHolder === currentDate}
                  clearable={true}
                  renderInput={(params) => (
                    <TTextField
                      {...params}
                      fullWidth={true}
                      error={Boolean(errors.dateTill)}
                      helperText={errors.dateTill?.message}
                    />
                  )}
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            sx={{ mt: 1 }}
            className={clsx({
              [classes.buttonContainer]: true,
              ['customModalFooter']: true,
            })}
          >
            <TButton btnText="Cancel" btnWidthSize="button-w-140" variant="text" onClick={() => handleClose()} />
            <TButton
              btnText="Apply"
              btnWidthSize="button-w-140"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NotesFilterForm;
