import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { KycHistoryRecordType } from '../../../components/myde-react-components/src/types/KycTypes';
import { KycAmlResultType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

interface KycReportData {
  applicationId: string;
  processId: number;
  kycData: { diff_run_id: string };
}

const initialState = {
  kycLoading: false,
  error: false,
  errorObj: <any>{},
  kycIdProcessDetail: <KycAmlResultType>{},
  kycStatus: '',
  kycRunId: '',
  kycHistoryRecord: <KycHistoryRecordType>{},
};

export const selectKyc = ({ kycIdProcess }: RootState) => ({
  kycIdProcessDetail: kycIdProcess.kycIdProcessDetail,
  kycLoading: kycIdProcess.kycLoading,
  error: kycIdProcess.error,
  kycStatus: kycIdProcess.kycStatus,
  kycRunId: kycIdProcess.kycRunId,
  kycHistoryRecord: kycIdProcess.kycHistoryRecord,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'kyc/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

export const getReportHistoryByRunId = createAsyncThunk(
  'kyc/getReportHistoryByRunId',
  async (reportData: KycReportData) => {
    return await getIdProcessDetailsByProcessId(reportData.applicationId, reportData.processId, reportData.kycData);
  },
);

// Reducers
export const kycIdProcessSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    setKycRunId: (state, action) => {
      state.kycRunId = action.payload;
    },
    resetKycRunId: (state) => {
      state.kycRunId = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.kycLoading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.kycLoading = false;
        state.error = false;
        if (action.payload) {
          state.kycIdProcessDetail = action.payload?.result || {};
          state.kycStatus = action.payload.status || '';
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.kycLoading = false;
        state.error = true;
      })
      .addCase(getReportHistoryByRunId.pending, (state) => {
        state.kycLoading = true;
        state.error = false;
      })
      .addCase(getReportHistoryByRunId.fulfilled, (state, action) => {
        state.kycLoading = false;
        state.error = false;
        if (action.payload) {
          state.kycHistoryRecord = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getReportHistoryByRunId.rejected, (state) => {
        state.kycLoading = false;
        state.error = true;
      });
  },
});

export const { setKycRunId, resetKycRunId } = kycIdProcessSlice.actions;

export default kycIdProcessSlice.reducer;
