import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { getEntityIdProcessDetails, selectEntity } from '../../redux/feature/id-process/entity/entitySlice';

import { PredefinedProcessType } from '../../types/applicantTypes';
import { getDescriptionHandle, removeUnderscore } from '../../utils/utils';
import TabHeader from '../common/tab-header/TabHeader';
import {
  ENTITY_PROCESS_IDS,
  FilePreviewType,
  TButton,
  TDocumentViewModal,
  theme,
  TLoader,
} from '../myde-react-components';
import { PORTAL } from '../myde-react-components/src/constants/portal';
import UserNoteSummary from '../userNotes/UserNoteSummary';

interface EntityIdProcessDetailsTabProp {
  idProcess: PredefinedProcessType;
}

const EntityIdProcessDetailsTab = ({ idProcess }: EntityIdProcessDetailsTabProp) => {
  //Constants
  const dispatch = useDispatch();
  const { t } = useTranslation('entity');
  const multipleDocumentProcesses = [
    ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING,
    ENTITY_PROCESS_IDS.FORMATION_DOCUMENT,
    ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT,
  ];

  //State
  const [preview, setPreview] = useState({} as FilePreviewType);
  const [open, setOpen] = useState(false);

  //Redux
  const { applicationId, applicantionDetail } = useSelector(selectApplicant);
  const { entityIdProcessDetails } = useSelector(selectEntity);

  //useEffects
  useEffect(() => {
    if (idProcess?.process_id && applicationId) {
      const payload = {
        applicationId,
        processId: idProcess?.process_id,
      };
      dispatch(getEntityIdProcessDetails(payload));
    }
  }, [idProcess, applicationId]);

  //Methods
  // data type is any here as idProcessData can have different type of value for all the entity id processes.
  const getPreviewPayload = (idProcessData: any, key: string, processId: number) => {
    if (idProcessData && idProcessData[key]) {
      let idProcessObj = idProcessData[key];
      if (multipleDocumentProcesses.includes(processId)) {
        idProcessObj = idProcessData[key][0];
      }
      return {
        name: idProcessObj?.doc_name,
        type: idProcessObj?.mime_type,
        preview: idProcessObj?.signed_url,
        size: idProcessObj?.size_in_kb,
      };
    }
    return {} as FilePreviewType;
  };
  const viewDocument = () => {
    let payload = {} as FilePreviewType;
    switch (idProcess?.process_id) {
      case ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID:
        payload = getPreviewPayload(entityIdProcessDetails?.data, 'tax_document', idProcess?.process_id);
        break;

      case ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING:
      case ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT:
      case ENTITY_PROCESS_IDS.FORMATION_DOCUMENT:
        if (entityIdProcessDetails?.data?.document_id_list?.length) {
          payload = getPreviewPayload(entityIdProcessDetails?.data, 'document_id_list', idProcess?.process_id);
        } else {
          toast.error('No document found');
        }
        break;

      case ENTITY_PROCESS_IDS.ACCREDITATION:
      case ENTITY_PROCESS_IDS.W8_W9_DOCUMENT:
        payload = getPreviewPayload(entityIdProcessDetails?.data, 'signed_document', idProcess?.process_id);
        break;

      default:
        payload = {} as FilePreviewType;
        break;
    }
    setPreview(payload);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPreview({} as FilePreviewType);
  };

  const getTitle = () => {
    if (idProcess?.process_id === ENTITY_PROCESS_IDS.W8_W9_DOCUMENT) {
      return `Form ${entityIdProcessDetails?.data?.document_type || ''}`;
    } else {
      return idProcess?.title;
    }
  };

  const getIdProcessStatus = (processId: number, idProcessData: any) => {
    return processId === ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID
      ? removeUnderscore(idProcessData?.verification_status)
      : idProcessData?.status_label;
  };

  //HTML
  return (
    <>
      <Box>
        <TLoader loading={false} />
        <Box>
          <TabHeader
            title={getTitle()}
            status={getIdProcessStatus(idProcess?.process_id, entityIdProcessDetails?.data)}
            applicationId={applicationId}
            processId={idProcess?.process_id}
            accountReviewStatus={applicantionDetail?.status}
            section={idProcess?.title}
          />
        </Box>
        {/* This code is related to the disclaimer section, keeping this code as it can be used in future */}
        {/* {hasDisclaimer && (
          <Box
            sx={{ px: theme.spacing(7), py: 2, mt: -2 }}
            className={clsx('textColor-200 text-small disclaimerSectionRedius', classes.disclaimerSection)}
          >
            {t('irsLetterDisclaimer')}
          </Box>
        )} */}
        <Box sx={{ mt: theme.spacing(7) }} className="textColor-300 text-medium">
          {t(`${getDescriptionHandle(idProcess?.process_id, entityIdProcessDetails, applicantionDetail) || ''}`)}
        </Box>
        <Box sx={{ mt: 3 }}>
          <TButton btnText="View Document" variant="contained" btnWidthSize="button-w-240" onClick={viewDocument} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <UserNoteSummary />
        </Box>
      </Box>

      <TDocumentViewModal file={preview} portalName={PORTAL.MERCHANT} open={open} closeModal={handleClose} />
    </>
  );
};

export default EntityIdProcessDetailsTab;
