import { apiGet, apiPost } from './axios';

export const getRepositories = async (params: object) => {
  const response = await apiGet('/merchant/repository-document', { params: params });
  return response.data;
};

export const getRepositoryById = async (repositoryId: string) => {
  const response = await apiGet(`/merchant/repository-document/${repositoryId}`);
  return response.data;
};

export const uploadLibraryDocument = async (data: any) => {
  const res = await apiPost('/merchant/repository-document', data);
  return res.data;
};
