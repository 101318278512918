import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import * as z from 'zod';
import { useDispatch, useSelector } from 'react-redux';

import { TButton, TDialog, TTextField, TTextArea, MAX_CHAR_LIMIT } from '../myde-react-components';
import { CustomIdRequirementSelectionType } from '../../types/inviteTypes';
import { titleCase } from '../../utils/utils';
import {
  addCustomIdRequirement,
  selectMerchantInvite,
  resetCustomIdResultData,
  editCustomIdRequirement,
} from '../../redux/feature/merchantInvite/merchantInviteSlice';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface AddIdRequirementsProps {
  open: boolean;
  customIdRequirements: CustomIdRequirementSelectionType[];
  editCustomRequirement: CustomIdRequirementSelectionType;
  isEditActive: boolean;
  closeForm: () => void;
  addRequirement: (data: CustomIdRequirementSelectionType) => void;
  updateRequirements: () => void;
}

const useStyles = makeStyles({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: '560px!important',
        '& .MuiDialogActions-root': {
          padding: '0 !important',
        },
      },
    },
  },
});

const AddIdRequirements = ({
  open,
  customIdRequirements,
  editCustomRequirement,
  isEditActive,
  closeForm,
  addRequirement,
  updateRequirements,
}: AddIdRequirementsProps) => {
  // Form Schema Setup
  const CustomIdProcessFormSchema = z.object({
    process_name: isEditActive
      ? z.string().min(1, { message: 'Title is required' })
      : z
          .string()
          .min(1, { message: 'Title is required' })
          .refine(
            (value) =>
              !customIdRequirements?.some(
                (item: CustomIdRequirementSelectionType) =>
                  titleCase(item?.process_name).toUpperCase() === titleCase(value).toUpperCase(),
              ),
            { message: 'Title should be unique' },
          ),
    process_description: z.string().min(1, { message: 'Description is required' }),
  });

  type CustomIdProcessFormSchemaPayload = z.infer<typeof CustomIdProcessFormSchema>;

  const { handleSubmit, control, formState, reset, setValue } = useForm<CustomIdProcessFormSchemaPayload>({
    resolver: zodResolver(CustomIdProcessFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // constants
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { customIdRequirementResult, editCustomIdRequirementResult } = useSelector(selectMerchantInvite);

  // state variables
  const [title, setTitle] = useState('');
  const [titleCount, setTitleCount] = useState(0);
  const [description, setDescription] = useState('');
  const [descriptionCount, setDescriptionCount] = useState(0);

  // use effect
  useEffect(() => {
    setTitle('');
    setTitleCount(0);
    setValue('process_name', '', { shouldValidate: false });
    setDescription('');
    setDescriptionCount(0);
    setValue('process_description', '', { shouldValidate: false });
  }, [open]);

  useEffect(() => {
    if (customIdRequirementResult?.id) {
      const payload = {
        process_id: customIdRequirementResult?.id,
        process_name: customIdRequirementResult?.process_name,
        process_description: customIdRequirementResult?.process_description,
        access_level: false,
        is_checked: true,
      };
      addRequirement(payload);
      reset();
      closeForm();
      dispatch(resetCustomIdResultData());
    }
  }, [customIdRequirementResult]);

  useEffect(() => {
    if (editCustomIdRequirementResult?.id) {
      reset();
      closeForm();
      dispatch(resetCustomIdResultData());
      updateRequirements();
    }
  }, [editCustomIdRequirementResult]);

  useEffect(() => {
    if (editCustomRequirement?.process_id) {
      setTitle(editCustomRequirement?.process_name);
      setTitleCount(editCustomRequirement?.process_name?.length);
      setValue('process_name', editCustomRequirement?.process_name, { shouldValidate: false });
      setDescription(editCustomRequirement?.process_description);
      setDescriptionCount(editCustomRequirement?.process_description?.length);
      setValue('process_description', editCustomRequirement?.process_description, { shouldValidate: true });
    }
  }, [editCustomRequirement]);

  // Methods
  const onSubmit = async () => {
    const editData = {
      id: editCustomRequirement?.process_id,
      process_name: title,
      process_description: description,
    };
    const data = {
      process_name: title,
      process_description: description,
    };
    if (isEditActive) {
      dispatch(editCustomIdRequirement(editData));
    } else {
      dispatch(addCustomIdRequirement(data));
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event?.target?.value);
    setTitleCount(event?.target?.value?.length);
    setValue('process_name', event?.target?.value, { shouldValidate: true });
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event?.target?.value);
    setDescriptionCount(event?.target?.value?.length);
    setValue('process_description', event?.target?.value, { shouldValidate: true });
  };

  return (
    <TDialog
      className={classes.dialogStyle}
      open={open}
      portalName={PORTAL.MERCHANT}
      title={isEditActive ? t('editCustomDocument') : t('addCustomDocument')}
      maxWidth={'xs'}
    >
      <Box>
        <form>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
              <Controller
                name="process_name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    value={title}
                    fullWidth
                    label="Title"
                    variant="outlined"
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY }}
                    error={Boolean(errors.process_name)}
                    helperText={errors.process_name?.message}
                    onChange={handleTitleChange}
                  />
                )}
              />
              <Box sx={{ mt: 1 }} className="text-small flex-basic-end font-weight-semibold textColor-300">
                {`${MAX_CHAR_LIMIT.CHAR_LENGTH_THIRTY - titleCount} character(s) remaining`}
              </Box>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 4 }}>
              <Controller
                name="process_description"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextArea
                    {...field}
                    value={description}
                    fullWidth
                    label="Description"
                    variant="outlined"
                    minRows={4}
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
                    error={Boolean(errors.process_description)}
                    helperText={errors.process_description?.message}
                    onChange={handleDescriptionChange}
                  />
                )}
              />
              <Box sx={{ mt: 1 }} className="text-small flex-basic-end font-weight-semibold textColor-300">
                {`${MAX_CHAR_LIMIT.CHAR_LENGTH - descriptionCount} character(s) remaining`}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mb: 1 }} className="flex-basic-end">
            <TButton btnText="Cancel" btnWidthSize="button-w-140" variant="text" onClick={closeForm} sx={{ mr: 3 }} />
            <TButton
              btnText={isEditActive ? 'Save' : 'Add'}
              btnWidthSize="button-w-140"
              variant="contained"
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </Box>
    </TDialog>
  );
};

export default AddIdRequirements;
