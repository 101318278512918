import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { TTextField, TButton, TAutocomplete, TCheckbox, theme } from '../myde-react-components';
import { IdName } from '../../types/commonTypes';
import {
  setEmailData,
  setStatusData,
  selectInvitation,
  setSelectedCoordinatorsData,
} from '../../redux/feature/merchantInvite/inviteSlice';
import clsx from 'clsx';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  formStyle: {
    width: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
  },
  autocompleteStyle: {
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        padding: '15px',
        '& .MuiAutocomplete-input': {
          padding: '20px 0px 0.5px 0px!important',
        },
      },
    },
  },
});

export interface ApplicantFilterProps {
  options: IdName[];
  coordinatorList?: IdName[];
  showStatusFilter: boolean;
  showCoordinatorFilter?: boolean;
  onSubmit: () => void;
}

const ApplicantFilterForm = ({
  options,
  coordinatorList,
  showStatusFilter,
  showCoordinatorFilter = false,
  onSubmit,
}: ApplicantFilterProps) => {
  // constants
  const classes = useStyles();
  const dispatch = useDispatch();

  // state variables
  const [status, setStatus] = useState([] as IdName[]);
  const [coordinator, setCoordinator] = useState({} as IdName);
  const [email, setEmail] = useState('');

  // use selectors
  const { emailData, statusData, selectedCoordinatorData } = useSelector(selectInvitation);

  //  useEffect
  useEffect(() => {
    statusData?.length > 0 ? setStatus(statusData) : setStatus([] as IdName[]);
    emailData ? setEmail(emailData) : setEmail('');
    selectedCoordinatorData?.id ? setCoordinator(selectedCoordinatorData) : setCoordinator({} as IdName);
  }, [statusData, emailData]);

  // methods
  const handleSelectChange = (_event: React.SyntheticEvent, value: any) => {
    if (value) {
      setStatus(value);
      dispatch(setStatusData(value));
    } else {
      setStatus([] as IdName[]);
      dispatch(setStatusData([]));
    }
  };

  const handleCoordinatorChange = (_event: React.SyntheticEvent, value: any) => {
    setCoordinator(value ? value : ({} as IdName));
    dispatch(setSelectedCoordinatorsData(value ? value : ({} as IdName)));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event?.target?.value);
    dispatch(setEmailData(event?.target?.value));
  };
  return (
    <>
      <Box sx={{ mt: 3, py: theme.spacing(2) }}>
        <Box sx={{ mb: 3 }}>
          <TTextField
            value={email}
            fullWidth
            label="Applicant's Email Address"
            variant="outlined"
            onChange={handleEmailChange}
          />
        </Box>
        {showCoordinatorFilter && (
          <Box sx={{ mb: 3 }}>
            <TAutocomplete
              value={coordinator}
              options={coordinatorList || []}
              disablePortal={true}
              sx={{ mb: 3 }}
              onChange={handleCoordinatorChange}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TTextField label="Coordinator" placeholder="Coordinator name" variant="outlined" {...params} />
              )}
            />
          </Box>
        )}
        {showStatusFilter && (
          <Box sx={{ mb: 3 }}>
            <TAutocomplete
              value={status}
              multiple
              options={options}
              disablePortal={true}
              sx={{ mb: 3 }}
              onChange={handleSelectChange}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <TCheckbox icon={icon} checkedIcon={checkedIcon} sx={{ mr: 4 }} checked={selected} />
                  {option.name}
                </li>
              )}
              renderInput={(params) => <TTextField label="Status" variant="outlined" {...params} />}
            />
          </Box>
        )}

        <Box
          className={clsx({
            [classes.buttonContainer]: true,
            ['customModalFooter']: true,
          })}
        >
          <TButton btnText="Apply" btnWidthSize="button-w-140" variant="contained" onClick={onSubmit} />
        </Box>
      </Box>
    </>
  );
};

export default ApplicantFilterForm;
