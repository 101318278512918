import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { BiometricIdProcessType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  biometricLoading: false,
  error: false,
  errorObj: <any>{},
  biometricIdProcessDetail: <BiometricIdProcessType>{},
};

export const selectBiometric = ({ biometricIdProcess }: RootState) => ({
  biometricIdProcessDetail: biometricIdProcess.biometricIdProcessDetail,
  biometricLoading: biometricIdProcess.biometricLoading,
  error: biometricIdProcess.error,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'biometric/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const biometricIdProcessSlice = createSlice({
  name: 'biometric',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.biometricLoading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.biometricLoading = false;
        state.error = false;
        if (action.payload) {
          state.biometricIdProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.biometricLoading = false;
        state.error = true;
      });
  },
});

export default biometricIdProcessSlice.reducer;
