import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import clsx from 'clsx';

import {
  clearApplicationIdProcessList,
  getApplicantDetails,
  getApplicationIdProcesses,
  resetParentApplicationDetails,
  selectApplicant,
  setCurrentTab,
  setSelectedApplicationId,
} from '../../redux/feature/applicant/applicantSlice';
import { ApplicationType, PredefinedProcessType } from '../../types/applicantTypes';
import { colorPalette, DEFAULT_TABLE_OPTIONS } from '../myde-react-components';
import { INVITE_MESSAGE } from '../../constants/constants';
import { useTheme } from '../../providers/custom-theme-provider';
import UserNoteSummary from '../userNotes/UserNoteSummary';
import { IdNameStatusType } from '../../types/commonTypes';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { getSavedUserTags } from '../../redux/feature/applicant/applicantTagSlice';

// Interfaces
interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  btnColor: {
    color: ({ primaryColor }) => `${primaryColor} !important`,
  },
});

const BeneficialOwnerTab = () => {
  // Constants
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const styleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);
  const { routeTo } = useRouter();
  const dispatch = useDispatch();

  // State Variables
  const [idProcessList, setIdProcessList] = useState([] as PredefinedProcessType[]);
  const [usersList, setUsersList] = useState([] as IdNameStatusType[]);

  // Redux Values
  const { parentApplicationDetailList, parentApplicationIdProcessList } = useSelector(selectApplicant);

  // Use Effects
  useEffect(() => {
    if (parentApplicationDetailList?.length > 0) {
      const result = parentApplicationDetailList?.map((item: ApplicationType) => {
        return {
          id: item?.id,
          name: item?.account?.name,
          status: INVITE_MESSAGE[item?.status],
        };
      });
      setUsersList(result);
    }
  }, [parentApplicationDetailList]);

  useEffect(() => {
    if (parentApplicationIdProcessList?.id_process_list?.length > 0) {
      setIdProcessList(parentApplicationIdProcessList?.id_process_list);
    }
  }, [parentApplicationIdProcessList]);

  // Methods
  const customBodyRender = (value: any) => {
    return <Box className="text-large textColor-200">{value}</Box>;
  };

  const navigateTo = (data: any) => {
    const userDetail =
      parentApplicationDetailList?.find((item: ApplicationType) => item?.id === data?.rowData[0]) ||
      ({} as ApplicationType);
    const query = { inviteId: userDetail?.id, userId: userDetail?.account?.trellis_uid };
    dispatch(setCurrentTab(0));
    dispatch(clearApplicationIdProcessList());
    dispatch(getSavedUserTags(`${userDetail?.account?.trellis_uid}`));
    dispatch(setSelectedApplicationId(userDetail?.id));
    dispatch(getApplicantDetails(userDetail?.id));
    dispatch(getApplicationIdProcesses(userDetail?.id));
    dispatch(resetParentApplicationDetails());
    routeTo(ROUTES.BENEFICIAL_USER, false, '', query);
  };

  // Table Data
  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: { customBodyRender, display: false },
    },
    {
      name: 'name',
      label: 'Name',
      options: { customBodyRender, display: true, sort: false },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value: any, _tableMeta: any) => {
          return (
            <Box className="flex-basic-start">
              <Box className="text-large textColor-200">{value}</Box>
              {value === INVITE_MESSAGE.APPROVED && (
                <Box className={clsx('icon-approved icon-size-20', classes.btnColor)} sx={{ pl: 1 }} />
              )}
            </Box>
          );
        },
        display: true,
        sort: false,
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        customBodyRender: (_value: any, tableMeta: any) => {
          return (
            <Box
              className={clsx('text-large font-weight-semibold cursorPointer', classes.btnColor)}
              onClick={() => navigateTo(tableMeta)}
            >
              {t('viewLabel')}
            </Box>
          );
        },
        display: true,
        sort: false,
      },
    },
  ];

  // HTML
  return (
    <Box>
      <Box className="text-h2 font-weight-semibold textColor-200">{t('beneficialOwnerLabel')}</Box>
      <Box sx={{ my: 4 }}>
        <Box className="text-medium textColor-300">{t('idRequirementsLabel')}</Box>
        {idProcessList?.map((item: PredefinedProcessType, index: number) => (
          <Box key={index} className="text-large font-weight-semibold textColor-200">
            {item?.title}
          </Box>
        ))}
      </Box>
      <Box sx={{ my: 4 }} className={classes.tableContainer}>
        <MUIDataTable title="" data={usersList} columns={columns} options={options} />
      </Box>
      <Grid container spacing={2}>
        <Grid sx={{ mt: 3 }} item lg={12} sm={12} md={12} xs={12}>
          <UserNoteSummary />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BeneficialOwnerTab;
