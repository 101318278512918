import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { FormFieldType, FormFieldRules, TButton, TTextField } from '../../..';

export interface DynamicFormProps {
  fieldSize?: string;
  fields: FormFieldType[];
  onSubmit: (data: any) => void;
  isAutoSubmitMode?: boolean;
  setIsFormValid?: (isFormValid: boolean) => void;
}

export const DynamicForm = ({
  fieldSize = '',
  fields,
  onSubmit,
  isAutoSubmitMode = false,
  setIsFormValid,
}: DynamicFormProps) => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (isAutoSubmitMode) {
      methods.watch((data) => {
        onSubmit(data);
      });
    }
  }, [methods.watch]);

  useEffect(() => {
    if (setIsFormValid) {
      setIsFormValid(methods.formState.isValid);
    }
  }, [methods.formState]);

  const getErrorMessages = (rules: FormFieldRules | undefined) => {
    if (!rules) return {};
    const rulesObj: any = { ...rules };
    if (rules.required && !rules.required) {
      rulesObj.required.message = 'This field is required';
    }
    if (rules.maxLength && !rules.maxLength.message) {
      rulesObj.maxLength.message = `Max char length should ${rules.maxLength.value}`;
    }
    if (rules.minLength && !rules.minLength.message) {
      rulesObj.minLength.message = `Min char length should ${rules.minLength.value}`;
    }
    return rulesObj;
  };

  const inputField = (item: FormFieldType) => {
    return (
      <Controller
        name={item.name}
        defaultValue={item?.defaultValue ? item?.defaultValue : ''}
        control={methods.control}
        render={({ field, formState: { errors } }) => {
          return (
            <TTextField
              {...field}
              label={item.label}
              variant="outlined"
              fullWidth
              type={item.type}
              required={item?.rules?.required?.value}
              error={Boolean(errors[item.name])}
              helperText={errors[item.name]?.message}
              {...methods.register(item.name, { ...getErrorMessages(item?.rules) })}
            />
          );
        }}
      />
    );
  };

  const renderFieldsByType = (item: FormFieldType) => {
    switch (item.type) {
      case 'text':
      case 'number':
      case 'password':
        return inputField(item);
      case 'select':
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        {fields.map((item) => (
          <Box sx={{ my: 2 }} className={fieldSize} key={`${item.type}_${item.name}`}>
            {renderFieldsByType(item)}
          </Box>
        ))}
        {!isAutoSubmitMode && (
          <Box className="flex-basic-center">
            <TButton
              type="submit"
              sx={{ mr: 1 }}
              btnWidthSize={'button-w-240'}
              btnText="Submit"
              variant="contained"
              disabled={!methods.formState.isValid}
            />
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default DynamicForm;
