import * as ROUTES from './routes';

export const NAVIGATE_TO_NOTIFICATION: any = {
  USER_APPLICATION: ROUTES.USER,
  APPLICATION_MESSAGE_LISTING: ROUTES.USER_MESSAGES,
  INVITATION_LISTING: ROUTES.DASHBOARD,
  APPLICATION_LISTING: ROUTES.DASHBOARD,
  COMMUNICATIONS: ROUTES.COMMUNICATIONS,
};

export const NOTIFICATION_NAVIGATIONS = {
  USER_APPLICATION: 'USER_APPLICATION',
  APPLICATION_MESSAGE_LISTING: 'APPLICATION_MESSAGE_LISTING',
  INVITATION_LISTING: 'INVITATION_LISTING',
  APPLICATION_LISTING: 'APPLICATION_LISTING',
  COMMUNICATIONS: 'COMMUNICATIONS',
};
