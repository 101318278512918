import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import * as ROUTES from '../constants/routes';
import Drawer from '../components/common/Drawer';
import { MERCHANT_PORTAL_FEATURE_LIST, colorPalette, theme } from '../components/myde-react-components';
import MobileDrawer from '../components/common/MobileDrawer';
import { selectUser } from '../redux/feature/user/userSlice';
import { wssConfig } from '../api/config';
import { WEB_SOCKET_FOCUS_ITEMS } from '../constants/constants';
import { setIsNotificationRead } from '../redux/feature/notification/notificationSlice';

export interface StyleProps {
  matches: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    flexGrow: 1,
    marginTop: ({ matches }) => (matches ? '28px' : '0px'),
    background: colorPalette.containerBack.base,
  },
}));

const App: FC<any> = (props: any) => {
  // Constants
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { children } = props;
  const styleProps = {
    matches,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const listItems = [
    {
      id: 0,
      title: t('applicantsTitle'),
      icon: <Box className="icon-briefcase icon-size-20" />,
      to: ROUTES.DASHBOARD,
      feature: MERCHANT_PORTAL_FEATURE_LIST.APPLICANTS,
    },
    {
      id: 1,
      title: t('communicationsLabel'),
      icon: <Box className="icon-communication icon-size-20" />,
      to: ROUTES.COMMUNICATIONS,
      feature: MERCHANT_PORTAL_FEATURE_LIST.COMMUNICATIONS,
    },
    {
      id: 2,
      title: t('manageEntities'),
      icon: <Box className="icon-manage-entities icon-size-20" />,
      to: ROUTES.ENTITIES,
      feature: MERCHANT_PORTAL_FEATURE_LIST.MANAGE_ENTITIES,
    },
    {
      id: 3,
      title: t('manageRepository'),
      icon: <Box className="icon-manage-entities icon-size-20" />,
      to: ROUTES.MANAGE_REPOSITORIES,
      feature: MERCHANT_PORTAL_FEATURE_LIST.MANAGE_REPOSITORIES,
    },
  ];

  // Redux Values
  const { selectedMerchant, authToken } = useSelector(selectUser);

  // Use Effect
  useEffect(() => {
    if (selectedMerchant?.merchant?.id) {
      const wsUrl = `${wssConfig.baseUrl}/${selectedMerchant?.merchant?.id}/websocket?token=${authToken}`;
      const socket = new WebSocket(wsUrl);
      connectToWebSocket(socket);
      return () => {
        socket.close();
      };
    }
  }, [authToken, selectedMerchant]);

  // Methods
  const connectToWebSocket = (sock: WebSocket) => {
    sock.onopen = function () {
      sock.send(
        JSON.stringify({
          connection: 'Connect to Web Socket',
        }),
      );
    };
    sock.onmessage = function (event) {
      wsSocketHandler(event);
    };
  };

  const wsSocketHandler = (event: any) => {
    const json = JSON.parse(event?.data);
    try {
      switch (json?.payload?.focus_item) {
        case WEB_SOCKET_FOCUS_ITEMS.ALERT_RECEIVED:
          dispatch(setIsNotificationRead(true));
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {matches ? (
          <MobileDrawer drawerProps={{ listItems }}>
            <Box component="main" className={classes.container}>
              {children || <Outlet />}
            </Box>
          </MobileDrawer>
        ) : (
          <>
            <Box>
              <Drawer listItems={listItems} />
            </Box>
            <Box component="main" className={classes.container}>
              {children || <Outlet />}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default App;
