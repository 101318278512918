import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';

import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { getSpecificIdProcessDetails } from '../../redux/feature/id-process/biometricIdProcessSlice';
import { selectBiometric } from '../../redux/feature/id-process/biometricIdProcessSlice';
import { FaceWordSearch, TLoader } from '../myde-react-components';
import { PredefinedProcessType } from '../../types/applicantTypes';
import UserNoteSummary from '../userNotes/UserNoteSummary';
import TabHeader from '../common/tab-header/TabHeader';
import { getIdProcessReviewStatus } from '../../utils/idProcess';
import { selectApplicationActions } from '../../redux/feature/id-process/idProcessActionsSlice';
import { PORTAL } from '../myde-react-components/src/constants/portal';
import { useTranslation } from 'react-i18next';
import { SectionTitleType } from '../myde-react-components/src/types/faceWordSearchTypes';

interface BiometricTabProps {
  processDetails: PredefinedProcessType;
}

const BiometricTab = ({ processDetails }: BiometricTabProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { applicantionDetail, applicationId, applicationIdProcessList } = useSelector(selectApplicant);
  const { biometricIdProcessDetail, biometricLoading } = useSelector(selectBiometric);
  const { loading } = useSelector(selectApplicationActions);

  const [status, setStatus] = useState('');

  const sectionTitles: SectionTitleType = {
    faceSearch: t('faceSearch'),
    imgFromIdDoc: t('imgFromIdDoc'),
    uploadedVideo: t('uploadedVideo'),
    wordSearch: t('wordSearch'),
    wordRecognized: t('wordRecognized'),
  };

  useEffect(() => {
    try {
      if (processDetails?.process_id) {
        const data = {
          applicationId: applicantionDetail?.id,
          processId: processDetails?.process_id,
        };
        dispatch(getSpecificIdProcessDetails(data));
      }
    } catch (error: any) {}
  }, [processDetails]);
  useEffect(() => {
    const currentProcess =
      applicationIdProcessList?.id_process_list?.find((process) => process.process_id === processDetails?.process_id) ||
      ({} as PredefinedProcessType);
    if (Object.keys(currentProcess)?.length > 0) {
      setStatus(getIdProcessReviewStatus(currentProcess?.merchant_review_status, processDetails?.status));
    }
  }, [applicationIdProcessList, processDetails]);

  return (
    <>
      {loading || biometricLoading || !status ? (
        <TLoader loading={loading || biometricLoading || !status} />
      ) : (
        <>
          {Object.keys(biometricIdProcessDetail)?.length > 0 && (
            <>
              <Box>
                <TabHeader
                  title={processDetails?.title}
                  status={status}
                  applicationId={applicationId}
                  processId={processDetails?.process_id}
                  accountReviewStatus={applicantionDetail?.status}
                  section={processDetails?.title}
                />
              </Box>
              <FaceWordSearch
                portalType={PORTAL.MERCHANT}
                faceWordSearchData={biometricIdProcessDetail}
                sectionTitles={sectionTitles}
              />
              <Grid sx={{ mt: 3 }} lg={12} sm={12} md={12} xs={12}>
                <UserNoteSummary />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BiometricTab;
