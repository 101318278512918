import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colorPalette, theme } from './components/myde-react-components';
import { getBranding } from './utils/storage';
import { defaultBranding } from './constants/constants';
import { fontStyle } from './styles/style';
import { BrandingType } from './types/userTypes';

export interface ThemeProps {
  color: string;
}

const ThemeWrapper = ({ children }: any) => {
  const [branding, setBranding] = useState({} as BrandingType);
  const [colors, setColors] = useState(defaultBranding?.colors);

  useEffect(() => {
    const brandingData = JSON.parse(getBranding());
    if (Object.keys(brandingData)?.length > 0) {
      setBranding(brandingData);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(branding).length !== 0) {
      setColors(branding?.colors);
    }
  }, [branding]);

  const CustomTheme = createTheme({
    palette: {
      error: {
        main: colors?.error,
      },
      info: {
        main: colors?.info,
      },
      primary: {
        main: colors?.primary,
      },
      success: {
        main: colors?.success,
      },
      warning: {
        main: colors?.warning,
      },
      secondary: {
        main: colors?.secondary,
      },
    },
    typography: {
      fontFamily: ['Montserrat'].join(','),
      subtitle1: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '29px',
        color: colorPalette.typoText.base,
      },
      body2: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        color: colorPalette.typoText.lighten1,
      },
      caption: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '15px',
        color: colorPalette.typoText.darken2,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            ...fontStyle.fontSize.font12,
            ...fontStyle.fontWeight.font500,
            color: colorPalette.typoText.base,
            fontFamily: 'Montserrat',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            ...fontStyle.fontSize.font14,
          },
        },
      },
    },
  });
  const currentTheme = branding ? CustomTheme : theme;

  return (
    <>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </>
  );
};

export default ThemeWrapper;
