import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { selectUser, setSelectedMerchant } from '../../redux/feature/user/userSlice';
import { LoginAsFormSchema, LoginAsFormSchemaPayload } from './validation';
import * as ROUTES from '../../constants/routes';
import { TTextField, TAutocomplete } from '../../components/myde-react-components';
import { useAuth } from '../../auth/auth-guard';
import { getAccessToken } from '../../utils/storage';

const LoginAs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control } = useForm<LoginAsFormSchemaPayload>({
    resolver: zodResolver(LoginAsFormSchema),
    mode: 'onChange',
  });

  const [myMerchant, setMerchant] = React.useState({
    id: '',
    name: '',
  });

  const { merchantData, authToken } = useSelector(selectUser);
  const { login } = useAuth();

  useEffect(() => {
    if (authToken) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [authToken]);

  const handleMerchantChange = (value: any) => {
    if (value && Object.keys(value.merchant).length > 0) {
      dispatch(setSelectedMerchant(value));
      setMerchant(value.merchant);
      const authenticationToken = value.access;
      login(authenticationToken);
      const token = getAccessToken();
      if (token !== '') {
        navigate(ROUTES.DASHBOARD);
      }
    } else {
      setMerchant({ id: '', name: '' });
    }
  };

  return (
    <>
      <Controller
        name="merchant"
        defaultValue={myMerchant.name}
        control={control}
        render={() => (
          <TAutocomplete
            disableClearable
            value={myMerchant}
            options={merchantData.merchants || []}
            getOptionLabel={(option) => option?.merchant?.name || ''}
            isOptionEqualToValue={(option, value) => option?.merchant?.id === value?.id}
            onChange={(event: React.SyntheticEvent, value: any) => handleMerchantChange(value)}
            renderInput={(params) => <TTextField label="Select Merchant" variant="outlined" {...params} />}
          />
        )}
      />
    </>
  );
};

export default LoginAs;
