import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useRouter } from '../../providers/custom-router-provider';

import * as ROUTES from '../../constants/routes';
import { TCard, TConfirmDialog, theme } from '../myde-react-components';
import CommunicationForm from './CommunicationForm';
import {
  resetCreateCommunicationResponse,
  resetUploadedDocument,
  saveSelectedTags,
  saveSelectedUsers,
  selectCommunications,
  setCommunicationFormTitle,
  setDocumentsList,
} from '../../redux/feature/communication/communicationSlice';
import { setShowAlert } from '../../redux/feature/common/commonSlice';
import { PORTAL } from '../myde-react-components/src/constants/portal';

const CommunicationFormIndex = () => {
  // constants
  const { routeTo } = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // state variables
  const [showDialog, setShowDialog] = useState(false);

  // use selectors
  const { selectedUsers, selectedTags, communicationFormTitle } = useSelector(selectCommunications);

  useEffect(() => {
    dispatch(setDocumentsList([]));
    dispatch(saveSelectedTags([]));
    dispatch(saveSelectedUsers([]));
    dispatch(resetUploadedDocument());
    dispatch(setCommunicationFormTitle(''));
    dispatch(setShowAlert(false));
  }, []);

  useEffect(() => {
    if (selectedUsers?.length > 0 || selectedTags?.length > 0 || communicationFormTitle) {
      dispatch(setShowAlert(true));
    }
  }, [selectedUsers, selectedTags, communicationFormTitle]);

  // methods
  const navigateBack = () => {
    if (selectedUsers?.length > 0 || selectedTags?.length > 0 || communicationFormTitle) {
      setShowDialog(true);
    } else {
      dispatch(setShowAlert(false));
      dispatch(resetCreateCommunicationResponse());
      routeTo(ROUTES.COMMUNICATIONS);
    }
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const closeForm = () => {
    setShowDialog(false);
    dispatch(resetCreateCommunicationResponse());
    routeTo(ROUTES.COMMUNICATIONS);
  };

  return (
    <>
      <Container sx={{ p: 5 }}>
        <TCard sx={{ p: 5 }} className="tcard-broder">
          <Box sx={{ pb: theme.spacing(8) }} className="flex-basic-start">
            <Box className="flex-basic-center" onClick={navigateBack}>
              <ArrowBackIosNewIcon className="textColor-200 cursorPointer" />
            </Box>
            <Box>
              <Box sx={{ pl: 2 }} className="textColor-200 text-h2 font-weight-semibold">
                Communications
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <CommunicationForm />
          </Box>
        </TCard>
        <TConfirmDialog
          portalName={PORTAL.MERCHANT}
          showDialog={showDialog}
          classNameConfirmDailog="confirmDialogBoxOpacityNote"
          title={'Confirmation'}
          onCancelClick={onCancel}
          onConfirmClick={closeForm}
          confirmationMessage={t('confirmationTextCommunication')}
        />
      </Container>
    </>
  );
};

export default CommunicationFormIndex;
