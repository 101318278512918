import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { TButton, TLoader, TPageHeader } from '../../components/myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';

import { getEntityDetailById } from '../../redux/feature/id-process/entity/entitySlice';
import useQuery from '../../hooks/useQuery';
import EntityTabs from '../../components/entityTabs/EntityTabs';

const EntityDetails = () => {
  //Constants
  const { routeTo } = useRouter();
  const dispatch = useDispatch();
  const query = useQuery();
  const entityId = parseInt(query.get('entityId') || '');
  const entityName = query.get('entityName') || '';

  //Use Effects
  useEffect(() => {
    if (entityId) {
      dispatch(getEntityDetailById(entityId));
    }
  }, []);

  //Methoods
  const navigateTo = () => {
    routeTo(ROUTES.ENTITIES, false);
  };

  const handlePublishClick = () => {
    //TODO
  };

  return (
    <Box sx={{ mb: 1 }}>
      <TLoader loading={false} />
      <Box sx={{ py: 1, px: 5 }}>
        <TPageHeader title={entityName} onBack={navigateTo}>
          <Box>
            <TButton
              btnText="Publish"
              variant="contained"
              icon={<span className={'icon-checked'}></span>}
              onClick={handlePublishClick}
            />
          </Box>
        </TPageHeader>
      </Box>
      <Box sx={{ px: 5 }}>
        <EntityTabs />
      </Box>
    </Box>
  );
};

export default EntityDetails;
