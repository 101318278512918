import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Container, Grid, Icon, Theme } from '@mui/material';

import { TButton, TCard, TDialog, TSearchComponent } from '../../components/myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import { fontStyle } from '../../styles/style';
import CustomTabs, { TabListType } from '../../components/common/tabs/Tabs';
import UserNoteList from '../../components/userNotes/UserNoteList';
import UserNoteModal from '../../components/userNotes/UserNoteModal';
import {
  getApplicationNotes,
  getFilteredNotes,
  selectApplicantNotes,
  setNotesFilterSet,
} from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import NotesFilterForm from '../../components/common/messageNotes/NotesFilterForm';
import { getDate } from '../../utils/utils';
import { SECTION_LIST } from '../../constants/constants';
import { useTheme } from '../../providers/custom-theme-provider';
interface StyleProps {
  currentThemes: Theme;
}
const useStyles = makeStyles<Theme, StyleProps>({
  pageHeader: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font600,
    verticalAlign: 'middle',
  },
  backIcon: {
    marginRight: 16,
    marginTop: 4,
  },
  searchSection: {
    display: 'flex',
    justifyContent: 'right',
  },
  dialogStyle: {
    '& .MuiPaper-root.MuiDialog-paper': {
      borderRadius: 0,
      width: '460px!important',
      '& .MuiDialogContent-root': {
        overflowY: 'unset',
      },
    },
  },
  setIconColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const UserNoteListIndex = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const { goBack } = useRouter();
  const dispatch = useDispatch();

  const { applicationId, selectedSection } = useSelector(selectApplicant);
  const { noteFilterSet, isArchivedNotes } = useSelector(selectApplicantNotes);

  const tabList: TabListType[] = [
    {
      label: 'All',
      component: <UserNoteList isArchived={false} />,
    },

    {
      label: 'Archived',
      component: <UserNoteList isArchived={true} />,
    },
  ];

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [resetSearch] = useState(false);
  const [showAddMsgDialog, setAddDialogFlag] = useState(false);
  const [tabs, setTabs] = useState<Array<TabListType>>([]);

  useEffect(() => {
    setTabs(tabList);
    dispatch(setNotesFilterSet({}));
  }, []);

  // Functions
  const filterRecords = (searchValue: string) => {
    setSearchText(searchValue);
    const data = {
      applicationId: applicationId,
      formPayload: {
        note: searchValue,
        is_archived: isArchivedNotes,
      },
    };
    if (Object.keys(noteFilterSet)?.length > 0) {
      if (noteFilterSet?.section !== '' || noteFilterSet?.section !== SECTION_LIST.ALL) {
        Object.assign(data?.formPayload, {
          section: noteFilterSet?.section,
        });
      }
      if (noteFilterSet?.modified_on_gte === getDate()) {
        Object.assign(data?.formPayload, {
          modified_on_gte: noteFilterSet?.modified_on_gte,
        });
      } else {
        Object.assign(data?.formPayload, {
          modified_on_gte: noteFilterSet?.modified_on_gte,
          modified_on_lte: noteFilterSet?.modified_on_lte,
        });
      }
    }

    dispatch(getFilteredNotes(data));
  };
  const extraFilterHandler = () => {
    setOpen(true);
  };
  const handleChange = () => {
    setOpen(false);
  };

  const onSubmit = async (formData: any) => {
    const payload = {
      formPayload: {
        is_archived: isArchivedNotes,
        section: formData?.section === SECTION_LIST.ALL ? '' : formData?.section,
        filter_count: formData?.section === SECTION_LIST.ALL ? 0 : 1,
      },
    };
    if (searchText !== '') {
      Object.assign(payload?.formPayload, {
        note: resetSearch ? '' : searchText,
      });
    }

    if (formData?.dateFrom === getDate()) {
      Object.assign(payload?.formPayload, {
        modified_on_gte: formData?.dateFrom || '',
        filter_count: formData?.section !== SECTION_LIST.ALL ? payload?.formPayload?.filter_count + 1 : 1,
      });
    } else {
      Object.assign(payload.formPayload, {
        modified_on_gte: formData?.dateFrom || '',
        modified_on_lte: formData?.dateTill || '',
        filter_count: formData?.section !== SECTION_LIST.ALL ? payload?.formPayload?.filter_count + 1 : 1,
      });
    }

    const data = {
      applicationId: applicationId,
      ...payload,
    };

    dispatch(getFilteredNotes(data));
    dispatch(setNotesFilterSet(payload?.formPayload));
    setOpen(false);
  };

  const resetNotesFilter = () => {
    const data = {
      page: 1,
      limit: 10,
      applicationId: applicationId,
      is_archived: isArchivedNotes,
      section: selectedSection !== SECTION_LIST.ACCOUNT ? selectedSection : '',
    };
    dispatch(getApplicationNotes(data));
    dispatch(setNotesFilterSet({}));
  };

  return (
    <Container sx={{ py: 3 }}>
      <TCard sx={{ p: 3 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <TButton
              sx={{ mr: 1, mb: 1 }}
              variant="text"
              icon={<Icon className="textColor-200">arrow_back_ios_new_icon</Icon>}
              size="small"
              onClick={() => goBack()}
            />
            <span className="text-h2 font-weight-semibold textColor-200">Notes</span>
          </Grid>
          <Grid item className={classes.searchSection}>
            <Box className="flex-basic-center align-items-end">
              <TSearchComponent
                placeholder="Search Description"
                onSearchUpdate={filterRecords}
                extraFilterHandler={extraFilterHandler}
                resetSearchFilter={resetSearch}
                appliedFilterCount={noteFilterSet?.filter_count}
                resetFilters={resetNotesFilter}
              />
              <TButton
                variant="contained"
                btnText="Add Note"
                sx={{ ml: 1 }}
                onClick={() => {
                  setAddDialogFlag(true);
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomTabs tabList={tabs} borderRequired={false} showBottomBorder={false} />
          </Grid>
        </Grid>
      </TCard>
      <UserNoteModal
        openDialog={showAddMsgDialog}
        onClose={() => {
          setAddDialogFlag(false);
        }}
      />
      <TDialog open={open} maxWidth={'xs'} className={classes.dialogStyle}>
        <NotesFilterForm onSubmit={onSubmit} handleClose={handleChange} />
      </TDialog>
    </Container>
  );
};

export default UserNoteListIndex;
