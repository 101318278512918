import React from 'react';
import { IconButton } from '@mui/material';
import {
  ID_PROCESS_REVIEW_STATUS,
  ID_PROCESS_STATUS,
  KYC_AML_RESULTS,
  PROCESS_STATUSES,
  PROCESS_STATUSES_LABEL,
  REJECTED_STATUS_LABEL,
} from '../constants/constants';

export const getIdProcessStatus = (data: string) => {
  switch (data) {
    case ID_PROCESS_STATUS.FAILED:
      return PROCESS_STATUSES_LABEL.VERIFICATION_FAILED;
    case ID_PROCESS_STATUS.SYSTEM_APPROVED:
    case ID_PROCESS_STATUS.PASSED:
      return PROCESS_STATUSES_LABEL.VERIFIED;
    case ID_PROCESS_STATUS.NEEDS_MANUAL_REVIEW:
      return PROCESS_STATUSES_LABEL.NEEDS_MANUAL_REVIEW;
    default:
      return PROCESS_STATUSES_LABEL.NOT_VERIFIED;
  }
};

export const getProfileIdProcessIcon = (data: string) => {
  switch (data) {
    case PROCESS_STATUSES.VERIFIED:
      return 'icon-approved';
    default:
      return 'icon-review-needed error-color-state';
  }
};

export const getIdProcessReviewStatus = (reviewStatus: string, processStatus: string) => {
  switch (reviewStatus) {
    case ID_PROCESS_REVIEW_STATUS.UNREVIEWED:
      return getIdProcessStatus(processStatus);
    case ID_PROCESS_REVIEW_STATUS.APPROVED:
      return reviewStatus;
    case ID_PROCESS_REVIEW_STATUS.REJECTED:
      return REJECTED_STATUS_LABEL.DECLINED;
    default:
      return getIdProcessStatus(processStatus);
  }
};

export const getAction = (status: string) => {
  switch (status) {
    case KYC_AML_RESULTS.CONSIDER:
      return (
        <IconButton edge="end" aria-label="delete" className="cursorDefault">
          <span className="icon-error-warning error-color-state actionIconFont-24"></span>
        </IconButton>
      );
    case KYC_AML_RESULTS.CLEAR:
    default:
      return (
        <IconButton className="cursorDefault" edge="end" aria-label="delete">
          <span className="icon-approved success-color-state actionIconFont-24"></span>
        </IconButton>
      );
  }
};
