import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TButton, TCard, theme, TTextField } from '../../components/myde-react-components';
import { useRouter } from '../../providers/custom-router-provider';
import { forgotPassword, selectUser } from '../../redux/feature/user/userSlice';
import { ForgotPasswordFormSchemaPayload, ForgotPasswordSchema } from './validation';
import resendEmailBgImage from '../../../src/common/assets/check-email-bg-image.svg';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { useTheme } from '../../providers/custom-theme-provider';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  forgotPasswordContainer: {
    width: '23rem',
    height: 'auto',
    background: 'transparent',
  },
  imgContainer: {
    width: '10rem',
    marginLeft: '-0.5rem',
  },
  mailIconColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const ForgotPassword = () => {
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'forgotPassword']);
  const { goBack } = useRouter();
  const { handleSubmit, control, formState, setError, getValues } = useForm<ForgotPasswordFormSchemaPayload>({
    resolver: zodResolver(ForgotPasswordSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;
  const { linkSentForForgotPassword } = useSelector(selectUser);

  //methods
  const sendEmail = async (payload: ForgotPasswordFormSchemaPayload) => {
    try {
      await dispatch(forgotPassword(payload));
    } catch (err: any) {
      setError('email', { type: 'manual', message: err.detail });
    }
  };

  const onSubmit = (formDetail: ForgotPasswordFormSchemaPayload) => {
    sendEmail(formDetail);
  };

  const resendEmail = () => {
    sendEmail(getValues());
  };

  const getForgotPasswordForm = () => {
    return (
      <>
        <Box className="textColor-200 text-h2 font-weight-semibold text-center">
          {t('forgotPasswordTitle', { ns: 'forgotPassword' })}
        </Box>
        <Box sx={{ mt: theme.spacing(5) }} className="textColor-200 text-medium text-center">
          {t('forgotPasswordSubTitle', { ns: 'forgotPassword' })}
        </Box>
        <form className="w-100">
          <Box sx={{ mt: 5 }}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TTextField
                  label="Your Email"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Grid sx={{ mt: 4 }} container spacing={0} className={classes.forgotPasswordContainer}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TButton variant="text" fullWidth btnText={'Back'} onClick={() => goBack()}></TButton>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TButton
                fullWidth
                variant={'contained'}
                type="submit"
                btnWidthSize="button-w-240"
                disabled={!isValid || isSubmitting}
                btnText={'Submit'}
                onClick={handleSubmit(onSubmit)}
              ></TButton>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const getResendLinkForm = () => {
    return (
      <>
        <Box sx={{ pt: theme.spacing(4) }} className="flex-basic-center">
          <Box className={clsx(classes.imgContainer, 'p-relative')}>
            <img src={resendEmailBgImage} alt="resendEmailBgImage" className="w-100" />
            <MarkEmailReadOutlinedIcon className={clsx(classes.mailIconColor, 'mailIcon')} />
          </Box>
        </Box>
        <Box
          className="text-h2 font-weight-semibold textColor-200"
          sx={{ mt: theme.spacing(10), mb: theme.spacing(5) }}
        >
          {t('checkYourEmail', { ns: 'forgotPassword' })}
        </Box>
        <Box sx={{ mx: 3 }} className="text-center">
          <span className="text-medium textColor-200">{t('checkYourEmailInfo', { ns: 'forgotPassword' })}</span>
        </Box>
        <Box sx={{ mx: 6, mt: theme.spacing(1) }}>
          <span className="text-medium textColor-200">{t('emailNotReceived', { ns: 'forgotPassword' })}</span>
        </Box>
        <Box sx={{ mb: 1, mt: theme.spacing(5), mx: 6 }}>
          <TButton
            variant={'contained'}
            btnWidthSize="button-w-240"
            btnText={'Resend Email'}
            onClick={resendEmail}
          ></TButton>
        </Box>
      </>
    );
  };

  return (
    <Box className="flex-basic-center">
      <TCard sx={{ m: 5 }} className="flex-basic-center height-vh-100 forgotPasswordOuterContainer">
        <Box className={classes.forgotPasswordContainer}>
          <Box className="flex-column-center height-vh-100">
            {linkSentForForgotPassword ? getResendLinkForm() : getForgotPasswordForm()}
          </Box>
        </Box>
      </TCard>
    </Box>
  );
};

export default ForgotPassword;
