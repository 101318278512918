import React, { useEffect, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import CustomTabs, { TabListType } from '../common/tabs/Tabs';
import EntityDetailsIndex from './EntityDetailsIndex';
import TaxIdIndex from './TaxIdIndex';
import ProfileDetailsIndex from './ProfileDetailsIndex';
import CommonDocumentIndex from './CommonDocumentIndex';
import AccreditationDetailsIndex from './AccreditationDetailsIndex';
import OtherDetails from './OtherDetails';
import {
  DOCUMENT_VERIFICATION_MIME_TYPES,
  PORTAL,
  TButton,
  TCommonAvatar,
  TDialog,
  TLoader,
  colorPalette,
  theme,
  ACCREDITATION_BADGE_TEXT,
  ENTITY_PROCESS_IDS,
} from '../myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';
import {
  selectEntity,
  updateEntityDetails,
  uploadEntityAvatarFile,
  getSpecificIdProcessDetailsByProcessId,
  getEntityDetailById,
  setSpecificEntityIdProcessDetails,
  resetSuccessIdProcessMessage,
} from '../../redux/feature/id-process/entity/entitySlice';
import { AddedEntityType, UpdateEntityDetailsType } from '../../types/entityTypes';
import AddMembersLanding from '../../components/addMembers/AddMembersLanding';
import { ROLES } from '../../constants/constants';
import { hexToRgbA } from '../../components/myde-react-components/src/utils/commonMethods';
import useQuery from '../../hooks/useQuery';
import { setDocumentList } from '../../redux/feature/document/documentSlice';
import { FilePreviewType } from '../../types/documentTypes';

interface StyleProps {
  currentThemes: Theme;
}

const useStyles = makeStyles<Theme, StyleProps>({
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        '& .MuiDialogActions-root': {
          padding: '0 !important',
        },
      },
    },
  },
  addProfilePicBtn: {
    '&.MuiButton-root': {
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
      color: `${colorPalette.typoText.lighten3} !important`,
      '&:hover': {
        backgroundColor: ({ currentThemes }) => `${hexToRgbA(currentThemes.palette.primary.main, 0.8)} !important`,
      },
      '& .MuiButton-startIcon': {
        '& .icon-add': {
          fontSize: '24px !important',
        },
      },
    },
  },
  checkboxColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  accreditationBadge: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  fileBtnParent: {
    '& .fileUploadBtn': {
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
  accreditationBadgeColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
});

const EntityTabs = () => {
  //Constants
  const query = useQuery();
  const entityId = parseInt(query.get('entityId') || '');
  const { t } = useTranslation(['common', 'entity']);
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  //states
  const [selectedEntity, setSelectedEntity] = useState({} as AddedEntityType);

  //Redux
  const { entityProfileURL, accreditationStatus, assignedEntityDetails } = useSelector(selectEntity);

  //State variables
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([] as TabListType[]);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isUploadProfileImage, setIsUploadProfileImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);

  const entityTabsList = [
    {
      label: 'Entity Details',
      component: <EntityDetailsIndex />,
      id: 0,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: null,
    },
    {
      label: 'Tax ID',
      component: <TaxIdIndex />,
      id: 1,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: null,
    },
    {
      label: 'Profile Details',
      component: <ProfileDetailsIndex />,
      id: 2,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: ENTITY_PROCESS_IDS.PROFILE_VERIFICATION,
    },
    {
      label: 'Owner(s)',
      component: <AddMembersLanding memberType={ROLES.OWNER} />,
      id: 3,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: null,
    },
    {
      label: 'User(s)',
      component: <AddMembersLanding memberType={ROLES.USER} />,
      id: 4,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: null,
    },
    {
      label: 'IRS Letter Evidencing Tax ID',
      component: <CommonDocumentIndex entityProcessId={ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID} />,
      id: 5,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: ENTITY_PROCESS_IDS.IRS_LETTER_EVIDENCING_TAX_ID,
    },
    {
      label: 'Formation Document(s)',
      component: <CommonDocumentIndex entityProcessId={ENTITY_PROCESS_IDS.FORMATION_DOCUMENT} />,
      id: 6,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: ENTITY_PROCESS_IDS.FORMATION_DOCUMENT,
    },
    {
      label: 'Ownership Document(s)',
      component: <CommonDocumentIndex entityProcessId={ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT} />,
      id: 7,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT,
    },
    {
      label: t('letterOfGoodStandingHeading', { ns: 'entity' }),
      component: <CommonDocumentIndex entityProcessId={ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING} />,
      id: 8,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: ENTITY_PROCESS_IDS.LETTER_OF_GOOD_STANDING,
    },
    {
      label: 'Accreditation',
      component: <AccreditationDetailsIndex />,
      id: 9,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: ENTITY_PROCESS_IDS.ACCREDITATION,
    },
    {
      label: 'Others',
      component: <OtherDetails />,
      id: 10,
      icon: <Box sx={{ pt: theme.spacing(1) }} className="icon-ideal-state" />,
      processId: null,
    },
  ];

  //Useffects
  useEffect(() => {
    if (entityId) {
      dispatch(getEntityDetailById(entityId));
    }
    setTabs(entityTabsList);
    setProfileImageUrl(selectedEntity?.avatar_url);
  }, []);

  useEffect(() => {
    if (assignedEntityDetails) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  useEffect(() => {
    if (selectedEntity) {
      setSelectedEntity(selectedEntity);
      setProfileImageUrl(selectedEntity?.avatar_url);
    }
    if (selectedEntity.trellis_uid) {
      getProcessDetailsById(ENTITY_PROCESS_IDS.ACCREDITATION);
    }
  }, [selectedEntity]);

  useEffect(() => {
    setAvatarUrl(entityProfileURL);
    setIsLoading(false);
    setIsUploadProfileImage(true);
  }, [entityProfileURL]);

  useEffect(() => {
    const selectedTabDetail = entityTabsList?.find((entityTab) => selectedTab === entityTab?.id);
    dispatch(setSpecificEntityIdProcessDetails({} as any));
    dispatch(resetSuccessIdProcessMessage());
    dispatch(setDocumentList([] as FilePreviewType[]));
    if (selectedTabDetail?.processId) {
      getProcessDetailsById(selectedTabDetail?.processId);
    }
  }, [selectedTab]);

  //Methods
  const updateEntityProfileImage = async () => {
    const payload: UpdateEntityDetailsType = {
      entityUid: selectedEntity?.trellis_uid,
      formData: {
        avatar_url: avatarUrl,
      },
    };
    await dispatch(updateEntityDetails(payload));
    setShowProfileDialog(false);
    await dispatch(getEntityDetailById(entityId));
  };

  //upload image
  const checkImage = (event: any) => {
    const [file] = event.target.files;
    const img: HTMLImageElement = document.createElement('img');
    img.onload = async function () {
      setIsLoading(true);
      await dispatch(uploadEntityAvatarFile(file));
    };
    img.src = URL.createObjectURL(file);
    setIsUploadProfileImage(false);
  };

  //Methods
  const getProcessDetailsById = (processId: number) => {
    const data = {
      entity_uid: selectedEntity?.trellis_uid,
      process_id: processId,
    };
    dispatch(getSpecificIdProcessDetailsByProcessId(data));
  };

  const getProfilePicture = () => {
    return (
      <>
        <Box className="flex-column-center">
          <Box className="p-relative" sx={{ pt: 3 }}>
            <TCommonAvatar
              icon={'icon-profile-users icon-size-92 iconWithTextColor'}
              imageUrl={profileImageUrl || ''}
              iconSize={'180px'}
              textSize={'2rem'}
            />
            <TButton
              className={clsx('addProfileImgBtn p-absolute', classes.addProfilePicBtn)}
              icon={<Box className="icon-add icon-size-32"></Box>}
              onClick={openModal}
            />
          </Box>
          <Box>
            {accreditationStatus && (
              <Box className="flex-basic-center">
                <Box
                  sx={{ mr: 1 }}
                  className={clsx('icon-accreditation-badge icon-size-16', classes.accreditationBadgeColor)}
                ></Box>
                <Box className="textColor-200 text-medium font-weight-medium">
                  {ACCREDITATION_BADGE_TEXT[accreditationStatus]}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  };

  const openModal = () => {
    setShowProfileDialog(true);
    setIsUploadProfileImage(false);
    setAvatarUrl(selectedEntity?.avatar_url);
  };

  const closeModal = () => {
    setShowProfileDialog(false);
    setAvatarUrl(selectedEntity?.avatar_url);
  };

  const onSave = () => {
    updateEntityProfileImage();
  };

  return (
    <Box>
      <TLoader loading={isLoading} />
      <CustomTabs
        tabList={tabs}
        isVertical={true}
        borderRequired={true}
        showBottomBorder={true}
        tabSelected={setSelectedTab}
        selectedTab={selectedTab}
        extraContent={getProfilePicture()}
      ></CustomTabs>

      <TDialog
        className={classes.dialogStyle}
        title={'Edit Profile Logo/Image'}
        open={showProfileDialog}
        onClose={closeModal}
        transparentTitle={true}
        portalName={PORTAL.MERCHANT}
      >
        <Box sx={{ mb: theme.spacing(5) }}>
          <Box sx={{ px: 3, mt: 3 }}>
            <TCommonAvatar
              imageUrl={avatarUrl || ''}
              icon="icon-profile-users icon-size-92 iconWithTextColor"
              iconSize={'247px'}
              textSize={'2rem'}
            />
            <Box sx={{ mt: theme.spacing(7), mb: 5, px: 6 }} className="text-medium textColor-200 text-center">
              {t('profileUploadSectionText')}
            </Box>
            <Box className="flex-basic-center">
              <Box className="flex-basic-start align-items-start">
                {!isUploadProfileImage ? (
                  <Box className={clsx(classes.fileBtnParent, 'flex-basic-center cursorPointer')}>
                    <label className="fileUploadBtn">
                      <input
                        accept={DOCUMENT_VERIFICATION_MIME_TYPES.toString()}
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        onChange={checkImage}
                      />
                      <Box className="flex-basic-center cursorPointer">
                        <Box className={clsx('text-medium font-weight-semibold', classes.labelColor)}>Upload</Box>
                      </Box>
                    </label>
                  </Box>
                ) : (
                  <Box>
                    <TButton
                      btnText="Cancel"
                      btnWidthSize="button-w-140"
                      variant="text"
                      onClick={closeModal}
                      sx={{ mr: 3 }}
                    />
                    <TButton btnText="Save" btnWidthSize="button-w-140" variant="contained" onClick={onSave} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </TDialog>
    </Box>
  );
};

export default EntityTabs;
