import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import { colorPalette } from '../../myde-react-components';
import clsx from 'clsx';
import { NOTE_TYPE } from '../../../constants/constants';
import NoteHistoryModal from './NoteHistoryModal';
import { ClickHandlerType } from '../../../types/commonTypes';
import { selectApplicant } from '../../../redux/feature/applicant/applicantSlice';
import { getNoteHistory } from '../../../redux/feature/applicant/applicantAccountNotesSlice';

export interface MessageNoteDetailCardProps {
  messageType: string;
  messageDate: string;
  messageTime: string;
  responder: string;
  message: string;
  section?: string;
  requestorType?: string;
  openDialog?: boolean;
  onClose?: ClickHandlerType;
  isOnMessageScreen?: boolean;
}
interface StyleProps {
  messageType: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  msgBox: {
    textAlign: ({ messageType }) => (messageType === NOTE_TYPE.QUE ? 'right' : 'left'),
    border: `1px solid ${colorPalette.border.card}`,
    borderRadius: 4,
    padding: 16,
  },
  msgDetails: {
    paddingBottom: 12,
  },
}));

const MessageNoteDetailCard: FC<MessageNoteDetailCardProps> = ({
  messageType,
  messageDate,
  messageTime,
  message,
  section,
  responder,
  isOnMessageScreen,
}: MessageNoteDetailCardProps) => {
  const styleProps = {
    messageType: messageType,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();

  //constants
  const [openModal, setOpenModal] = useState(false);
  const { applicationId } = useSelector(selectApplicant);
  const params: any = useLocation();
  const noteId = params?.state?.noteId;

  const handleClick = () => {
    const data = {
      applicationId: applicationId || '',
      noteId: noteId || '',
      data: {
        note: message || '',
        section: section || '',
      },
    };
    setOpenModal(true);
    dispatch(getNoteHistory(data));
  };

  return (
    <>
      <Box className={classes.msgBox}>
        <Box onClick={handleClick}>
          <Box className={classes.msgDetails}>
            {section && (
              <span className="text-extra-small font-weight-regular textColor-300">Section: {section} | </span>
            )}
            {messageType === NOTE_TYPE.QUE && (
              <span className="text-extra-small font-weight-regular textColor-300">Asked on </span>
            )}
            <span className="text-extra-small font-weight-regular textColor-300" onClick={handleClick}>
              {messageDate} at {messageTime}
            </span>
            {responder && (
              <span>
                <span className="text-extra-small font-weight-regular textColor-300"> by </span>
                <span
                  className={clsx({
                    ['textColor-200 text-small']: true,
                    ['font-weight-semibold']: messageType === NOTE_TYPE.QUE,
                    ['font-weight-medium']: messageType !== NOTE_TYPE.QUE,
                  })}
                >
                  {responder}
                </span>
              </span>
            )}
          </Box>
          <Box
            className={clsx({
              ['textColor-200 text-medium font-weight-medium text-break-word']: messageType === NOTE_TYPE.QUE,
              ['textColor-200 text-medium font-weight-regular text-break-word']: messageType !== NOTE_TYPE.QUE,
            })}
          >
            {message}
          </Box>
        </Box>
        {isOnMessageScreen && (
          <NoteHistoryModal showDialog={openModal} title="Note History" onCancelModal={() => setOpenModal(false)} />
        )}
      </Box>
    </>
  );
};

export default MessageNoteDetailCard;
