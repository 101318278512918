import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PDF_MIME_TYPE, PDF_SUPPORTED_TYPE, ENTITY_PROCESS_IDS, APPLICATION_STATUS } from '../myde-react-components';
import { selectEntity, submitEntityIdProcess } from '../../redux/feature/id-process/entity/entitySlice';
import { useTranslation } from 'react-i18next';
import { UploadedDocumentDetailsType } from '../../types/entityTypes';
import UploadIdProcessDoc from './components/UploadIdProcessDoc';
import { FilePreviewType } from '../../types/documentTypes';

const OwnershipDocument = () => {
  // Constants
  const { t } = useTranslation(['common', 'entity']);
  const dispatch = useDispatch();

  //Redux Values
  const { specificEntityIdProcessDetails, loading, successfulIdProcessDetails, assignedEntityDetails } =
    useSelector(selectEntity);

  // State Values
  const [resetUploadedFileFlag, setResetUploadedFileFlag] = useState(false);
  const [existingFileList, setExistingFileList] = useState([] as FilePreviewType[]);

  // Use Effects
  useEffect(() => {
    if (specificEntityIdProcessDetails?.data?.document_id_list?.length > 0) {
      const previousUploadedFileList = specificEntityIdProcessDetails?.data?.document_id_list;
      const fileList: FilePreviewType[] = [];
      previousUploadedFileList?.forEach((previousFile: UploadedDocumentDetailsType) => {
        const file: FilePreviewType = {
          id: previousFile?.doc_id,
          name: previousFile?.doc_name,
          size: previousFile?.size_in_kb,
          type: previousFile?.mime_type,
          preview: previousFile?.signed_url,
          isDeleteAllowed: true,
        };
        fileList.push(file);
      });
      setExistingFileList(fileList);
    } else {
      setExistingFileList([]);
    }
  }, [specificEntityIdProcessDetails]);
  useEffect(() => {
    setResetUploadedFileFlag(Object.keys(successfulIdProcessDetails)?.length > 0);
  }, [successfulIdProcessDetails]);

  //Methods
  const handleSave = (docList: any[]) => {
    //Save API
    const payload = {
      process_id: ENTITY_PROCESS_IDS.OWNERSHIP_DOCUMENT,
      process_data: {
        document_id_list: docList?.map((doc) => doc?.doc_id || doc?.id),
      },
      action: APPLICATION_STATUS.SUBMIT,
    };
    // TODO:: Need to update entity_uid
    dispatch(submitEntityIdProcess({ entity_uid: assignedEntityDetails?.trellis_uid, payload }));
  };

  // HTML
  return (
    <UploadIdProcessDoc
      pageTitle={t('ownershipDocumentHeading', { ns: 'entity' })}
      acceptedFileFormat={PDF_MIME_TYPE}
      supportedFileFormat={PDF_SUPPORTED_TYPE}
      existingFileList={existingFileList}
      loading={loading}
      resetUploadedFile={resetUploadedFileFlag}
      isMultiple={true}
      onSave={handleSave}
    />
  );
};

export default OwnershipDocument;
