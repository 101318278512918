import React, { useEffect, useState, ClipboardEvent } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { Box, Chip, Grid, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { MAX_CHAR_LIMIT, TBadge, TSuccessDialog } from '../myde-react-components';

import {
  getCommunicationCategoriesData,
  selectCommunications,
  getMerchantTagsData,
  saveSelectedTags,
  saveSelectedUsers,
  setCommunicationFormTitle,
  resetCreateCommunicationResponse,
  resetSpecificCommunicationData,
  setCurrentTabId,
  setAllSelectedUsers,
} from '../../redux/feature/communication/communicationSlice';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { draftCommunication, transmitCommunication } from '../../api/communication';
import { getMerchantInvit, selectInvitation } from '../../redux/feature/merchantInvite/inviteSlice';

import {
  colorPalette,
  TAutocomplete,
  TTextField,
  TButton,
  TDialog,
  TConfirmDialog,
  TAlertDialog,
  TTooltip,
  theme,
} from '../myde-react-components';
import { fontStyle } from '../../styles/style';
import { useTheme } from '../../providers/custom-theme-provider';
import { INVITE_STATUS, USER_TYPE } from '../../constants/constants';
import { BtnType, IdNameType } from '../../types/commonTypes';
import { MerchantCommunicationListType, TagsResultType } from '../../types/communicationTypes';
import { InviteResultType } from '../../types/inviteTypes';

import ListModal from '../common/modals/ListModal';
import UploadDocForm from './UploadDocForm';
import { CommunicationFormSchema, CommunicationFormSchemaPayload } from './validation';
import { selectSession, setNextRoute, setShowAlert } from '../../redux/feature/common/commonSlice';
import { ContentEditableEvent, DefaultEditor } from 'react-simple-wysiwyg';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface StyleProps {
  currentThemes: Theme;
  isAllSelected: boolean;
}

// styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  label: {
    alignItems: 'start !important',
    '& .MuiTypography-root': {
      ...fontStyle.fontSize.font12,
      ...fontStyle.fontWeight.font600,
      color: colorPalette.typoText.lighten1,
    },
  },
  checkboxStyle: {
    padding: '3px 10px !important',
  },
  uploadFormContainer: {
    margin: '13px 0px !important',
    padding: '0px 26px !important',
  },
  btnAlign: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  noteCount: {
    display: 'flex',
    justifyContent: 'end',
    ...fontStyle.fontSize.font12,
  },

  recipientList: {
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
          minWidth: '0 !important',
        },
        '& .MuiAutocomplete-endAdornment': {
          '& .MuiButtonBase-root': {
            padding: '0 !important',
          },
        },
        '& .MuiAutocomplete-tag': {
          marginBottom: '7px !important',
        },
      },
    },
  },
  btnStyle: {
    color: ({ currentThemes, isAllSelected }) =>
      isAllSelected ? colorPalette.typoText.disabled : currentThemes.palette.primary.main,
    pointerEvents: 'none',
  },
  recipientEmail: {
    marginLeft: '10px',
    marginTop: '3px',
  },
  selectedRecipientName: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main}`,
  },
}));

const CommunicationForm = () => {
  // form schema setup
  const { handleSubmit, control, formState, setValue } = useForm<CommunicationFormSchemaPayload>({
    resolver: zodResolver(CommunicationFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // state variables
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [titleCount, setTitleCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [recipients, setRecipients] = useState<Array<IdNameType>>([]);
  const [openRecipients, setOpenRecipients] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [transmitDialog, setTransmitDialog] = useState(false);
  const [users, setUsers] = useState<Array<IdNameType>>([]);
  const [userIds, setUserIds] = useState<Array<string>>([]);
  const [userTags, setUserTags] = useState<Array<string>>([]);
  const [tags, setTags] = useState<Array<IdNameType>>([]);
  const [enableButtons, setEnableButtons] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [duplicateUsersList, setDuplicateUsersList] = useState<Array<IdNameType>>([]);
  const [duplicateTagsList, setDuplicateTagsList] = useState<Array<IdNameType>>([]);
  const [allUserData, setAllUserData] = useState<Array<IdNameType>>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [previousUsersData, setPreviousUsersData] = useState<Array<IdNameType>>([]);
  const [previousTagsData, setPreviousTagsData] = useState<Array<IdNameType>>([]);
  const [isUsersData, setIsUsersData] = useState(false);
  const [isTagsData, setIsTagsData] = useState(false);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [hasMoreTags, setHasMoreTags] = useState(true);
  const [invitePage, setInvitePage] = useState(1);
  const [tagsPage, setTagsPage] = useState(1);
  const [hasSearchValue, setHasSearchValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [show, setShow] = useState(false);

  // constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
    isAllSelected,
  };
  const classes = useStyles(styleProps);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const inviteParams = {
    page: invitePage,
    status: INVITE_STATUS.APPROVED,
    ordering: 'account__name',
    is_parent_application: true,
    limit: 20,
  };
  const tagParams = {
    page: tagsPage,
    ordering: 'tag_name',
    limit: 20,
  };
  // use selectors
  const {
    merchantTags,
    selectedUsers,
    selectedTags,
    documentList,
    saveInUserRepository,
    createCommunicationResponse,
    specificCommunicationData,
    hasNextTags,
  } = useSelector(selectCommunications);
  const { inviteData, hasNextInvites } = useSelector(selectInvitation);
  const { showAlert, nextRoute } = useSelector(selectSession);

  // use effects
  useEffect(() => {
    if (message?.length === MAX_CHAR_LIMIT.CHAR_LENGTH) {
      setMessageCount(message?.length);
      setValue('message', message, { shouldValidate: true });
      setMessage(message);
    }
  }, [message]);

  useEffect(() => {
    dispatch(getCommunicationCategoriesData());
  }, []);

  useEffect(() => {
    if (!showAlert && nextRoute?.path) {
      routeTo(nextRoute?.path, nextRoute?.isChild, nextRoute?.stateName, nextRoute?.query, nextRoute?.stateObj);
      dispatch(setNextRoute({}));
    }
  }, [showAlert]);

  useEffect(() => {
    if (nextRoute?.path) {
      setShowAlertDialog(true);
    }
  }, [nextRoute]);

  useEffect(() => {
    if (specificCommunicationData?.id) {
      const allUsers = getMappedData(specificCommunicationData?.account_id_name_list);
      const allTags = getMappedData(specificCommunicationData?.tag_id_name_list);
      if (specificCommunicationData?.title) {
        setValue('title', specificCommunicationData?.title, { shouldValidate: true });
      }
      setTitle(specificCommunicationData?.title);
      setTitleCount(specificCommunicationData?.title?.length);
      setMessage(specificCommunicationData?.message);
      setMessageCount(specificCommunicationData?.message?.length);
      setDuplicateUsersList(allUsers);
      setDuplicateTagsList(allTags);
      dispatch(saveSelectedUsers(allUsers));
      dispatch(saveSelectedTags(allTags));
      dispatch(setCommunicationFormTitle(specificCommunicationData?.title));
    }
  }, [specificCommunicationData]);

  useEffect(() => {
    if (invitePage > 1) {
      if (isUsersData) {
        setPreviousUsersData(users);
        dispatch(getMerchantInvit(inviteParams));
      }
    }
  }, [invitePage, isUsersData]);

  useEffect(() => {
    if (isTagsData) {
      setPreviousTagsData(tags);
      dispatch(getMerchantTagsData(tagParams));
    }
  }, [tagsPage, isTagsData]);

  useEffect(() => {
    if (!hasNextInvites) {
      setHasMoreUsers(false);
    }
  }, [hasNextInvites]);

  useEffect(() => {
    if (!hasNextTags) {
      setHasMoreTags(false);
    }
  }, [hasNextTags]);

  useEffect(() => {
    if (inviteData?.length > 0) {
      const data: Array<IdNameType> = inviteData?.map((item: InviteResultType) => {
        return {
          id: item?.account?.id,
          name: item?.account?.name,
          type: item?.account?.type,
          emailId: item?.account?.email,
        };
      });
      let combinedData: IdNameType[] = [];
      if (previousUsersData?.length > 0 && !hasSearchValue) {
        combinedData = [...previousUsersData, ...data];
      } else {
        combinedData = [...data];
      }
      setUsers(combinedData);
    } else {
      if (openRecipients) {
        setUsers([]);
      }
    }
  }, [inviteData]);

  useEffect(() => {
    if (merchantTags?.length > 0) {
      const data: Array<IdNameType> = merchantTags?.map((item: TagsResultType) => {
        return {
          id: item?.id,
          name: item?.tag_name,
        };
      });
      let combinedData: IdNameType[] = [];
      if (previousTagsData?.length > 0 && !hasSearchValue) {
        combinedData = [...previousTagsData, ...data];
      } else {
        combinedData = [...data];
      }
      setTags(combinedData);
    } else {
      if (openTags) {
        setTags([]);
      }
    }
  }, [merchantTags]);

  useEffect(() => {
    if (selectedUsers?.length > 0) {
      setOpenRecipients(false);
      dispatch(getMerchantInvit(inviteParams));
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedTags?.length > 0) {
      setOpenTags(false);
      dispatch(getMerchantTagsData(tagParams));
    }
  }, [selectedTags]);

  useEffect(() => {
    if (!openRecipients) {
      dispatch(getMerchantInvit(inviteParams));
    }
    if (!openTags) {
      dispatch(getMerchantTagsData(tagParams));
    }
  }, [openRecipients, openTags]);

  useEffect(() => {
    const filterIds: Array<string> = [];
    if (recipients?.length > 0) {
      recipients?.forEach((item: IdNameType) => {
        filterIds.push(item?.id);
      });
      setUserIds(filterIds);
      setUserTags(filterIds);
    } else {
      setUserIds([]);
      setUserTags([]);
    }
  }, [recipients]);

  useEffect(() => {
    if (duplicateUsersList?.length > 0 || duplicateTagsList?.length > 0) {
      const combinedData: Array<IdNameType> = [...duplicateUsersList, ...duplicateTagsList];
      setRecipients(combinedData);
    } else {
      setRecipients([]);
    }
  }, [duplicateUsersList, duplicateTagsList]);

  useEffect(() => {
    if (allUserData?.length > 0) {
      setRecipients(allUserData);
    } else {
      setRecipients([]);
    }
  }, [allUserData]);

  // methods
  const getCommunicationId = () => {
    if (specificCommunicationData?.id) {
      return specificCommunicationData.id;
    } else {
      return createCommunicationResponse?.id;
    }
  };

  const openRecipientsModal = () => {
    setOpenRecipients(true);
  };

  const getMappedData = (arr: IdNameType[]) => {
    let data: IdNameType[] = [];
    if (arr?.length > 0) {
      data = arr?.map((item) => {
        return {
          ...item,
        };
      });
    }
    return data;
  };

  const openTagsModal = () => {
    setOpenTags(true);
  };

  const onCancelAction = () => {
    setOpenRecipients(false);
    setOpenTags(false);
  };

  const searchRecipients = (value: string) => {
    setHasSearchValue(value);
    let data = {};
    if (value) {
      data = {
        status: INVITE_STATUS.APPROVED,
        name: value,
      };
    } else {
      data = {
        ...inviteParams,
      };
    }
    dispatch(getMerchantInvit(data));
  };

  const searchTags = (value: string) => {
    setHasSearchValue(value);
    let data = {};
    if (value) {
      data = {
        tag_name: value,
      };
    } else {
      data = {
        ...tagParams,
      };
    }
    dispatch(getMerchantTagsData(data));
  };

  const onAddActionRecipients = (data: IdNameType[]) => {
    if (data?.length > 0) {
      let combinedUsers: Array<IdNameType> = [];
      combinedUsers = [...data];
      setDuplicateUsersList(combinedUsers);
      dispatch(saveSelectedUsers(combinedUsers));
    }
    setSelectedData(false);
  };

  const onAddActionTags = (data: IdNameType[]) => {
    if (data?.length > 0) {
      let combinedTags: Array<IdNameType> = [];
      combinedTags = [...data];
      setDuplicateTagsList(combinedTags);
      dispatch(saveSelectedTags(combinedTags));
    }
    setSelectedData(false);
  };

  const getSelectedDetails = () => {
    setSelectedData(true);
  };

  const enableActionButtons = (value: boolean) => {
    setEnableButtons(value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleCount(event?.target?.value?.length);
    setTitle(event?.target?.value);
    setValue('title', event?.target?.value, { shouldValidate: true });
    dispatch(setCommunicationFormTitle(event?.target?.value));
  };

  const handleMessagePasteChange = (event: ClipboardEvent<HTMLElement>) => {
    const { clipboardData } = event;
    const pastedText = clipboardData?.getData('text');
    if (pastedText.length > MAX_CHAR_LIMIT.CHAR_LENGTH) {
      const plainText = pastedText?.substring(0, MAX_CHAR_LIMIT.CHAR_LENGTH);
      setMessageCount(plainText?.length);
      setValue('message', plainText, { shouldValidate: true });
      setMessage(plainText);
    }
  };
  const handleMessageChange = (event: ContentEditableEvent) => {
    if (event?.target?.value?.length <= MAX_CHAR_LIMIT.CHAR_LENGTH) {
      setMessageCount(event?.target?.value?.length);
      setValue('message', event?.target?.value, { shouldValidate: true });
      setMessage(event?.target?.value);
    }
  };
  const saveAsDraftOnly = async (formData: CommunicationFormSchemaPayload) => {
    const data = {
      title: formData?.title,
      message: formData?.message,
      account_id_name_list: selectedUsers,
      tag_id_name_list: selectedTags,
      document_list: documentList,
      save_in_user_repository: saveInUserRepository,
    };
    const communicationId = getCommunicationId();
    try {
      const result: MerchantCommunicationListType = await draftCommunication(communicationId, data);
      if (result?.id) {
        toast.success('Draft saved successfully !');
        dispatch(resetCreateCommunicationResponse());
        dispatch(resetSpecificCommunicationData());
        dispatch(setCurrentTabId(1));
        dispatch(setShowAlert(false));
        routeTo(ROUTES.COMMUNICATIONS);
      } else {
        toast.error('Sorry! Unable to draft communication. Please try after sometime.');
      }
    } catch (error) {
      toast.error('Sorry! Unable to draft communication. Please try after sometime.');
    }
  };

  const saveAsDraftAndTransmit = async () => {
    const data = {
      title: title,
      message: message,
      account_id_name_list: selectedUsers,
      tag_id_name_list: selectedTags,
      document_list: documentList,
      save_in_user_repository: saveInUserRepository,
    };
    const communicationId = getCommunicationId();
    try {
      const draft: MerchantCommunicationListType = await draftCommunication(communicationId, data);
      if (draft?.id) {
        const transmit = await transmitCommunication(communicationId);
        if (transmit?.id) {
          dispatch(resetCreateCommunicationResponse());
          dispatch(resetSpecificCommunicationData());
          dispatch(setCurrentTabId(0));
          dispatch(setShowAlert(false));
        } else {
          toast.error('Sorry! Unable to transmit communication. Please try after sometime.');
        }
      }
    } catch (err) {
      toast.error('Sorry! Unable to transmit communication. Please try after sometime.');
    }
  };

  const handleChipDelete = (item: IdNameType) => {
    const usersList = [...duplicateUsersList];
    const tagsList = [...duplicateTagsList];
    const allData = [...allUserData];
    const searchUser = duplicateUsersList?.findIndex((user) => user?.id === item?.id);
    const searchTag = duplicateTagsList?.findIndex((tag) => tag?.id === item?.id);
    const searchAllUsers = allData?.findIndex((user) => user?.id === item?.id);
    if (searchUser !== -1) {
      usersList.splice(searchUser, 1);
      setDuplicateUsersList(usersList);
      dispatch(saveSelectedUsers(usersList));
    } else if (searchTag !== -1) {
      tagsList.splice(searchTag, 1);
      setDuplicateTagsList(tagsList);
      dispatch(saveSelectedTags(tagsList));
    } else if (searchAllUsers !== -1) {
      setAllUserData([]);
      dispatch(saveSelectedUsers([]));
      dispatch(saveSelectedTags([]));
      setIsAllSelected(false);
      setDuplicateUsersList([]);
      setDuplicateTagsList([]);
    }
  };

  const validateAndTransmit = () => {
    if (recipients?.length === 0) {
      setTransmitDialog(true);
    } else {
      handleSubmit(saveAsDraftAndTransmit)();
    }
    setShow(true);
  };

  const handleRecipientsChange = (_event: React.SyntheticEvent, value: IdNameType[]) => {
    const uniqueUsersList = value?.filter((item: IdNameType) => {
      return item?.type === USER_TYPE.INDIVIDUAL || item?.type === USER_TYPE.ENTITY;
    });
    setDuplicateUsersList(uniqueUsersList);
    dispatch(saveSelectedUsers(uniqueUsersList));
  };

  const onCancel = () => {
    setShowDialog(false);
    setShowAlertDialog(false);
  };

  const onYes = () => {
    selectAllData(true);
    setShowDialog(false);
  };

  const onSelectAll = (_event: any, value: boolean) => {
    if (value) {
      if (recipients?.length === 0) {
        selectAllData(true);
      } else {
        setShowDialog(value);
      }
    } else {
      setIsAllSelected(value);
      setDuplicateUsersList([]);
      setDuplicateTagsList([]);
      dispatch(saveSelectedUsers([]));
      dispatch(saveSelectedTags([]));
    }
  };

  const selectAllData = (value: boolean) => {
    if (value) {
      if (users?.length > 0) {
        setIsAllSelected(true);
        dispatch(setAllSelectedUsers(true));
        const allUsers = [
          {
            id: 'all_users',
            name: 'All Recipients',
          },
        ];
        setAllUserData(allUsers);
        dispatch(saveSelectedUsers(users));
      }
    } else {
      setIsAllSelected(value);
      dispatch(setAllSelectedUsers(value));
      setDuplicateUsersList([]);
      setDuplicateTagsList([]);
      dispatch(saveSelectedUsers([]));
      dispatch(saveSelectedTags([]));
    }
  };

  const closeAndNavigate = () => {
    dispatch(setShowAlert(false));
    setShowAlertDialog(false);
  };

  const fetchMoreRecipients = (value: boolean) => {
    if (hasNextInvites) {
      setInvitePage(invitePage + 1);
    }
    setIsUsersData(value);
  };

  const fetchMoreTags = (value: boolean) => {
    if (hasNextTags) {
      setTagsPage(tagsPage + 1);
    }
    setIsTagsData(value);
  };

  const dialogBtns: BtnType[] = [
    {
      id: 1,
      title: 'Cancel',
      variant: 'text',
      buttonAction: onCancelAction,
      disabled: false,
    },
    {
      id: 2,
      title: 'Add',
      variant: 'contained',
      buttonAction: getSelectedDetails,
      disabled: !enableButtons,
    },
  ];

  const getSelectedFiles = (file: any) => {
    setSelectedFiles(file);
  };

  const goToCommunication = () => {
    setShow(false);
    routeTo(ROUTES.COMMUNICATIONS);
  };

  // HTML
  return (
    <>
      <form>
        <Box sx={{ pb: 4 }}>
          <Grid sx={{ pl: 2 }} container spacing={2}>
            <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
              To
              {isAllSelected && selectedUsers?.length > 0 ? (
                <span>{`(${selectedUsers?.length} \t recipients)`}</span>
              ) : (
                <></>
              )}
            </Box>
            <Grid sm={12} md={12} lg={12}>
              <Controller
                name="to"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TAutocomplete
                    {...field}
                    className={classes.recipientList}
                    noOptionsText={'Not Found'}
                    multiple
                    disableClearable
                    limitTags={5}
                    value={recipients}
                    options={users}
                    disablePortal={true}
                    getOptionLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props} key={option.id}>
                          <span className={clsx(selected && [classes.selectedRecipientName, 'font-weight-bold'])}>
                            {option?.name}
                          </span>
                          <span className={clsx(classes.recipientEmail, 'text-small')}>{option?.emailId}</span>
                          {option?.type === USER_TYPE.ENTITY && (
                            <span>
                              <Box sx={{ ml: 1 }}>
                                <TBadge bagdeFontStyle={'text-small font-weight-bold'} label={'E'} />
                              </Box>
                            </span>
                          )}
                        </li>
                      );
                    }}
                    onChange={handleRecipientsChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index: number) => (
                        <Box key={index}>
                          {option?.emailId ? (
                            <TTooltip title={option?.emailId}>
                              <Chip
                                {...getTagProps({ index })}
                                sx={{ mr: 1, mb: 1 }}
                                deleteIcon={<CloseIcon />}
                                variant="outlined"
                                label={option?.name}
                                onDelete={() => handleChipDelete(option)}
                              />
                            </TTooltip>
                          ) : (
                            <Chip
                              {...getTagProps({ index })}
                              sx={{ mr: 1, mb: 1 }}
                              deleteIcon={<CloseIcon />}
                              variant="outlined"
                              label={option?.name}
                              onDelete={() => handleChipDelete(option)}
                            />
                          )}
                        </Box>
                      ))
                    }
                    renderInput={(params) => (
                      <TTextField
                        {...params}
                        variant="outlined"
                        error={Boolean(errors.to)}
                        helperText={errors.to?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid
              sx={{ color: currentThemes.palette.primary.main, mb: 1 }}
              className={'flex-basic-end'}
              item
              sm={12}
              md={12}
              lg={12}
            >
              <Box
                className="font-weight-semibold text-medium cursorPointer"
                onClick={(e) => onSelectAll(e, !isAllSelected)}
                sx={{ mr: 6 }}
              >
                {isAllSelected ? '-' : '+'} All
              </Box>
              <Box
                className={clsx({
                  ['font-weight-semibold text-medium cursorPointer']: true,
                  [classes.btnStyle]: isAllSelected,
                })}
                onClick={openRecipientsModal}
                sx={{ mr: 3 }}
              >
                + Recipient
              </Box>
              <Box
                className={clsx({
                  ['font-weight-semibold text-medium cursorPointer']: true,
                  [classes.btnStyle]: isAllSelected,
                })}
                onClick={openTagsModal}
                sx={{ ml: 2 }}
              >
                + Tag
              </Box>
            </Grid>
            <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
              Title
            </Box>
            <Grid sm={12} md={12} lg={12} sx={{ mb: 4 }}>
              <Controller
                name="title"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    value={title}
                    label="Type Title Text"
                    variant="outlined"
                    error={Boolean(errors.title)}
                    helperText={errors.title?.message}
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH }}
                    onChange={handleTitleChange}
                  />
                )}
              />
              <Box
                sx={{ mt: 1 }}
                className={clsx({
                  [classes.noteCount]: true,
                  ['text-small font-weight-semibold textColor-300']: true,
                })}
              >{`${MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH - titleCount} character(s) remaining`}</Box>
            </Grid>
            <Box sx={{ mb: 1 }} className="textColor-200 text-large font-weight-semibold">
              Message
            </Box>
            <Grid sm={12} md={12} lg={12}>
              <Controller
                name="message"
                defaultValue=""
                control={control}
                render={() => (
                  <DefaultEditor
                    value={message}
                    onPaste={(event: ClipboardEvent<HTMLElement>) => handleMessagePasteChange(event)}
                    onChange={(event: ContentEditableEvent) => {
                      handleMessageChange(event);
                    }}
                  />
                )}
              />
              <Box
                sx={{ mt: 1 }}
                className={clsx({
                  [classes.noteCount]: true,
                  ['text-small font-weight-semibold textColor-300']: true,
                })}
              >{`${MAX_CHAR_LIMIT.CHAR_LENGTH - messageCount} character(s) remaining`}</Box>
            </Grid>
            <Box sx={{ pt: 4, mb: 1 }} className="textColor-200 text-large font-weight-semibold">
              Attach Files
            </Box>
            <Grid sm={12} md={12} lg={12}>
              <UploadDocForm getSelectedFiles={getSelectedFiles} />
            </Grid>
          </Grid>
          {openRecipients && (
            <ListModal
              hasSelectedData={selectedData}
              hasSelectAll={false}
              hasCheckboxSelection={true}
              open={openRecipients}
              dialogTitle={'Recipients'}
              maxWidth={'xs'}
              list={users}
              idList={userIds}
              hasMore={hasMoreUsers}
              dialogBtns={dialogBtns}
              getSelectedData={onAddActionRecipients}
              enableActionButtons={enableActionButtons}
              onSearch={searchRecipients}
              onClose={onCancelAction}
              fetchMoreData={fetchMoreRecipients}
            />
          )}
          {openTags && (
            <ListModal
              hasSelectedData={selectedData}
              hasSelectAll={false}
              hasCheckboxSelection={true}
              open={openTags}
              dialogTitle={'Tags'}
              maxWidth={'xs'}
              list={tags}
              idList={userTags}
              hasMore={hasMoreTags}
              dialogBtns={dialogBtns}
              getSelectedData={onAddActionTags}
              enableActionButtons={enableActionButtons}
              onSearch={searchTags}
              onClose={onCancelAction}
              fetchMoreData={fetchMoreTags}
            />
          )}
        </Box>
        <Box sx={{ mt: 4 }} className="flex-basic-end">
          <TButton
            sx={{ mr: theme.spacing(5) }}
            btnText="Save as Draft"
            btnWidthSize="button-w-140"
            variant="outlined"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(saveAsDraftOnly)}
          />
          <TButton
            btnText="Transmit"
            btnWidthSize="button-w-140"
            variant="contained"
            disabled={!isValid || isSubmitting || selectedFiles?.length > 0}
            onClick={validateAndTransmit}
          />
        </Box>
      </form>
      <TDialog open={transmitDialog} title={'Error'} onClose={() => setTransmitDialog(false)}>
        <Box>{t('transmitDataMessage')}</Box>
      </TDialog>
      <TConfirmDialog
        showDialog={showDialog}
        classNameConfirmDailog="confirmDialogBoxOpacityNote"
        title={'Select All Recipients'}
        noBtnTitle={'Cancel'}
        yesBtnTitle={'Select All'}
        onCancelClick={onCancel}
        onConfirmClick={onYes}
        confirmationMessage={t('selectAllText')}
        portalName={PORTAL.MERCHANT}
      />
      <TAlertDialog
        showDialog={showAlertDialog}
        content={t('confirmationTextCommunication')}
        portalName={PORTAL.MERCHANT}
        onCancel={onCancel}
        onConfirm={closeAndNavigate}
      />
      <TSuccessDialog
        open={show}
        title={t('communicationSuccessfullyTransmittedLabel')}
        message=""
        onButtonClick={goToCommunication}
        portalType={PORTAL.MERCHANT}
      />
    </>
  );
};

export default CommunicationForm;
