import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FilePreviewType } from '../../types/documentTypes';
import { DOCUMENT_VERIFICATION_MIME_TYPES, DocumentViewModal, PORTAL, theme } from '../myde-react-components';
import PdfThumbnail from '../../components/myde-react-components/src/common/FileType/Pdf_Thumbnail.svg';
import imageThumbnail from '../../components/myde-react-components/src/common/FileType/Image_Thumbnail.svg';
import { useTheme } from '../../providers/custom-theme-provider';
import { uploadDocument } from '../../api/documents';
import { DOCUMENT_UPLOAD_TYPE } from '../../constants/constants';

// Interfaces
interface PreviewFileByTypeProps {
  files: FilePreviewType[];
  handleDelete: (item: FilePreviewType) => void;
}

interface StyleProps {
  primaryColor: string;
}

// Styles
const useStyles = makeStyles<Theme, StyleProps>({
  imageContainer: {
    width: '21px',
    height: '25px',
  },
  iconColor: {
    color: ({ primaryColor }) => `${primaryColor} !important`,
  },
});

const PreviewFileByType = ({ files, handleDelete }: PreviewFileByTypeProps) => {
  // Constants
  const { currentThemes } = useTheme();
  const primaryColor = currentThemes.palette.primary.main;
  const styleProps = {
    primaryColor,
  };
  const classes = useStyles(styleProps);

  // State Variables
  const [preview, setPreview] = useState({} as FilePreviewType);

  const getThumbnail = (fileType: string) => {
    if (DOCUMENT_VERIFICATION_MIME_TYPES.includes(fileType)) {
      return imageThumbnail;
    } else {
      return PdfThumbnail;
    }
  };

  const previewDocument = (item: any) => {
    setPreview(item);
  };

  const handlePreviewClose = () => {
    setPreview({} as FilePreviewType);
  };

  // HTML
  return (
    <>
      {files?.map((item: FilePreviewType, index: number) => (
        <Box key={index}>
          <Box className="flex-basic-start">
            <Box sx={{ pr: 2 }} className="cursorPointer" onClick={() => previewDocument(item)}>
              <img src={getThumbnail(item?.type)} className={classes.imageContainer} />
            </Box>
            <Box className="align-items-start w-100">
              <Box className="text-extra-small textColor-300">
                <Box className="flex-basic-space-between w-100">
                  <Box className="cursorPointer" onClick={() => previewDocument(item)}>
                    <Box>{item?.name}</Box>
                  </Box>
                  <Box sx={{ pr: 2 }}>
                    {item?.isDeleteAllowed && (
                      <Box
                        className={clsx('icon-delete cursorPointer icon-size-16', classes.iconColor)}
                        onClick={() => handleDelete(item)}
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: theme.spacing(1) }}>{`${item?.size} Kb`}</Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
        </Box>
      ))}
      <DocumentViewModal
        open={!!preview?.preview}
        file={preview}
        portalName={PORTAL.MERCHANT}
        closeModal={handlePreviewClose}
      />
    </>
  );
};

export default PreviewFileByType;
