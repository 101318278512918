import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { getIdProcessDetailsByProcessId } from '../../../api/idProcesses';
import { IdDocProcessType, IdDocResultType } from '../../../types/idProcessTypes';

interface formData {
  applicationId: string;
  processId: number;
}

const initialState = {
  idDocLoading: false,
  error: false,
  errorObj: <any>{},
  results: <IdDocResultType>{},
  idDocProcessDetail: <IdDocProcessType>{},
};

export const selectIdDoc = ({ idDocProcess }: RootState) => ({
  idDocProcessDetail: idDocProcess.idDocProcessDetail,
  idDocLoading: idDocProcess.idDocLoading,
  error: idDocProcess.error,
  results: idDocProcess.results,
});

// Actions
export const getSpecificIdProcessDetails = createAsyncThunk(
  'idDoc/getSpecificIdProcessDetails',
  async (data: formData) => {
    return await getIdProcessDetailsByProcessId(data.applicationId, data.processId);
  },
);

// Reducers
export const idDocProcessSlice = createSlice({
  name: 'idDoc',
  initialState,
  reducers: {
    setResults: (state, action) => {
      state.results = action.payload;
    },
    resetResults: (state) => {
      state.results = <IdDocResultType>{};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecificIdProcessDetails.pending, (state) => {
        state.idDocLoading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetails.fulfilled, (state, action: any) => {
        state.idDocLoading = false;
        state.error = false;
        if (action.payload) {
          state.idDocProcessDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetails.rejected, (state) => {
        state.idDocLoading = false;
        state.error = true;
      });
  },
});

export const { setResults, resetResults } = idDocProcessSlice.actions;

export default idDocProcessSlice.reducer;
