import React, { useEffect, useState } from 'react';
import { Box, Container, Divider, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  ENTITY_INVITE_STATUS_FILTER_OPTIONS,
  MERCHANT_USER_ROLES,
  TCard,
  TDialog,
  TSearchComponent,
} from '../../components/myde-react-components';
import EntityViewList from '../../components/entityTabs/EntityViewList';
import ApplicantsFilterForm from '../../components/applicants/ApplicantsFilterForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInvitation,
  setEmailData,
  setFilters,
  setSelectedCoordinatorsData,
  setStatusData,
} from '../../redux/feature/merchantInvite/inviteSlice';
import { IdName } from '../../types/commonTypes';
import { selectUser } from '../../redux/feature/user/userSlice';
import { getAdminUserList, selectEntity } from '../../redux/feature/id-process/entity/entitySlice';

const EntityIndex = () => {
  // Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux
  const { emailData, statusData, selectedCoordinatorData, applicantFilters } = useSelector(selectInvitation);
  const { coordinatorsList } = useSelector(selectEntity);
  const { userRoles } = useSelector(selectUser);

  // State Variables
  const [coordinatorFilter, setCoordinatorFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState([] as string[]);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [coordinatorsData, setCoordinatorsData] = useState([] as IdName[]);
  const [searchValue, setSearchValue] = useState('');

  //useEffect
  useEffect(() => {
    if (userRoles?.includes(MERCHANT_USER_ROLES.ADMIN)) {
      const params = {
        role: MERCHANT_USER_ROLES.COORDINATOR,
      };
      dispatch(getAdminUserList(params));
    }
  }, []);

  useEffect(() => {
    const data = statusData?.map((item) => item?.id);
    setFilterStatus(data || []);
  }, [statusData]);

  useEffect(() => {
    setCoordinatorFilter(selectedCoordinatorData?.id ? selectedCoordinatorData?.name : '');
  }, [selectedCoordinatorData]);

  useEffect(() => {
    if (coordinatorsList?.length) {
      const result = coordinatorsList?.map((coordinator) => {
        return {
          id: coordinator?.id,
          name: `${coordinator?.first_name} ${coordinator?.last_name}`,
        };
      });
      setCoordinatorsData(result);
    }
  }, [coordinatorsList]);

  // Methods
  const handleFilterChange = debounce((value: string) => {
    setSearchValue(value);
  }, 300);

  const extraFilterHandler = () => {
    setShowFilterDialog(true);
  };

  const onSubmit = () => {
    // On Submit method
    let filters = {};
    const emailText = {};
    const statuses = {};
    const coordinator = {};
    if (emailData) {
      Object.assign(emailText, { email: emailData });
    }
    if (filterStatus?.length > 0) {
      Object.assign(statuses, { status_in: `{${filterStatus?.toString()}}` });
    }
    if (coordinatorFilter) {
      Object.assign(coordinator, { coordinator_name: coordinatorFilter });
    }
    filters = {
      ...applicantFilters,
      ...emailText,
      ...statuses,
      ...coordinator,
    };
    dispatch(setFilters(filters));
    setShowFilterDialog(false);
  };

  const handleClose = () => {
    setShowFilterDialog(false);
  };

  const resetFilters = () => {
    setSearchValue('');
    dispatch(setEmailData(''));
    dispatch(setStatusData([]));
    dispatch(setSelectedCoordinatorsData({} as IdName));
    dispatch(setFilters({}));
  };

  return (
    <Container sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <TCard>
            <Box sx={{ py: 4 }} className="flex-basic-space-between">
              <Box sx={{ px: 5 }} className="text-h2 font-weight-semibold textColor-200">
                {t('manageEntityViewTitle')}
              </Box>
              <Box sx={{ px: 4 }}>
                <Box className="flex-basic-center">
                  <Box sx={{ mr: 2 }}>
                    <TSearchComponent
                      onSearchUpdate={handleFilterChange}
                      placeholder={'Search by name'}
                      extraFilterHandler={extraFilterHandler}
                      resetFilters={resetFilters}
                      resetSearchFilter={!searchValue}
                      appliedFilters={applicantFilters}
                      appliedFilterCount={Object.keys(applicantFilters)?.length}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 4 }}>
              <EntityViewList searchValue={searchValue} />
            </Box>
          </TCard>
        </Grid>
      </Grid>
      <TDialog open={showFilterDialog} title="Filters" onClose={handleClose} onResetFilter={resetFilters}>
        <ApplicantsFilterForm
          options={ENTITY_INVITE_STATUS_FILTER_OPTIONS}
          showStatusFilter={true}
          coordinatorList={coordinatorsData}
          showCoordinatorFilter={userRoles?.includes(MERCHANT_USER_ROLES.ADMIN)}
          onSubmit={onSubmit}
        />
      </TDialog>
    </Container>
  );
};

export default EntityIndex;
