import React from 'react';

import { ApplicationType } from '../../types/applicantTypes';
import AssignCoordinator from './AssignCoordinator';

interface UserEntityIndexProps {
  applicationInfo: ApplicationType;
}

const UserEntitiesIndex = ({ applicationInfo }: UserEntityIndexProps) => {
  return <AssignCoordinator applicationInfo={applicationInfo} />;
};

export default UserEntitiesIndex;
