import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { getSpecificIdProcessDetails } from '../../redux/feature/id-process/wetSignatureSlice';
import { colorPalette, TLoader } from '../myde-react-components';
import { PredefinedProcessType } from '../../types/applicantTypes';
import UserNoteSummary from '../userNotes/UserNoteSummary';
import TabHeader from '../common/tab-header/TabHeader';
import { getIdProcessReviewStatus } from '../../utils/idProcess';
import { selectApplicationActions } from '../../redux/feature/id-process/idProcessActionsSlice';
import { selectWetSignature } from '../../redux/feature/id-process/wetSignatureSlice';
import clsx from 'clsx';

interface WetSignatureProps {
  processDetails: PredefinedProcessType;
}

const useStyles = makeStyles({
  previewImageContainer: {
    width: '80%',
    height: 'auto',
    objectFit: 'fill',
  },
  profileImage: {
    width: '100%',
    height: 'auto',
  },
  verificationItem: {
    marginTop: 14,
    padding: '10px 20px',
    background: colorPalette.containerBack.base,
    borderRadius: '100px',
    margin: '11px 0px',
    textAlign: 'center',
    width: '20%',
  },
});

const WetSignature = ({ processDetails }: WetSignatureProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { applicantionDetail, applicationId, applicationIdProcessList } = useSelector(selectApplicant);
  const { wetSignatureIdProcessDetail, wetSignatureLoading } = useSelector(selectWetSignature);
  const { loading } = useSelector(selectApplicationActions);

  const [status, setStatus] = useState('');

  useEffect(() => {
    try {
      if (processDetails?.process_id) {
        const data = {
          applicationId: applicantionDetail?.id,
          processId: processDetails?.process_id,
        };
        dispatch(getSpecificIdProcessDetails(data));
      }
    } catch (error: any) {}
  }, [processDetails]);

  useEffect(() => {
    const currentProcess =
      applicationIdProcessList?.id_process_list?.find((process) => process.process_id === processDetails?.process_id) ||
      ({} as PredefinedProcessType);
    if (Object.keys(currentProcess)?.length > 0) {
      setStatus(getIdProcessReviewStatus(currentProcess?.merchant_review_status, processDetails?.status));
    }
  }, [applicationIdProcessList, processDetails]);

  return (
    <>
      {loading || wetSignatureLoading || !status ? (
        <TLoader loading={loading || wetSignatureLoading || !status} />
      ) : (
        <>
          {Object.keys(wetSignatureIdProcessDetail)?.length > 0 && (
            <>
              <Box>
                <TabHeader
                  title={processDetails?.title}
                  status={status}
                  applicationId={applicationId}
                  processId={processDetails?.process_id}
                  accountReviewStatus={applicantionDetail?.status}
                  section={processDetails?.title}
                />
              </Box>
              <Box sx={{ my: 3 }}>
                <Box className={clsx(classes.verificationItem, 'text-medium textColor-200')}>
                  {wetSignatureIdProcessDetail?.data?.verification_string}
                </Box>
              </Box>
              <Box sx={{ my: 3 }}>
                <Box className={classes.previewImageContainer}>
                  {wetSignatureIdProcessDetail?.data?.signature?.signed_url ? (
                    <img
                      src={wetSignatureIdProcessDetail?.data?.signature?.signed_url}
                      loading="lazy"
                      className={classes.profileImage}
                      alt="Signature"
                    />
                  ) : (
                    <>No Image Available</>
                  )}
                </Box>
              </Box>

              <Grid sx={{ mt: 3 }} lg={12} sm={12} md={12} xs={12}>
                <UserNoteSummary />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WetSignature;
