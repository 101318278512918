import React, { useEffect, useState } from 'react';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import { getApplicantDetails, selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import AccountSummaryCard, { SummaryListType } from '../common/messageNotes/AccountSummaryCard';
import {
  getNoteSummary,
  selectApplicantNotes,
  setNoteUpdated,
} from '../../redux/feature/applicant/applicantAccountNotesSlice';
import { useDispatch, useSelector } from 'react-redux';
import UserNoteModal from './UserNoteModal';
import { SECTION_LIST } from '../../constants/constants';

const UserNoteSummary = () => {
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  const { applicationId, selectedSection } = useSelector(selectApplicant);
  const { noteCount, noteUpdated } = useSelector(selectApplicantNotes);

  // State Values
  const [showDialog, setDialogFlag] = useState(false);
  const [summaryList, setSummaryList] = useState([] as SummaryListType[]);

  // Use Effects
  useEffect(() => {
    const summary: SummaryListType[] = [
      {
        label: 'All',
        value: noteCount,
      },
    ];
    setSummaryList(summary);
  }, [noteCount]);
  useEffect(() => {
    if (applicationId) {
      const currentSection = selectedSection !== SECTION_LIST.ACCOUNT ? selectedSection : '';
      getUpdatedNotesSummary(currentSection);
    }
  }, [applicationId, selectedSection]);
  useEffect(() => {
    if (noteUpdated) {
      getUpdatedNotesSummary(selectedSection);
      dispatch(setNoteUpdated(false));
      dispatch(getApplicantDetails(applicationId));
    }
  }, [noteUpdated]);

  // Methods
  const getUpdatedNotesSummary = (sectionName: string) => {
    const summaryData = {
      applicationId: applicationId,
      section: sectionName,
    };
    dispatch(getNoteSummary(summaryData));
  };

  const viewNotes = () => {
    const query = { inviteId: applicationId };
    routeTo(ROUTES.USER_NOTES, false, '', query);
  };

  return (
    <>
      <AccountSummaryCard
        title={'Notes'}
        summaryList={summaryList}
        headerBtnTxt={'+ Add Note'}
        onHeaderClick={viewNotes}
        onHeaderBtnClick={() => {
          setDialogFlag(true);
        }}
      />
      {showDialog && (
        <UserNoteModal
          openDialog={showDialog}
          onClose={() => {
            setDialogFlag(false);
          }}
        />
      )}
    </>
  );
};

export default UserNoteSummary;
