import * as z from 'zod';

export const LoginFormSchema = z.object({
  email: z.string().nonempty('Email is required').email(),
  password: z.string().nonempty('Password is required'),
});

export const LoginAsFormSchema = z.object({
  merchant: z.string()
});
export type LoginFormSchemaPayload = z.infer<typeof LoginFormSchema>;
export type LoginAsFormSchemaPayload = z.infer<typeof LoginAsFormSchema>;