import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getSpecificIdProcessDetails, selectW9IdProcess } from '../../redux/feature/id-process/w9IdProcessSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { FilePreviewType, TButton, TDocumentViewModal, TLoader } from '../../components/myde-react-components';
import { DocumentListType, PredefinedProcessType } from '../../types/applicantTypes';
import UserNoteSummary from '../../components/userNotes/UserNoteSummary';
import TabHeader from '../common/tab-header/TabHeader';
import { getIdProcessReviewStatus } from '../../utils/idProcess';
import { PROCESS_IDS } from '../../constants/constants';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface W9TabProps {
  idProcess: PredefinedProcessType;
}

const W9Tab = ({ idProcess }: W9TabProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // Redux Values
  const { applicationId, applicantionDetail, applicationIdProcessList } = useSelector(selectApplicant);
  const { w9IdProcessDetail, w9Loading } = useSelector(selectW9IdProcess);

  // State Values
  const [processId, setProcessId] = useState(0);
  const [status, setStatus] = useState('');
  const [file, setFile] = useState({} as FilePreviewType);
  const [showModal, setShowModal] = useState(false);

  // Use Effects
  useEffect(() => {
    try {
      if (idProcess?.process_id) {
        setProcessId(idProcess?.process_id);
      }
    } catch (error: any) {}
  }, [idProcess]);

  useEffect(() => {
    if (processId > 0) {
      getProcessDetails();
    }
  }, [processId]);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      const data = applicationIdProcessList?.id_process_list?.find(
        (item) => item.process_id === PROCESS_IDS.W9_DOCUMENT,
      );
      if (data?.process_id) {
        setStatus(getVerificationStatus(data?.merchant_review_status, w9IdProcessDetail?.status));
      }
    }
  }, [applicationIdProcessList, w9IdProcessDetail]);

  const getProcessDetails = () => {
    const data = {
      applicationId: applicationId,
      processId: processId,
    };
    dispatch(getSpecificIdProcessDetails(data));
  };

  const setPreviewFiles = (file: DocumentListType) => {
    const data = {
      name: file?.doc_name,
      type: file?.mime_type,
      preview: file?.signed_url,
      size: file?.size_in_kb,
    };
    setFile(data);
    setShowModal(true);
  };

  const getVerificationStatus = (reviewStatus: string, currentStatus: string) => {
    return getIdProcessReviewStatus(reviewStatus, currentStatus);
  };

  // HTML
  return (
    <>
      {w9Loading || processId === 0 ? (
        <TLoader loading={w9Loading || processId === 0} />
      ) : (
        <Box>
          <TabHeader
            title="Form W-9"
            status={status}
            applicationId={applicationId}
            processId={processId}
            accountReviewStatus={applicantionDetail?.status}
            section={idProcess?.title}
          />
          {w9IdProcessDetail && (
            <Box>
              <Box sx={{ my: 5 }} className="text-medium textColor-300">
                <Box sx={{ mb: 1 }} className="font-weight-bold">
                  {t('w9Quesation')}
                </Box>
                {t('w9TabDescText')}
              </Box>
              <TButton
                variant="contained"
                btnText="View Document"
                disabled={!w9IdProcessDetail?.data?.signed_document}
                onClick={() => {
                  setPreviewFiles(w9IdProcessDetail?.data?.signed_document);
                }}
              />
              <TDocumentViewModal
                open={showModal}
                file={file}
                closeModal={() => setShowModal(false)}
                portalName={PORTAL.MERCHANT}
              />
            </Box>
          )}
          <Grid sx={{ mt: 3 }} lg={12} sm={12} md={12} xs={12}>
            <UserNoteSummary />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default W9Tab;
