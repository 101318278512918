import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
  getCountOfNotifications,
  getSystemNotifications,
  getUserNotifications,
  markNotificationsAsRead,
} from '../../../api/notifications';
import {
  NotificationsResultType,
  NotificationsType,
} from '../../../components/myde-react-components/src/components/Notifications/notificationsType';

type NotificationState = {
  loading: boolean;
  error: boolean;
  isNotificationRead: boolean;
  notificationCount: number;
  notifications: NotificationsResultType[];
  systemNotificationSet: NotificationsType;
  userNotificationSet: NotificationsType;
};

const initialState: NotificationState = {
  loading: false,
  error: false,
  notificationCount: 0,
  notifications: [],
  systemNotificationSet: <NotificationsType>{},
  userNotificationSet: <NotificationsType>{},
  isNotificationRead: false,
};

//selector
export const selectNotification = ({ notification }: RootState) => ({
  loading: notification.loading,
  notificationCount: notification.notificationCount,
  notifications: notification.notifications,
  systemNotificationSet: notification.systemNotificationSet,
  userNotificationSet: notification.userNotificationSet,
  isNotificationRead: notification.isNotificationRead,
});

//actions
export const getNotificationsCount = createAsyncThunk('notifications/getNotificationsCount', async () => {
  return await getCountOfNotifications();
});

export const getMerchantSystemNotifications = createAsyncThunk(
  'notifications/getMerchantSystemNotifications',
  async (params: object) => {
    return await getSystemNotifications(params);
  },
);

export const getMerchantUserNotifications = createAsyncThunk(
  'notifications/getMerchantUserNotifications',
  async (params: object) => {
    return await getUserNotifications(params);
  },
);

export const markAsRead = createAsyncThunk('notifications/markAsRead', async (data: object) => {
  return await markNotificationsAsRead(data);
});

//reducer
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setIsNotificationRead: (state, action) => {
      state.isNotificationRead = action?.payload;
    },
    clearUserNotifications: (state) => {
      state.userNotificationSet = {} as NotificationsType;
    },
    clearSystemNotifications: (state) => {
      state.systemNotificationSet = {} as NotificationsType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsCount.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getNotificationsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.notificationCount = action?.payload?.count;
        } else {
          state.error = true;
        }
      })
      .addCase(getNotificationsCount.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantSystemNotifications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMerchantSystemNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.systemNotificationSet = action.payload;
          state.notifications = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantSystemNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantUserNotifications.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMerchantUserNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userNotificationSet = action.payload;
          state.notifications = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantUserNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(markAsRead.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = false;
        } else {
          state.error = true;
        }
      })
      .addCase(markAsRead.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setIsNotificationRead, clearSystemNotifications, clearUserNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
