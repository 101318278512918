import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { colorPalette, theme } from '../myde-react-components';
import { TDrawerProps } from './Drawer';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import UserIcon from './UserIcon';

const drawerWidth = 240;

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: '75%',
      boxSizing: 'border-box',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    overflow: 'unset',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.12)',
    height: '100vh',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.12)',
    height: '100vh',
    overflow: 'unset',
    width: drawerWidth / 2,
  },
  toolbar: {
    position: 'relative',
    height: '89px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '89px',
  },

  drawerIcon: {
    height: '20px',
    width: '20px',
    borderRadius: '2px',
    color: theme.palette.primary.main,
    backgroundColor: colorPalette.containerBack.lighten3,
  },
  flipIcon: {
    transform: 'rotate(-180deg)',
  },

  //Menu List Css
  menuListItem: {
    minHeight: '35px',
    padding: '7px 0px',
    borderRadius: '0px',
  },
  menuIcon: {
    marginRight: '10px',
    paddingLeft: '42px',
  },
  toggleIcon: {
    height: '32px',
    width: '32px',
    position: 'absolute',
    right: '-16px',
    top: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '999999',
    boxShadow: '4px 0px 12px rgb(0 0 0 / 12%)',
    borderRadius: '50%',
    transform: 'rotate(-270deg)',
    background: colorPalette.containerBack.lighten3,
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  menuText: {
    fontSize: '16px',
    lineHeight: '19.5px',
    color: colorPalette.info,
  },
  listItem: {
    '& MuiListItem': {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
        color: colorPalette.info,
      },
    },
  },
  selected: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontWeight: 400,
    borderRight: `4px solid ${theme.palette.primary.main}`,
    '&$selected': {
      color: theme.palette.primary.main,
      backgroundColor: colorPalette.backgroundFadeColors.primary,
    },
    '&:hover': {
      backgroundColor: colorPalette.menu.base,
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  menuLabel: {
    color: theme.palette.primary.main,
  },
  appBar: {
    '& .MuiPaper-root.MuiAppBar-root': {
      backgroundColor: colorPalette.containerBack.base,
      boxShadow: 'none',
    },
    '& .MuiToolbar-root': {
      position: 'absolute',
    },
  },
  toolbarAlign: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerHeader: { marginRight: 'auto', marginLeft: 'auto' },
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface MobileDrawerProps {
  children?: any;
  drawerProps: TDrawerProps;
}

const MobileDrawer = ({ children, drawerProps }: MobileDrawerProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const menuItems = drawerProps.listItems;

  const [activeItem, setactiveItem] = React.useState(menuItems[0].id);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event: any, index: any, to: string) => {
    setactiveItem(index);
    navigate(to);
    setOpen(false);
  };

  const renderMenu = (item: any) => {
    return (
      <ListItem
        selected={activeItem === item.id}
        button
        key={item.id}
        className={clsx({
          [classes.menuListItem]: true,
          [classes.selected]: activeItem === item.id,
        })}
        onClick={(event) => handleListItemClick(event, item.id, item.to)}
      >
        <ListItemIcon
          className={clsx({
            [classes.menuIcon]: true,
            [classes.icon]: activeItem === item.id,
          })}
        >
          {item.icon}
        </ListItemIcon>
        {open && (
          <ListItemText>
            <span
              className={clsx({
                [classes.menuText]: true,
                [classes.menuLabel]: activeItem === item.id,
              })}
            >
              {item.title}
            </span>
          </ListItemText>
        )}
      </ListItem>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box className={classes.appBar}>
        <AppBar position="fixed" open={open}>
          <Toolbar className={classes.toolbarAlign}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon color="primary" />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open}>
        <DrawerHeader>
          <Box sx={{ pt: 3, pb: 2 }} className={classes.drawerHeader}>
            <UserIcon iconSize={'60px'} />
          </Box>
          <IconButton onClick={handleDrawerClose} sx={{ mb: 'auto', mt: 2 }}>
            {theme.direction === 'ltr' ? <CloseIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <List>{menuItems.map((item) => renderMenu(item))}</List>
        </List>
      </Drawer>
      <Main sx={{ pt: 3 }}>{children}</Main>
    </Box>
  );
};

export default MobileDrawer;
