import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../..';
import {
  createNewMsg,
  filteredRecords,
  getApplicationMessageList,
  getApplicationMessageSummary,
  getMessageDetailById,
  updateMerchantReply,
  updateMsgStatus,
} from '../../../api/applicants';
import { MsgApiFormData, MsgFilterApiFormData } from '../../../components/userMessages/UserMessageList';
import { ApplicantAppliedFilterType, ApplicantMessageType, MessageSummaryType } from '../../../types/applicantTypes';

const initialState = {
  loading: false,
  error: false,
  errorObj: <any>{},
  messageList: [] as ApplicantMessageType[],
  messageSummary: <MessageSummaryType>{},
  newMsgAdded: false,
  msgUpdated: false,
  selectedMessageId: '',
  count: 0,
  selectedMessageDetail: <ApplicantMessageType>{},
  filterSet: <ApplicantAppliedFilterType>{},
  isArchived: false,
  isResolved: false,
};

export const selectApplicationMessage = ({ applicationMessage }: RootState) => ({
  loading: applicationMessage.loading,
  error: applicationMessage.error,
  messageList: applicationMessage.messageList,
  messageSummary: applicationMessage.messageSummary,
  newMsgAdded: applicationMessage.newMsgAdded,
  msgUpdated: applicationMessage.msgUpdated,
  selectedMessageId: applicationMessage.selectedMessageId,
  selectedMessageDetail: applicationMessage.selectedMessageDetail,
  filterSet: applicationMessage.filterSet,
  count: applicationMessage.count,
  isArchived: applicationMessage.isArchived,
  isResolved: applicationMessage.isResolved,
});

// Actions
export const getMessageDetails = createAsyncThunk(
  'applicationMessage/getMessageDetails',
  async (formData: MsgApiFormData) => {
    return await getApplicationMessageList(formData);
  },
);
export const getMessageSummary = createAsyncThunk(
  'applicationMessage/getMessageSummary',
  async (applicationId: string) => {
    return await getApplicationMessageSummary(applicationId);
  },
);
export const getMsgDetailById = createAsyncThunk(
  'applicationMessage/getMsgDetailById',
  async (data: { applicationId: string; messageId: string }) => {
    return await getMessageDetailById(data);
  },
);
export const addMsg = createAsyncThunk(
  'applicationMessage/addMsg',
  async (data: { applicationId: string; request: string }) => {
    return await createNewMsg(data);
  },
);
export const updateMsg = createAsyncThunk(
  'applicationMessage/updateMsg',
  async (data: { applicationId: string; msgId: string; status: string }) => {
    return await updateMsgStatus(data);
  },
);
export const updateMerchantMsg = createAsyncThunk(
  'applicationMessage/updateMerchantMsg',
  async (data: { text: string; appId: string; msgId: string }) => {
    return await updateMerchantReply(data);
  },
);

export const getFilteredRecords = createAsyncThunk(
  'applicationMessage/getfilteredRecords',
  async (filterData: MsgFilterApiFormData) => {
    return await filteredRecords(filterData);
  },
);

// Reducers
export const applicationMessageSlice = createSlice({
  name: 'applicationMessage',
  initialState,
  reducers: {
    setNewMsgFlag: (state, action) => {
      state.newMsgAdded = action.payload;
    },
    setMsgUpdatedFlag: (state, action) => {
      state.msgUpdated = action.payload;
    },
    setSelectedMessageId: (state, action) => {
      state.selectedMessageId = action.payload;
    },
    setFilterSet: (state, action) => {
      state.filterSet = action.payload;
    },
    setIsArchived: (state, action) => {
      state.isArchived = action.payload;
    },
    setIsResolved: (state, action) => {
      state.isResolved = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getMessageDetails
      .addCase(getMessageDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMessageDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.count = action.payload.count;
          state.messageList = action.payload?.results || [] as ApplicantMessageType[];
        } else {
          state.error = true;
        }
      })
      .addCase(getMessageDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // addMsg
      .addCase(addMsg.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addMsg.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.newMsgAdded = true;
          toast.success('Message sent successfully !');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to add new message. Please try after sometime.');
        }
      })
      .addCase(addMsg.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // updateMsg
      .addCase(updateMsg.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateMsg.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.msgUpdated = true;
          toast.success('Message status updated successfully!');
        } else {
          state.error = true;
          toast.error('Sorry! Unable to update your message status. Please try after sometime.');
        }
      })
      .addCase(updateMsg.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getMessageSummary
      .addCase(getMessageSummary.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMessageSummary.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.messageSummary = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getMessageSummary.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // getMsgDetailById
      .addCase(getMsgDetailById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMsgDetailById.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.selectedMessageDetail = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getMsgDetailById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // updateMerchantMsg
      .addCase(updateMerchantMsg.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateMerchantMsg.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.newMsgAdded = true;
        } else {
          state.error = true;
        }
      })
      .addCase(updateMerchantMsg.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getFilteredRecords.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getFilteredRecords.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.messageList = action.payload?.results || [] as ApplicantMessageType[];
        } else {
          state.error = true;
        }
      })
      .addCase(getFilteredRecords.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { setNewMsgFlag, setMsgUpdatedFlag, setSelectedMessageId, setFilterSet, setIsArchived,setIsResolved } =
  applicationMessageSlice.actions;

export default applicationMessageSlice.reducer;
