import { apiDelete, apiGet, apiPatch, apiPost } from './axios';

export const getAllEntityMembersByRole = async (entity_uid: number, filters?: object) => {
  const res = await apiGet(`/entity/${entity_uid}`, { params: filters });
  return res.data;
};

export const deleteMember = async (entity_uid: number, trellis_uid: number) => {
  const res = await apiDelete(`/entity/${entity_uid}/member/${trellis_uid}`);
  return res.data;
};

export const updateMember = async (entity_uid: number, trellis_uid: number, payload: object) => {
  const res = await apiPatch(`/entity/${entity_uid}/member/${trellis_uid}`, payload);
  return res.data;
};

export const sendMemberInvites = async (entity_uid: number, payload: object) => {
  const res = await apiPost(`/entity/${entity_uid}/invite`, payload);
  return res.data;
};
