import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getIdRequirementsOfMerchant,
  getIdProcessMasterDetails,
  getTAppsForMerchant,
  addCustomRequirement,
  editCustomRequirement,
  listCustomRequirements,
  getInviteTemplate,
  getInviteTemplatesListData,
} from '../../../api/invitations';
import { RootState } from '../..';
import {
  IdProcessResultType,
  TAppDataType,
  TAppFormData,
  TAppFormInviteFields,
  CustomIdResultType,
  IndividualInviteTemplate,
  EntityInviteTemplate,
  InviteTemplateResultsType,
  CustomLibraryDocumentResult,
  LibraryDocumentSelectionType,
} from '../../../types/inviteTypes';
import { USER_TYPE } from '../../../constants/constants';
import { RepositoryResultType } from '../../../types/repositoryTypes';
import { getRepositories } from '../../../api/repository';

const initialState = {
  individualIdProcessData: [] as IdProcessResultType[],
  entityIdProcessData: [] as IdProcessResultType[],
  merchantTApps: <TAppDataType>{},
  customIdRequirementResult: <CustomIdResultType>{},
  editCustomIdRequirementResult: <CustomIdResultType>{},
  customIdRequirementList: [] as CustomIdResultType[],
  loading: false,
  error: false,
  errorObj: <any>{},
  isCsvUploaded: false,
  isFormValid: false,
  tAppFormData: [] as TAppFormData[],
  tAppFormDataWithoutFields: [] as TAppFormData[],
  tAppFieldData: [] as TAppFormInviteFields[],
  isEntityInvite: null,
  individualInviteTemplate: <IndividualInviteTemplate>{},
  entityInviteTemplate: <EntityInviteTemplate>{},
  inviteTemplateList: [] as InviteTemplateResultsType[],
  specificInviteTemplate: <InviteTemplateResultsType>{},
  templateId: '',
  libraryDocumentList: [] as RepositoryResultType[],
  addLibraryDocument: <CustomLibraryDocumentResult>{},
  editLibraryData: <LibraryDocumentSelectionType>{},
};

// Selector
export const selectMerchantInvite = ({ invites }: RootState) => ({
  individualIdProcessData: invites.individualIdProcessData,
  entityIdProcessData: invites.entityIdProcessData,
  merchantTApps: invites.merchantTApps,
  loading: invites.loading,
  error: invites.error,
  isCsvUploaded: invites.isCsvUploaded,
  isFormValid: invites.isFormValid,
  tAppFormData: invites.tAppFormData,
  tAppFormDataWithoutFields: invites.tAppFormDataWithoutFields,
  tAppFieldData: invites.tAppFieldData,
  customIdRequirementResult: invites.customIdRequirementResult,
  customIdRequirementList: invites.customIdRequirementList,
  editCustomIdRequirementResult: invites.editCustomIdRequirementResult,
  isEntityInvite: invites.isEntityInvite,
  individualInviteTemplate: invites.individualInviteTemplate,
  entityInviteTemplate: invites.entityInviteTemplate,
  inviteTemplateList: invites.inviteTemplateList,
  specificInviteTemplate: invites.specificInviteTemplate,
  templateId: invites.templateId,
  libraryDocumentList: invites.libraryDocumentList,
  addLibraryDocument: invites.addLibraryDocument,
  editLibraryData: invites.editLibraryData,
});

// Actions
export const getMerchantIdRequirements = createAsyncThunk('merchantInvite/getMerchantIdRequirements', async () => {
  return await getIdRequirementsOfMerchant();
});

export const getIndividualIdProcesses = createAsyncThunk(
  'merchantInvite/getIndividualIdProcesses',
  async (params: object) => {
    return await getIdProcessMasterDetails(params);
  },
);

export const getEntityIdProcesses = createAsyncThunk('merchantInvite/getEntityIdProcesses', async (params: object) => {
  return await getIdProcessMasterDetails(params);
});

export const getMerchantTApps = createAsyncThunk('merchantInvite/getMerchantTApps', async (params: object) => {
  return await getTAppsForMerchant(params);
});

export const addCustomIdRequirement = createAsyncThunk(
  'merchantInvite/addCustomIdRequirement',
  async (formData: object) => {
    return await addCustomRequirement(formData);
  },
);

export const editCustomIdRequirement = createAsyncThunk(
  'merchantInvite/editCustomIdRequirement',
  async (formData: { id: string; process_name: string; process_description: string }) => {
    return await editCustomRequirement(formData?.id, {
      process_name: formData.process_name,
      process_description: formData.process_description,
    });
  },
);

export const listCustomIdRequirement = createAsyncThunk(
  'merchantInvite/listCustomIdRequirement',
  async (params: object) => {
    return await listCustomRequirements(params);
  },
);

export const getIndividualInviteTemplate = createAsyncThunk('merchantInvite/getIndividualInviteTemplate', async () => {
  return await getInviteTemplate({});
});

export const getEntityInviteTemplate = createAsyncThunk('merchantInvite/getEntityInviteTemplate', async () => {
  return await getInviteTemplate({ type: USER_TYPE.ENTITY });
});

export const getInviteTemplatesList = createAsyncThunk(
  'merchantInvite/getInviteTemplatesList',
  async (params: object) => {
    return await getInviteTemplatesListData(params);
  },
);

export const getLibraryDocumentList = createAsyncThunk(
  'merchantInvite/getLibraryDocumentList',
  async (params?: object) => {
    return await getRepositories(params || {});
  },
);

export const addCustomLibraryDocument = createAsyncThunk(
  'merchantInvite/addCustomLibraryDocument',
  async (formData: object) => {
    return await addCustomRequirement(formData);
  },
);

export const editCustomLibraryDocument = createAsyncThunk(
  'merchantInvite/editCustomLibraryDocument',
  async (formData: { id: string; payload: object }) => {
    return await editCustomRequirement(formData?.id, formData?.payload);
  },
);

// Reducers
export const merchantInviteSlice = createSlice({
  name: 'merchantInviteSlice',
  initialState,
  reducers: {
    setIsCsvUploaded: (state, action) => {
      state.isCsvUploaded = action?.payload;
    },
    setIsFormValid: (state, action) => {
      state.isFormValid = action?.payload;
    },
    setTAppFormData: (state, action) => {
      state.tAppFormData = action?.payload;
    },
    setTAppFormDataWithoutFields: (state, action) => {
      state.tAppFormDataWithoutFields = action?.payload;
    },
    setTAppFieldData: (state, action) => {
      state.tAppFieldData = action?.payload;
    },
    resetCustomIdResultData: (state) => {
      state.customIdRequirementResult = <CustomIdResultType>{};
      state.editCustomIdRequirementResult = <CustomIdResultType>{};
    },
    setIsEntityInvite: (state, action) => {
      state.isEntityInvite = action.payload;
    },
    // TODO: to be removed once multiple templates design is updated
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setAddCustomLibraryDocument: (state, action) => {
      state.addLibraryDocument = action.payload;
    },
    setEditLibraryData: (state, action) => {
      state.editLibraryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndividualInviteTemplate.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIndividualInviteTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.individualInviteTemplate = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getIndividualInviteTemplate.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityInviteTemplate.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityInviteTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.entityInviteTemplate = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityInviteTemplate.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getIndividualIdProcesses.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIndividualIdProcesses.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.individualIdProcessData = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getIndividualIdProcesses.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityIdProcesses.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityIdProcesses.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.entityIdProcessData = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityIdProcesses.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getMerchantTApps.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getMerchantTApps.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.merchantTApps = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getMerchantTApps.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addCustomIdRequirement.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addCustomIdRequirement.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.customIdRequirementResult = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(addCustomIdRequirement.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(editCustomIdRequirement.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(editCustomIdRequirement.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload) {
          state.editCustomIdRequirementResult = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(editCustomIdRequirement.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(listCustomIdRequirement.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(listCustomIdRequirement.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.customIdRequirementList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(listCustomIdRequirement.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getInviteTemplatesList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getInviteTemplatesList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action?.payload?.results) {
          state.inviteTemplateList = action.payload.results;
          state.specificInviteTemplate = action.payload.results[0]; // TODO: to be removed once multiple templates design is updated
          state.templateId = action.payload.results[0]?.id;
        } else {
          state.error = true;
        }
      })
      .addCase(getInviteTemplatesList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getLibraryDocumentList.pending, (state) => {
        state.error = false;
      })
      .addCase(getLibraryDocumentList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.libraryDocumentList = action?.payload?.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getLibraryDocumentList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(addCustomLibraryDocument.pending, (state) => {
        state.error = false;
      })
      .addCase(addCustomLibraryDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.addLibraryDocument = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(addCustomLibraryDocument.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(editCustomLibraryDocument.pending, (state) => {
        state.error = false;
      })
      .addCase(editCustomLibraryDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.addLibraryDocument = action?.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(editCustomLibraryDocument.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setIsCsvUploaded,
  setIsFormValid,
  setTAppFormData,
  setTAppFormDataWithoutFields,
  setTAppFieldData,
  resetCustomIdResultData,
  setIsEntityInvite,
  setTemplateId,
  setAddCustomLibraryDocument,
  setEditLibraryData,
} = merchantInviteSlice.actions;

export default merchantInviteSlice.reducer;
