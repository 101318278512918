import React from 'react';
import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { fontStyle } from '../../styles/style';
import { colorPalette, SUPPORT_EMAILS } from '../myde-react-components';

const useStyles = makeStyles({
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    ...fontStyle.fontSize.font14,
    ...fontStyle.fontWeight.font400,
    fontFamily: 'Montserrat',
  },
  link: {
    color: colorPalette.primary,
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

const PublicFooter = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <>
      <Box className={classes.linkContainer}>
        <Box>
          <Link
            href={process.env.REACT_APP_PRIVACY_POLICY}
            target="_blank"
            className="textDecorationNone text-medium font-weight-medium cursorPointer"
            rel="noopener noreferrer"
          >
            {t('privacyPolicy')}
          </Link>
        </Box>
        <Box>
          <Link
            className="text-medium font-weight-medium cursorPointer textDecorationNone"
            href={`mailto: ${SUPPORT_EMAILS.HELP_ASSISTANCE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('helpandAssistance')}
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default PublicFooter;
