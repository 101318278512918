import React from 'react';
import { Container, Grid } from '@mui/material';
import CommunicationLanding from './CommunicationLanding';

const CommunicationIndex = () => {
  // HTML
  return (
    <Container>
      <Grid sx={{ px: 2, py: 5 }} item sm={12} md={12} lg={12}>
        <CommunicationLanding />
      </Grid>
    </Container>
  );
};

export default CommunicationIndex;
