import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IncomeVerificationResultType, PredefinedProcessType } from '../../types/applicantTypes';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectIncomeVerificationIdProcess,
  getIncomeVerificationDetails,
} from '../../redux/feature/id-process/incomeVerificationSlice';
import { selectApplicant } from '../../redux/feature/applicant/applicantSlice';
import { FilePreviewType, PROCESS_IDS, theme, TLoader } from '../myde-react-components';
import TabHeader from '../common/tab-header/TabHeader';
import PreviewFileByType from './PreviewFileByType';
import { getIdProcessReviewStatus } from '../../utils/idProcess';
import { PORTAL } from '../myde-react-components/src/constants/portal';
import UserNoteSummary from '../userNotes/UserNoteSummary';

interface IncomeVerificationProps {
  idProcess: PredefinedProcessType;
}

function IncomeVerificationTab({ idProcess }: IncomeVerificationProps) {
  //constants
  const { applicationId, applicantionDetail, applicationIdProcessList } = useSelector(selectApplicant);
  const { incomeIdProcessDetail, loading } = useSelector(selectIncomeVerificationIdProcess);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  //state
  const [incomeVerificationData, setIncomeVerificationData] = useState({} as IncomeVerificationResultType);
  const [files, setFiles] = useState([] as FilePreviewType[]);
  const [status, setStatus] = useState('');

  //useEffect
  useEffect(() => {
    const data = {
      applicationId: applicationId,
      processId: idProcess?.process_id,
    };
    dispatch(getIncomeVerificationDetails(data));
  }, []);

  useEffect(() => {
    if (incomeIdProcessDetail && Object.keys(incomeIdProcessDetail)?.length > 0) {
      setIncomeVerificationData(incomeIdProcessDetail);
    }
  }, [incomeIdProcessDetail]);

  useEffect(() => {
    setPreviewFiles(incomeVerificationData?.data?.document_id_list);
  }, [incomeVerificationData?.data?.document_id_list]);

  useEffect(() => {
    if (applicationIdProcessList?.id_process_list?.length > 0) {
      const data = applicationIdProcessList?.id_process_list?.find(
        (item) => item.process_id === PROCESS_IDS.INCOME_VERIFICATION,
      );
      if (data?.process_id) {
        setStatus(getVerificationStatus(data?.merchant_review_status, incomeVerificationData?.status));
      }
    }
  }, [applicationIdProcessList, incomeVerificationData]);

  //methods
  const setPreviewFiles = (files: any) => {
    const fileList: any[] = [];
    files?.forEach((file: any) => {
      const data = {
        name: file?.doc_name,
        type: file?.mime_type,
        preview: file?.signed_url,
        size: file?.size_in_kb,
      };
      fileList?.push(data);
    });
    const combineFile = [...fileList];
    setFiles(combineFile);
  };

  const getVerificationStatus = (reviewStatus: string, currentStatus: string) => {
    return getIdProcessReviewStatus(reviewStatus, currentStatus);
  };

  return (
    <>
      <Box>
        <TLoader loading={loading} />
        <Box>
          <TabHeader
            title={'Income Verification'}
            status={status}
            applicationId={applicationId}
            processId={idProcess?.process_id}
            accountReviewStatus={applicantionDetail?.status}
            section={idProcess?.title}
          />
        </Box>
        <Box sx={{ pt: theme.spacing(3) }} className="text-medium textColor-300">
          {t('incomeVerificationText')}
        </Box>
        <Box sx={{ pt: 3 }} className="text-medium textColor-300">
          {t('documents')}
        </Box>
        <Box>
          <PreviewFileByType portalName={PORTAL.MERCHANT} files={files} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <UserNoteSummary />
        </Box>
      </Box>
    </>
  );
}

export default IncomeVerificationTab;
