import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { DIALOG_WIDTH, MAX_CHAR_LIMIT, TAlertDialog, TTextArea } from '../myde-react-components';
import { PORTAL } from '../myde-react-components/src/constants/portal';

interface AddNoteModalProps {
  showDialog: boolean;
  requirement: any;
  onAdd: (data: any) => void;
  onCancel: () => void;
}
const AddNoteModal = ({ showDialog, requirement, onAdd, onCancel }: AddNoteModalProps) => {
  // State Variables
  const [value, setValue] = useState('');
  const [count, setCount] = useState(0);

  // Use Effect
  useEffect(() => {
    if (requirement?.note) {
      setValue(requirement?.note);
    } else {
      setValue('');
    }
  }, [requirement]);

  // Methods
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event?.target?.value);
    setCount(event?.target?.value?.length);
  };

  const addNote = () => {
    const payload = {
      ...requirement,
      note: value,
    };
    onAdd(payload);
    onCancel();
  };

  // HTML
  return (
    <Box>
      <TAlertDialog
        dialogWidth={`${DIALOG_WIDTH.MERCHANT_PORTAL_MEDIUM}px!important`}
        showDialog={showDialog}
        portalName={PORTAL.MERCHANT}
        title={requirement?.process_name}
        content={''}
        noBtnTitle={'Cancel'}
        yesBtnTitle={requirement?.note ? 'Update' : 'Add'}
        extraContent={
          <Box>
            <TTextArea
              fullWidth
              value={value}
              label={requirement?.note ? 'Update Note' : 'Add Note'}
              variant="outlined"
              minRows={3}
              inputProps={{ maxLength: MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH }}
              onChange={handleChange}
            />
            <Box sx={{ mt: 1 }} className="text-small flex-basic-end font-weight-semibold textColor-300">
              {`${MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH - count} character(s) remaining`}
            </Box>
          </Box>
        }
        onConfirm={addNote}
        onCancel={onCancel}
      />
    </Box>
  );
};

export default AddNoteModal;
