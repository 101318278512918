import React, { useEffect, useState } from 'react';
import { Container, Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';
import moment from 'moment';

import { TButton, TCard, colorPalette, theme, TDocumentViewModal, FilePreviewType } from '../myde-react-components';
import { fontStyle } from '../../styles/style';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { selectCommunications } from '../../redux/feature/communication/communicationSlice';
import { SpecificMerchantCommunicationType, MerchantDocumentDataType } from '../../types/communicationTypes';
import { useTheme } from '../../providers/custom-theme-provider';
import ListModal from '../common/modals/ListModal';
import { IdNameType } from '../../types/commonTypes';
import { DEFAULT_TABLE_OPTIONS } from '../myde-react-components/src/constants/constants';
import { DEFAULT_PAGINATION_LIMIT } from '../../constants/constants';
import { PORTAL } from '../myde-react-components/src/constants/portal';

export interface StyleProps {
  currentThemes: Theme;
}

// styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  title: {
    ...fontStyle.fontSize.font24,
    ...fontStyle.fontWeight.font600,
  },
  titleContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  btnStyle: {
    position: 'absolute',
    top: 16,
    left: 2,
  },
  recipientsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  recipientName: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font400,
    color: colorPalette.typoText.lighten1,
  },
  labelStyle: {
    ...fontStyle.fontSize.font16,
    ...fontStyle.fontWeight.font600,
    color: colorPalette.typoText.lighten1,
  },
  titleText: {
    ...fontStyle.fontSize.font12,
    ...fontStyle.fontWeight.font500,
    color: colorPalette.typoText.darken2,
  },
  tableCommunicationContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },
  searchBox: {
    paddlingTop: '0.5em',
  },
  linkText: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  previewImageContainer: {
    height: 'auto',
    width: '100%',
    objectFit: 'fill',
    margin: 'auto',
    padding: '24px',
  },
  previewImage: {
    height: 'auto',
    width: '100%',
  },
  recipientText: {
    ...fontStyle.fontWeight.font600,
    ...fontStyle.fontSize.font16,
    color: colorPalette.typoText.darken3,
  },
}));

const CommunicationDetail = () => {
  // constants
  const noOfRecipientsToShow = 5;
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { routeTo } = useRouter();
  const { t } = useTranslation('common');
  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    rowsPerPage: [DEFAULT_PAGINATION_LIMIT],
    textLabels: {
      body: {
        noMatch: 'No Documents Found',
      },
    },
  };

  // state variables
  const [communication, setCommunication] = useState({} as SpecificMerchantCommunicationType);
  const [document, setDocument] = useState({} as MerchantDocumentDataType);
  const [showDocDialog, setShowDocDialog] = useState(false);
  const [showRecipients, setShowRecipients] = useState(false);
  const [restRecipientList, setRestRecipientList] = useState<Array<IdNameType>>([]);
  const [recipients, setRecipients] = useState<Array<IdNameType>>([]);

  // use selectors
  const { specificCommunicationData } = useSelector(selectCommunications);

  // methods
  const goBack = () => {
    routeTo(ROUTES.COMMUNICATIONS);
  };

  const getFileStatusText = (status: boolean) => {
    if (status) {
      return t('fileStatusText');
    } else {
      return '';
    }
  };

  const customBodyRender = (value: any) => {
    return <Box className="text-large font-weight-semibold textColor-200">{value}</Box>;
  };

  const selectDoc = (tableMeta: any) => {
    const docList = communication?.document_list;
    const rowIndex: number = tableMeta?.rowIndex || 0;
    const doc: MerchantDocumentDataType = docList[rowIndex] || ({} as MerchantDocumentDataType);
    setDocument(doc);
  };

  const handleClose = () => {
    setShowDocDialog(false);
    setShowRecipients(false);
    setDocument({} as MerchantDocumentDataType);
  };

  const handleChange = (value: string) => {
    if (value) {
      const filtered = restRecipientList.filter((item) => {
        return item?.name?.indexOf(value) > -1;
      });
      setRestRecipientList(filtered);
    } else {
      if (communication?.id) {
        const data = getUpdatedRecipientsList(communication) || [];
        setRestRecipientList(data);
      }
    }
  };

  const getUpdatedRecipientsList = (data: SpecificMerchantCommunicationType) => {
    if (data?.id) {
      const users = data?.account_id_name_list;
      const tags = data?.tag_id_name_list;
      let result: IdNameType[] = [];
      if (users?.length > 0 || tags?.length > 0) {
        result = [...users, ...tags];
      } else {
        result = [];
      }
      return result;
    }
  };

  // use effects
  useEffect(() => {
    if (specificCommunicationData?.id) {
      setCommunication(specificCommunicationData);
    }
  }, [specificCommunicationData]);

  useEffect(() => {
    if (document?.doc_id) {
      setShowDocDialog(true);
    } else {
      setShowDocDialog(false);
    }
  }, [document]);

  useEffect(() => {
    if (communication?.id) {
      const data = getUpdatedRecipientsList(communication) || [];
      setRecipients(data);
      setRestRecipientList(data);
    }
  }, [communication]);

  // Table Column
  const columns = [
    {
      name: 'display_name',
      label: 'File Name',
      options: {
        customBodyRender: (value: string, tableMeta: any) => {
          return (
            <Box
              className={clsx(classes.linkText, 'cursorPointer text-large font-weight-semibold')}
              onClick={() => {
                selectDoc(tableMeta);
              }}
            >
              {value ? value : '-'}
            </Box>
          );
        },
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: { customBodyRender },
    },
    {
      name: 'year_month',
      label: 'Year/Month',
      options: {
        customBodyRender: (value: any, _tableMeta: any) => {
          return (
            <Box className="text-large font-weight-regular textColor-200">
              {value ? moment(value)?.format('YYYY MMMM') : '-'}
            </Box>
          );
        },
      },
    },
  ];

  const getDocument = () => {
    const previewDoc: FilePreviewType = {
      name: document?.doc_name,
      preview: document?.signed_url,
      size: document?.size_in_kb,
      type: document?.mime_type,
    };
    return previewDoc;
  };

  return (
    <Container sx={{ p: 5 }}>
      <TCard sx={{ p: 5 }}>
        <Grid container spacing={2} className={classes.titleContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box className={classes.btnStyle}>
              <TButton
                variant="text"
                icon={<ArrowBackIosNewIcon className="textColor-200" />}
                size="small"
                onClick={goBack}
              />
            </Box>
            <Box sx={{ ml: 5 }} className="textColor-200 text-h2 font-weight-semibold">
              Message Details
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ mt: theme.spacing(5) }}>
              <Box sx={{ pb: theme.spacing(1) }} className="textColor-200 text-large font-weight-semibold">
                To:
              </Box>

              <Box className={classes.recipientsContainer}>
                {recipients?.map((item, index) => (
                  <>
                    {index < noOfRecipientsToShow && (
                      <Box
                        key={index}
                        sx={{ mr: 1 }}
                        className={clsx('textColor-300 text-medium font-weight-regular', {
                          ['textCapitalize']: item?.type,
                        })}
                      >
                        {item?.name}
                        {index < recipients?.length - 1 && <span>,</span>}
                      </Box>
                    )}
                  </>
                ))}
                {recipients?.length > noOfRecipientsToShow && (
                  <Box
                    className={clsx('cursorPointer textColor-300 text-medium font-weight-regular', classes.linkText)}
                    onClick={() => setShowRecipients(true)}
                  >
                    +{recipients?.length - noOfRecipientsToShow} {'\t'} more
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Box sx={{ pb: theme.spacing(1) }} className="textColor-200 text-large font-weight-semibold">
                Title:
              </Box>
              <Box className="textColor-300 text-medium font-weight-regular">{communication?.title}</Box>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Box sx={{ pb: theme.spacing(1) }} className="textColor-200 text-large font-weight-semibold">
                Message:
              </Box>
              <Box className="textColor-300 text-medium font-weight-regular">
                <div dangerouslySetInnerHTML={{ __html: communication?.message || '-' }}></div>
              </Box>
            </Box>
            <Box sx={{ mt: 4, mb: 1 }}>
              <Box
                sx={{ pb: theme.spacing(1) }}
                className="textColor-200 text-large font-weight-semibold"
              >{`Files:\t ${getFileStatusText(communication?.save_in_user_repository)}`}</Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className={clsx(classes.tableCommunicationContainer, 'doc-list-table')}>
                  <MUIDataTable title="" data={communication?.document_list} columns={columns} options={options} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TDocumentViewModal
          open={showDocDialog}
          file={getDocument()}
          portalName={PORTAL.MERCHANT}
          closeModal={handleClose}
        />
        <ListModal
          open={showRecipients}
          hasSelectAll={false}
          hasMore={false}
          hasCheckboxSelection={false}
          list={restRecipientList}
          dialogTitle={'Recipients'}
          maxWidth={'xs'}
          onClose={handleClose}
          onSearch={handleChange}
        />
      </TCard>
    </Container>
  );
};

export default CommunicationDetail;
