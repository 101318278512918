import React from 'react';
import { Grid } from '@mui/material';
import { INVITE_STATUS } from '../../../constants/constants';
import IdProcessActions from '../../userTabs/components/IdProcessActions';

interface TabHeaderProps {
  title: string;
  status: string;
  applicationId: string;
  processId: number;
  accountReviewStatus: string;
  section: string;
  customIdProcess?: boolean;
}

const TabHeader = ({
  title,
  status,
  applicationId,
  processId,
  accountReviewStatus,
  section,
  customIdProcess,
}: TabHeaderProps) => {
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
        <Grid item className="text-h2 font-weight-semibold">
          {!customIdProcess ? (
            <span className="textColor-200">{title}</span>
          ) : (
            <span className="textColor-200">{title} </span>
          )}
          {!customIdProcess && status && (
            <span className="textStatusColor-100 textCapitalize">{`: ${status.toLowerCase()}`}</span>
          )}
        </Grid>
        {[INVITE_STATUS.PENDING_REVIEW, INVITE_STATUS.UNDER_REVIEW].includes(accountReviewStatus) && (
          <Grid item>
            <IdProcessActions applicationId={applicationId} processId={processId} section={section} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TabHeader;
