import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { selectApplicant } from '../../../redux/feature/applicant/applicantSlice';
import { colorPalette } from '../../myde-react-components';
import clsx from 'clsx';

// Styles
const useStyles = makeStyles({
  pendingBox: {
    borderTop: `1px solid ${colorPalette.border.card}`,
    borderBottom: `1px solid ${colorPalette.border.card}`,
  },
});

// Main Component
const AccountStatusMsg = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  // Redux Values
  const { accountStatusMsg } = useSelector(selectApplicant);

  // Use Effects
  useEffect(() => {
    if (accountStatusMsg) {
      setMessage(accountStatusMsg);
    }
  }, [accountStatusMsg]);

  // HTML
  return (
    <Box
      sx={{ py: 1 }}
      className={clsx({
        [classes.pendingBox]: true,
        ['text-medium textColor-300']: true,
      })}
    >
      {message}
    </Box>
  );
};

export default AccountStatusMsg;
